import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import {
  deleteFromS3,
  fileToBase64URL,
  uploadPictureToS3,
} from '../../utils/misc';

import BounceLoader from 'react-spinners/BounceLoader';
import Card from 'components/Card';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import InputMask from 'react-input-mask';
import api from '../../utils/api';
import chevronLeftSVG from '../../assets/icons/chevron-left.svg';
import colors from '../../assets/resources/colors';
import swal from '@sweetalert/with-react';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';

const MarketplaceStoreState = {
  CREATED: 'CREATED',
  ERROR: 'ERROR',
  NOT_CREATED: 'NOT_CREATED',
  LOADING: 'LOADING',
  IDLE: 'IDLE',
};

function NewPayStore() {
  const { REACT_APP_TITLE } = process.env;
  const formRef = useRef(null);

  const history = useHistory();
  const { id } = useParams();

  const { REACT_APP_COOKIES_USER_ID } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userId = cookies[REACT_APP_COOKIES_USER_ID];

  const [store, setStore] = useState();
  const [stores, setStores] = useState([]);
  const [countries, setCountries] = useState([]);
  const [logo, setLogo] = useState({
    url: '',
    oldUrl: '',
    image: null,
  });
  const [userPhoto, setUserPhoto] = useState({
    url: '',
    oldUrl: '',
    image: null,
  });
  const [createMarketplaceStore, setCreateMarketplaceStore] = useState(
    MarketplaceStoreState.IDLE
  );

  useEffect(() => {
    document.title = `${id ? 'Editar' : 'Nuevo'} comercio | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    getCountries();
    if (id) gePaymentStore(id);

    if (!id) {
      api.stores
        .getNamesAndUsers()
        .then((res) => setStores(res.data))
        .catch((err) => {
          toast.warning(
            '[SERVER_ERROR] No se pudo obtener la info. de los comercios'
          );
          console.error(err);
        });
    }
  }, []);

  const gePaymentStore = (id) => {
    api.pay
      .getOne(id)
      .then((response) => {
        const _tmpStore = response.data[0];

        setStore(_tmpStore);
        setLogo({
          url: _tmpStore.logo,
        });
        setUserPhoto({
          url: _tmpStore.user.photo,
        });

        setCreateMarketplaceStore(
          _tmpStore.user.store !== null
            ? MarketplaceStoreState.CREATED
            : MarketplaceStoreState.NOT_CREATED
        );
      })
      .catch((err) => {
        toast.warning(
          '[SERVER_ERROR] No se pudo obtener la info. del comercio'
        );
        console.error(err);
      });
  };

  const getCountries = () => {
    api.countries
      .getAll()
      .then((res) => setCountries(res.data))
      .catch((err) => {
        toast.warning(
          '[SERVER_ERROR] No se pudo obtener la info. de los paises'
        );
        console.error(err);
      });
  };

  const getStore = (id) => {
    api.stores
      .getOne(id)
      .then((response) => {
        const _tmpStore = response.data[0];

        setStore(_tmpStore);
        setLogo({
          url: _tmpStore.image,
        });
        setUserPhoto({
          url: _tmpStore.user.photo,
        });
      })
      .catch((err) => {
        toast.warning(
          '[SERVER_ERROR] No se pudo obtener la info. del comercio'
        );
        console.error(err);
      });
  };

  const handleSubmit = () => {
    formRef.current && formRef.current.handleSubmit();
  };

  const addPicture = async (event, type) => {
    const file = event.target.files[0];

    if (!file) return;

    if (file.size / 1024 >= 2048) {
      toast.warning('Error: La imagen supera el peso maximo permitido');
      event.target.value = '';
    } else {
      const prevURL = logo.url;

      if (type === 'logo') {
        setLogo({
          url: '',
          oldUrl: prevURL,
          image: {
            base64: await fileToBase64URL(file),
            type: file.type,
            name: file.name,
          },
        });
      }

      if (type === 'user') {
        setUserPhoto({
          url: '',
          oldUrl: prevURL,
          image: {
            base64: await fileToBase64URL(file),
            type: file.type,
            name: file.name,
          },
        });
      }
    }
  };

  const findStates = (id) => {
    return (countries?.find((el) => el.countriesId == id) ?? {})?.states ?? [];
  };

  const findCities = (countriesId, statesId) => {
    return (
      (findStates(countriesId)?.find((el) => el.statesId == statesId) ?? {})
        ?.cities ?? []
    );
  };

  async function saveStore(values, { setSubmitting }) {
    toast.info('Procesando, espere un momento...');

    const {
      usersId,
      commission,
      name,
      bankAccount,
      accountType,
      bankName,
      registry,
      discount,
      active,
      password,
      firstname,
      lastname,
      email,
      phone,
      photo,
      dui,
      nit,
      fk_countriesId,
      fk_statesId,
      fk_citiesId,
      address,
      accountHolder,
      representative,
      duiAccountHolder,
      duiRepresentative,
    } = values;
    let body = {};

    setSubmitting(true);

    if (!usersId) {
      body = {
        password,
        active: '1',
        firstname,
        lastname,
        email,
        phone,
        photo,
        dui,
        nit,
        fk_rolesId: '2',
        createdBy: +userId,
        photo: userPhoto.image
          ? await uploadPictureToS3(
              userPhoto.image.base64,
              userPhoto.image.type,
              userPhoto.image.name
            )
          : userPhoto.url,
        payment_stores: {
          commission: +commission,
          name,
          bankAccount,
          accountType,
          bankName,
          registry,
          discount,
          active,
          fk_countriesId,
          fk_statesId,
          fk_citiesId,
          address,
          accountHolder,
          representative,
          duiAccountHolder,
          duiRepresentative,
          logo: logo.image
            ? await uploadPictureToS3(
                logo.image.base64,
                logo.image.type,
                logo.image.name
              )
            : logo.url,
          createdBy: +userId,
        },
      };
    } else {
      body = {
        ...values,
        commission: +commission,
        ...(id ? { updatedBy: +userId } : { createdBy: +userId }),
        ...(!id ? { fk_usersId: +values.usersId } : {}),
        logo: logo.image
          ? await uploadPictureToS3(
              logo.image.base64,
              logo.image.type,
              logo.image.name
            )
          : logo.url,
      };
    }

    if (logo?.oldUrl?.trim()) {
      deleteFromS3(logo.oldUrl.substring(logo.oldUrl.lastIndexOf('/') + 1));
    }

    if (userPhoto?.oldUrl?.trim()) {
      deleteFromS3(
        userPhoto.oldUrl.substring(userPhoto.oldUrl.lastIndexOf('/') + 1)
      );
    }

    if (!id && !usersId) {
      api.users
        .createUserWithStore(body)
        .then((res) => {
          toast.success('Comercio creado con exito');
          setSubmitting(false);
          history.push('/admin/payment-stores');
        })
        .catch((err) => {
          toast.warning('Error al crear el comercio');
          console.error(err);
          setSubmitting(false);
        });
    } else if (id) {
      api.pay
        .update(id, body)
        .then((_) => {
          toast.success('Comercio actualizado con exito');
          history.push('/admin/payment-stores');
        })
        .catch((err) => {
          toast.warning('[SERVER_ERROR] No se pudo actualizar el comercio');
          console.error(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      api.pay
        .create(body)
        .then((_) => {
          toast.success('Comercio creado con exito');
          history.push('/admin/payment-stores');
        })
        .catch((err) => {
          toast.warning('[SERVER_ERROR] No se pudo crear el comercio');
          console.error(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  }

  const restorePassword = () => {
    swal({
      buttons: ['Cancelar', 'Restaurar'],
      title: 'Cambiar contraseña',
      content: {
        element: 'input',
        attributes: {
          type: 'password',
        },
      },
    })
      .then((newPassword) => {
        if (newPassword) {
          api.users
            .updateOne(store.user.usersId, {
              password: newPassword,
              updatedBy: userId,
            })
            .then(() => {
              history.goBack();
              toast.success('Contraseña actualizada');
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const linkMarketplaceStore = () => {
    toast.info('Creando comercio en Marketplace');
    setCreateMarketplaceStore(MarketplaceStoreState.LOADING);

    api.stores
      .create({
        name: store?.name ?? '',
        registry: store?.registry ?? '',
        representative: store?.representative ?? '',
        image: store?.logo ?? '',
        fk_usersId: +store?.fk_usersId,
        createdBy: userId,
        description: '',
        isVerified: false,
        available: true,
        freeDelivery: false,
        active: false,
        isDraft: false,
      })
      .then((res) => {
        const _storeId = res.data.storesId;

        toast.success('Comercio creado en Marketplace');
        setCreateMarketplaceStore(MarketplaceStoreState.CREATED);

        if (_storeId) history.push(`/admin/stores/${_storeId}/edit`);
        else history.goBack();
      })
      .catch((err) => {
        toast.warning('Error al crear el comercio');
        setCreateMarketplaceStore(MarketplaceStoreState.ERROR);
        console.error(err);
      });
  };

  return (
    <div className="content">
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        validationSchema={Yup.object().shape({
          name: Yup.string(),
          bankAccount: Yup.string().nullable(),
          bankName: Yup.string().nullable(),
          accountType: Yup.string().nullable(),
          commission: Yup.number().min(0, 'El porcentaje debe ser mayor a 0'),
          discount: Yup.number().nullable(),
          active: Yup.boolean(),
        })}
        validate={(values) => {
          let errors = {};

          if (!values.newStore) {
            if (!values.usersId) errors.usersId = 'Debe seleccionar una tienda';
          }

          return errors;
        }}
        initialValues={{
          name: store?.name ?? '',
          bankAccount: store?.bankAccount ?? '',
          accountType: store?.accountType ?? '',
          bankName: store?.bankName ?? '',
          registry: store?.registry ?? '',
          commission: store?.commission ?? 0,
          discount: store?.discount ?? 0,
          active: store?.active ?? true,
          usersId: +store?.fk_usersId ?? '',
          accountHolder: store?.accountHolder ?? '',
          duiAccountHolder: store?.duiAccountHolder ?? '',
          representative: store?.representative ?? '',
          duiRepresentative: store?.duiRepresentative ?? '',
          address: store?.address || store?.stores_branches?.[0]?.address || '',
          fk_countriesId: store?.fk_countriesId ?? null,
          fk_statesId: store?.fk_statesId ?? null,
          fk_citiesId: store?.fk_citiesId ?? null,
          heading: null,

          newStore: !store?.fk_usersId,
          storeSearched: null,

          password: '',
          // active: '1',
          firstname: store?.user?.firstname ?? '',
          lastname: store?.user?.lastname ?? '',
          email: store?.user?.email ?? '',
          phone: store?.user?.phone ?? '',
          dui: store?.user?.dui ?? '',
          nit: store?.user?.nit ?? '',
        }}
        onSubmit={saveStore}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <div className="row">
              <div className="col-6 col-md-3 col-xl-2">
                <Link to="/admin/payment-stores">
                  <button
                    type="button"
                    className="w-100 bg-beige tuyo-btn px-3 py-2 mr-2 rounded-pill border d-inline-flex align-items-center justify-content-center"
                  >
                    <img src={chevronLeftSVG} alt="" className="mr-2" />
                    Regresar
                  </button>
                </Link>
              </div>
              <div className="col-6 col-md-3 order-md-3 col-xl-2">
                <button
                  disabled={isSubmitting}
                  type="button"
                  className="w-100 bg-purple tuyo-btn px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                  onClick={handleSubmit}
                >
                  {isSubmitting ? (id ? 'Actualizando' : 'Creando') : 'Guardar'}
                  <BounceLoader color="#fff" loading={isSubmitting} size="18" />
                </button>
              </div>
              <div className="col-12 col-md-6 col-xl-8 mt-3 mt-md-0">
                <h3 className="text-dark-blue font-size-2x font-weight-bold text-center">
                  {id ? 'Editar comercio' : 'Nuevo comercio'}
                </h3>
              </div>
            </div>
            <div className="row mt-3 mt-md-4">
              <div className="col-12">
                {(id && store) || !id ? (
                  <>
                    <Card className="p-3 p-md-4">
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <p className="mb-2">Logo del comercio</p>
                          <button
                            type="button"
                            className="w-100 add-picture-btn"
                            style={{
                              backgroundSize:
                                logo.image || logo.url ? 'cover' : '',
                              border: '2px solid rgba(0,0,0,0.16)',
                              paddingTop: '100%',
                              borderRadius: '1rem',
                              backgroundImage:
                                logo.url || logo.image
                                  ? `url('${logo?.image?.base64 || logo?.url}')`
                                  : '',
                            }}
                          >
                            <input
                              accept=".jpg,.jpeg,.png"
                              id="logo"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(evt) => addPicture(evt, 'logo')}
                            />
                            <label
                              htmlFor="logo"
                              className="mb-0"
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </button>
                          <small className="font-style-italic text-gray mt-1">
                            Peso máximo por imagen: 2mb
                          </small>
                        </div>

                        <div className="col-12 col-md-9">
                          <div className="row h-100 align-items-center">
                            <div className="col-12 col-md-4">
                              <label htmlFor="name" className="d-block">
                                Nombre
                              </label>
                              <Field
                                name="name"
                                type="text"
                                placeholder="Tuyo App"
                                className="w-100 rounded p-2"
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="text-red"
                              />
                            </div>

                            <div className="col-12 col-md-4">
                              <label htmlFor="bankAccount" className="d-block">
                                Cuenta bancaria
                              </label>
                              <Field
                                name="bankAccount"
                                type="text"
                                placeholder="1234567890"
                                className="w-100 rounded p-2"
                              />
                              <ErrorMessage
                                name="bankAccount"
                                component="span"
                                className="text-red"
                              />
                            </div>

                            <div className="col-12 col-md-4">
                              <label htmlFor="accountType" className="d-block">
                                Tipo de cuenta
                              </label>
                              <Field
                                name="accountType"
                                type="text"
                                placeholder="Cuenta corriente"
                                className="w-100 rounded p-2"
                              />
                              <ErrorMessage
                                name="accountType"
                                component="span"
                                className="text-red"
                              />
                            </div>

                            <div className="col-12 col-md-4">
                              <label htmlFor="commission" className="d-block">
                                Comisión
                              </label>
                              <Field
                                name="commission"
                                type="text"
                                placeholder="10"
                                className="w-100 rounded p-2"
                              />
                              <ErrorMessage
                                name="commission"
                                component="span"
                                className="text-red"
                              />
                            </div>

                            <div className="col-12 col-md-4">
                              <label htmlFor="bankName" className="d-block">
                                Nombre del banco
                              </label>
                              <Field
                                name="bankName"
                                type="text"
                                placeholder="Banco Agricola"
                                className="w-100 rounded p-2"
                              />
                              <ErrorMessage
                                name="bankName"
                                component="span"
                                className="text-red"
                              />
                            </div>

                            <div className="col-12 col-md-4">
                              <label htmlFor="active" className="d-block">
                                Comercio activo
                              </label>
                              <Field name="active">
                                {({ field, form: { setFieldValue } }) => (
                                  <input
                                    checked={field.value}
                                    {...field}
                                    type="checkbox"
                                    onChange={(event) =>
                                      setFieldValue(
                                        'active',
                                        event.target.checked
                                      )
                                    }
                                  />
                                )}
                              </Field>
                            </div>

                            <div className="col-12 col-md-4">
                              <label
                                htmlFor="accountHolder"
                                className="d-block"
                              >
                                Titular de la cuenta
                              </label>
                              <Field
                                name="accountHolder"
                                type="text"
                                placeholder="Juan Pérez"
                                className="w-100 rounded p-2"
                              />
                              <ErrorMessage
                                name="accountHolder"
                                component="span"
                                className="text-red"
                              />
                            </div>

                            <div className="col-12 col-md-4">
                              <label htmlFor="registry" className="d-block">
                                Registro
                              </label>
                              <Field
                                name="registry"
                                type="text"
                                placeholder="12351-2"
                                className="w-100 rounded p-2"
                              />
                              <ErrorMessage
                                name="registry"
                                component="span"
                                className="text-red"
                              />
                            </div>

                            <div className="col-12 col-md-4">
                              <label
                                htmlFor="duiAccountHolder"
                                className="d-block"
                              >
                                DUI del titular de la cuenta
                              </label>
                              <Field
                                name="duiAccountHolder"
                                type="text"
                                placeholder="12345678-9"
                                className="w-100 rounded p-2"
                              >
                                {({ field, form: { setFieldValue } }) => (
                                  <InputMask
                                    {...field}
                                    onChange={(event) =>
                                      setFieldValue(
                                        'duiAccountHolder',
                                        event.target.value
                                      )
                                    }
                                    className="p-2 rounded w-100"
                                    maskChar={null}
                                    mask="99999999 9"
                                  />
                                )}
                              </Field>
                              <ErrorMessage
                                name="duiAccountHolder"
                                component="span"
                                className="text-red"
                              />
                            </div>

                            {!id && (
                              <div className="col-12 col-md-4">
                                <label htmlFor="newStore" className="d-block">
                                  Nuevo comercio
                                </label>
                                <Field name="newStore">
                                  {({ field, form: { setFieldValue } }) => (
                                    <input
                                      checked={field.value}
                                      {...field}
                                      type="checkbox"
                                      onChange={(event) =>
                                        setFieldValue(
                                          'newStore',
                                          event.target.checked
                                        )
                                      }
                                    />
                                  )}
                                </Field>
                              </div>
                            )}

                            {/* <div className="col-12 col-md-4">
                              <label htmlFor="discount" className="d-block">
                                Descuento
                              </label>
                              <Field name="discount">
                                {({ field, form: { setFieldValue } }) => (
                                  <>
                                    <input
                                      {...field}
                                      type="text"
                                      placeholder="10"
                                      className="w-100 rounded p-2"
                                      onChange={(event) => {
                                        setFieldValue(
                                          'discount',
                                          validPctg(event.target.value)
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name="discount"
                                      component="span"
                                      className="text-red"
                                    />
                                  </>
                                )}
                              </Field>
                            </div> */}

                            {!id && values.newStore == false && (
                              <div className="col-12 col-md-6">
                                <label
                                  htmlFor="storeSearched"
                                  className="d-block"
                                >
                                  Tienda ligada
                                </label>
                                <div className="w-full row">
                                  <div className="col-8">
                                    <input
                                      className="w-100"
                                      onChange={(event) => {
                                        const { value } = event.target;
                                        const store = stores.find((store) =>
                                          store.name
                                            .toLowerCase()
                                            .includes(value.toLowerCase())
                                        );

                                        const storesId = store?.storesId;

                                        if (storesId) {
                                          setFieldValue(
                                            'storeSearched',
                                            storesId
                                          );
                                        }
                                      }}
                                      type="text"
                                    />
                                    <Field
                                      name="storeSearched"
                                      type="text"
                                      as="select"
                                      className="w-100 rounded p-2"
                                      onChange={(event) => {
                                        const { value } = event.target;

                                        setFieldValue('storeSearched', value);
                                      }}
                                    >
                                      <option value="">
                                        Seleccione una tienda
                                      </option>
                                      {stores.map((store) => (
                                        <option
                                          key={store.storesId}
                                          value={store.storesId}
                                        >
                                          {store.name}
                                        </option>
                                      ))}
                                    </Field>
                                  </div>
                                  <div className="col-3">
                                    <button
                                      type="button"
                                      className="bg-purple tuyo-btn p-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                                      onClick={(evt) => {
                                        evt.preventDefault();
                                        if (values.storeSearched)
                                          getStore(+values.storeSearched);
                                      }}
                                    >
                                      Completar
                                    </button>
                                  </div>
                                </div>
                                <ErrorMessage
                                  name="usersId"
                                  component="span"
                                  className="text-red"
                                />
                              </div>
                            )}

                            {id && (
                              <div className="col-12 col-md-4">
                                <a className="" onClick={restorePassword}>
                                  Cambiar contraseña
                                </a>
                              </div>
                            )}

                            {createMarketplaceStore !==
                              MarketplaceStoreState.CREATED &&
                              id && (
                                <div className="col-12 col-md-4 mt-3">
                                  <button
                                    type="button"
                                    className="w-100 bg-light-gray tuyo-btn px-4 py-2 rounded font-weight-bold d-flex justify-content-around align-items-center"
                                    onClick={linkMarketplaceStore}
                                  >
                                    Crear comercio en Marketplace
                                    <BounceLoader
                                      color="#000"
                                      loading={
                                        createMarketplaceStore ==
                                        MarketplaceStoreState.LOADING
                                      }
                                      size="18"
                                    />
                                  </button>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </Card>

                    <Card className="p-3 p-md-4 mt-3">
                      <div className="row">
                        <div className="col-12 col-md-4 mt-2">
                          <label htmlFor="representative" className="d-block">
                            Representante
                          </label>
                          <Field
                            name="representative"
                            type="text"
                            placeholder="Juan Pérez"
                            className="w-100 rounded p-2"
                          />
                          <ErrorMessage
                            name="representative"
                            component="span"
                            className="text-red"
                          />
                        </div>

                        <div className="col-12 col-md-4 mt-2">
                          <label
                            htmlFor="duiRepresentative"
                            className="d-block"
                          >
                            DUI del representante
                          </label>
                          <Field
                            name="duiRepresentative"
                            type="text"
                            placeholder="12345678-9"
                            className="w-100 rounded p-2"
                          >
                            {({ field, form: { setFieldValue } }) => (
                              <InputMask
                                {...field}
                                onChange={(event) =>
                                  setFieldValue(
                                    'duiRepresentative',
                                    event.target.value
                                  )
                                }
                                className="p-2 rounded w-100"
                                maskChar={null}
                                mask="99999999 9"
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="duiRepresentative"
                            component="span"
                            className="text-red"
                          />
                        </div>

                        <div className="col-12 col-md-4 mt-2">
                          <label htmlFor="address" className="d-block">
                            Dirección
                          </label>
                          <Field
                            name="address"
                            type="text"
                            placeholder="Calle falsa 123"
                            className="w-100 rounded p-2"
                          />
                          <ErrorMessage
                            name="address"
                            component="span"
                            className="text-red"
                          />
                        </div>

                        <div className="col-12 col-md-4 mt-2">
                          <label htmlFor="fk_countriesId" className="d-block">
                            País
                          </label>
                          <Field
                            name="fk_countriesId"
                            type="text"
                            as="select"
                            className="w-100 rounded p-2"
                          >
                            <option value="">Seleccione una país</option>
                            {countries.map((country) => (
                              <option
                                key={country.countriesId}
                                value={country.countriesId}
                              >
                                {country.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="fk_countriesId"
                            component="span"
                            className="text-red"
                          />
                        </div>

                        <div className="col-12 col-md-4 mt-2">
                          <label htmlFor="fk_statesId" className="d-block">
                            Departamento
                          </label>
                          <Field
                            name="fk_statesId"
                            type="text"
                            as="select"
                            className="w-100 rounded p-2"
                          >
                            <option value="">Seleccione un departamento</option>
                            {(findStates(values.fk_countriesId) ?? []).map(
                              (state) => (
                                <option
                                  key={state.statesId}
                                  value={state.statesId}
                                >
                                  {state.name}
                                </option>
                              )
                            )}
                          </Field>
                          <ErrorMessage
                            name="fk_statesId"
                            component="span"
                            className="text-red"
                          />
                        </div>

                        <div className="col-12 col-md-4 mt-2">
                          <label htmlFor="fk_citiesId" className="d-block">
                            Ciudad
                          </label>
                          <Field
                            name="fk_citiesId"
                            type="text"
                            as="select"
                            className="w-100 rounded p-2"
                          >
                            <option value="">Seleccione una ciudad</option>
                            {(
                              findCities(
                                values.fk_countriesId,
                                values.fk_statesId
                              ) ?? []
                            ).map((city) => (
                              <option key={city.citiesId} value={city.citiesId}>
                                {city.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="fk_citiesId"
                            component="span"
                            className="text-red"
                          />
                        </div>
                      </div>
                    </Card>

                    {/* {values.newStore == true && ( */}
                    <Card className="p-3 p-md-4 mt-3">
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <p className="mb-2">Foto de usuario</p>
                          <button
                            type="button"
                            className="w-100 add-picture-btn"
                            style={{
                              backgroundSize:
                                userPhoto.image || userPhoto.url ? 'cover' : '',
                              border: '2px solid rgba(0,0,0,0.16)',
                              paddingTop: '100%',
                              borderRadius: '1rem',
                              backgroundImage:
                                userPhoto.url || userPhoto.image
                                  ? `url('${
                                      userPhoto?.image?.base64 || userPhoto?.url
                                    }')`
                                  : '',
                            }}
                          >
                            <input
                              readOnly={id}
                              accept=".jpg,.jpeg,.png"
                              id="userPhoto"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(evt) => addPicture(evt, 'user')}
                            />
                            <label
                              htmlFor="userPhoto"
                              className="mb-0"
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </button>
                          <small className="font-style-italic text-gray mt-1">
                            Peso máximo por imagen: 2mb
                          </small>
                        </div>
                        <div className="col-12 col-md-9 row">
                          <div className="col-12 col-md-4">
                            <label htmlFor="firstname" className="d-block">
                              Nombre
                            </label>
                            <Field
                              readOnly={id}
                              name="firstname"
                              type="text"
                              placeholder="Nombre"
                              className="w-100 rounded p-2"
                            />
                            <ErrorMessage
                              name="firstname"
                              component="span"
                              className="text-red"
                            />
                          </div>

                          <div className="col-12 col-md-4">
                            <label htmlFor="lastname" className="d-block">
                              Apellido
                            </label>
                            <Field
                              readOnly={id}
                              name="lastname"
                              type="text"
                              placeholder="Apellido"
                              className="w-100 rounded p-2"
                            />
                            <ErrorMessage
                              name="lastname"
                              component="span"
                              className="text-red"
                            />
                          </div>

                          <div className="col-12 col-md-4">
                            <label htmlFor="password" className="d-block">
                              Contraseña
                            </label>
                            <Field
                              readOnly={id}
                              name="password"
                              type="text"
                              placeholder="Contraseña"
                              className="w-100 rounded p-2"
                            />
                            <ErrorMessage
                              name="password"
                              component="span"
                              className="text-red"
                            />
                          </div>

                          <div className="col-12 col-md-4">
                            <label htmlFor="email" className="d-block">
                              Email
                            </label>
                            <Field
                              readOnly={id}
                              name="email"
                              type="text"
                              placeholder="Email"
                              className="w-100 rounded p-2"
                            />
                            <ErrorMessage
                              name="email"
                              component="span"
                              className="text-red"
                            />
                          </div>

                          <div className="col-12 col-md-4">
                            <label htmlFor="phone" className="d-block">
                              Teléfono
                            </label>
                            <Field
                              readOnly={id}
                              name="phone"
                              type="text"
                              placeholder="Teléfono"
                              className="w-100 rounded p-2"
                            />
                            <ErrorMessage
                              name="phone"
                              component="span"
                              className="text-red"
                            />
                          </div>

                          <div className="col-12 col-md-4">
                            <label htmlFor="dui" className="d-block">
                              DUI
                            </label>
                            <Field
                              readOnly={id}
                              name="dui"
                              type="text"
                              placeholder="Teléfono"
                              className="w-100 rounded p-2"
                            >
                              {({ field, form: { setFieldValue } }) => (
                                <InputMask
                                  {...field}
                                  onChange={(event) =>
                                    setFieldValue('dui', event.target.value)
                                  }
                                  className="p-2 rounded w-100"
                                  maskChar={null}
                                  mask="99999999 9"
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="dui"
                              component="span"
                              className="text-red"
                            />
                          </div>

                          <div className="col-12 col-md-4">
                            <label htmlFor="nit" className="d-block">
                              NIT
                            </label>
                            <Field
                              readOnly={id}
                              name="nit"
                              type="text"
                              placeholder="Teléfono"
                              className="w-100 rounded p-2"
                            >
                              {({ field, form: { setFieldValue } }) => (
                                <InputMask
                                  {...field}
                                  onChange={(event) =>
                                    setFieldValue('nit', event.target.value)
                                  }
                                  className="p-2 rounded w-100"
                                  maskChar={null}
                                  mask="9999 999999 999 9"
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="nit"
                              component="span"
                              className="text-red"
                            />
                          </div>

                          {values.usersId && id && (
                            <div className="col-12 col-md-4 d-flex justify-content-center">
                              <a
                                className="mt-1"
                                href={
                                  '/admin/users/' + values.usersId + '/edit'
                                }
                              >
                                Ver/Editar usuario
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </Card>
                    {/* )} */}
                  </>
                ) : (
                  <div
                    className="p-5 m-5 d-flex justify-content-center align-items-center"
                    style={{
                      flexGrow: '1',
                    }}
                  >
                    <ClimbingBoxLoader color={colors.green} size="25" />
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default NewPayStore;
