import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import swal from '@sweetalert/with-react';

import Card from '../../components/Card';
import SubCategoriesTable from '../../components/SubCategoriesTable/SubCategoriesTable';

import api from '../../utils/api';
import { fileToBase64URL, uploadPictureToS3 } from '../../utils/misc';

import chevronLeftSVG from '../../assets/icons/chevron-left.svg';

function NewCategory() {
  const formRef = useRef(null);
  const history = useHistory();

  /* URL params */
  const { id: productsCategoriesId } = useParams();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  const [category, setCategory] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [pictures, setPictures] = useState({ image: null });
  const [picturesToDelete, setPicturesToDelete] = useState([]);
  const [hasNotSubCategories, setHasNotSubCategories] = useState(false);
  const [subCategories, setSubCategories] = useState();
  const [subCategoriesToDelete, setSubCategoriesToDelete] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState();

  useEffect(() => {
    document.title = `${
      productsCategoriesId ? 'Editar' : 'Nueva'
    } categoria | ${REACT_APP_TITLE}`;
  }, []);

  // Get product types
  useEffect(() => {
    api
      .findAll(
        '/productstypes?filter=' +
          JSON.stringify({
            attributes: ['productsTypesId', 'name'],
          })
      )
      .then((response) => {
        setProductTypes([...response.data]);
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener lista de tipos de producto');
      });
  }, []);

  useEffect(() => {
    if (productTypes?.length > 0) {
      if (!selectedProductType) {
        setSelectedProductType(productTypes[0]?.productsTypesId);
      }

      if (category) {
        setSelectedProductType(category?.fk_productsTypesId);
      }
    }
  }, [productTypes, category]);

  // Get category
  useEffect(() => {
    if (productsCategoriesId) {
      api.categories
        .getOne(productsCategoriesId)
        .then((res) => {
          setCategory(res.data);
        })
        .catch((err) => {
          console.error(err);
          toast.warning(
            '[SERVER ERROR]: No se pudo obtener los datos de esta categoria'
          );
        });
    }
  }, []);

  useEffect(() => {
    if (category) {
      setPictures({ image: { URL: category.image } });
    }
  }, [category]);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const addPicture = async (field, event) => {
    const file = event.target.files[0];
    if (file.size / 1024 >= 2048) {
      toast.warning('Error: La imagen supera el peso maximo permitido');
      event.target.value = '';
    } else {
      const prevURL = pictures[field] ? pictures[field].URL : null;
      prevURL && setPicturesToDelete([...picturesToDelete, prevURL]);
      file &&
        setPictures({
          ...pictures,
          [field]: {
            base64: await fileToBase64URL(file),
            type: file.type,
            name: file.name,
          },
        });
    }
  };

  const createSubCategories = async (response, subCategory) => {
    let body = {
      name: subCategory.name,
      name_en: subCategory.name_en,
      active: '1',
      fk_categoriesId: productsCategoriesId
        ? productsCategoriesId
        : response.productsCategoriesId,
    };

    subCategory.image.base64
      ? (body = {
          ...body,
          image: await uploadPictureToS3(
            subCategory.image.base64,
            subCategory.image.type,
            subCategory.image.name
          ),
        })
      : response.image
      ? (body = { ...body, image: response.image })
      : (body = { ...body, image: subCategory.image });

    subCategory.productsSubcategoriesId
      ? (body = { ...body, updatedBy: userID })
      : (body = { ...body, createdBy: userID });

    return subCategory.productsSubcategoriesId
      ? api.subcategories.updateOne(subCategory.productsSubcategoriesId, body)
      : api.subcategories.createOne([body]);
  };

  const deleteSubCategories = async (subCategoriesToDelete) => {
    if (subCategoriesToDelete.length > 0) {
      for (const subCategoryID of subCategoriesToDelete) {
        await api.subcategories
          .deleteOne(subCategoryID)
          .then(() => {})
          .catch((err) => {
            console.error(err);
            toast.warning(
              '[SERVER_ERROR] No se pudo eliminar una subcategoria'
            );
          });
      }
    }
  };

  const createCategory = async (formValues) => {
    const { active, description, name, name_en } = formValues;

    const { image } = pictures;

    let body = {
      name: name,
      name_en: name_en,
      active: active ? 1 : 0,
      description: description,
      fk_productsTypesId: selectedProductType,
    };

    productsCategoriesId
      ? (body = {
          ...body,
          image: image.base64
            ? await uploadPictureToS3(image.base64, image.type, image.name)
            : image.URL,
          updatedBy: userID,
        })
      : (body = {
          ...body,
          image:
            image.URL != 'empty'
              ? await uploadPictureToS3(image.base64, image.type, image.name)
              : '',
          createdBy: userID,
        });

    setIsSaving(true);
    const categoryAction = productsCategoriesId
      ? api.categories
          .updateOne(productsCategoriesId, body)
          .then((res) => {
            subCategories.forEach((subCategory) => {
              createSubCategories(res.data, subCategory);
            });
          })
          .then(() => {
            deleteSubCategories(subCategoriesToDelete);
          })
          .then(() => {
            toast.success('¡Categoria actualizada!');
            history.push('/admin/categories');
          })
      : api.categories
          .create(body)
          .then((res) => {
            subCategories.forEach((subCategory) => {
              createSubCategories(res.data, subCategory);
            });
          })
          .then(() => {
            toast.success('¡Categoria creada!');
            history.push('/admin/categories');
          });

    categoryAction.catch((err) => {
      console.error(err);
      setIsSaving(false);
      toast.warning(`[SERVER_ERROR] No se pudo crear la categoria`);
    });
  };

  const updateFreeShipping = (freeShipping) => {
    api.categories
      .updateOne(productsCategoriesId, {
        freeDelivery: freeShipping,
        updatedBy: userID,
      })
      .then(() => {
        subCategories.map((subCategory) => {
          api.subcategories.updateOne(subCategory.productsSubcategoriesId, {
            freeDelivery: freeShipping,
            updatedBy: userID,
          });
        });
      })
      .then(() => {
        toast.success(
          `Se ${freeShipping ? 'activo' : 'desactivo'} el envio gratis`
        );
      })
      .catch((err) => {
        toast.warning(
          `[SERVER_ERROR] Hubo un problema al ${
            freeShipping ? 'activar' : 'desactivar'
          } el envio gratis`
        );
        console.error(err);
      });
  };

  const selectShipping = () => {
    swal({
      buttons: {
        cancel: 'Cancelar',
        deactivate: { text: 'Desactivar', value: false },
        activate: { text: 'Activar', value: true },
      },
      dangerMode: true,
      icon: 'warning',
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">
            Envio Gratis
          </p>
          <p className="mt-3">¿Que desea hacer?</p>
        </div>
      ),
    }).then((res) => {
      res
        ? updateFreeShipping(true)
        : res == false
        ? updateFreeShipping(false)
        : swal.close();
    });
  };

  return (
    <div className="content">
      <div className="row">
        <div className="col-6 col-md-3 col-xl-2">
          <Link to="/admin/categories">
            <button
              type="button"
              className="w-100 bg-beige tuyo-btn px-3 py-2 mr-2 rounded-pill border d-inline-flex align-items-center justify-content-center"
            >
              <img src={chevronLeftSVG} alt="" className="mr-2" />
              Regresar
            </button>
          </Link>
        </div>
        <div className="col-12 col-md-3 col-xl-8">
          <div className="row">
            <div className="col-12 col-md-9">
              <h3 className="text-dark-blue font-size-2x font-weight-bold text-center">
                {productsCategoriesId ? 'Editar categoria' : 'Nueva categoria'}
              </h3>
            </div>
            {productsCategoriesId ? (
              <div className="col-12 col-md-3 mb-3">
                <button
                  type="button"
                  className="w-100 bg-purple tuyo-btn px-3 py-2 mr-2 rounded text-light font-weight-bold d-inline-flex align-items-center justify-content-center"
                  onClick={selectShipping}
                >
                  Envio Gratis
                </button>
              </div>
            ) : (
              <div className="col-12 col-md-3 mt-3" />
            )}
          </div>
        </div>
        <div className="col-12 col-md-3 order-md-3 col-xl-2">
          <button
            disabled={isSaving}
            type="button"
            className="w-100 bg-purple tuyo-btn px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
            onClick={handleSubmit}
          >
            {isSaving
              ? productsCategoriesId
                ? 'Actualizando'
                : 'Creando'
              : 'Guardar'}
            <BounceLoader color="#fff" loading={isSaving} size="18" />
          </button>
        </div>
      </div>
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            validationSchema={Yup.object().shape({
              name: Yup.string().trim().required('Campo requerido'),
            })}
            initialValues={{
              name: productsCategoriesId ? category?.name : '',
              name_en: productsCategoriesId ? category?.name_en : '',
              active: productsCategoriesId ? category?.active == 1 : true,
              description: productsCategoriesId ? category?.description : '',
            }}
            onSubmit={(values) => {
              createCategory(values);
            }}
          >
            {() => (
              <Form>
                <Card className="p-3 p-md-4">
                  <div className="row">
                    <div className="col-12 col-md-3 mt-3 mt-md-0 text-center">
                      <p className="mb-2">Imagen</p>
                      <button
                        type="button"
                        className="w-100 add-picture-btn"
                        style={{
                          backgroundSize: pictures.image ? 'cover' : '',
                          border: '2px solid rgba(0,0,0,0.16)',
                          paddingTop: '100%',
                          borderRadius: '1rem',
                          backgroundImage: pictures.image
                            ? "url('" +
                              (pictures.image.base64 || pictures.image.URL) +
                              "')"
                            : '',
                        }}
                      >
                        <input
                          accept=".jpg,.jpeg,.png"
                          id="image"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={(event) => addPicture('image', event)}
                        />
                        <label
                          htmlFor="image"
                          className="mb-0"
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            right: '0',
                            bottom: '0',
                          }}
                        />
                      </button>
                      <small className="font-style-italic text-gray mt-1">
                        Peso máximo por imagen: 2mb
                      </small>
                    </div>
                    <div className="col-12 col-md-9">
                      <div className="row h-100 align-items-center">
                        <div className="col-12 col-md-8">
                          <label htmlFor="name" className="d-block">
                            Nombre
                          </label>
                          <Field
                            name="name"
                            text="text"
                            placeholder="Herramientas"
                            className="w-100 rounded p-2"
                          />
                          <ErrorMessage
                            name="name"
                            component="span"
                            className="text-red"
                          />
                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column justify-content-center align-items-center">
                          <label className="d-block" htmlFor="active">
                            Activa
                          </label>
                          <Field name="active" type="checkbox" id="active" />
                        </div>
                        <div className="col-12 col-md-8">
                          <label htmlFor="name_en" className="d-block">
                            Nombre en ingles
                          </label>
                          <Field
                            name="name_en"
                            text="text"
                            placeholder="Tools"
                            className="w-100 rounded p-2"
                          />
                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column justify-content-center align-items-center">
                          <label
                            className="d-block"
                            htmlFor="hasNotSubCategories"
                          >
                            Sin subcategorias
                          </label>
                          <input
                            id="hasNotSubCategories"
                            type="checkbox"
                            onChange={() => {
                              setHasNotSubCategories(!hasNotSubCategories);
                            }}
                          />
                        </div>
                        <div className="col-12">
                          <label htmlFor="productType" className="d-block">
                            Tipo de producto
                          </label>
                          <select
                            name="productType"
                            id="productType"
                            className="w-100 p-2 rounded"
                            value={selectedProductType}
                            onChange={(event) => {
                              setSelectedProductType(
                                parseInt(event.target.value)
                              );
                            }}
                          >
                            {productTypes.map((type) => (
                              <option value={type?.productsTypesId}>
                                {type?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 mt-3 mt-md-0">
                      <label htmlFor="description" className="d-block">
                        Descripcion
                      </label>
                      <Field
                        placeholder=""
                        name="description"
                        as="textarea"
                        className="rounded w-100 p-2"
                        rows="5"
                      />
                    </div>
                  </div>
                </Card>
              </Form>
            )}
          </Formik>
          {!hasNotSubCategories && (
            <div className="row mt-3 mt-md-4">
              <div className="col-12 my-3">
                <div
                  className="w-100"
                  style={{ borderBottom: '2px dashed var(--light-gray)' }}
                />
              </div>
              <div style={{ overflowX: 'auto' }} className="col-12">
                <p className="text-dark-blue font-weight-bold font-size-125x text-center">
                  Subcategorías
                </p>
                <SubCategoriesTable
                  editable
                  categoryID={productsCategoriesId}
                  onSubCategoryChange={(subCategories) =>
                    setSubCategories(subCategories)
                  }
                  onSubCategoryDelete={(id) =>
                    setSubCategoriesToDelete([...subCategoriesToDelete, id])
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewCategory;
