import React, { useEffect, useState } from 'react';
import { BounceLoader } from 'react-spinners';

import Card from 'components/Card';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import api from 'utils/api';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import Table from 'components/Table/Table';
import { useHistory } from 'react-router';
import jwtDecode from 'jwt-decode';
import { ROLES } from 'utils/misc';
import { parseDate } from 'utils/misc';
import swal from 'sweetalert';

function UserCreate() {
  const history = useHistory();
  const { REACT_APP_TITLE, REACT_APP_COOKIES_USER_ID, REACT_APP_COOKIES_JWT } =
    process.env;
  const [cookies] = useCookies([
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  ]);
  const userId = cookies[REACT_APP_COOKIES_USER_ID];

  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);

  const tableProps = {
    options: {
      id: 'usersId',
      pageSize: 10,
    },
    columns: [
      {
        text: 'Nombre',
        key: 'name',
        className: 'text-center',
      },
      {
        key: 'username',
        text: 'Usuario',
        className: 'text-center',
      },
      {
        text: 'Email',
        key: 'email',
        className: 'text-center',
      },
      {
        text: 'Rol',
        key: 'role',
        className: 'text-center text-uppercase',
      },
      {
        text: 'Activo',
        key: 'active',
        isCustom: true,
        render: (value) => (
          <div className="d-flex justify-content-center">
            {value == '1' ? (
              <span className="material-icons text-tuyo-green">
                check_circle
              </span>
            ) : (
              <span className="material-icons text-gray">cancel</span>
            )}
          </div>
        ),
      },
      {
        text: 'Creado el',
        key: 'createdAt',
        isCustom: true,
        className: 'text-center',
        render: (value) => parseDate(value),
      },
      {
        text: 'Acciones',
        isCustom: true,
        render: (_, rowId) => {
          return (
            <div className="d-flex justify-content-center">
              <button
                className="mx-2"
                onClick={(evt) => {
                  evt.stopPropagation();
                  restorePassword(rowId);
                }}
              >
                <span className="material-icons">lock_reset</span>
              </button>
            </div>
          );
        },
      },
    ],
  };

  const restorePassword = (usersId) => {
    swal({
      buttons: ['Cancelar', 'Restaurar'],
      title: 'Cambiar contraseña',
      content: {
        element: 'input',
      },
    })
      .then((newPassword) => {
        if (newPassword) {
          api.users
            .updateOne(usersId, {
              password: newPassword,
              updatedBy: +userId,
            })
            .then(() => toast.success('Contraseña actualizada'));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const formikValidate = (values) => {
    const errors = {};

    if (!values.email) errors.email = 'El email es requerido';
    if (!values.password) errors.password = 'La contraseña es requerida';
    if (!values.role) errors.role = 'El rol es requerido';
    if (!values.gender) errors.gender = 'El genero es requerido';
    if (!values.firstname) errors.firstname = 'El nombre es requerido';
    if (!values.lastname) errors.lastname = 'El apellido es requerido';

    return errors;
  };

  const formikOnSubmit = (values, { setSubmitting, resetForm }) => {
    const createdBy = cookies[REACT_APP_COOKIES_USER_ID];
    setSubmitting(true);

    api.users
      .createInternalUser({
        ...values,
        active: '1',
        verify: 1,
        photo:
          'https://compressed-media.s3.us-east-2.amazonaws.com/1610988802356-nophoto.png.png',
        fk_rolesId: +values.role,
        createdBy,
      })
      .then(() => {
        resetForm();
        getInteralUsers();
        toast.success('Usuario creado correctamente');
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error al crear el usuario');
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    document.title = `Crear usuario | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    getRoles();
    getInteralUsers();
  }, []);

  const getRoles = () => {
    const _jwt = cookies[REACT_APP_COOKIES_JWT];
    const JWT = jwtDecode(_jwt);
    const role = JWT.user.role.name;

    api.roles
      .getAll()
      .then((res) => {
        let tmpRoles = res.data
          ?.filter((r) => (role == ROLES.ROOT ? true : r.name != 'root'))
          ?.map((role) => ({
            id: role.rolesId,
            value: role.name,
          }));

        setRoles(tmpRoles);
      })
      .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));
  };

  const getInteralUsers = () =>
    api.users
      .getInternalUsers()
      .then((res) => {
        let tmpUsers = res.data?.map((user) => ({
          ...user,
          name: `${user.firstname} ${user.lastname}`,
          role: user.role.name,
        }));

        setUsers(tmpUsers);
      })
      .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));

  return (
    <div className="content">
      <div>
        <h3 className="text-dark-blue font-size-2x font-weight-bold col-12">
          Usuarios TUYO
        </h3>
        <div className="row">
          <div className="col">
            <Card className="p-md-4 mb-4">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  password: '',
                  firstname: '',
                  lastname: '',
                  email: '',
                  gender: '',
                  role: '',
                }}
                validate={formikValidate}
                onSubmit={formikOnSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="row">
                      <div className="col-12 col-md-6 mt-2">
                        <label htmlFor="firstname" className="d-block">
                          Nombre
                        </label>
                        <Field
                          className="w-100 p-2 rounded"
                          name="firstname"
                          type="text"
                          placeholder="Ej: Paula"
                        />
                        <ErrorMessage name="firstname">
                          {(msg) => (
                            <small style={{ color: 'red' }}>{msg}</small>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <label htmlFor="lastname" className="d-block">
                          Apellido
                        </label>
                        <Field
                          className="w-100 p-2 rounded"
                          name="lastname"
                          type="text"
                          placeholder="Ej: Gonu"
                        />
                        <ErrorMessage name="lastname">
                          {(msg) => (
                            <small style={{ color: 'red' }}>{msg}</small>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <label htmlFor="email" className="d-block">
                          Correo electrónico
                        </label>
                        <Field
                          className="w-100 p-2 rounded"
                          name="email"
                          type="text"
                          placeholder="Ej: paula@tuyoapp.com"
                        />
                        <ErrorMessage name="email">
                          {(msg) => (
                            <small style={{ color: 'red' }}>{msg}</small>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <label htmlFor="gender">Genero</label>
                        <Field
                          className="p-2 rounded w-100"
                          as="select"
                          name="gender"
                        >
                          <option value="">Seleccione una opcion</option>
                          <option value="M">Masculino</option>
                          <option value="F">Femenino</option>
                          <option value="O">Prefiero no decirlo</option>
                        </Field>
                        <ErrorMessage name="gender">
                          {(msg) => (
                            <small style={{ color: 'red' }}>{msg}</small>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <label htmlFor="role">Rol</label>
                        <Field
                          className="p-2 rounded w-100"
                          as="select"
                          name="role"
                        >
                          <option value="">Seleccione una opcion</option>
                          {roles.map((role) => (
                            <option key={role.id} value={role.id}>
                              {role.value.toUpperCase().replaceAll('-', ' ')}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name="role">
                          {(msg) => (
                            <small style={{ color: 'red' }}>{msg}</small>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <label htmlFor="password" className="d-block">
                          Contraseña
                        </label>
                        <Field
                          className="w-100 p-2 rounded"
                          name="password"
                          type="text"
                          placeholder="Ej: TUYO2021"
                        />
                        <ErrorMessage name="password">
                          {(msg) => (
                            <small style={{ color: 'red' }}>{msg}</small>
                          )}
                        </ErrorMessage>
                      </div>

                      <div className="col-12 mt-3">
                        <button className="w-100 bg-green tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around">
                          Crear usuario
                          <BounceLoader
                            color="#fff"
                            loading={isSubmitting}
                            size="18"
                          />
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
            {users.length > 0 && (
              <Card className="p-md-4">
                <Table
                  options={tableProps.options}
                  columns={tableProps.columns}
                  data={users}
                  pageSize={10}
                  onRowClick={(user) => {
                    history.push(`/admin/users/${user}/edit`);
                  }}
                />
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserCreate;
