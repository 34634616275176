import React, { useEffect, useRef, useState } from 'react';
import { fileToBase64URL } from 'utils/misc';

import Modal from '../Modal';

import pictureSVG from 'assets/icons/image-regular.svg';
import addSVG from 'assets/icons/add-black.svg';
import eyeSVG from 'assets/icons/eye-regular.svg';
import editSVG from 'assets/icons/edit-regular.svg';
import altAddSVG from 'assets/icons/add-gray.svg';
import deleteSVG from 'assets/icons/delete.svg';

import api from '../../utils/api';

import { toast } from 'react-toastify';

const subCategoryTemplate = {
  name: 'Todo en',
  name_en: 'All in',
  active: '1',
  image: ''
}

function SubCategoriesTable({ categoryID, editable, onSubCategoryChange, onSubCategoryDelete }) {
  const [subCategories, setSubCategories] = useState([])
  const [inPreviewPicture, setInPreviewPicture] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() =>{
    if(categoryID) {
      api.subcategories.getByCategory(categoryID)
      .then(res => {
        setSubCategories(res.data);
      })
      .catch(err => console.error(err));
    }else{
      setSubCategories([subCategoryTemplate]);
    }
  }, [])

  useEffect(() =>{
    if (onSubCategoryChange) {
      onSubCategoryChange(subCategories)
    }
  }, [subCategories])

  const addSubCategory = () => {
    setSubCategories([
      ...subCategories,
      { ...subCategoryTemplate }
    ])
  };

  const deleteSubCategory = (index) => {
    setSubCategories([
      ...subCategories.slice(0, index),
      ...subCategories.slice(index + 1)
    ]);

    const IDSubCategoryToDelete = subCategories[index].productsSubcategoriesId;
    onSubCategoryDelete(IDSubCategoryToDelete);
  };

  const setSubCategoryValue = (value, categoryIndex, key) => {
    setSubCategories([
      ...subCategories.slice(0, categoryIndex),
      {
        ...subCategories[categoryIndex],
        [key]: value
      },
      ...subCategories.slice(categoryIndex + 1),
    ]);
  };

  const addSubCategoryPicture = async (event, index) => {
    const file = event.target.files[0];
    if (file.size / 1024 >= 2048) {
      toast.warning('Error: La imagen supera el peso maximo permitido');
      event.target.value = ''
    }else{
      const onEditSubCategory = { ...subCategories[index] };
      setSubCategories([
        ...subCategories.slice(0, index),
        {
          ...onEditSubCategory,
          image: {
            oldURL: onEditSubCategory
              ? onEditSubCategory.url || onEditSubCategory.oldURL
              : null,
            base64: await fileToBase64URL(file),
            type: file.type,
            name: file.name
          },
        },
        ...subCategories.slice(index + 1)
      ]);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const previewSubCategoryPicture = (image) => {
    setInPreviewPicture(image.base64 ? image.base64 : image);
    setIsModalOpen(true);
  };


  return (
    subCategories &&
    <div style={{ overflowX: 'auto' }}>
      <div className="d-flex">
        <div className="d-inline-block mt-3" style={{ overflow: 'auto', width: '100%', scrollBehavior: 'smooth', paddingBottom: '0.5rem' }}>
          <div className="d-flex bg-beige py-2" style={{ borderBottomLeftRadius: '0.5rem', borderTopLeftRadius: '0.5rem', overflow: 'show' }}>
            <div className="d-inline-block mt-3 subcategories-table" style={{ overflow: 'auto', width: '100%' }}>
              <div
                className="d-flex bg-beige py-2 font-weight-bold text-black"
                style={{ borderBottomRightRadius: '0.5rem', borderTopRightRadius: '0.5rem' }}
              >
                <div
                  style={{ flexBasis: '30%', flexGrow: '1' }}
                  className="d-flex justify-content-center align-items-center"
                >
                  Nombre
                </div>
                <div
                  style={{ flexBasis: '30%', flexGrow: '1' }}
                  className="px-2 d-flex justify-content-center align-items-center"
                >
                  Nombre en inglés
                </div>
                <div
                  style={{ flexBasis: '10%', flexGrow: '1' }}
                  className="px-2 d-flex align-items-center justify-content-center"
                >
                  <img src={pictureSVG} alt="" style={{ height: '16px' }} />
                </div>
                {
                  <div style={{ flexBasis: '10%' }} className="justify-content-center d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      className="p-1 opt-btn"
                      data-tip="Añadir subcategoria"
                      onClick={addSubCategory}
                    >
                      <img src={addSVG} alt="" style={{ height: '16px' }} />
                    </button>
                  </div>
                }
              </div>
              {
                subCategories.map((subCategory, index) =>(
                  <div key={'categories-' + index} className="py-2 d-flex w-100">
                    <div style={{ flexBasis: '30%', flexGrow: '1' }} className="px d-flex align-items-center-2">
                      {
                        <input
                          type="text"
                          className="rounded p-1 w-100"
                          value={subCategory.name}
                          onChange={(e) => { setSubCategoryValue(e.target.value, index, 'name') }}
                        />
                      }
                    </div>
                    <div style={{ flexBasis: '30%', flexGrow: '1' }} className="px-2 d-flex align-items-center">
                      {
                        <input
                          type="text"
                          className="rounded p-1 w-100"
                          value={subCategory.name_en}
                          onChange={(e) => { setSubCategoryValue(e.target.value, index, 'name_en') }} 
                        />
                      }
                    </div>
                    <div style={{ flexBasis: '10%', flexGrow: '1' }} className="justify-content-center d-flex justify-content-center align-items-center">
                      {
                        subCategory.image &&
                        <button
                          type="button"
                          className="p-2 opt-btn"
                          data-tip="Ver imagen"
                          onClick={() => previewSubCategoryPicture(subCategory.image)}
                        >
                          <img src={eyeSVG} alt="" style={{ height: '16px' }} />
                        </button>
                      }
                      <button
                        type="button"
                        className="p-2 opt-btn ml-1"
                        data-tip={`${subCategory.image ? 'Cambiar' : 'Subir'} imagen`}
                        style={{ position: 'relative' }}>
                        <img src={subCategory.image ? editSVG : altAddSVG} alt="" style={{ height: '16px' }} />
                        <input 
                          accept=".jpg,.jpeg,.png" 
                          id={'new-pic' + index} 
                          type="file" 
                          style={{ display: 'none' }} 
                          onChange={(e) => addSubCategoryPicture(e, index)} 
                        />
                        <label
                          htmlFor={'new-pic' + index}
                          className="mb-0 d-flex align-items-center"
                          style={{ cursor: 'pointer', position: 'absolute', top: '0', left: '0', right: '0', bottom: '0' }}/>
                      </button>
                    </div>
                    {
                      <div style={{ flexBasis: '10%', flexShrink: '1' }} className="d-flex justify-content-center align-items-center">
                        <button
                          type="button"
                          className="p-2 opt-btn"
                          data-tip="Eliminar subcategoria"
                          onClick={() => deleteSubCategory(index)}
                        >
                          <img src={deleteSVG} alt="" style={{ height: '16px' }} />
                        </button>
                      </div>
                    }
                  </div>
                ))
              }
              {
                isModalOpen &&
                <Modal onCloseRequest={closeModal}>
                  <div 
                    className="w-100 d-flex justify-content-center" 
                    style={{
                      backgroundImage: 'url(\'' + inPreviewPicture + '\')',
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      height: '72vh'
                    }} 
                  />
                </Modal>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubCategoriesTable;