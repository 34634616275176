import React, { useEffect } from 'react';

import Card from '../../components/Card';
import ReactTooltip from 'react-tooltip';
import ReportGenerator from 'components/ReportGenerator';
import Table from 'components/TableV2/Table';
import api from 'utils/api';
import download from 'downloadjs';
import { useHistory } from 'react-router-dom';

function PayStoresList() {
  const { REACT_APP_TITLE } = process.env;
  const history = useHistory();

  const printQR = (src) => {
    const img = document.createElement('img');
    const qr = window.open(
      '',
      '_blank',
      'location=yes,height=579,width=386,scrollbars=yes,status=yes'
    );

    img.src = src;
    img.style.width = '100%';
    img.style.height = '100%';
    img.style.display = 'block';
    img.style.objectFit = 'contain';
    img.style.marginBottom = 'auto';

    qr.document.open();

    qr.document.write(`<html lang="en-US"><head><title>Ticket</title>
    <link href="https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    <style>
    body{font-family: Muli,sans-serif;}
    @page {
      size: 102mm 152mm; 
      margin: 5mm 5mm 5mm 5mm; 
    }
    </style>
    </head><body>`);

    qr.document.write(img.outerHTML);
    qr.document.write('</body></html>');

    qr.document.close();

    qr.onload = () => {
      setTimeout(() => {
        qr.focus();
        qr.print();
        setTimeout(function () {
          qr.close();
        }, 500);
      }, 100);
    };
  };

  const tableProps = {
    rowId: 'paymentStoresId',
    baseURL: '/paymentstores',
    baseFilter: {
      attributes: [
        'paymentStoresId',
        'name',
        'logo',
        'qr',
        'commission',
        'active',
      ],
      order: [['createdAt', 'desc']],
      include: [
        {
          association: 'user',
          attributes: ['username', 'firstname', 'lastname'],
        },
      ],
    },
    pageSize: 10,
    columns: [
      {
        title: '',
        objectProperty: 'logo',
        searchable: false,
        sortable: false,
        middleware: (value) => {
          return (
            <img
              src={value}
              alt="logo"
              className="img-fluid img-thumbnail rounded mx-auto d-block fit-image"
              style={{ width: 50, height: 50 }}
            />
          );
        },
      },
      {
        title: 'ID',
        objectProperty: 'paymentStoresId',
        searchable: true,
        searchType: 'number-equal',
        sortable: true,
      },
      {
        title: 'Nombre Usuario',
        objectProperty: 'user',
        searchable: true,
        searchType: 'text',
        gatewayObjectProperty: '$user.firstname$',
        middleware: (user) => user?.firstname,
      },
      {
        title: 'Apellido Usuario',
        objectProperty: 'user',
        searchable: true,
        searchType: 'text',
        gatewayObjectProperty: '$user.lastname$',
        middleware: (user) => user?.lastname,
      },
      {
        title: 'Usuario',
        objectProperty: 'user',
        searchable: true,
        searchType: 'text',
        gatewayObjectProperty: '$user.username$',
        middleware: (user) => user?.username,
      },
      {
        title: 'Tienda',
        objectProperty: 'name',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Activo',
        objectProperty: 'active',
        columnType: 'boolean',
      },
      {
        title: 'Comision',
        objectProperty: 'commission',
        searchable: true,
        searchType: 'text',
        middleware: (value) => `${value}%`,
      },
      {
        objectProperty: 'qr',
        middleware: (value) => (
          <>
            <button
              data-tip="Imprimir"
              onClick={(event) => {
                event.stopPropagation();
                printQR(value);
              }}
            >
              <i className="material-icons">print</i>
            </button>
            <ReactTooltip effect="solid" />
          </>
        ),
      },
    ],
  };

  const onSubmit = (values, { setSubmitting }) => {
    api.paymentvouchers
      .download({
        start: values.startDate,
        end: values.endDate,
        status: 'COMPLETED',
      })
      .then((res) => {
        download(
          res.data,
          `Liquidacion- ${values.startDate} - ${values.endDate}.csv`,
          res.headers['content-type']
        );
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    document.title = `Comercios | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Comercios
          </h3>
        </div>
        <div className="col-12 col-md-3 d-flex">
          <button
            type="button"
            className="w-100 bg-purple tuyo-btn px-3 py-2 mr-2 rounded text-light font-weight-bold d-inline-flex align-items-center justify-content-center"
            onClick={() => {
              history.push('/admin/payment-stores/new');
            }}
          >
            Nuevo comercio
          </button>
        </div>
      </div>
      <ReportGenerator className="mt-4" onSubmit={onSubmit} />
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            <Table
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
              onRowClick={(category) => {
                history.push(
                  `/admin/payment-stores/${category.paymentStoresId}/edit`
                );
              }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PayStoresList;
