import React from 'react';
import BarLoader from 'react-spinners/BarLoader';

import Choice from '../Choice/Choice';
import Status from '../Status';

import { STATUS } from '../../assets/resources/status';
import colors from 'assets/resources/colors';
import { formatPrice, formatAmount } from 'utils/misc';

function Cell({
  columnDef,
  rowId,
  data,
  rowStatus,
  rowIsSaving,
  hideChoices,
  setIDRowSaving,
  onChoiceChange,
  updateOrderStatus,
}) {
  function genCell() {
    if (columnDef.isImage)
      return (
        <div>
          <button
            style={{
              backgroundImage: "url('" + data + "')",
              cursor: columnDef.middleware ? 'zoom-in' : 'default',
            }}
            className="product-thumbnail "
            onClick={(e) => {
              if (!e) var e = window.event;
              e.cancelBubble = true;
              if (e.stopPropagation) e.stopPropagation();

              if (columnDef?.middleware) columnDef.middleware(data, rowId);
            }}
          />
        </div>
      );

    if (columnDef.isCheckbox)
      return (
        <button
          onClick={(e) => {
            if (!e) var e = window.event;
            e.cancelBubble = true;
            if (e.stopPropagation) e.stopPropagation();
            if (columnDef?.middleware) columnDef.middleware(data, rowId);
          }}
        >
          <span className="transition-all text-indigo-dark material-icons-round">
            {data == true ? 'check_box' : 'check_box_outline_blank'}
          </span>
        </button>
      );

    if (columnDef.isBoolean)
      return (
        <span
          className={`transition-all material-icons-round ${
            data == true
              ? 'text-tuyo-green'
              : columnDef.isDestructive
              ? 'text-red'
              : 'text-gray'
          }`}
        >
          {data == true ? 'check_circle' : 'check_circle_outline'}
        </span>
      );

    if (columnDef.isChoice)
      return !columnDef.hidden(rowStatus) ? (
        rowIsSaving ? (
          <div className="w-100 h-100 d-flex align-items-center justify-content-center">
            <BarLoader size="10" loading={true} color={colors.green} />
          </div>
        ) : (
          <Choice
            hide={hideChoices}
            text="Aprobar"
            onChange={(val) => {
              setIDRowSaving(rowId);
              onChoiceChange(rowId, val);
            }}
          />
        )
      ) : rowStatus === STATUS.ORDER_ACCEPTED ? (
        rowIsSaving ? (
          <div className="w-100 h-100 d-flex align-items-center justify-content-center">
            <BarLoader size="10" loading={rowIsSaving} color={colors.green} />
          </div>
        ) : (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setIDRowSaving(rowId);
              updateOrderStatus(rowId, STATUS.READY);
            }}
            className="tuyo-btn bg-green rounded text-white font-weight-bold py-1 px-2 w-100"
          >
            Orden lista
          </button>
        )
      ) : (
        ''
      );

    if (columnDef.isButton)
      return !columnDef.hidden?.(data) ? (
        <button
          onClick={(event) => {
            event.stopPropagation();
            columnDef.onClick(rowId);
          }}
          className={`font-weight-bold bg-light-gray w-100 h-100 rounded py-2 tuyo-btn ${columnDef.className}`}
          type="button"
        >
          {columnDef.buttonText}
        </button>
      ) : null;

    if (columnDef.isStatus) return <Status className="w-100" value={data} />;

    if (columnDef.isCurrency) return formatPrice(data);

    if (columnDef.isQty) return formatAmount(data);

    if (columnDef.isCustom) return columnDef.render(data, rowId);

    return data;
  }

  return (
    <td
      key={`${columnDef.key}-${rowId}`}
      className={'py-2 px-1 py-md-3 px-md-2 ' + columnDef.className}
    >
      {genCell()}
    </td>
  );
}

export default Cell;
