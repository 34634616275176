import Card from 'components/Card';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ClimbingBoxLoader } from 'react-spinners';
import { useParams, useHistory } from 'react-router-dom';

import api from 'utils/api';
import colors from 'assets/resources/colors';

import swal from '@sweetalert/with-react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useCookies } from 'react-cookie';
import { formatTaxRanges } from 'utils/misc';
import Table from 'components/Table/Table';

function ShippingCost() {
  const { id } = useParams();
  const history = useHistory();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userId = cookies[REACT_APP_COOKIES_USER_ID];
  const [shippingType, setShippingType] = useState();
  const [costs, setCosts] = useState();

  useEffect(() => {
    document.title = `Costo de servicio | ${REACT_APP_TITLE}`;
  }, []);

  const table = {
    options: { id: 'id' },
    columns: [
      { text: 'Mínimo', key: 'min', className: 'text-center' },
      {
        text: 'Maximo',
        key: 'max',
        className: 'text-center',
        isCustom: true,
        render: (value) => value ?? 'Sin límite',
      },
      {
        text: 'Local',
        key: 'local',
        className: 'text-center',
        isCustom: true,
        render: (value) => (
          <div className="d-flex justify-content-center">
            Pesado: {value.heavy} <br />
            Liviano: {value.car} <br />
            Moto: {value.moto} <br />
          </div>
        ),
      },
      {
        text: 'Nacional',
        key: 'urbano',
        className: 'text-center',
        isCustom: true,
        render: (value) => (
          <div className="d-flex justify-content-center">
            Pesado: {value.heavy} <br />
            Liviano: {value.car} <br />
            Moto: {value.moto} <br />
          </div>
        ),
      },
      {
        text: 'Acciones',
        isCustom: true,
        fullData: true,
        render: (value) => (
          <div className="d-flex justify-content-center">
            <button
              className="material-icons"
              onClick={() =>
                showSwal({
                  initialValues: value,
                  isEdit: true,
                })
              }
            >
              edit
            </button>
            <button
              className="material-icons"
              onClick={() => deleteFee(value.id)}
            >
              delete
            </button>
          </div>
        ),
      },
    ],
  };

  const parseCosts = (costs) =>
    formatTaxRanges(costs).map((cost, i) => ({
      ...cost,
      id: i,
    }));

  const unparseCosts = (costs) =>
    costs.map((cost) => ({
      local: {
        heavy: cost.local.heavy,
        car: cost.local.car,
        moto: cost.local.moto,
      },
      urbano: {
        heavy: cost.urbano.heavy,
        car: cost.urbano.car,
        moto: cost.urbano.moto,
      },
      minimum: cost.minimum,
    }));

  const getData = () => {
    api.shippingtype
      .getOne(id)
      .then(({ data }) => {
        setShippingType(data);
        setCosts(parseCosts(data.costs));
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener la lista negra');
      });
  };

  const deleteFee = (id) => {
    swal({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esta acción',
      icon: 'warning',
      buttons: ['Cancelar', 'Eliminar'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setCosts((c) => c.filter((cost) => cost.id !== id));
      }
    });
  };

  const updateFee = (values, id) => {
    setCosts((c) =>
      parseCosts(c.map((cost) => (cost.id === id ? values : cost)))
    );

    swal('¡Éxito!', 'Tarifa actualizada', 'success');
  };

  const createFee = (values) => {
    setCosts((c) => parseCosts([...c, values]));
    swal('¡Éxito!', 'Tarifa creada', 'success');
  };

  const showSwal = ({ initialValues, isEdit }) => {
    swal({
      title: isEdit ? 'Editar Costo' : 'Crear Costo',
      buttons: false,
      content: (
        <Formik
          initialValues={{
            minimum: initialValues?.minimum ?? 0,
            local: {
              heavy: initialValues?.local?.heavy ?? 0,
              car: initialValues?.local?.car ?? 0,
              moto: initialValues?.local?.moto ?? 0,
            },
            urbano: {
              heavy: initialValues?.urbano?.heavy ?? 0,
              car: initialValues?.urbano?.car ?? 0,
              moto: initialValues?.urbano?.moto ?? 0,
            },
          }}
          validate={(values) => {
            const errors = {};
            if (!(values.minimum >= 0)) errors.minimun = 'Campo requerido';

            if (!(values.local.heavy >= 0)) errors.value = 'Campo requerido';
            if (!(values.local.car >= 0)) errors.value = 'Campo requerido';
            if (!(values.local.moto >= 0)) errors.value = 'Campo requerido';

            if (!(values.urbano.heavy >= 0)) errors.value = 'Campo requerido';
            if (!(values.urbano.car >= 0)) errors.value = 'Campo requerido';
            if (!(values.urbano.moto >= 0)) errors.value = 'Campo requerido';

            return errors;
          }}
          onSubmit={(values) => {
            isEdit ? updateFee(values, initialValues.id) : createFee(values);
          }}
        >
          {() => (
            <Form>
              <div className="form-group">
                <label htmlFor="minimum">Mínimo</label>
                <Field className="form-control" name="minimum" type="number" />
                <ErrorMessage
                  name="minimun"
                  component="span"
                  className="text-danger"
                />
              </div>

              <p>Local</p>
              <hr />

              <div className="form-group">
                <label htmlFor="local.heavy">Pesado</label>
                <Field
                  className="form-control"
                  name="local.heavy"
                  type="number"
                />
                <ErrorMessage
                  name="local"
                  component="span"
                  className="text-danger"
                />
              </div>

              <div className="form-group">
                <label htmlFor="local.car">Liviano</label>
                <Field
                  className="form-control"
                  name="local.car"
                  type="number"
                />
                <ErrorMessage
                  name="local"
                  component="span"
                  className="text-danger"
                />
              </div>

              <div className="form-group">
                <label htmlFor="local.moto">Moto</label>
                <Field
                  className="form-control"
                  name="local.moto"
                  type="number"
                />
                <ErrorMessage
                  name="local"
                  component="span"
                  className="text-danger"
                />
              </div>

              <p>Nacional</p>
              <hr />

              <div className="form-group">
                <label htmlFor="urbano.heavy">Pesado</label>
                <Field
                  className="form-control"
                  name="urbano.heavy"
                  type="number"
                />
                <ErrorMessage
                  name="urbano"
                  component="span"
                  className="text-danger"
                />
              </div>

              <div className="form-group">
                <label htmlFor="urbano.car">Liviano</label>
                <Field
                  className="form-control"
                  name="urbano.car"
                  type="number"
                />
                <ErrorMessage
                  name="urbano"
                  component="span"
                  className="text-danger"
                />
              </div>

              <div className="form-group">
                <label htmlFor="urbano.moto">Moto</label>
                <Field
                  className="form-control"
                  name="urbano.moto"
                  type="number"
                />
                <ErrorMessage
                  name="urbano"
                  component="span"
                  className="text-danger"
                />
              </div>

              <hr />

              <button
                type="submit"
                className="bg-green tuyo-btn px-4 py-2 mt-4 rounded text-light font-weight-bold d-flex align-items-center justify-content-around col-12 "
              >
                Guardar
              </button>
            </Form>
          )}
        </Formik>
      ),
    });
  };

  const updateShippingType = () => {
    swal({
      title: '¿Estás seguro de actualizar los costos?',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        api.shippingtype
          .update(shippingType.shippingTypesId, {
            costs: unparseCosts(costs),
            updatedBy: +userId,
          })
          .then((res) => {
            if (res.status === 200) {
              toast.success('Tipo de envío actualizado');
              getData();
            }
          })
          .catch((err) => {
            toast.error('Error al actualizar el tipo de envío');
          })
          .finally(() => {
            setTimeout(() => {
              history.goBack();
            }, 1000);
          });
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="content">
      <div className="row">
        <h3 className="col-12 col-md-6 text-dark-blue font-size-2x font-weight-bold whitespace-pre-line">
          {`Editar costo de envío\n${shippingType?.title ?? ''}`}
        </h3>
        <div className="col-12 col-md-6 h-10 d-flex">
          <button
            type="button"
            className="w-100 bg-purple tuyo-btn px-3 py-2 mr-2 rounded text-light font-weight-bold d-inline-flex align-items-center justify-content-center"
            onClick={showSwal}
          >
            Nuevo rango
          </button>
          <button
            type="button"
            className="w-100 bg-purple tuyo-btn px-3 py-2 mr-2 rounded text-light font-weight-bold d-inline-flex align-items-center justify-content-center"
            onClick={updateShippingType}
          >
            Guardar
          </button>
        </div>
      </div>
      <Card className="mt-3 mt-md-4">
        {costs ? (
          <Table
            pageSize={10}
            options={table.options}
            columns={table.columns}
            data={costs}
          />
        ) : (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </Card>
    </div>
  );
}

export default ShippingCost;
