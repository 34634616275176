import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import BounceLoader from 'react-spinners/BounceLoader';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import download from 'downloadjs';

import Card from 'components/Card';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';

import api from 'utils/api';

const reportTypes = {
  requests: {
    display: 'Listado de Solicitudes',
    fileName: 'AffiliationRequestsReport',
    HTTPRequestMethod: 'POST',
    endpoint: '/affiliation-request/report',
    showDateSelectors: true,
    showFileTypeSelector: false,
    showStoreSelector: false,
    defaultFileType: 'csv',
  },
};

const openInNewTab = (url) => {
  window.open(url, '_blank').focus();
};

function AffiliationsList() {
  const history = useHistory();
  const { REACT_APP_COOKIES_USER_ID } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  const [tableRemountCount, setTableRemountCount] = useState(0);

  useEffect(() => {
    document.title = `Solicitudes de Afiliación | ${process.env.REACT_APP_TITLE}`;
  }, []);

  const tableProps = {
    rowId: 'affiliationRequestsId',
    baseURL: '/affiliation-request',
    baseFilter: {
      attributes: [
        'affiliationRequestsId',
        'company',
        'firstname',
        'lastname',
        'email',
        'phone',
        'website',
        'social',
        'status',
        'reason',
        'createdAt',
      ],
      order: [
        ['status', 'desc'],
        ['affiliationRequestsId', 'asc'],
      ],
    },
    pageSize: 25,
    columns: [
      {
        title: 'ID',
        objectProperty: 'affiliationRequestsId', // TODO: change this
        searchable: true,
        searchType: 'number-equal',
        sortable: true,
      },
      {
        title: 'Empresa',
        objectProperty: 'company',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Nombre',
        objectProperty: 'firstname',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Apellido',
        objectProperty: 'lastname',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Correo',
        objectProperty: 'email',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Telefono',
        objectProperty: 'phone',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Estado',
        objectProperty: 'status',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Creación',
        objectProperty: 'createdAt',
        searchable: true,
        searchType: 'timestamp',
        sortable: true,
        middleware: (createdAt) => new Date(createdAt).toLocaleString(),
      },
      {
        middleware: (req) =>
          req?.website ? (
            <>
              <button
                data-tip="Ver sitio Web"
                onClick={(event) => {
                  event.stopPropagation();
                  openInNewTab(req.website);
                }}
              >
                <i className="material-icons text-dark-blue">public</i>
              </button>
              <ReactTooltip effect="solid" />
            </>
          ) : null,
      },
      {
        middleware: (req) =>
          req?.social ? (
            <>
              <button
                data-tip="Ver Redes Sociales"
                onClick={(event) => {
                  event.stopPropagation();
                  openInNewTab(req.social);
                }}
              >
                <i className="material-icons text-dark-blue">face</i>
              </button>
              <ReactTooltip effect="solid" />
            </>
          ) : null,
      },
      {
        middleware: (req) =>
          req?.reason ? (
            <>
              <button data-tip={req?.reason} onClick={() => null}>
                <i className="material-icons text-danger">info</i>
              </button>
              <ReactTooltip effect="solid" />
            </>
          ) : null,
      },
    ],
  };

  const [reportType, setReportType] = useState('requests');
  const [reportStartDate, setReportStartDate] = useState();
  const [reportEndDate, setReportEndDate] = useState();
  const [reportFileType, setReportFileType] = useState('pdf');
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  useEffect(() => {
    setReportFileType(reportTypes[reportType].defaultFileType);
  }, [reportType]);

  function generateReport() {
    setIsGeneratingReport(true);

    const selectedReportType = reportTypes[reportType];

    const action =
      selectedReportType.HTTPRequestMethod === 'GET'
        ? api.findAll(selectedReportType.endpoint, { responseType: 'blob' })
        : api.create(
            selectedReportType.endpoint,
            {
              startDate: reportStartDate,
              endDate: reportEndDate,
              ...(selectedReportType.showFileTypeSelector
                ? { type: reportFileType }
                : {}),
            },
            { responseType: 'blob' }
          );

    action
      .then((response) => {
        download(
          response.data,
          `${selectedReportType.fileName} - ${
            selectedReportType.showDateSelectors
              ? `${reportStartDate} to ${reportEndDate}`
              : new Date().toLocaleString()
          }.${reportFileType}`,
          response.headers['content-type']
        );
      })
      .catch((error) => {
        console.error(error);
        toast.warning('[SERVER_ERROR] ' + error);
      })
      .finally(() => {
        setIsGeneratingReport(false);
      });
  }
  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-6">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Solicitudes de Afiliación a Tuyo App
          </h3>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4 mb-4">
            <div className="row">
              <div className="col d-flex flex-column">
                <label htmlFor="report">Tipo de reporte</label>
                <select
                  className="p-2"
                  name="report"
                  id="report"
                  defaultValue={reportType}
                  onChange={(event) => {
                    setReportType(event.target.value);
                  }}
                >
                  {Object.entries(reportTypes).map(([key, value], index) => (
                    <option value={key} key={index}>
                      {value.display}
                    </option>
                  ))}
                </select>
              </div>
              {reportTypes[reportType].showFileTypeSelector && (
                <div className="d-flex flex-column justify-content-center col-1">
                  <div className="d-flex">
                    <input
                      onChange={(event) => {
                        if (event.target.value === 'on') {
                          setReportFileType('csv');
                        }
                      }}
                      type="radio"
                      name="fileType"
                      id="csv"
                      checked={reportFileType === 'csv'}
                    />
                    <label htmlFor="csv" className="ml-2">
                      .csv
                    </label>
                  </div>
                  <div className="d-flex">
                    <input
                      onChange={(event) => {
                        if (event.target.value === 'on') {
                          setReportFileType('pdf');
                        }
                      }}
                      type="radio"
                      name="fileType"
                      id="pdf"
                      checked={reportFileType === 'pdf'}
                    />
                    <label htmlFor="pdf" className="ml-2">
                      .pdf
                    </label>
                  </div>
                </div>
              )}
              {reportTypes[reportType].showDateSelectors && (
                <div className="col">
                  <div className="row">
                    <div className="col d-flex flex-column">
                      <label htmlFor="report">Inicio</label>
                      <input
                        className="rounded p-2"
                        type="date"
                        value={reportStartDate}
                        onChange={(event) => {
                          setReportStartDate(event.target.value);
                        }}
                      />
                    </div>
                    <div className="col d-flex flex-column">
                      <label htmlFor="report">Final</label>
                      <input
                        className="rounded p-2"
                        type="date"
                        value={reportEndDate}
                        onChange={(event) => {
                          setReportEndDate(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <button
              onClick={generateReport}
              disabled={
                isGeneratingReport ||
                (reportTypes[reportType].showDateSelectors &&
                  (!reportStartDate || !reportEndDate))
              }
              className="w-100 bg-purple tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around mt-2"
            >
              Generar
              <BounceLoader
                color="#fff"
                loading={isGeneratingReport}
                size="18"
              />
            </button>
          </Card>
          <Card className="p-md-4">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
              onRowClick={(affiliation) => {
                history.push(
                  `/admin/market-affiliations/${affiliation.affiliationRequestsId}`
                ); // TODO: change this
              }}
              remountCount={tableRemountCount}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default AffiliationsList;
