import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

import AmazonDetailCard from 'components/Amazon/AmazonDetailCard';
import Card from '../components/Card';
import { Link } from 'react-router-dom';
import ReactModal from 'components/Modal';
import Table from 'components/Table/Table';
import api from 'utils/api';
import chevronLeftSVG from '../assets/icons/chevron-left.svg';
import { formatPrice } from 'utils/misc';
import { getASINbyURL } from 'utils/misc';
import { toast } from 'react-toastify';
import { tuyoPriceFormula } from 'utils/misc';

function Amazon() {
  const [onlyAsin, setOnlyAsin] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [stores, setStores] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    product: null,
  });

  const formRef = useRef(null);

  const { REACT_APP_TITLE } = process.env;

  const amazonTable = {
    options: {
      id: 'asin',
      pageSize: 10,
    },
    columns: [
      { text: '✓', key: 'isSelected', width: '5%', isCheckbox: true },
      {
        text: 'Imagen',
        key: 'image',
        className: 'text-center',
        isImage: true,
      },
      { text: 'ID', key: 'asin', className: 'text-center' },
      {
        text: 'Precio Amazon',
        key: 'amazonPrice',
        className: 'text-center',
        isCurrency: true,
      },
      {
        text: 'Precio Tuyo',
        key: 'tuyoPrice',
        className: 'text-center',
        isCurrency: true,
      },
      { text: 'Nombre', key: 'title', className: 'text-start' },
    ],
  };

  useEffect(() => {
    document.title = `Amazon | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    api.categories.getCategoriesWithSubCategories().then((res) => {
      setCategories(res.data);
    });
  }, []);

  function searchStore(store = '') {
    setStores([]);

    api.stores
      .findByName(store, ['storesId', 'name', 'active'], {
        include: [
          { association: 'stores_branches', attributes: ['storesBranchesId'] },
        ],
      })
      .then((res) => {
        setStores([...res.data]);

        if (res.data.length > 0) {
          setSelectedStore(res.data[0]);
        }
      });
  }

  function uploadProducts() {
    const { storesId, stores_branches: branches } = selectedStore;
    const { storesBranchesId } = branches[0];

    if (!selectedProducts.length) {
      toast.error('Selecciona al menos un producto');
      return;
    }

    if (!selectedStore) {
      toast.error('Selecciona una tienda');
      return;
    }

    if (!storesBranchesId) {
      toast.error('La tienda seleccionada no tiene una sucursal asignada');
      return;
    }

    const products = selectedProducts.map((product) => {
      product.fk_storesId = +storesId;
      product.stock[0].fk_storesBranchesId = +storesBranchesId;
      product.variants.map((variant) => {
        variant.stock[0].fk_storesBranchesId = +storesBranchesId;

        return variant;
      });

      return product;
    });

    setIsModalOpen(false);

    api.bulk
      .products(products)
      .then((res) => {
        toast.success(
          'Los productos se han subido correctamente, en breve serán publicados'
        );
        setSelectedProducts([]);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error al cargar los productos');
      });
  }

  function searchProducts(values) {
    if (values.searchTerm.trim() === '') {
      toast.warning('Porfavor, digite un termino de busqueda');
      return;
    }

    setIsLoading(true);

    api.amazon
      .searchProducts(values.searchTerm)
      .then((res) => {
        if (res.status == 429) {
          toast.error('Has superado el limite de consultas diarias');
          return;
        }

        setProducts([
          ...res.results
            .filter(
              (item, pos, arr) =>
                arr.findIndex((t) => t.asin === item.asin) === pos
            )
            .filter((product) => product?.prices?.current_price)
            .map((product) => ({
              ...product,
              amazonPrice: product?.prices?.current_price,
              tuyoPrice: tuyoPriceFormula(product?.prices?.current_price),
              isSelected: false,
            })),
        ]);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleModal = (open, asin = '') => {
    if (open == false) {
      setModalInfo({
        isOpen: false,
        product: null,
      });
    }

    if (open == true) {
      let tmpAsin = '';

      if (asin.includes('amazon.com')) {
        tmpAsin = getASINbyURL(asin);
        if (!tmpAsin) {
          toast.error('Link invalido, ingrese un link valido');
          return;
        }
      } else {
        tmpAsin = asin;
      }

      setModalInfo({
        ...modalInfo,
        isOpen: true,
      });

      api.amazon
        .getProductByAsin(tmpAsin)
        .then((res) => {
          if (res?.status || 0 == 400) {
            toast.error('Producto no encontrado');
            setModalInfo({
              isOpen: false,
              product: null,
            });
            return;
          }

          setModalInfo({
            isOpen: true,
            product: res,
          });
        })
        .catch((err) => {
          toast.error('Producto no encontrado');
          setModalInfo({
            isOpen: false,
            product: null,
          });
        });
    }
  };

  return (
    <div className="content">
      <ReactModal
        isOpen={isModalOpen}
        onCloseRequest={() => {
          setIsModalOpen(false);
        }}
      >
        <h3 className="text-dark-blue font-size-2x font-weight-bold text-center mb-4">
          Cargar productos
        </h3>
        <p>Busca una tienda</p>
        <Formik
          initialValues={{
            store: '',
          }}
          validate={(value) => {
            if (!value.store.trim()) {
              toast.error('Porfavor, ingrese una tienda');
            }
          }}
          onSubmit={(values) => {
            searchStore(values.store);
          }}
        >
          {({ values, handleChange }) => (
            <Form>
              <div
                style={{
                  padding: '1rem 0',
                }}
                className="row w-full m-0"
              >
                <div className="col-9 pl-0 pr-4">
                  <input
                    className="rounded w-full h-full"
                    name="store"
                    type="text"
                    value={values.store}
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  style={{
                    margin: 'auto !important',
                  }}
                  className="bg-green tuyo-btn px-4 py-2 mt-1 rounded text-light font-weight-bold d-flex align-items-center justify-content-around col-3 "
                >
                  Buscar
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {stores?.length > 0 && (
          <select
            name="categoryId"
            className="p-2 rounded w-100"
            onChange={({ target }) => {
              setSelectedStore(
                stores.find((store) => store.storesId == target.value)
              );
            }}
          >
            {stores.map((store) => (
              <option key={store.storesId} value={store.storesId}>
                {store.name}
              </option>
            ))}
          </select>
        )}
        <p className="mt-4 text-indigo-dark">
          Subira {selectedProducts.length} productos.
        </p>
        <button
          type="button"
          className="w-100 bg-green tuyo-btn px-4 py-2 mt-1 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
          onClick={() => {
            uploadProducts();
          }}
        >
          Continuar
        </button>
      </ReactModal>

      <AmazonDetailCard
        categories={categories}
        product={modalInfo.product}
        onSave={(product) => {
          if (!onlyAsin) {
            products.find(
              (item) => item.asin === product.sku
            ).isSelected = true;
          }

          setSelectedProducts((prev) => [...prev, product]);
          handleModal(false);
        }}
        onClose={() => handleModal(false)}
        hide={modalInfo.isOpen}
      />
      <div className="row">
        <div className="col-6 col-md-3 col-xl-2">
          <Link to="/admin/c807">
            <button
              type="button"
              className="w-100 bg-beige tuyo-btn px-3 py-2 mr-2 rounded-pill border d-inline-flex align-items-center justify-content-center"
            >
              <img src={chevronLeftSVG} alt="" className="mr-2" />
              Regresar
            </button>
          </Link>
        </div>
        <div className="col-12 col-md-5 col-xl-7 mt-3 mt-md-0 order-3 order-md-2">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center">
            Añadir producto
          </h3>
        </div>
        <div className="col-6 col-md-4 col-xl-3 order-md-3 order-2 switch-button d-flex align-items-center justify-content-end">
          <label htmlFor="switch-label" className="mr-2">
            AGREGAR POR URL
          </label>
          <input
            value={onlyAsin}
            onChange={() => {
              setProducts([]);
              setOnlyAsin(!onlyAsin);
            }}
            type="checkbox"
            name="switch-button"
            id="switch-label"
            className="switch-button__checkbox"
          />
          <label
            htmlFor="switch-label"
            className="switch-button__label"
          ></label>
        </div>
      </div>
      <div className="">
        {selectedProducts.length > 0 && (
          <>
            <Card className="mt-3 mt-md-4">
              <p className="font-size-150x font-weight-bold">
                Productos a subir
              </p>
              <hr />

              {selectedProducts.map((product, index) => (
                <div key={index}>
                  <div className="d-flex">
                    <div className="col-2 col-md-1 col-xl-1">
                      <img
                        src={product?.images[0]?.url}
                        alt=""
                        className="img-fluid rounded"
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </div>
                    <p className="font-weight-semibold w-full">
                      {product?.name?.substring(0, 20)} -{' '}
                      {formatPrice(+product?.regularPrice)}
                    </p>
                    <button
                      onClick={() => {
                        selectedProducts.splice(index, 1);
                        setSelectedProducts([...selectedProducts]);

                        const tmpProdcut = products.find(
                          (item) => item.asin === product.sku
                        );
                        if (tmpProdcut) {
                          tmpProdcut.isSelected = false;
                          setProducts([...products]);
                        }
                      }}
                      type="button"
                    >
                      <i className="material-icons text-danger">delete</i>
                    </button>
                  </div>
                  <hr />
                </div>
              ))}
              <div className="row my-3">
                <div className="col-12 d-flex">
                  <button
                    type="button"
                    className="w-100 bg-green tuyo-btn px-4 py-2 mt-1 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                    onClick={() => {
                      setIsModalOpen(true);
                      setStores([]);
                    }}
                  >
                    Subir productos
                  </button>
                </div>
              </div>
            </Card>
          </>
        )}

        {onlyAsin ? (
          <Card className="p-4 mt-3 mt-md-4">
            <div>
              <Formik
                enableReinitialize={true}
                innerRef={formRef}
                initialValues={{
                  asin: '',
                }}
                onSubmit={(values) => {
                  if (!values.asin) {
                    toast.error('Ingrese un ASIN');
                    return;
                  }

                  handleModal(true, values.asin);
                }}
              >
                <Form className="row">
                  <div className="col-12 col-md-8 mt-3">
                    <label htmlFor="asinCode" className="d-block">
                      Buscar por Asin o URL
                    </label>
                    <Field
                      name="asin"
                      text="asin"
                      placeholder="Ej.B1204FS3 ó https://www.amazon.com/Cards-Against-Humanity-LLC-CAHUS/dp/B004S8F7QM"
                      className="w-100 rounded p-2"
                    />
                  </div>
                  <div className="col-12 col-md-4 mt-3 d-flex">
                    <button
                      type="button"
                      className="w-100 bg-purple tuyo-btn px-4 py-2 mt-1 rounded text-light font-weight-bold d-flex align-items-center justify-content-around mt-auto"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {isLoading ? 'Buscando...' : 'Buscar'}
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </Card>
        ) : (
          <Card className="p-4 mt-3 mt-md-4">
            <div>
              <Formik
                enableReinitialize={true}
                innerRef={formRef}
                initialValues={{
                  searchTerm: '',
                }}
                onSubmit={(values) => {
                  searchProducts(values);
                }}
              >
                <Form className="row">
                  <div className="col-12 col-md-8 mt-3">
                    <label htmlFor="giftName" className="d-block">
                      Buscar producto
                    </label>
                    <Field
                      name="searchTerm"
                      text="text"
                      placeholder="Ej. Nitendo Switch"
                      className="w-100 rounded p-2"
                    />
                  </div>
                  <div className="col-12 col-md-4 mt-3 d-flex">
                    <button
                      type="button"
                      className="w-100 bg-purple tuyo-btn px-4 py-2 mt-1 rounded text-light font-weight-bold d-flex align-items-center justify-content-around mt-auto"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {isLoading ? 'Buscando...' : 'Buscar'}
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </Card>
        )}

        {products.length > 0 && (
          <Card className="mt-4">
            <Table
              options={amazonTable.options}
              columns={amazonTable.columns}
              data={products}
              pageSize={10}
              onRowClick={(id, row) => {
                if (selectedProducts.find((item) => item.sku === id)) {
                  const index = selectedProducts.findIndex(
                    (item) => item.sku === id
                  );
                  selectedProducts.splice(index, 1);
                  row.isSelected = false;

                  setSelectedProducts([...selectedProducts]);
                  return;
                }

                handleModal(true, id);
              }}
            />
          </Card>
        )}
      </div>
    </div>
  );
}

export default Amazon;
