import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Datetime from 'react-datetime';
import BounceLoader from 'react-spinners/BounceLoader';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import Card from '../../components/Card';

import api from '../../utils/api';
import colors from '../../assets/resources/colors';
import {
  fileToBase64URL,
  uploadPictureToS3,
  deleteFromS3,
} from '../../utils/misc';
import CustomBanner from 'components/BannerComponent';
import moment from 'moment';
import {
  TagSelectorModal,
  TagSelectorModalType,
} from 'components/TagSelectorModal';
import TagSelectorModalButton from 'components/TagSelectorModalButton';

function NewSpecialOffer() {
  const formRef = useRef(null);
  const history = useHistory();

  /* URL params */
  const { id: specialOfferId } = useParams();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  const [initilizedValues, setInitilizedValues] = useState();

  const [specialOffer, setSpecialOffer] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [banner, setBanner] = useState();

  const [isSaving, setIsSaving] = useState(false);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    items: [],
    initialItems: [],
    type: null,
    onSubmit: () => {},
  });

  /* Set page title */
  useEffect(() => {
    document.title = `${
      specialOfferId ? 'Editar' : 'Nueva'
    } sección especial | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    if (specialOffer) initializeIds();
  }, [specialOffer]);

  useEffect(() => {
    if (specialOfferId) {
      api.specialOffers
        .findOne(specialOfferId)
        .then(({ data }) => {
          const offer = data[0];

          setSpecialOffer({ ...offer });
          setThumbnail({ URL: offer.thumbnail });
          setBanner({ URL: offer.banner });
        })
        .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));
    }
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const initializeIds = () => {
    const {
      stores,
      products,
      categories,
      subcategories,
      excludeStores,
      excludeProducts,
      excludeCategories,
      excludeSubcategories,
    } = specialOffer;

    let initial = {
      stores: [],
      products: [],
      categories: [],
      subcategories: [],
      excludeStores: [],
      excludeProducts: [],
      excludeCategories: [],
      excludeSubcategories: [],
    };

    const tmpStores = [...(stores ?? []), ...(excludeStores ?? [])];
    const tmpProducts = [...(products ?? []), ...(excludeProducts ?? [])];
    const tmpCategories = [...(categories ?? []), ...(excludeCategories ?? [])];
    const tmpSubCategories = [
      ...(subcategories ?? []),
      ...(excludeSubcategories ?? []),
    ];

    Promise.allSettled([
      api.findAll(
        `/stores?filter=${JSON.stringify({
          attributes: ['storesId', 'name'],
          order: [['name', 'asc']],
          where: {
            active: true,
            storesId: {
              $in: tmpStores,
            },
          },
        })}`
      ),
      api.findAll(
        `/categories?filter=${JSON.stringify({
          attributes: ['productsCategoriesId', 'name'],
          order: [['name', 'asc']],
          where: {
            productsCategoriesId: {
              $in: tmpCategories,
            },
          },
        })}`
      ),
      api.findAll(
        `/products?filter=${JSON.stringify({
          where: { active: '1' },
          attributes: ['productsId', 'name'],
          order: [['name', 'asc']],
          where: {
            productsId: {
              $in: tmpProducts,
            },
          },
        })}`
      ),
      api.findAll(
        `/subcategories?filter=${JSON.stringify({
          attributes: ['productsSubcategoriesId', 'name'],
          order: [['name', 'asc']],
          where: {
            productsSubcategoriesId: {
              $in: tmpSubCategories,
            },
          },
        })}`
      ),
    ])
      .then(
        ([
          storesResponse,
          categoriesResponse,
          productsResponse,
          subcategoriesResponse,
        ]) => {
          if (storesResponse.status === 'fulfilled') {
            const res = storesResponse?.value?.data?.map((store) => ({
              id: store.storesId,
              value: store.name,
            }));

            initial.stores = res.filter((store) => stores.includes(store.id));
            initial.excludeStores = res.filter((store) =>
              excludeStores.includes(store.id)
            );
          }

          if (categoriesResponse.status === 'fulfilled') {
            const res = categoriesResponse?.value?.data?.map((category) => ({
              id: category.productsCategoriesId,
              value: category.name,
            }));

            initial.categories = res.filter((category) =>
              categories.includes(category.id)
            );
            initial.excludeCategories = res.filter((category) =>
              excludeCategories.includes(category.id)
            );
          }

          if (productsResponse.status === 'fulfilled') {
            const res = productsResponse?.value?.data?.map((product) => ({
              id: product.productsId,
              value: product.name,
            }));

            initial.products = res.filter((product) =>
              products.includes(product.id)
            );
            initial.excludeProducts = res.filter((product) =>
              excludeProducts.includes(product.id)
            );
          }

          if (subcategoriesResponse.status === 'fulfilled') {
            const res = subcategoriesResponse?.value?.data?.map(
              (subcategory) => ({
                id: subcategory.productsSubcategoriesId,
                value: subcategory.name,
              })
            );

            initial.subcategories = res.filter((subcategory) =>
              subcategories.includes(subcategory.id)
            );
            initial.excludeSubcategories = res.filter((subcategory) =>
              excludeSubcategories.includes(subcategory.id)
            );
          }
        }
      )
      .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`))
      .finally(() => setInitilizedValues(initial));
  };

  async function save(formValues) {
    const userId = cookies[REACT_APP_COOKIES_USER_ID];

    if (!thumbnail) {
      toast.error('Debes subir una imagen para la miniatura');
      return;
    }

    if (!banner) {
      toast.error('Debes subir una imagen para el banner');
      return;
    }

    toast.success(
      'Espere un momento, la sección especial esta siendo procesada'
    );

    setIsSaving(true);

    let values = {
      ...formValues,
    };

    if (values.banCoupons != true) {
      delete values.banCoupons;
    }

    const body = {
      ...values,
      stores: formValues.stores.map((store) =>
        typeof store === 'object' ? store.id : store
      ),
      products: formValues.products.map((product) =>
        typeof product === 'object' ? product.id : product
      ),
      categories: formValues.categories.map((category) =>
        typeof category === 'object' ? category.id : category
      ),
      subcategories: formValues.subcategories.map((subcategory) =>
        typeof subcategory === 'object' ? subcategory.id : subcategory
      ),
      excludeStores: formValues.excludeStores.map((store) =>
        typeof store === 'object' ? store.id : store
      ),
      excludeProducts: formValues.excludeProducts.map((product) =>
        typeof product === 'object' ? product.id : product
      ),
      excludeCategories: formValues.excludeCategories.map((category) =>
        typeof category === 'object' ? category.id : category
      ),
      excludeSubcategories: formValues.excludeSubcategories.map((subcategory) =>
        typeof subcategory === 'object' ? subcategory.id : subcategory
      ),
      banner: banner.base64
        ? await uploadPictureToS3(banner.base64, banner.type, banner.name)
        : banner.URL,
      thumbnail: thumbnail.base64
        ? await uploadPictureToS3(
            thumbnail.base64,
            thumbnail.type,
            thumbnail.name
          )
        : thumbnail.URL,
      createdBy: +userId,
    };

    if(specialOfferId) {
      delete body.discount;
      delete body.freeDelivery;
    }

    if (thumbnail.oldURL) {
      deleteFromS3(
        thumbnail.oldURL.substring(thumbnail?.oldURL?.lastIndexOf('/') + 1)
      );
    }

    if (banner.oldURL) {
      deleteFromS3(
        banner.oldURL.substring(banner?.oldURL?.lastIndexOf('/') + 1)
      );
    }

    body[specialOfferId ? 'updatedBy' : 'createdBy'] =
      +cookies[REACT_APP_COOKIES_USER_ID];

    const action = specialOfferId
      ? api.specialOffers.update(specialOfferId, body)
      : api.specialOffers.create(body);

    action
      .then((_) => {
        toast.success(
          `Sección especial ${specialOfferId ? 'actualizada' : 'creada'}`
        );
        history.replace('/admin/special-offers');
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
      })
      .finally((_) => setIsSaving(false));
  }

  async function changeImage(event, imageType) {
    const file = event.target.files[0];
    if (file) {
      if (file.size / 1024 >= 2048) {
        toast.warning('Error: La imagen supera el peso maximo permitido');
        event.target.value = '';
      } else {
        const { type, name } = file;

        if (imageType === 'banner') {
          setBanner({
            base64: await fileToBase64URL(file),
            type,
            name,
            ...(specialOfferId && specialOffer ? { oldURL: banner.URL } : {}),
          });
        }

        if (imageType === 'thumbnail') {
          setThumbnail({
            base64: await fileToBase64URL(file),
            type,
            name,
            ...(specialOfferId && specialOffer
              ? { oldURL: thumbnail.URL }
              : {}),
          });
        }
      }
    }
  }

  return (
    <div className="content">
      <TagSelectorModal
        {...modalInfo}
        onClose={() => {
          setModalInfo({
            isOpen: false,
            items: [],
          });
        }}
      />
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center text-md-left w-100">
            {specialOfferId ? 'Editar' : 'Nueva'} sección especial
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <button
            disabled={isSaving}
            onClick={handleSubmit}
            type="button"
            className="bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
          >
            {isSaving ? 'Guardando' : 'Guardar'}
            <BounceLoader color="#fff" loading={isSaving} size="18" />
          </button>
        </div>
      </div>
      <Card className="p-4 mt-3 mt-md-4">
        {(specialOfferId && specialOffer) || !specialOfferId ? (
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            validationSchema={Yup.object().shape({
              title: Yup.string().trim().required('Campo requerido'),
              subtitle: Yup.string().trim().required('Campo requerido'),
            })}
            validateOnChange={false}
            validate={(values) => {
              const errors = {};

              if (!values.title) {
                errors.title = 'Campo requerido';
              }

              if (!values.subtitle) {
                errors.subtitle = 'Campo requerido';
              }

              if (!values.start) {
                errors.start = 'Campo requerido';
              }

              if (!values.end) {
                errors.end = 'Campo requerido';
              }

              return errors;
            }}
            initialValues={{
              banCoupons: false,
              title: specialOffer?.title || '',
              subtitle: specialOffer?.subtitle || '',
              active: specialOffer?.active || true,
              start: specialOffer?.start || '',
              end: specialOffer?.end || '',
              isFlashSale: specialOffer?.isFlashSale || false,
              discount: null,
              freeDelivery: null,
              stores:
                specialOffer?.stores?.length > 0
                  ? [...(initilizedValues?.stores ?? [])]
                  : [],
              products:
                specialOffer?.products?.length > 0
                  ? [...(initilizedValues?.products ?? [])]
                  : [],
              categories:
                specialOffer?.categories?.length > 0
                  ? [...(initilizedValues?.categories ?? [])]
                  : [],
              subcategories:
                specialOffer?.subcategories?.length > 0
                  ? [...(initilizedValues?.subcategories ?? [])]
                  : [],
              excludeStores:
                specialOffer?.excludeStores?.length > 0
                  ? [...(initilizedValues?.excludeStores ?? [])]
                  : [],
              excludeProducts:
                specialOffer?.excludeProducts?.length > 0
                  ? [...(initilizedValues?.excludeProducts ?? [])]
                  : [],
              excludeCategories:
                specialOffer?.excludeCategories?.length > 0
                  ? [...(initilizedValues?.excludeCategories ?? [])]
                  : [],
              excludeSubcategories:
                specialOffer?.excludeSubcategories?.length > 0
                  ? [...(initilizedValues?.excludeSubcategories ?? [])]
                  : [],
            }}
            onSubmit={(values) => {
              save(values);
            }}
          >
            {({ values }) => (
              <Form>
                <div
                  style={{
                    minHeight: '10rem',
                    justifyContent: 'center',
                    margin: '0 auto',
                  }}
                  className="row w-full"
                >
                  <CustomBanner
                    onChange={(event) => changeImage(event, 'banner')}
                    className={`col-8 col-md-6 ${
                      values.isFlashSale ? 'col-xl-5' : 'col-xl-5'
                    }`}
                    id="banner"
                    aspectRatio={9 / 16}
                    aspectRatioText="2:1"
                    image={banner}
                    title="Banner"
                  />

                  <CustomBanner
                    onChange={(event) => changeImage(event, 'thumbnail')}
                    // className="mx-4 col-8 col-md-4 col-xl-3"
                    className={`col-8 col-md-4 ${
                      values.isFlashSale ? 'col-xl-5' : 'col-xl-3'
                    }`}
                    id="thumbnail"
                    aspectRatio={values.isFlashSale ? 9 / 16 : 1 / 1}
                    aspectRatioText={values.isFlashSale ? '17:10' : '1:1'}
                    image={thumbnail}
                    title="Miniatura"
                  />
                </div>

                <div className="row">
                  <div className="col-12 col-md-5 mt-2">
                    <label htmlFor="title" className="d-block">
                      Título
                    </label>
                    <Field
                      className="w-100 p-2 rounded"
                      name="title"
                      type="text"
                      placeholder="Título del banner"
                    />
                    <ErrorMessage
                      name="title"
                      component="span"
                      className="text-red"
                    />
                  </div>

                  <div className="col-12 col-md-5 mt-2">
                    <label htmlFor="subtitle" className="d-block">
                      Subtítulo
                    </label>
                    <Field
                      className="w-100 p-2 rounded"
                      name="subtitle"
                      type="text"
                      placeholder="Subtítulo del banner"
                    />
                    <ErrorMessage
                      name="subtitle"
                      component="span"
                      className="text-red"
                    />
                  </div>

                  <div className="col-12 col-md-2 d-flex flex-column justify-content-center align-items-center mt-2 col-md-2">
                    <label className="d-block" htmlFor="active">
                      Activo
                    </label>
                    <Field name="active" type="checkbox" id="active" />
                  </div>

                  <div className="col-12 col-md-5 mt-2">
                    <label className="d-block" htmlFor="start">
                      Inicio
                    </label>
                    <Field name="start">
                      {({ field, form: { setFieldValue } }) => (
                        <Datetime
                          value={moment(field.value)}
                          onChange={(data) =>
                            setFieldValue('start', moment(data).toString())
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="start"
                      component="span"
                      className="text-red"
                    />
                  </div>

                  <div className="col-12 col-md-5 mt-2">
                    <label className="d-block" htmlFor="end">
                      Fin
                    </label>
                    <Field name="end">
                      {({ field, form: { setFieldValue } }) => (
                        <Datetime
                          value={moment(field.value)}
                          onChange={(data) =>
                            setFieldValue('end', moment(data).toString())
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="end"
                      component="span"
                      className="text-red"
                    />
                  </div>

                  <div className="col-12 col-md-2 d-flex flex-column justify-content-center align-items-center mt-2 col-md-2">
                    <label className="d-block" htmlFor="isFlashSale">
                      Flash sale
                    </label>
                    <Field
                      name="isFlashSale"
                      type="checkbox"
                      id="isFlashSale"
                    />
                  </div>

                  {values.isFlashSale && !specialOfferId && (
                    <>
                      <div className="col-12 col-md-5 mt-2">
                        <label htmlFor="discount" className="d-block">
                          Descuento
                        </label>
                        <Field
                          className="w-100 p-2 rounded"
                          name="discount"
                          type="number"
                          placeholder="Descuento"
                        />
                        <ErrorMessage
                          name="discount"
                          component="span"
                          className="text-red"
                        />
                      </div>

                      <div className="col-12 col-md-5 mt-2">
                        <label htmlFor="freeDelivery" className="d-block">
                          Envío gratis
                        </label>
                        <Field
                          name="freeDelivery"
                          type="checkbox"
                          placeholder="Envío gratis"
                        />
                        <ErrorMessage
                          name="freeDelivery"
                          component="span"
                          className="text-red"
                        />
                      </div>
                    </>
                  )}

                  {/* {values.stores.length > 0 && !Boolean(specialOfferId) && (
                    <div className="col-12 col-md-2 d-flex flex-column justify-content-center align-items-center mt-2 col-md-2">
                      <label className="d-block" htmlFor="banCoupons">
                        Bloquear cupones
                      </label>
                      <Field
                        name="banCoupons"
                        type="checkbox"
                        id="banCoupons"
                      />
                    </div>
                  )} */}

                  <div className="col-12 my-4">
                    <h4 className="text-dark-blue font-weight-bold text-center">
                      Participantes
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <Field name="categories">
                      {({ field, form: { setFieldValue } }) => (
                        <TagSelectorModalButton
                          title={
                            field?.value?.length > 0
                              ? 'Editar categorías'
                              : 'Agregar categorías'
                          }
                          tags={field.value}
                          onClick={() => {
                            setModalInfo({
                              title: 'Agregar categorias',
                              initialItems: field.value,
                              onSubmit: (data) =>
                                setFieldValue('categories', data),
                              isOpen: true,
                              type: TagSelectorModalType.CATEGORIES,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <Field name="subcategories">
                      {({ field, form: { setFieldValue } }) => (
                        <TagSelectorModalButton
                          title={
                            field?.value?.length > 0
                              ? 'Editar subcategorias'
                              : 'Agregar subcategorias'
                          }
                          tags={field.value}
                          onClick={() => {
                            setModalInfo({
                              title: 'Agregar subcategorias',
                              initialItems: field.value,
                              onSubmit: (data) =>
                                setFieldValue('subcategories', data),
                              isOpen: true,
                              type: TagSelectorModalType.SUBCATEGORIES,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <Field name="stores">
                      {({ field, form: { setFieldValue } }) => (
                        <TagSelectorModalButton
                          title={
                            field?.value?.length > 0
                              ? 'Editar tiendas'
                              : 'Agregar tiendas'
                          }
                          tags={field.value}
                          onClick={() => {
                            setModalInfo({
                              title: 'Agregar tiendas',
                              initialItems: field.value,
                              onSubmit: (data) => setFieldValue('stores', data),
                              isOpen: true,
                              type: TagSelectorModalType.STORES,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <Field name="products">
                      {({ field, form: { setFieldValue } }) => (
                        <TagSelectorModalButton
                          title={
                            field?.value.length > 0
                              ? 'Editar productos'
                              : 'Agregar productos'
                          }
                          tags={field.value}
                          onClick={() => {
                            setModalInfo({
                              title: 'Agregar productos',
                              initialItems: field.value,
                              onSubmit: (data) =>
                                setFieldValue('products', data),
                              isOpen: true,
                              type: TagSelectorModalType.PRODUCTS,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="col-12 my-4">
                    <h4 className="text-dark-blue font-weight-bold text-center">
                      Excluidos
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <Field name="excludeCategories">
                      {({ field, form: { setFieldValue } }) => (
                        <TagSelectorModalButton
                          title={
                            field?.value?.length > 0
                              ? 'Editar categorías'
                              : 'Excluir categorías'
                          }
                          tags={field.value}
                          onClick={() => {
                            setModalInfo({
                              title: 'Excluir categorias',
                              initialItems: field.value,
                              onSubmit: (data) =>
                                setFieldValue('excludeCategories', data),
                              isOpen: true,
                              type: TagSelectorModalType.CATEGORIES,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <Field name="excludeSubcategories">
                      {({ field, form: { setFieldValue } }) => (
                        <TagSelectorModalButton
                          title={
                            field?.value?.length > 0
                              ? 'Editar subcategorias'
                              : 'Agregar subcategorias'
                          }
                          tags={field.value}
                          onClick={() => {
                            setModalInfo({
                              title: 'Excluir subcategorias',
                              initialItems: field.value,
                              onSubmit: (data) =>
                                setFieldValue('excludeSubcategories', data),
                              isOpen: true,
                              type: TagSelectorModalType.SUBCATEGORIES,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <Field name="excludeStores">
                      {({ field, form: { setFieldValue } }) => (
                        <TagSelectorModalButton
                          title={
                            field?.value?.length > 0
                              ? 'Editar tiendas'
                              : 'Agregar tiendas'
                          }
                          tags={field.value}
                          onClick={() => {
                            setModalInfo({
                              title: 'Excluir tiendas',
                              initialItems: field.value,
                              onSubmit: (data) =>
                                setFieldValue('excludeStores', data),
                              isOpen: true,
                              type: TagSelectorModalType.STORES,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <Field name="excludeProducts">
                      {({ field, form: { setFieldValue } }) => (
                        <TagSelectorModalButton
                          title={
                            field?.value.length > 0
                              ? 'Editar productos'
                              : 'Agregar productos'
                          }
                          tags={field.value}
                          onClick={() => {
                            setModalInfo({
                              title: 'Excluir productos',
                              initialItems: field.value,
                              onSubmit: (data) =>
                                setFieldValue('excludeProducts', data),
                              isOpen: true,
                              type: TagSelectorModalType.PRODUCTS,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </Card>
    </div>
  );
}

export default NewSpecialOffer;
