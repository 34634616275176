import React, { useState, useRef, useEffect } from 'react';
import Datetime from 'react-datetime';
import * as moment from 'moment';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import BounceLoader from 'react-spinners/BounceLoader';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';

import Card from '../../components/Card';

import { validPrice } from '../../utils/misc';
import api from '../../utils/api';
import colors from '../../assets/resources/colors';

const TYPES = {
  TOTAL: { key: 'TOTAL', text: 'Total' },
  PRODUCTS: { key: 'PRODUCTS', text: 'Productos' },
  SHIPPING: { key: 'SHIPPING', text: 'Envío' },
};

const CARD_TYPES = {
  DEBIT: { key: 'DEBIT', text: 'Débito' },
  CREDIT: { key: 'CREDIT', text: 'Crédito' },
};

const BANKS = {
  AGRICOLA: { key: 'AGRICOLA', text: 'Banco Agrícola' },
  PTS_AGRICOLA: { key: 'PTS_AGRICOLA', text: 'Puntos Banco Agrícola' },
  BAC: { key: 'BAC', text: 'BAC Credomatic (Visa y MasterCard)' },
  BAC_AMEX: { key: 'BAC_AMEX', text: 'BAC Credomatic (American Express)' },
  CUSCATLAN: { key: 'CUSCATLAN', text: 'Banco Cuscatlán' },
  DAVIVIENDA: { key: 'DAVIVIENDA', text: 'Davivienda' },
  AZUL: { key: 'AZUL', text: 'Banco Azul' },
  FEDECREDITO: { key: 'FEDECREDITO', text: 'Fedecrédito' },
  CREDISIMAN: { key: 'CREDISIMAN', text: 'Credisiman' },
  OPTO: { key: 'OPTO', text: 'Opto' },
  TIGO_MONEY: { key: 'TIGO_MONEY', text: 'Tigo Money' },
};

function NewCardDiscount() {
  const formRef = useRef(null);
  const history = useHistory();

  /* URL params */
  const { id: cardDiscountId } = useParams();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  useEffect(() => {
    document.title = `${
      cardDiscountId ? 'Editar' : 'Nuevo'
    } descuento | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    api
      .findAll(
        '/stores?filter=' +
          JSON.stringify({
            attributes: ['storesId', 'name'],
          })
      )
      .then((res) => {
        setStores(
          res.data.map((store) => ({
            value: store?.storesId,
            label: store?.name,
          }))
        );
      })
      .catch((err) => {
        console.error(err);
        toast.warn('[SERVER_ERROR] ' + err);
      });
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [cardDiscount, setCardDiscount] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [stores, setStores] = useState();
  const [selectedStores, setSelectedStores] = useState([]);

  useEffect((_) => {
    cardDiscountId &&
      api
        .findOne('/carddiscounts', cardDiscountId)
        .then((res) => {
          setCardDiscount(res.data);
        })
        .catch((err) => toast.warning('[SERVER_ERROR] ' + err));
  }, []);

  useEffect(() => {
    if (stores?.length > 0 && cardDiscount?.stores?.trim()) {
      setSelectedStores(
        cardDiscount?.stores
          ?.split(',')
          .map((storeId) => parseInt(storeId))
          .map((storeId) => ({
            value: storeId,
            label: stores?.find((store) => store?.value === storeId)?.label,
          }))
      );
    }
  }, [stores, cardDiscount]);

  function save(formValues) {
    setIsSaving(true);
    const {
      type,
      cardType,
      bank,
      value,
      maxDiscountPerUser,
      startline,
      deadline,
      active,
    } = formValues;

    const body = {
      type,
      cardType,
      bank,
      value,
      maxDiscountPerUser,
      active: active ? 1 : 0,
      startline:
        !startline.trim() || startline === 'Invalid date' ? null : startline,
      deadline:
        !deadline.trim() || deadline === 'Invalid date' ? null : deadline,
      stores: selectedStores.map((store) => store.value).join(','),
    };

    body[cardDiscountId ? 'updatedBy' : 'createdBy'] =
      cookies[REACT_APP_COOKIES_USER_ID];
    const action = cardDiscountId
      ? api.update('/carddiscounts', cardDiscountId, body)
      : api.create('/carddiscounts', body);

    action
      .then((_) => {
        toast.success(`Descuento ${cardDiscountId ? 'actualizado' : 'creado'}`);
        history.push('/admin/card-discounts');
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
      })
      .finally((_) => setIsSaving(false));
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center text-md-left w-100">
            {cardDiscountId ? 'Editar' : 'Nuevo'} descuento
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <button
            disabled={isSaving}
            onClick={handleSubmit}
            type="button"
            className="bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
          >
            {isSaving ? 'Guardando' : 'Guardar'}
            <BounceLoader color="#fff" loading={isSaving} size="18" />
          </button>
        </div>
      </div>
      <Card className="p-4 mt-3 mt-md-4">
        {(cardDiscountId && cardDiscount) || !cardDiscountId ? (
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            validationSchema={Yup.object().shape({
              value: Yup.string().trim().required('Campo requerido'),
              maxDiscountPerUser: Yup.string()
                .trim()
                .required('Campo requerido'),
            })}
            initialValues={{
              type: cardDiscount ? cardDiscount.type : TYPES.TOTAL.key,
              cardType: cardDiscount
                ? cardDiscount.cardType
                : CARD_TYPES.DEBIT.key,
              bank: cardDiscount ? cardDiscount.bank : BANKS.AGRICOLA.key,
              value: cardDiscount ? cardDiscount.value : '',
              maxDiscountPerUser: cardDiscount
                ? cardDiscount.maxDiscountPerUser
                : '',
              active: cardDiscount ? cardDiscount.active : false,
              startline: cardDiscount ? cardDiscount.startline : '',
              deadline: cardDiscount ? cardDiscount.deadline : '',
            }}
            onSubmit={(values) => {
              save(values);
            }}
          >
            {() => (
              <Form>
                <div className="row">
                  <div className="col-12 col-md-3 mt-2">
                    <label className="d-block" htmlFor="bank">
                      Banco
                    </label>
                    <Field
                      as="select"
                      name="bank"
                      className="p-2 rounded w-100"
                    >
                      {Object.entries(BANKS).map((bank, index) => (
                        <option key={index} value={bank[1].key}>
                          {bank[1].text}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-12 col-md-3 mt-2">
                    <label className="d-block" htmlFor="cardType">
                      Tipo de tarjeta
                    </label>
                    <Field
                      as="select"
                      name="cardType"
                      className="p-2 rounded w-100"
                    >
                      {Object.entries(CARD_TYPES).map((type, index) => (
                        <option key={index} value={type[1].key}>
                          {type[1].text}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-12 col-md-3 mt-2">
                    <label className="d-block" htmlFor="type">
                      Aplica a
                    </label>
                    <Field
                      as="select"
                      name="type"
                      className="p-2 rounded w-100"
                    >
                      {Object.entries(TYPES).map((type, index) => (
                        <option key={index} value={type[1].key}>
                          {type[1].text}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-12 col-md-3 d-flex flex-column justify-content-start align-items-center mt-2">
                    <label className="d-block" htmlFor="active">
                      Activo
                    </label>
                    <Field name="active" type="checkbox" id="active" />
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="value" className="d-block">
                      Valor
                    </label>
                    <Field name="value">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            className="p-2 rounded w-100"
                            placeholder="15"
                            onChange={(e) => {
                              setFieldValue(
                                'value',
                                validPrice(e.target.value)
                              );
                            }}
                          />
                          <small className="font-style-italic text-gray d-block">
                            Valores menores a 1 serán tomados como porcentaje
                          </small>
                          <ErrorMessage
                            name="value"
                            component="span"
                            className="text-red"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="maxDiscountPerUser" className="d-block">
                      Descuento máximo por usuario
                    </label>
                    <Field name="maxDiscountPerUser">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            className="p-2 rounded w-100"
                            placeholder="100"
                            onChange={(e) => {
                              setFieldValue(
                                'maxDiscountPerUser',
                                validPrice(e.target.value)
                              );
                            }}
                          />
                          <small className="font-style-italic text-gray d-block">
                            0 indica que no tiene máximo
                          </small>
                          <ErrorMessage
                            name="maxDiscountPerUser"
                            component="span"
                            className="text-red"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="col-12 mt-2">
                    <label className="d-block">Comercios participantes</label>
                    <Select
                      options={stores}
                      isMulti
                      isLoading={!stores}
                      loadingMessage={() => 'Cargando...'}
                      placeholder="Selecciona..."
                      value={selectedStores}
                      onChange={(value) => {
                        setSelectedStores([...value]);
                      }}
                    />
                    <small className="font-style-italic text-gray">
                      Por defecto participan todos los comercios
                    </small>
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label className="d-block" htmlFor="startline">
                      Inicio
                    </label>
                    <Field name="startline">
                      {({ field, form: { setFieldValue } }) => (
                        <Datetime
                          value={moment(field.value)}
                          onChange={(data) =>
                            setFieldValue('startline', moment(data).toString())
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label className="d-block" htmlFor="deadline">
                      Fin
                    </label>
                    <Field name="deadline">
                      {({ field, form: { setFieldValue } }) => (
                        <Datetime
                          value={moment(field.value)}
                          onChange={(data) =>
                            setFieldValue('deadline', moment(data).toString())
                          }
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </Card>
    </div>
  );
}

export default NewCardDiscount;
