import React from 'react';

function TextInput(props) {
  const {
    value,
    onChange,
    title,
    field,
    placeholder,
    rows = 10,
    className = '',
    area = true,
    type = 'text',
    step = 0.01,
    valueFormat = (value) => value,
    onChangeFormat = (value) => value,
  } = props;

  return (
    <div className={className}>
      <label htmlFor={field} className="text-indigo-dark font-semibold">
        {title}
      </label>
      {area ? (
        <textarea
          id={field}
          value={value}
          onChange={(e) =>
            onChange({
              [field]: e.target.value,
            })
          }
          placeholder={placeholder}
          className="w-full p-2"
          rows={rows}
        />
      ) : (
        <input
          id={field}
          value={valueFormat ? valueFormat(value) : value}
          type={type}
          step={step}
          min={0}
          onChange={(e) => {
            const value = e.target.value;

            onChange({
              [field]: onChangeFormat
                ? onChangeFormat(value)
                : type === 'number'
                ? parseFloat(value)
                : value,
            });
          }}
          onBlur={(e) => {
            if (type === 'number' && e.target.value === '')
              onChange({
                [field]: 0,
              });
          }}
          placeholder={placeholder}
          className="w-full p-2"
        />
      )}
    </div>
  );
}

export default TextInput;
