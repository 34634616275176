import React, { Component } from "react";
import jwt_decode from 'jwt-decode';
import UserCard from "components/UserCard/UserCard.jsx";

import hamburgerMenuSVG from '../../assets/icons/hamburger-menu.svg'

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }

  render() {
    const { cookies } = this.props;
    const JWT = cookies.get(process.env.REACT_APP_COOKIES_JWT);
    let decodedJWT;
    let store;
    let firstname;
    let lastname;
    let photo;
    let role;

    if (JWT) {
      decodedJWT = jwt_decode(JWT);
      const user = decodedJWT.user;
      store = user.store;
      firstname = user.firstname;
      lastname = user.lastname;
      photo = user.photo;
      role = user.role.name?.replaceAll('-',' ');
    }

    const renderContent = JWT
      ? (
        <div className="px-3 px-xl-4 d-flex align-items-center justify-content-end justify-content-md-between p-2">
          <p className="font-weight-extrabold text-indigo-dark font-size-125x d-none d-md-block">Dashboard Logistico</p>
          <div className="d-flex">
            <UserCard
              store={store?.name}
              name={`${firstname} ${lastname}`}
              role={role}
              picture={photo} />
            {
              <button className="d-xl-none p-2" onClick={event => this.mobileSidebarToggle(event)}>
                <img src={hamburgerMenuSVG} alt="" style={{ height: '24px' }} />
              </button>
            }
          </div>
        </div>
      )
      : null;

    return renderContent;
  }
}

export default Header;
