import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '../../components/Card';

import api from '../../utils/api';

import chevronLeftSVG from '../../assets/icons/chevron-left.svg';
import { openBase64PDFInNewTab } from 'utils/misc';

function ViewC807() {
  /* URL params */
  const { id: c807OrdersId } = useParams();

  const { REACT_APP_TITLE } = process.env;

  const [c807Order, setC807Order] = useState([]);
  const [shippingStatus, setShippingStatus] = useState([]);

  useEffect(() => {
    document.title = `Nueva orden C807 | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    if (c807OrdersId) {
      api.c807
        .getOne(c807OrdersId)
        .then((res) => {
          setC807Order(res.data);
        })
        .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));
    }
  }, []);

  const createC807PDF = () => {
    if (c807OrdersId) {
      api.c807
        .genPdf({
          guide: c807Order.guide,
        })
        .then((res) => openBase64PDFInNewTab(res.data.pdf))
        .catch((err) => {
          console.error(err);
          toast.warning('Error al generar PDF');
        });
    }
  };

  const getShippingStatus = () => {
    if (c807OrdersId) {
      api.c807
        .getShippingStatus({
          guide: c807Order.guide,
        })
        .then((res) => {
          setShippingStatus([...res.data]);
        })
        .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));
    }
  };

  return (
    <div className="content">
      <div className="row">
        <div className="col-6 col-md-3 col-xl-2">
          <Link to="/admin/c807">
            <button
              type="button"
              className="w-100 bg-beige tuyo-btn px-3 py-2 mr-2 rounded-pill border d-inline-flex align-items-center justify-content-center"
            >
              <img src={chevronLeftSVG} alt="" className="mr-2" />
              Regresar
            </button>
          </Link>
        </div>
        <div className="col-12 col-md-6 col-xl-8 mt-3 mt-md-0">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center">
            {c807OrdersId ? 'Orden C807' : 'Crear orden C807'}
          </h3>
        </div>
      </div>
      <div className="">
        <Card className="p-4 mt-3 mt-md-4">
          <div className="row">
            <div className="col-12 col-md-8 mt-3">
              <label htmlFor="giftName" className="d-block">
                C807 Id de la orden
              </label>
              <div className="p-2 border rounded">
                <p>{c807Order.guide}</p>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-3">
              <label htmlFor="totalToCollect" className="d-block">
                Total a recolectar
              </label>
              <div className="p-2 border rounded">
                <p>{(c807Order.amountCCE || 0).toFixed(2)}</p>
              </div>
            </div>
            <div className="col-12 col-md-8 mt-3">
              <label htmlFor="totalToCollect" className="d-block">
                Direccion
              </label>
              <div className="p-2 border rounded">
                <p>{c807Order.indications}</p>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-3">
              <label htmlFor="totalToCollect" className="d-block">
                Orden Tuyo
              </label>
              <div className="p-2 border rounded">
                <p>{c807Order.fk_ordersId}</p>
              </div>
            </div>

            <div className="col-12 col-md-4 mt-3">
              <label className="d-block" htmlFor="box">
                Estado de la orden
              </label>
              <button
                type="button"
                className="w-100 bg-purple tuyo-btn px-4 py-2 mt-1 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                onClick={() => {
                  getShippingStatus();
                }}
              >
                Ver estado del envio
              </button>
            </div>

            {c807OrdersId && (
              <div className="col-12 col-md-4 mt-3">
                <label className="d-block" htmlFor="box">
                  Sticker
                </label>
                <button
                  type="button"
                  className="w-100 bg-purple tuyo-btn px-4 py-2 mt-1 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                  onClick={() => {
                    createC807PDF();
                  }}
                >
                  Imprimir Sticker
                </button>
              </div>
            )}
          </div>
        </Card>

        {shippingStatus.length > 0 && (
          <Card className="mt-4 p-4">
            {shippingStatus.map((status, index) => (
              <div key={index}>
                <p>
                  <strong>{status.estatus}</strong> - {status.fecha}
                </p>
                {index !== shippingStatus.length - 1 && <hr />}
              </div>
            ))}
          </Card>
        )}
      </div>
    </div>
  );
}

export default ViewC807;
