import React from 'react';

function BooleanButton(props) {
  const {
    value,
    field,
    onChange,
    title,
    trueValue = true,
    falseValue = false,
    className = '',
  } = props;

  const handleClick = () =>
    onChange({
      [field]: value !== trueValue ? trueValue ?? true : falseValue ?? false,
    });

  return (
    <div className={`flex flex-column items-center ${className}`}>
      <label htmlFor={field} className="text-indigo-dark font-semibold w-full ">
        {title}
      </label>
      <button id={field} onClick={handleClick}>
        <span
          className={`material-icons transition-all ${
            value === trueValue ? 'text-tuyo-green' : 'text-gray'
          }`}
        >
          {value === trueValue ? 'check_circle' : 'highlight_off'}
        </span>
      </button>
    </div>
  );
}

export default BooleanButton;
