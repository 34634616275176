import ActiveOrders from './views/ActiveOrders';
import Amazon from 'views/amazon';
import BanksAvailableMonthsList from './views/banks-available-months/list';
import BannersList from './views/banners/list';
import Blacklist from 'views/blacklist';
import C807LIst from 'views/c807/list';
import CanceledOrders from './views/CanceledOrders';
import CardDiscountsList from './views/card-discounts/list';
import CategoriesList from './views/categories/list';
import CouponsList from './views/coupons/list';
import Dashboard from 'views/Dashboard';
import DriversList from './views/drivers/list';
import EditUser from 'views/users/edit';
import Fees from 'views/Fees';
import FinishedOrders from 'views/FinishedOrders';
import Log from 'views/log';
import Logout from 'views/Logout';
import Monitoreo from 'views/Monitoreo';
import NewBankAvailableMonths from 'views/banks-available-months/new';
import NewBanner from 'views/banners/new';
import NewCardDiscount from 'views/card-discounts/new';
import NewCategory from 'views/categories/new';
import NewCollection from 'views/collections/new';
import NewCoupon from 'views/coupons/new';
import NewDriver from 'views/drivers/new';
import NewPayStore from 'views/payment-stores/new';
import NewPopup from 'views/popup/new';
import NewRole from 'views/roles/new';
import NewSpecialOffer from 'views/special-offers/new';
import NewStore from 'views/stores/new';
import NewUrbano from 'views/urbano/new';
import PayStoresList from 'views/payment-stores/list';
import PaymentTransactionList from 'views/PaymentTransactions';
import PaymentDailyLiquidations from 'views/PaymentDailyLiquidations';
import PaymentVoucherList from 'views/PaymentVouchers';
import PendingOrders from './views/PendingOrders';
import PopupList from 'views/popup/list';
import { ROLES } from 'utils/misc';
import RefundOrders from './views/RefundOrders';
import RolesList from './views/roles/list';
import Searches from 'views/Searches';
import SendMasiveNotifications from 'views/PushNotification';
import SpecialOffersList from 'views/special-offers/list';
import StoresList from './views/stores/list';
import Transactions from './views/transactions';
import UpdateProducts from 'views/UpdateProducts';
import UrbanoList from './views/urbano/list';
import UserCreate from 'views/UserCreate';
import UsersList from 'views/users/list';
import ViewC807 from 'views/c807/new';
import BannedCoupons from 'views/BannedCoupons';
import DeliveryTimes from 'views/DeliveryTimes';
import StockLimitsList from 'views/stock-limits/list';
import NewStockLimit from 'views/stock-limits/new';
import NewSpecialDiscount from 'views/special-discounts/new';
import SpecialDiscountsList from 'views/special-discounts/list';
import RootSettings from 'views/RootSettings';
import turboOrders from 'views/turbo/orders';
import TurboStores from 'views/turbo/stores';
import TurboCategories from 'views/turbo/categories';
import TurboProducts from 'views/turbo/products';
import Positions from 'views/Positions';
import RecurringTransactionList from 'views/recurrings/RecurringTransactions';
import RecurringPayments from 'views/recurrings/RecurringPayments';
import EmailLogs from 'views/EmailLog';
import ScheduledOrders from 'views/scheduled/orders';
import ProductReports from 'views/ProductsReports';
import ShippingCostsList from 'views/shipping-costs/list';
import ShippingCost from 'views/shipping-costs/edit';
import AffiliationsList from 'views/market-affiliations/list';
import CheckAffiliationRequest from 'views/market-affiliations/check';
import VoucherTemplateList from 'views/VoucherTemplates';

const {
  ROOT: R,
  GERENCY: G,
  FINANCE: F,
  LOGISTIC: L,
  MARKETING: MKT,
  MARKETING_LEAD: MKTL,
  CUSTOMER_EXPERIENCE: CX,
  CUSTOMER_EXPERIENCE_LEAD: CXL,
  KEY_ACCOUNT_MANAGER: KAM,
  BUYER: B,
  PAYMENTS_KAM: PK,
} = ROLES;

const sidebarRoutes = [
  {
    path: '/dashboard',
    name: 'Inicio',
    icon: 'dashboard',
    component: Dashboard,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    estrict: true,
    roles: [R, G, F, L, MKTL, KAM, CX, CXL, B],
  },
  {
    name: 'Órdenes',
    icon: 'list_alt',
    flexGrow: '0',
    className: '',
    children: [
      {
        path: '/monitoreo',
        name: 'Monitoreo',
        icon: 'share_location',
        component: Monitoreo,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        estrict: true,
        roles: [R, G, F, L, MKTL, KAM, CX, CXL, B],
      },
      {
        path: '/scheduled-orders',
        name: 'Ordenes Programadas',
        icon: 'today',
        component: ScheduledOrders,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, CXL],
      },
      {
        path: '/product-reports',
        name: 'Reporte de productos',
        icon: 'inventory',
        component: ProductReports,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, KAM, CX, CXL, B],
      },
      {
        path: '/pending',
        name: 'Pendientes',
        icon: 'pending_actions',
        component: PendingOrders,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, KAM, CX, CXL, B],
      },
      {
        path: '/active',
        name: 'Activos',
        icon: 'assignment',
        component: ActiveOrders,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, KAM, CX, CXL, B],
      },
      {
        path: '/canceled',
        name: 'Cancelados',
        icon: 'assignment_late',
        component: CanceledOrders,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, KAM, CX, CXL, B],
      },
      {
        path: '/refunds',
        name: 'Reembolsos',
        icon: 'assignment_returned',
        component: RefundOrders,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, KAM, CX, CXL, B],
      },
      {
        path: '/finished',
        name: 'Finalizadas',
        icon: 'assignment_turned_in',
        component: FinishedOrders,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, KAM, CX, CXL, B],
      },
      {
        path: '/urbano',
        name: 'Urbano',
        icon: 'local_shipping',
        component: UrbanoList,
        layout: '/admin',
        flexGrow: '1',
        classNames: 'd-flex align-items-end pb-3',
        roles: [R, G, F, L, MKTL, KAM, CX, CXL],
      },
      {
        path: '/c807',
        name: 'C807',
        icon: 'local_shipping',
        component: C807LIst,
        layout: '/admin',
        flexGrow: '1',
        classNames: 'd-flex align-items-end pb-3',
        roles: [R, G, F, L, MKTL, KAM, CX, CXL],
      },
    ],
  },
  {
    name: 'Turbo Envio',
    icon: 'rocket',
    flexGrow: '0',
    className: '',
    children: [
      {
        path: '/turbo-orders',
        name: 'Ordenes',
        icon: 'pending_actions',
        component: turboOrders,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, CXL],
      },
      {
        path: '/turbo-stores',
        name: 'Tiendas',
        icon: 'rule',
        component: TurboStores,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, CXL],
      },
      {
        path: '/turbo-categories',
        name: 'Categorias',
        icon: 'rule',
        component: TurboCategories,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, CXL],
      },
      {
        path: '/turbo-positions',
        name: 'Mapa',
        icon: 'share_location',
        component: Positions,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        estrict: true,
        roles: [R, G, F, L, MKTL, KAM, CX, CXL, B],
      },
    ],
  },
  {
    name: 'Tuyo Pay',
    icon: 'payments',
    flexGrow: '0',
    className: '',
    children: [
      {
        path: '/payment-stores',
        name: 'Comercios',
        icon: 'store',
        component: PayStoresList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, KAM, PK],
      },
      {
        path: '/payment-vouchers',
        name: 'Comprobantes',
        icon: 'local_activity',
        component: PaymentVoucherList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, PK],
      },
      {
        path: '/payment-transactions',
        name: 'Transacciones',
        icon: 'payments',
        component: PaymentTransactionList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, PK],
      },
      {
        path: '/daily-liquidations',
        name: 'Reporte Liquidaciones',
        icon: 'payments',
        component: PaymentDailyLiquidations,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, PK],
      },
      {
        path: '/vouchers-templates',
        name: 'Links Multipago',
        icon: 'local_activity',
        component: VoucherTemplateList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, PK],
      },
    ],
  },
  {
    name: 'Recurrentes',
    icon: 'payments',
    flexGrow: '0',
    className: '',
    children: [
      {
        path: '/recurring-payments',
        name: 'Pagos',
        icon: 'fact_check',
        component: RecurringPayments,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, PK],
      },
      {
        path: '/recurring-transactions',
        name: 'Transacciones',
        icon: 'payments',
        component: RecurringTransactionList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, PK],
      },
    ],
  },
  {
    name: 'Marketplace',
    icon: 'shopping_bag',
    flexGrow: '0',
    className: '',
    roles: [R, G, F, B],
    children: [
      {
        path: '/market-affiliations',
        name: 'Afiliaciones',
        icon: 'list_alt',
        component: AffiliationsList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, KAM, CX, CXL, B],
      },
      {
        path: '/stores',
        name: 'Comercios',
        icon: 'store',
        component: StoresList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, KAM, CX, CXL, B],
      },
      {
        path: '/transactions',
        name: 'Transacciones',
        icon: 'payments',
        component: Transactions,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, CXL],
      },
    ],
  },
  {
    name: 'Usuarios',
    icon: 'people_alt',
    flexGrow: '0',
    className: '',
    children: [
      {
        path: '/users',
        name: 'Usuarios',
        icon: 'person',
        component: UsersList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, MKTL, CX, CXL, B],
      },
      {
        path: '/create-user',
        name: 'Usuarios internos',
        icon: 'group_add',
        component: UserCreate,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G],
      },
      {
        path: '/send-notifications',
        name: 'Notificar usuarios',
        icon: 'notifications',
        component: SendMasiveNotifications,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKT, MKTL, B],
      },
      {
        path: '/drivers',
        name: 'Conductores',
        icon: 'two_wheeler',
        component: DriversList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, CXL],
      },
      {
        path: '/roles',
        name: 'Roles',
        icon: 'groups',
        component: RolesList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R],
      },
    ],
  },
  {
    name: 'Promociones',
    icon: 'devices_fold',
    flexGrow: '0',
    className: '',
    children: [
      {
        path: '/coupons',
        name: 'Cupones',
        icon: 'confirmation_number',
        component: CouponsList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKTL, CXL],
      },
      {
        path: '/ban-coupons',
        name: 'Bloquear cupones',
        icon: 'block',
        component: BannedCoupons,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKTL, CXL],
      },
      {
        path: '/special-offers',
        name: 'Secciones especiales',
        icon: 'featured_play_list',
        component: SpecialOffersList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKT, MKTL, KAM],
      },
      {
        path: '/stock-limits',
        name: 'Límites de compra',
        icon: 'production_quantity_limits',
        component: StockLimitsList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKTL, KAM],
      },
      {
        path: '/special-discounts',
        name: 'Descuentos especiales',
        icon: 'discount',
        component: SpecialDiscountsList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKTL],
      },
      {
        path: '/card-discounts',
        name: 'Descuentos con\ntarjeta',
        icon: 'price_change',
        component: CardDiscountsList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKTL],
      },
      {
        path: '/banks-available-months',
        name: 'Pagos en cuotas',
        icon: 'event_repeat',
        component: BanksAvailableMonthsList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G],
      },
    ],
  },
  {
    name: 'Visuales',
    icon: 'view_carousel',
    flexGrow: '0',
    className: '',
    children: [
      {
        path: '/banners',
        name: 'Banners',
        icon: 'featured_video',
        component: BannersList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKT, MKTL],
      },
      {
        path: '/popup',
        name: 'Popup',
        icon: 'smart_button',
        component: PopupList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKT, MKTL],
      },
    ],
  },
  {
    name: 'Productos',
    icon: 'backup_table',
    flexGrow: '0',
    className: '',
    children: [
      {
        path: '/categories',
        name: 'Categorias',
        icon: 'category',
        component: CategoriesList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKT, MKTL, B],
      },
      {
        path: '/update-products',
        name: 'Actualizar productos',
        icon: 'update',
        layout: '/admin',
        flexGrow: '0',
        className: '',
        component: UpdateProducts,
        roles: [R, G, CXL, CX, B],
      },
      {
        path: '/amazon-products',
        name: 'Productos de Amazon',
        icon: 'shopping_bag',
        component: Amazon,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKT, MKTL, B],
      },
    ],
  },
  {
    name: 'Miscelaneo',
    icon: 'miscellaneous_services',
    flexGrow: '0',
    className: '',
    children: [
      {
        path: '/emails',
        name: 'Historial de correos',
        icon: 'email',
        component: EmailLogs,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKTL],
      },
      {
        path: '/searches',
        name: 'Búsquedas',
        icon: 'manage_search',
        component: Searches,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, MKT, MKTL],
      },
      {
        path: '/fees',
        name: 'Tarifas',
        icon: 'table_rows',
        component: Fees,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G],
      },
      {
        path: '/delivery-times',
        name: 'Horarios de entrega',
        icon: 'schedule',
        component: DeliveryTimes,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, CXL, CX],
      },
      {
        path: '/shipping-costs',
        name: 'Costos de envío',
        icon: 'local_shipping',
        component: ShippingCostsList,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, CXL, MKTL],
      },
      {
        path: '/black-list',
        name: 'Lista Negra',
        icon: 'fact_check',
        component: Blacklist,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, F, L, CX, CXL],
      },

      {
        path: '/log',
        name: 'Log',
        icon: 'segment',
        component: Log,
        layout: '/admin',
        flexGrow: '0',
        className: '',
        roles: [R, G, CXL],
      },
    ],
  },
  {
    path: '/logout',
    name: 'Cerrar sesión',
    icon: 'logout',
    component: Logout,
    layout: '',
    flexGrow: '1',
    className: 'd-flex align-items-end pb-3',
    roles: '*',
  },
];

const routes = [
  {
    layout: '/admin',
    path: '/drivers/new',
    component: NewDriver,
  },
  {
    layout: '/admin',
    path: '/drivers/:id/edit',
    component: NewDriver,
  },
  {
    layout: '/admin',
    path: '/users/:id/edit',
    component: EditUser,
  },
  {
    layout: '/admin',
    path: '/dashboard/:orderID',
    component: Dashboard,
  },
  {
    layout: '/admin',
    path: '/stores/new',
    component: NewStore,
  },
  {
    layout: '/admin',
    path: '/stores/:id/edit',
    component: NewStore,
  },
  {
    layout: '/admin',
    path: '/market-affiliations/:id',
    component: CheckAffiliationRequest,
  },
  {
    layout: '/admin',
    path: '/coupons/new',
    component: NewCoupon,
  },
  {
    layout: '/admin',
    path: '/coupons/:id/edit',
    component: NewCoupon,
  },
  {
    layout: '/admin',
    path: '/banners/new',
    component: NewBanner,
  },
  {
    layout: '/admin',
    path: '/banners/:id/edit',
    component: NewBanner,
  },
  {
    layout: '/admin',
    path: '/popup/new',
    component: NewPopup,
  },
  {
    layout: '/admin',
    path: '/popup/:id/edit',
    component: NewPopup,
  },
  {
    layout: '/admin',
    path: '/special-offers/new',
    component: NewSpecialOffer,
  },
  {
    layout: '/admin',
    path: '/special-offers/:id/edit',
    component: NewSpecialOffer,
  },
  {
    layout: '/admin',
    path: '/special-discounts/new',
    component: NewSpecialDiscount,
  },
  {
    layout: '/admin',
    path: '/special-discounts/:id/edit',
    component: NewSpecialDiscount,
  },
  {
    layout: '/admin',
    path: '/categories/new',
    component: NewCategory,
  },
  {
    layout: '/admin',
    path: '/categories/:id/edit',
    component: NewCategory,
  },
  {
    layout: '/admin',
    path: '/roles/new',
    component: NewRole,
  },
  {
    layout: '/admin',
    path: '/roles/:id/edit',
    component: NewRole,
  },
  {
    layout: '/admin',
    path: '/card-discounts/new',
    component: NewCardDiscount,
  },
  {
    layout: '/admin',
    path: '/card-discounts/:id/edit',
    component: NewCardDiscount,
  },
  {
    layout: '/admin',
    path: '/banks-available-months/new',
    component: NewBankAvailableMonths,
  },
  {
    layout: '/admin',
    path: '/banks-available-months/:id/edit',
    component: NewBankAvailableMonths,
  },
  {
    layout: '/admin',
    path: '/urbano/new',
    component: NewUrbano,
  },
  {
    layout: '/admin',
    path: '/urbano/:id/view',
    component: NewUrbano,
  },
  {
    layout: '/admin',
    path: '/c807/:id/view',
    component: ViewC807,
  },
  {
    layout: '/admin',
    path: '/collections/:id/edit',
    component: NewCollection,
  },
  {
    layout: '/admin',
    path: '/collections/new',
    component: NewCollection,
  },
  {
    layout: '/admin',
    path: '/push-notifications',
    component: SendMasiveNotifications,
  },
  {
    layout: '/admin',
    path: '/payment-stores/new',
    component: NewPayStore,
  },
  {
    layout: '/admin',
    path: '/payment-stores/:id/edit',
    component: NewPayStore,
  },
  {
    layout: '/admin',
    path: '/stock-limits/new',
    component: NewStockLimit,
  },
  {
    layout: '/admin',
    path: '/stock-limits/:id/edit',
    component: NewStockLimit,
  },
  {
    layout: '/admin',
    path: '/root-settings',
    component: RootSettings,
  },
  {
    layout: '/admin',
    path: '/turbo-products/:id/:store',
    component: TurboProducts,
  },
  {
    layout: '/admin',
    path: '/shipping-costs/:id',
    component: ShippingCost,
  }
];

export { routes, sidebarRoutes };
