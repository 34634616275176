import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ClimbingBoxLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import Card from 'components/Card';
import Table from 'components/Table/Table';
import ReactModal from 'components/Modal';

import colors from 'assets/resources/colors';
import api from 'utils/api';
import {
  validPctg,
  fileToBase64URL,
  uploadPictureToS3,
  deleteFromS3,
} from 'utils/misc';

const SavingStatus = {
  Idle: 'Idle',
  Saving: 'Saving',
  Error: 'Error',
  Success: 'Success',
};

const productsTable = {
  options: {
    id: 'productsId',
  },
  columns: [
    { text: '', key: 'image', className: 'product-thumbnail', isImage: true },
    { text: 'ID', key: 'productsId' },
    { text: 'Nombre', key: 'name' },
    { text: 'Categoría', key: 'category' },
    { text: 'Subcategoría', key: 'subcategory' },
    { text: 'Tienda', key: 'store' },
  ],
};

const variantsTable = {
  options: {
    id: 'productsVariantsId',
  },
  columns: [
    { text: '', key: 'image', className: 'product-thumbnail', isImage: true },
    {
      text: 'ID Variante',
      key: 'productsVariantsId',
      className: 'text-center',
    },
    { text: 'Atributos', key: 'attributes', className: 'text-center' },
    { text: 'Valores', key: 'values', className: 'text-center' },
  ],
};

const AXIOS_CANCELED_ERROR_MESSAGE = 'AXIOS_CANCELED';

function isAxiosCanceledErrorMessage(message) {
  return message === AXIOS_CANCELED_ERROR_MESSAGE;
}

function NewCollection() {
  const { id } = useParams();
  const formRef = useRef(null);
  const history = useHistory();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  const [savingStatus, setSavingStatus] = useState(SavingStatus.Idle);
  const [filterProducts, setFilterProducts] = useState(false);
  const [variantsToRemove, setVariantsToRemove] = useState([]);

  // Collection
  const [collection, setCollection] = useState();
  const [image, setImage] = useState();
  const [imageToDelete, setImageToDelete] = useState();

  // Tables
  const [allProducts, setAllProducts] = useState();
  const [selectedVariants, setSelectedVariants] = useState([]);

  // Modal
  const [showModal, setShowModal] = useState(false);
  const [variantsOnView, setVariantsOnView] = useState();
  const [selectedProduct, setSelectedProduct] = useState();

  // Set title
  useEffect(() => {
    document.title = `${
      id ? 'Editar' : 'Nueva'
    } colección | ${REACT_APP_TITLE}`;
  }, []);

  // Get products
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    api
      .findAll(
        '/products?filter=' +
          JSON.stringify({
            where: {
              active: '1',
              isDraft: false,
            },
            attributes: ['productsId', 'name'],
            include: [
              { association: 'category', attributes: ['name'] },
              { association: 'subcategory', attributes: ['name'] },
              { association: 'store', attributes: ['name'] },
              { association: 'images', attributes: ['url'] },
              { association: 'variants', attributes: ['productsVariantsId'] },
            ],
          }),
        {
          cancelToken: source.token,
        }
      )
      .then((response) => {
        setAllProducts(
          response.data.map((product) => ({
            ...product,
            category: product?.category?.name,
            subcategory: product?.subcategory?.name,
            store: product?.store?.name,
            image: product?.images[0]?.url,
          }))
        );
      })
      .catch((error) => {
        if (!isAxiosCanceledErrorMessage(error.message)) {
          console.error(error);
          toast.warning('No se pudo obtener el listado de productos');
        }
      });

    return () => {
      source.cancel(AXIOS_CANCELED_ERROR_MESSAGE);
    };
  }, []);

  // Get collection
  useEffect(() => {
    if (id) {
      api
        .findAll(
          '/collections?filter=' +
            JSON.stringify({
              where: {
                collectionsId: id,
              },
            })
        )
        .then((response) => {
          setCollection(response.data[0]);
        })
        .catch((error) => {
          toast.warning('No se pudo obtener la colección');
          console.error(error);
        });
    }
  }, []);

  // Get collection variants
  useEffect(() => {
    if (id) {
      api
        .findAll(
          '/collectionsproducts?filter=' +
            JSON.stringify({
              where: {
                fk_collectionsId: id,
              },
              attributes: ['collectionsProductsId'],
              include: [
                {
                  association: 'variant',
                  attributes: [
                    'productsVariantsId',
                    'attributes',
                    'values',
                    'image',
                  ],
                  include: [
                    {
                      association: 'product',
                      attributes: ['productsId', 'name'],
                      include: [
                        { association: 'category', attributes: ['name'] },
                        { association: 'subcategory', attributes: ['name'] },
                        { association: 'store', attributes: ['name'] },
                        {
                          association: 'images',
                          attributes: ['url'],
                          limit: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
            })
        )
        .then((response) => {
          setSelectedVariants(
            response.data.map((collectionProduct) => ({
              ...collectionProduct.variant,
              collectionsProductsId: collectionProduct.collectionsProductsId,
              attributes: collectionProduct.variant.attributes
                .split(',')
                .filter((string) => string?.trim())
                .join(' | '),
              values: collectionProduct.variant.values
                .split(',')
                .filter((string) => string?.trim())
                .join(' | '),
              productName: collectionProduct.variant.product.name,
              productsId: collectionProduct.variant.product.productsId,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
          toast.warning(
            'No se pudo obtener listado de variantes seleccionadas'
          );
        });
    }
  }, []);

  useEffect(() => {
    if (filterProducts) {
      setAllProducts(
        allProducts.filter(
          (product) =>
            !product.variants.every((variant) =>
              selectedVariants.find(
                (sVariant) =>
                  sVariant.productsVariantsId === variant.productsVariantsId
              )
            )
        )
      );
    }
  }, [filterProducts]);

  useEffect(() => {
    if (selectedVariants?.length > 0 && allProducts?.length > 0) {
      setFilterProducts(true);
    }
  }, [allProducts, selectedVariants]);

  // Get selected product variants
  useEffect(() => {
    if (selectedProduct) {
      getProductVariants(selectedProduct.productsId);
    }
  }, [selectedProduct]);

  // Set collection's image
  useEffect(() => {
    if (collection?.image) {
      setImage({ URL: collection.image });
    }
  }, [collection]);

  function getProductVariants(productId) {
    api
      .findAll(
        '/productsvariants?filter=' +
          JSON.stringify({
            where: {
              fk_productsId: productId,
              available: true,
              visibility: true,
            },
            attributes: ['productsVariantsId', 'image', 'attributes', 'values'],
          })
      )
      .then((response) => {
        setVariantsOnView(
          response.data
            .filter(
              (variant) =>
                !selectedVariants.find(
                  (iVariant) =>
                    iVariant.productsVariantsId === variant.productsVariantsId
                )
            )
            .map((variant) => ({
              ...variant,
              attributes: variant.attributes
                .split(',')
                .filter((string) => string?.trim())
                .join(' | '),
              values: variant.values
                .split(',')
                .filter((string) => string?.trim())
                .join(' | '),
            }))
        );
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener variantes del producto');
      });
  }

  async function save(formValues) {
    if (selectedVariants?.length < 1) {
      toast.error(
        'Debes seleccionar al menos una variante que pertenecerá a la colección'
      );
    } else if (!image) {
      toast.error('La colección debe tener una imagen');
    } else {
      setSavingStatus(SavingStatus.Saving);

      const collectionsEndpoint = '/collections';
      const userId = cookies[REACT_APP_COOKIES_USER_ID];

      const { title, discount, active, subtitle1, subtitle2 } = formValues;
      const body = {
        image: image.base64
          ? await uploadPictureToS3(image.base64, image.type, image.name)
          : image.URL,
        title,
        discount,
        active,
        subtitle1,
        subtitle2,
        [collection ? 'updatedBy' : 'createdBy']: userId,
      };

      const action = collection
        ? api.update(collectionsEndpoint, collection.collectionsId, body)
        : api.create(collectionsEndpoint, body);

      if (imageToDelete?.trim()) {
        await deleteFromS3(imageToDelete);
      }

      action
        .then((response) => {
          const variantsToAdd = selectedVariants.filter(
            (variant) => !variant?.collectionsProductsId
          );

          if (variantsToAdd.length > 0) {
            return api.create(
              '/collectionsproducts',
              variantsToAdd.map((variant) => ({
                fk_collectionsId: id || response.data.collectionsId,
                fk_productsVariantsId: variant.productsVariantsId,
                createdBy: userId,
              }))
            );
          }
        })
        .then(() => {
          if (variantsToRemove.length > 0) {
            return api.delete('/collectionsproducts', variantsToRemove);
          }
        })
        .then(() => {
          setSavingStatus(SavingStatus.Success);
          toast.success(`Colección ${collection ? 'actualizada' : 'creada'}`);
          history.push('/admin/collections');
        })
        .catch((error) => {
          console.error(error);
          toast.warning(
            `No se pudo ${collection ? 'actualizar' : 'crear'} la colección`
          );
          setSavingStatus(SavingStatus.Error);
        })
        .finally(() => {
          setSavingStatus(SavingStatus.Idle);
        });
    }
  }

  async function updateImage(event) {
    const file = event.target.files[0];

    if (file) {
      if (file.size / 1024 >= 2048) {
        toast.warning('Peso máximo de la imagen: 2MB');
        event.target.value = '';
      } else {
        if (image?.URL) {
          setImageToDelete(image.URL);
        }

        setImage({
          ...image,
          base64: await fileToBase64URL(file),
          type: file.type,
          name: file.name,
        });
      }
    }
  }

  function closeModal() {
    setShowModal(false);
    setVariantsOnView(null);
    setSelectedProduct(null);
  }

  function removeVariant(id) {
    const variantToRemove = selectedVariants.find(
      (variant) => variant.productsVariantsId === id
    );
    const product = variantToRemove.product;
    const productOnList = allProducts.find(
      (aProduct) => aProduct.productsId === product.productsId
    );

    if (variantToRemove?.collectionsProductsId) {
      setVariantsToRemove([
        ...variantsToRemove,
        variantToRemove.collectionsProductsId,
      ]);
    }

    if (!productOnList) {
      setAllProducts([
        {
          ...product,
          category: product?.category?.name,
          subcategory: product?.subcategory?.name,
          store: product?.store?.name,
          image: product?.images[0]?.url,
        },
        ...allProducts,
      ]);
    }

    setSelectedVariants(
      selectedVariants.filter((variant) => variant.productsVariantsId !== id)
    );

    toast.success('Variante eliminada');
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            {id ? 'Editar' : 'Nueva'} colección
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <button
            onClick={() => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
            }}
            disabled={savingStatus === SavingStatus.Saving}
            type="button"
            className="bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
          >
            {savingStatus === SavingStatus.Saving ? 'Guardando...' : 'Guardar'}
          </button>
        </div>
      </div>
      <Card className="p-4 mt-3 mt-md-4">
        {id && !collection ? (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        ) : (
          <>
            <div className="row justify-content-center">
              <div className="col-12 col-md-4 col-xl-3">
                <button
                  type="button"
                  className="w-100 add-picture-btn"
                  style={{
                    backgroundSize: image ? 'cover' : '',
                    border: '2px solid rgba(0,0,0,0.16)',
                    paddingTop: '100%',
                    borderRadius: '1rem',
                    backgroundImage: image
                      ? "url('" + (image.base64 || image.URL) + "')"
                      : '',
                  }}
                >
                  <input
                    accept=".jpg,.jpeg,.png"
                    id="image"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => updateImage(event)}
                  />
                  <label
                    htmlFor="image"
                    className="mb-0"
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      right: '0',
                      bottom: '0',
                    }}
                  />
                </button>
              </div>
            </div>
            <Formik
              innerRef={formRef}
              validationSchema={Yup.object().shape({
                title: Yup.string().trim().required('Campo requerido'),
                subtitle1: Yup.string().trim().required('Campo requerido'),
              })}
              initialValues={collection || {}}
              onSubmit={(values) => {
                save(values);
              }}
            >
              {() => (
                <Form className="row">
                  <div className="col-12 col-xl-4 mt-2">
                    <label htmlFor="title" className="d-block">
                      Título
                    </label>
                    <Field name="title">
                      {({ field, meta }) => (
                        <>
                          <input
                            className="p-2 rounded w-100"
                            type="text"
                            {...field}
                          />
                          {meta.error && (
                            <p className="text-red font-size-075x pt-1 pl-1">
                              {meta.error}
                            </p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="col-12 col-md-6 col-xl-4 mt-2">
                    <label htmlFor="discount" className="d-block">
                      Descuento
                    </label>
                    <Field name="discount">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            className="p-2 rounded w-100"
                            onChange={(event) => {
                              setFieldValue(
                                'discount',
                                validPctg(event.target.value)
                              );
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="col-12 col-md-6 col-xl-4 d-flex flex-column justify-content-center align-items-center mt-2">
                    <label className="d-block" htmlFor="active">
                      Activo
                    </label>
                    <Field name="active" type="checkbox" id="active" />
                  </div>
                  <div className="col-12 mt-2">
                    <label htmlFor="subtitle1" className="d-block">
                      Subtítulo 1
                    </label>
                    <Field name="subtitle1">
                      {({ field, meta }) => (
                        <>
                          <input
                            className="p-2 rounded w-100"
                            type="text"
                            {...field}
                          />
                          {meta.error && (
                            <p className="text-red font-size-075x pt-1 pl-1">
                              {meta.error}
                            </p>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="col-12 mt-2">
                    <label htmlFor="subtitle2" className="d-block">
                      Subtítulo 2
                    </label>
                    <Field name="subtitle2" className="p-2 rounded w-100" />
                  </div>
                </Form>
              )}
            </Formik>
            <h4 className="mt-4 mb-2 font-weight-bold">
              Productos seleccionados
            </h4>
            <Table
              options={{ ...variantsTable.options, showDelBtn: true }}
              data={selectedVariants}
              columns={[
                { text: 'Nombre', key: 'productName' },
                {
                  text: 'ID Producto',
                  key: 'productsId',
                  className: 'text-center',
                },
                ...variantsTable.columns,
              ]}
              pageSize={10}
              onDelete={(variantId) => {
                removeVariant(variantId);
              }}
            />
            {allProducts ? (
              <>
                <h4 className="mt-4 mb-2 font-weight-bold">
                  Todos los productos
                </h4>
                <Table
                  options={productsTable.options}
                  data={allProducts || []}
                  columns={productsTable.columns}
                  pageSize={10}
                  onRowClick={(id, row) => {
                    setShowModal(true);
                    setSelectedProduct(row);
                  }}
                />
              </>
            ) : (
              <div
                className="p-5 m-5 d-flex justify-content-center align-items-center"
                style={{
                  flexGrow: '1',
                }}
              >
                <ClimbingBoxLoader color={colors.green} size="25" />
              </div>
            )}
          </>
        )}
      </Card>
      {showModal && (
        <ReactModal onCloseRequest={closeModal}>
          <h4 className="mb-2 font-weight-bold">
            Selecciona la variante a añadir
          </h4>
          {variantsOnView ? (
            <Table
              data={variantsOnView}
              options={variantsTable.options}
              columns={variantsTable.columns}
              pageSize={10}
              onRowClick={(_, row) => {
                setSelectedVariants([
                  ...selectedVariants,
                  {
                    ...row,
                    product: { ...selectedProduct },
                    productName: selectedProduct.name,
                    productsId: selectedProduct.productsId,
                  },
                ]);
                if (variantsOnView.length === 1) {
                  setAllProducts(
                    allProducts.filter(
                      (product) =>
                        product.productsId !== selectedProduct.productsId
                    )
                  );
                }

                closeModal();
                toast.success('Variante añadida');
              }}
            />
          ) : (
            <div
              className="p-5 m-5 d-flex justify-content-center align-items-center"
              style={{
                flexGrow: '1',
              }}
            >
              <ClimbingBoxLoader color={colors.green} size="25" />
            </div>
          )}
        </ReactModal>
      )}
    </div>
  );
}

export default NewCollection;
