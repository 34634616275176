import React from 'react';

function Pagination(props) {
  return (
    <div
      className="d-flex justify-content-between my-2"
      style={{ position: 'sticky', left: '0' }}
    >
      <p>
        Results {props.page * props.pageSize - (props.pageSize - 1)} -{' '}
        {Math.min(props.page * props.pageSize, props.totalRows)} of{' '}
        {props.totalRows}
      </p>
      <div>
        <button
          className="mr-2"
          disabled={props.page === 1}
          onClick={() => {
            props.setPage(1);
          }}
        >
          ⏮️
        </button>
        <button
          className="mr-2"
          disabled={props.page === 1}
          onClick={() => {
            props.setPage(props.page - 1);
          }}
        >
          ◀️
        </button>
        <span className="mr-2">
          Page {props.page} of {props.totalPages}
        </span>
        <button
          className="mr-2"
          disabled={props.page === props.totalPages}
          onClick={() => {
            props.setPage(props.page + 1);
          }}
        >
          ▶️
        </button>
        <button
          className="mr-2"
          disabled={props.page === props.totalPages}
          onClick={() => {
            props.setPage(props.totalPages);
          }}
        >
          ⏭️
        </button>
      </div>
    </div>
  );
}

export default Pagination;
