import React from 'react';

function TagSelectorModalButton({ title, onClick, tags }) {
  return (
    <>
      <button
        onClick={() => {
          if (onClick) onClick(tags);
        }}
        type="button"
        className="tuyo-btn bg-green text-white px-4 py-2 rounded w-full mb-2"
      >
        {title}
      </button>
      {tags?.map((item) => (
        <button
          type="button"
          style={{
            display: 'inline-flex',
          }}
          className="p-1 m-1 rounded-lg bg-light-gray"
          key={item.id}
        >
          {item.value}
        </button>
      ))}
    </>
  );
}

export default TagSelectorModalButton;
