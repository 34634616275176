import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import mapboxgl from 'mapbox-gl';

import Card from '../components/Card';
import api from '../utils/api';
import { getOrderCode } from 'utils/misc';

mapboxgl.accessToken = 'pk.eyJ1IjoiYWlybWluZCIsImEiOiJja2VzMGI4Z3UxMno0MnRteml0NDgzNm9xIn0.x_Wm68TBw_g6ZqlSZg-SFQ';

function Monitoreo() {
  const { REACT_APP_URL, REACT_APP_TITLE } = process.env;

  useEffect(() => {
    document.title = `Monitoreo | ${REACT_APP_TITLE}`;
  }, []);

  const [drivers, setDrivers] = useState();
  const [driversLocations, setDriversLocations] = useState();
  const [orders, setOrders] = useState();

  const mapContainer = useRef(null);

  /* Get drivers, drivers' locations & orders */
  useEffect(() => {
    api.drivers
      .getAll()
      .then(res => {
        setDrivers(res.data);
      })
      .catch(err => {
        toast.warning(`[SERVER_ERROR] ${err}`);
        console.error(err);
      });

    api.drivers.locations
      .getAll()
      .then(res => {
        setDriversLocations(res.data);
      })
      .catch(err => {
        toast.warning(`[SERVER_ERROR] ${err}`);
        console.error(err);
      });

    api
      .findAll(
        '/orders?filter=' +
        JSON.stringify({
          paranoid: false,
          where: {
            $not: {
              orderStatus: {
                $or: [
                  { $iLike: 'orden entregada' },
                  { $iLike: 'orden cancelada' },
                  { $iLike: 'orden no entregada' },
                  { $iLike: 'orden no recolectada' }
                ]
              }
            }
          },
          attributes: ['ordersId', 'fk_driverId', 'apartment', 'address', 'city', 'state'],
          order: [['createdAt', 'desc']],
          include: [
            'user',
            {
              association: 'details',
              include: ['store'],
            },
          ],
        })
      )
      .then(res => {
        setOrders(res.data);
      })
      .catch(err => {
        toast.warning(`[SERVER_ERROR] ${err}`);
        console.error(err);
      });
  }, []);

  /* Render heatmap on component mount */
  useEffect(
    _ => {
      if (mapContainer.current && orders && driversLocations && drivers) {
        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [-89.2365921, 13.7034519],
          zoom: 12,
        });
        map.on('load', function () {
          const geojson = {
            type: 'FeatureCollection',
            features: driversLocations.map(driverLocation => ({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: driverLocation.location.coordinates,
              },
              properties: {
                ...driverLocation,
                ...(drivers.find(driver => driver.usersId === driverLocation.fk_usersId) || {}),
                ...(orders.find(order => order.fk_driverId === driverLocation.fk_usersId) || {}),
              },
            })),
          };

          // add markers to map
          geojson.features.forEach(marker => {
            const { fk_usersId, firstname, lastname, ordersId, apartment, address, city, state, user, details } = marker.properties;

            // create a HTML element for each feature
            var el = document.createElement('div');
            el.className = 'marker';

            // make a marker for each feature and add to the map
            new mapboxgl.Marker(el)
              .setLngLat(marker.geometry.coordinates)
              .setPopup(
                new mapboxgl.Popup({ offset: 25, maxWidth: '480px', closeButton: false }) // add popups
                  .setHTML(
                    `
                <p><span class="font-weight-bold text-green">ID del conductor: </span>${fk_usersId}<p/>
                <p class="mb-4"><span class="font-weight-bold text-green">Nombre: </span>${firstname} ${lastname}<p/>
              ` +
                    (ordersId
                      ? `
                <p><span class="font-weight-bold text-green">ID de orden: </span>${ordersId} (TY-${getOrderCode(ordersId)})<p/>
                <p><span class="font-weight-bold text-green">Dirección de entrega: </span>${[apartment, address, city, state].join(', ')}<p/>
                <p><span class="font-weight-bold text-green">Comprador: </span>${user.firstname} ${user.lastname}<p/>
                <p class="mb-3"><span class="font-weight-bold text-green">Comercios: </span>${details?.map(detail => detail?.store?.name).join(', ')}<p/>

                <div class="text-center">
                  <a href="${REACT_APP_URL + '/admin/dashboard/' + ordersId}">
                    <button
                      type="button"
                      class="bg-green tuyo-btn px-4 py-2 rounded text-light font-weight-bold">
                      Ver orden
                    </button>
                  </a>
                </div>
              `
                      : '')
                  )
              )
              .addTo(map);
          });
        });

        map.addControl(new mapboxgl.FullscreenControl());
      }
    },
    [orders, drivers, driversLocations]
  );

  return (
    <div className='content'>
      <div className='row mt-1 mt-md-2'>
        <div className='col-12 mt-2 mt-md-3'>
          <Card className='p-0'>
            <div id='map' ref={mapContainer} style={{ width: '100%', height: '600px', borderRadius: '1rem' }} />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Monitoreo;
