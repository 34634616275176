import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import Card from '../components/Card';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';
import api from '../utils/api';
import { formatPrice, parseDate } from 'utils/misc';
import SERFINSA_ERRORS from '../assets/resources/serfinsa_errors';

function Transactions() {
  const history = useHistory();
  const { REACT_APP_TITLE } = process.env;

  const showModal = (transaction) => {
    let isVoid = false;
    Swal.fire({
      title: '¿Qué quieres hacer?',
      text: `Puedes ANULAR o REEMBOLSAR la transacción ${
        transaction.usersTransactionsId
      } con monto ${formatPrice(
        parseFloat(
          transaction.amount / (transaction.amount?.includes('.') ? 1 : 100)
        )
      )} del usuario ${transaction?.user?.firstname} ${
        transaction?.user?.lastname
      }`,
      showCancelButton: true,
      showDenyButton: true,
      cancelButtonText: 'Cerrar',
      confirmButtonText: 'Anular transacción',
      denyButtonText: `Reembolsar transacción`,
      reverseButtons: true,
      closeOnClickOutside: false,
      dangerMode: true,
      className: 'md:min-w-[625px]',
    }).then((choice) => {
      if (choice.isDismissed) return null;
      isVoid = choice.isConfirmed;
      Swal.fire({
        title: `¿Estás seguro de ${
          choice.isConfirmed ? 'anular' : 'reembolsar'
        } la transacción ${transaction.usersTransactionsId}?`,
        text: `Estás a punto de ${
          choice.isConfirmed ? 'anular' : 'reembolsar'
        } la transacción con monto ${formatPrice(
          parseFloat(
            transaction.amount / (transaction.amount?.includes('.') ? 1 : 100)
          )
        )} del usuario ${transaction?.user?.firstname} ${
          transaction?.user?.lastname
        }.\nEsta acción es irreversible.`,
        icon: 'warning',
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: `${
          choice.isConfirmed ? 'Anular' : 'Reembolsar'
        } la transacción`,
        closeOnClickOutside: false,
        showLoaderOnConfirm: true,
        reverseButtons: true,
      })
        .then((result) => {
          if (result.isDismissed) return result;
          return api.transactions.refund({
            isVoid,
            amount: parseFloat(
              transaction.amount / (transaction.amount?.includes('.') ? 1 : 100)
            ),
            identifier: transaction.identifier,
            transactionId: transaction.usersTransactionsId,
            usersId: transaction.fk_usersId,
          });
        })
        .then((results) => {
          if (results.isDismissed) return toast.warning(`Acción no completada`);
          Swal.hideLoading();
          return Swal.fire('Acción realizada!', '', 'success');
        })
        .catch((err) => {
          if (err) {
            let msg = '';
            if (err.response) {
              msg = `${err?.response?.data?.resCode} - ${err?.response?.data?.resMsg}`;
            }
            toast.warning(`Error al realizar la acción ${msg}`);
            console.error(err);
          }
          Swal.hideLoading();
        });
    });
  };

  const tableProps = {
    rowId: 'usersTransactionsId',
    baseURL: '/userstransactions',
    baseFilter: {
      attributes: [
        'usersTransactionsId',
        'orderReference',
        'type',
        'card',
        'amount',
        'auth',
        'createdAt',
        'ref',
        'identifier',
        'resCode',
        'resMsg',
      ],
      order: [['usersTransactionsId', 'desc']],
      where: {
        type: {
          $and: [{ $not: 'BALANCE_ADD' }, { $not: 'BALANCE_REMOVE' }],
        },
      },
      include: [
        {
          association: 'user',
          attributes: ['usersId', 'firstname', 'lastname'],
        },
      ],
    },
    pageSize: 10,
    columns: [
      {
        title: 'Pago',
        middleware: (transaction) => (
          <>
            <div
              data-tip={
                transaction?.resCode == '00' ||
                transaction?.type == 'CASH' ||
                (!isNaN(parseInt(transaction?.auth, 10)) &&
                  transaction.ref?.trim() &&
                  transaction.auth?.trim())
                  ? 'Procesado'
                  : `Error ${transaction.resCode} - ${
                      SERFINSA_ERRORS[`ERROR ${transaction.resCode}`] || ''
                    }`
              }
              style={{ height: '18px' }}
            >
              {transaction?.resCode == '00' ||
              transaction?.type == 'CASH' ||
              (!isNaN(parseInt(transaction?.auth, 10)) &&
                transaction.ref?.trim() &&
                transaction.auth?.trim()) ? (
                <span className="material-icons-round text-green">
                  check_circle
                </span>
              ) : (
                <span className="material-icons-round text-red">warning</span>
              )}
            </div>
            <ReactTooltip effect="solid" />
          </>
        ),
      },
      {
        title: 'Id',
        objectProperty: 'usersTransactionsId',
        sortable: true,
      },
      {
        title: 'Orden',
        objectProperty: 'orderReference',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Tipo',
        objectProperty: 'type',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Monto',
        objectProperty: 'amount',
        middleware: (amount) =>
          formatPrice(parseFloat(amount / (amount?.includes('.') ? 1 : 100))),
      },
      {
        title: 'Referencia',
        objectProperty: 'ref',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Autorización',
        objectProperty: 'auth',
        searchable: true,
        searchType: 'text',
        middleware: (auth) => (!isNaN(parseInt(auth, 10)) ? auth : ''),
      },
      {
        title: 'Tarjeta',
        objectProperty: 'card',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Nombre',
        objectProperty: 'user',
        gatewayObjectProperty: '$user.firstname$',
        searchable: true,
        searchType: 'text',
        middleware: (user) => user?.firstname,
      },
      {
        title: 'Apellido',
        objectProperty: 'user',
        gatewayObjectProperty: '$user.lastname$',
        searchable: true,
        searchType: 'text',
        middleware: (user) => user?.lastname,
      },
      {
        title: 'Creado',
        objectProperty: 'createdAt',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (createdAt) => parseDate(createdAt),
      },
      {
        title: 'Error',
        middleware: (transaction) => (
          <span className="text-red">
            {transaction?.resCode == '00' ||
            transaction?.type == 'CASH' ||
            (!isNaN(parseInt(transaction?.auth, 10)) &&
              transaction.ref?.trim() &&
              transaction.auth?.trim())
              ? ''
              : transaction?.resMsg
              ? `${transaction?.resCode} - ${transaction?.resMsg}`
              : transaction?.auth?.startsWith('ERROR')
              ? `${transaction?.auth} - ${
                  SERFINSA_ERRORS[transaction?.auth] || ''
                }`
              : `Error ${transaction?.resCode} - ${
                  SERFINSA_ERRORS[`ERROR ${transaction?.resCode}`] || ''
                }`}
          </span>
        ),
      },
      {
        title: 'Acción',
        middleware: (transaction) => (
          <div>
            {transaction?.identifier?.trim() &&
            transaction?.resCode == '00' &&
            !['VOID', 'REFUND'].includes(transaction?.type) ? (
              <button
                onClick={() => showModal(transaction)}
                className="bg-red tuyo-btn text-sm py-1 px-1.5 rounded text-white font-weight-bold m-1 w-full"
              >
                Anular
              </button>
            ) : (
              ''
            )}
            <button
              onClick={() =>
                history.push(`/admin/users/${transaction.user.usersId}/edit`)
              }
              className="bg-orange tuyo-btn text-sm py-1 px-1.5 rounded text-white font-weight-bold m-1 w-full"
            >
              Ver Usuario
            </button>
          </div>
        ),
      },
    ],
  };

  useEffect(() => {
    document.title = `Transacciones Tuyo App | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Transacciones Tuyo App
          </h3>
        </div>
      </div>
      <div className="row mt-2" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-1 no-shadow">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Transactions;
