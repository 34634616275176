import React from 'react';

function SelectButton(props) {
  const { value, values, onChange, className, field, title } = props;

  const handleClick = (evt) => {
    const { value } = evt.target;
    onChange({
      [field]: value,
    });
  };

  return (
    <div className={`flex flex-column items-center ${className}`}>
      <label htmlFor={field} className="text-indigo-dark font-semibold w-full ">
        {title}
      </label>
      <select
        className="p-2 rounded w-full"
        value={value || ''}
        onChange={handleClick}
      >
        <option value="" disabled>
          Selecciona una opción
        </option>
        {values?.map((item) => {
          const { value, label } = item;
          return (
            <option key={item} value={value}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default SelectButton;
