import React, { useEffect, useState, useRef } from 'react';

import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Ticket from 'components/Ticket';
import { useCookies } from 'react-cookie';
import swal from '@sweetalert/with-react';
import ReactDOMServer from 'react-dom/server';
import { Link, useHistory } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import ReactModal from './Modal';

import SendNotification from './SendNotification';

import Status from './Status';

import Table from './Table/Table';

import {
  formatPrice,
  getDelivery,
  getGoogleMapsUrFromCoordinates,
  getOrderCode,
  getOrderStatus,
  getWebURL,
  openBase64PDFInNewTab,
  verifyAmazonASIN,
} from '../utils/misc';
import { status } from '../assets/resources/status';
import api from '../utils/api';
import circleCheckSVG from '../assets/icons/check-circle-solid-green.svg';
import colors from '../assets/resources/colors';
import InternalNotes from './OrderDetail/InternalNotes';
import OrderInvoice from './OrderInvoice';
import Signature from './OrderDetail/Signature';
import UpdateAddressModal from './OrderDetail/UpdateAddress';

const productsTable = {
  columns: [
    { text: '', key: 'image', isImage: true },
    { text: 'Product ID', key: 'productsId', className: 'text-center' },
    { text: 'SKU', key: 'sku' },
    {
      text: 'Cantidad',
      key: 'qty',
      className: 'text-center',
      isCustom: true,
      render: (qty) => {
        if (qty > 1)
          return (
            <div className="d-flex align-items-center align-items-center justify-content-center">
              <span>{qty}</span>
              <i
                style={{
                  fontSize: '1rem',
                }}
                className="material-icons text-purple"
              >
                error
              </i>
            </div>
          );

        return qty;
      },
    },
    { text: 'Nombre', key: 'name', className: 'text-center' },
    { text: 'Variación', key: 'variant' },
    {
      text: 'Precio',
      key: 'price',
      isCurrency: true,
      className: 'text-center',
    },
    {
      text: 'Descuento',
      key: 'discount',
      isPctg: true,
      className: 'text-center',
    },
    { text: 'Transporte', key: 'cargoType', className: 'text-center' },
    {
      text: 'Fragil',
      key: 'isFragile',
      className: 'text-center',
      isBoolean: true,
    },
    {
      text: 'Envío gratis',
      key: 'freeShipping',
      className: 'text-center',
      isBoolean: true,
    },
    {
      text: 'Acepta devolución',
      key: 'canRefund',
      className: 'text-center',
      isBoolean: true,
      isDestructive: true,
    },
    {
      text: 'Acepta efectivo',
      key: 'onlyCash',
      className: 'text-center',
      isBoolean: true,
      isDestructive: true,
    },
    {
      text: 'Stock interno',
      key: 'warehouse',
      className: 'text-center',
      isBoolean: true,
      isDestructive: true,
    },
    {
      text: 'Cobertura nacional',
      key: 'nationwide',
      className: 'text-center',
      isBoolean: true,
      isDestructive: true,
    },
    {
      text: 'Tiempo de preparación',
      key: 'preparationTime',
      className: 'text-center',
      isCustom: true,
      render: (preparationTime) =>
        preparationTime > 0
          ? `${parseInt(preparationTime / 24 / 60 / 60 / 1000)} Días`
          : 'N/A',
    },
    { text: 'ETA', key: 'cesc' },
    { text: 'Tipo', key: 'type' },
    { text: 'Estado', key: 'status', className: 'text-center' },
    {
      text: 'Acciones',
      key: 'sku',
      className: 'text-center',
      isCustom: true,
      render: (data, rowId) => {
        return verifyAmazonASIN(data) ? (
          <div className="d-flex justify-content-center">
            <a
              className="font-weight-bold bg-amazon w-100 h-100 rounded px-2 py-1 tuyo-btn text-white"
              onClick={(e) => e.stopPropagation()}
              href={`http://www.amazon.com/dp/${data.substring(0, 10)}`}
              target="_blank"
            >
              Amazon
            </a>
          </div>
        ) : (
          <></>
        );
      },
    },
  ],
  options: {
    id: 'ordersDetailsId',
  },
};

const OrderUpdateStatus = {
  Idle: 'Idle',
  Saving: 'Saving',
  Error: 'Error',
  Success: 'Success',
};

const orderStatusTexts = [
  {
    ids: [1],
    text: 'Orden ingresada',
  },
  {
    ids: [2],
    text: 'Orden aceptada',
  },
  {
    ids: [3],
    text: 'Orden en camino',
  },
  {
    ids: [4],
    text: 'Orden entregada',
  },
  {
    ids: [5],
    text: 'Orden no recolectada',
  },
  {
    ids: [6],
    text: 'Orden no entregada',
  },
  {
    ids: [7, 8],
    text: 'Orden cancelada',
  },
  {
    ids: [9],
    text: 'Peticion de cambio',
  },
  {
    ids: [10],
    text: 'Peticion de reembolso',
  },
  {
    ids: [11],
    text: 'Cambio aprobado',
  },
  {
    ids: [12],
    text: 'Cambio denegado',
  },
  {
    ids: [13],
    text: 'Reembolso aprobado',
  },
  {
    ids: [14],
    text: 'Reembolso denegado',
  },
  {
    ids: [15],
    text: 'Orden lista',
  },
  {
    ids: [16],
    text: 'Entregado en bodega',
  },
  {
    ids: [17],
    text: 'En ruta',
  },
  {
    ids: [18],
    text: 'Cancelado en punto de entrega',
  },
  {
    ids: [19],
    text: 'De camino al comercio',
  },
  {
    ids: [20],
    text: 'Espera en comercio',
  },
];

const OrderDetail = ({
  id,
  onCloseRequest,
  sortProductsByStore,
  showStatus,
  requestGetOrders,
}) => {
  const formRef = useRef(null);
  const { REACT_APP_COOKIES_USER_ID, REACT_APP_URBANO_SHIPPER_BRANCH_ID } =
    process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  const [drivers, setDrivers] = useState([]);
  const [driverDetail, setDriverDetail] = useState();
  const [isAssigningDriver, setIsAssigningDriver] = useState(false);
  const [isUrbanOrder, setIsUrbanOrder] = useState(false);
  const [isC807Order, setIsC807Order] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [urbanOrder, setUrbanOrder] = useState([]);
  const [c807Order, setC807Order] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [giftcard, setGiftcard] = useState(null);

  const [action, setAction] = useState();
  const [urbanoOrderStatus, setUrbanoOrderStatus] = useState(
    OrderUpdateStatus.Idle
  );
  const [possibleOrderStatus, setPossibleOrderStatus] = useState([]);
  const [newOrderStatus, setNewOrderStatus] = useState();
  const [updateOrderStatusStatus, setUpdateOrderStatusStatus] = useState(
    OrderUpdateStatus.Idle
  );
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [order, setOrder] = useState();
  const [notifications, setNotifications] = useState();

  const [c807OrderStatus, setC807OrderStatus] = useState(
    OrderUpdateStatus.Idle
  );

  const BOXES = [
    { text: 'Pequeña', value: 1 },
    { text: 'Mediana', value: 2 },
    { text: 'Grande', value: 3 },
    { text: 'XXL', value: 4 },
  ];

  const actions = {
    'Cancelado (U)': 7,
    'Cancelado (C)': 8,
    'Orden lista': 15,
  };

  const history = useHistory();

  // Get order notifications
  useEffect(() => {
    api
      .findAll(
        '/notifications?filter=' +
          JSON.stringify({
            attributes: ['createdAt', 'message'],
            where: {
              url: {
                $or: [
                  { $iLike: encodeURI(`%order/${id}%`) },
                  { $iLike: encodeURI(`%orderresume/${id}%`) },
                ],
              },
            },
            order: [['createdAt', 'asc']],
          })
      )
      .then((response) => {
        setNotifications([...response.data]);
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener notificaciones de la orden');
      });
  }, []);

  useEffect(() => {
    getData();
  }, []);

  // Get available order status
  useEffect(() => {
    api
      .findAll(
        '/purchasestatuses?filter=' +
          JSON.stringify({
            attributes: ['purchaseStatusesId', 'name'],
          })
      )
      .then((response) => {
        setPossibleOrderStatus(response.data);
      })
      .catch((error) => {
        console.error(error);
        toast.warning(
          '[SERVER_ERROR] No se pudo obtener lista de de status de órden'
        );
      });
  }, []);

  // Set first possible order status as new order status
  useEffect(() => {
    if (possibleOrderStatus?.length > 0) {
      setNewOrderStatus(possibleOrderStatus[0]);
    }
  }, [possibleOrderStatus]);

  useEffect(() => {
    api.drivers
      .getAll()
      .then((response) => {
        setDrivers(response.data);
        getDriverDetails(response.data[0].usersId, 0);
      })
      .catch((error) => {
        toast.warning('No se pudo obtener listado de conductores');
        console.error(error);
      });
  }, []);

  useEffect(() => {
    api.urbano
      .getByOrder(id)
      .then((res) => {
        setUrbanOrder(res.data);
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
        console.error(err);
      });

    api.c807
      .getByOrder(id)
      .then((res) => {
        setC807Order(res.data);
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (action) {
      updateStatus(id, action);
    }
  }, [action]);

  //get payment method
  useEffect(() => {
    if (order?.paymentType) {
      getPaymentMethod();
    }
  }, [order]);

  function getData() {
    api
      .findAll(
        '/orders?filter=' +
          JSON.stringify({
            where: { ordersId: id },
            paranoid: false,
            include: [
              {
                association: 'user',
                paranoid: false,
                attributes: [
                  'usersId',
                  'firstname',
                  'lastname',
                  'phone',
                  'email',
                ],
              },
              {
                association: 'driver',
                attributes: ['usersId', 'firstname', 'lastname', 'phone'],
              },
              {
                association: 'orders_histories',
                attributes: ['ordersHistoriesId', 'status', 'createdAt'],
                order: [['createdAt', 'asc']],
                separate: true,
              },
              {
                association: 'details',
                attributes: [
                  'fk_storesId',
                  'ordersDetailsId',
                  'fk_ordersId',
                  'price',
                  'qty',
                  'fk_purchaseStatusesId',
                  'discount',
                  'name',
                  'image',
                  'cesc',
                  'createdAt',
                  'preparationTime',
                ],
                include: [
                  {
                    association: 'details_histories',
                    attributes: ['fk_purchaseStatusesId', 'createdAt'],
                    order: [['createdAt', 'asc']],
                    separate: true,
                  },
                  {
                    association: 'store',
                    attributes: ['storesId', 'name', 'available'],
                  },
                  {
                    association: 'store_branch',
                    attributes: [
                      'storesBranchesId',
                      'name',
                      'phone',
                      'representative',
                      'address',
                    ],
                    include: [
                      {
                        association: 'city',
                        attributes: ['citiesId', 'name'],
                      },
                      {
                        association: 'state',
                        attributes: ['statesId', 'name'],
                      },
                    ],
                  },
                  {
                    association: 'variant',
                    attributes: ['productsVariantsId', 'sku', 'values'],
                    include: [
                      {
                        association: 'product',
                        attributes: [
                          'productsId',
                          'cargoType',
                          'isFragile',
                          'freeShipping',
                          'canRefund',
                          'onlyCash',
                          'warehouse',
                          'nationwide',
                        ],
                        include: [
                          {
                            association: 'flavor',
                            attributes: ['productsTypesId', 'name'],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          })
      )
      .then((response) => {
        let tmpOrder = response.data[0];

        const {
          ordersId,
          user,
          paymentStatus,
          paymentType,
          details,
          completed,
        } = tmpOrder;

        if (completed === 'gc') {
          api.giftcards
            .getOne(ordersId)
            .then((res) => setGiftcard(res?.data?.[0]))
            .catch((err) => console.error(err));
        }

        tmpOrder = {
          ...tmpOrder,
          fk_ordersId: ordersId,
          code: getOrderCode(ordersId),
          buyerPhone: user?.phone,
          paymentStatus:
            paymentStatus === 'COMPLETADO' || paymentType === 'CASH' ? (
              <img src={circleCheckSVG} alt="" style={{ height: '18px' }} />
            ) : null,
          paymentType: paymentType.split('|')[0],
          status: getOrderStatus(details),
        };

        const branchs = [];

        tmpOrder.details = tmpOrder.details?.map((detail) => {
          const {
            ordersDetailsId,
            variant,
            image,
            name,
            price,
            discount,
            store,
            store_branch,
            qty,
            fk_purchaseStatusesId,
            cesc,
            preparationTime,
            createdAt,
          } = detail;
          const { sku, product, values } = variant || {};
          const {
            cargoType,
            isFragile,
            freeShipping,
            canRefund,
            onlyCash,
            warehouse,
            nationwide,
          } = product || {};
          const {
            storesId,
            name: storeName,
            available: available,
          } = store || {};
          const {
            name: branchName,
            phone,
            representative,
            storesBranchesId,
            address,
            city,
            state,
          } = store_branch;

          return {
            status: (
              <Status
                className="w-100"
                value={fk_purchaseStatusesId}
                dataTip={detail.details_histories
                  ?.map(
                    (entry) =>
                      `[${new Date(entry.createdAt).toLocaleString()}] ${
                        status.find(
                          (status) => entry.fk_purchaseStatusesId === status.id
                        ).text
                      }`
                  )
                  .join('<br>')}
              />
            ),
            branch: {
              storesId,
              storesBranchesId,
              storeName,
              name: branchName,
              phone,
              representative,
              address: [address, city.name, state.name].join(', '),
              available,
            },
            ordersDetailsId,
            sku,
            image,
            name,
            variant: values
              ?.split(',')
              .filter((string) => string.trim())
              .join(' '),
            price,
            discount: `${discount || 0}%`,
            cargoType,
            isFragile,
            freeShipping,
            canRefund,
            onlyCash,
            warehouse,
            nationwide,
            qty,
            fk_purchaseStatusesId,
            preparationTime: preparationTime || cesc,
            cesc:
              parseInt((preparationTime || cesc), 10) >= 0 &&
              parseInt((preparationTime || cesc), 10) >= 24 * 60 * 60 * 1000
                ? getDelivery(parseInt((preparationTime || cesc), 10), createdAt)
                : '',
            createdAt,
            productsId: detail?.variant?.product?.productsId,
            type: (
              <span
                className="px-2 py-1 font-weight-bold rounded text-white"
                style={{
                  backgroundColor:
                    detail?.variant?.product?.flavor?.productsTypesId === 1
                      ? colors.green
                      : colors.goodies_pink,
                }}
              >
                {detail?.variant?.product?.flavor?.name.toUpperCase()}
              </span>
            ),
          };
        });

        tmpOrder.details.forEach((detail) => {
          const existentBranch = branchs.find(
            (branch) =>
              branch.storesBranchesId === detail.branch.storesBranchesId
          );

          if (existentBranch) {
            existentBranch.details.push(detail);
          } else {
            branchs.push({
              ...detail.branch,
              details: [detail],
            });
          }
        });

        tmpOrder.branchs = branchs;

        setOrder({ ...tmpOrder });
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener detalle de la orden');
      });
  }

  async function updateOrderStatus(
    orderId,
    orderStatus,
    purchaseStatusName,
    userId
  ) {
    await api.update('/orders', orderId, {
      orderStatus,
      updatedBy: userId,
    });

    return api.create('/ordershistories', {
      // status: purchaseStatusName,
      status: orderStatus,
      fk_ordersId: orderId,
      createdBy: userId,
    });
  }

  async function updateOrderDetailsStatus(details, statusId, userId) {
    await Promise.all(
      details?.map((detail) =>
        api.update('/orderdetails', detail.ordersDetailsId, {
          fk_purchaseStatusesId: statusId,
          updatedBy: userId,
        })
      )
    );

    return Promise.all(
      details?.map((detail) =>
        api.create('/ordersdetailshistories', {
          fk_purchaseStatusesId: statusId,
          fk_ordersDetailsId: detail.ordersDetailsId,
          createdBy: userId,
        })
      )
    );
  }

  function updateStoreOrderStatus(branch) {
    setUpdateOrderStatusStatus(OrderUpdateStatus.Saving);

    const userId = cookies[REACT_APP_COOKIES_USER_ID];
    const shouldUpdateOrderStatus =
      order?.branchs?.length === 1 ||
      order?.branchs
        ?.filter(
          (filterBranch) =>
            filterBranch?.storesBranchesId !== branch?.storesBranchesId
        )
        .every((everyBranch) =>
          everyBranch?.details?.every(
            (detail) =>
              detail?.fk_purchaseStatusesId ===
              newOrderStatus.purchaseStatusesId
          )
        );

    updateOrderDetailsStatus(
      branch?.details,
      newOrderStatus?.purchaseStatusesId,
      userId
    )
      .then(() => {
        if (shouldUpdateOrderStatus) {
          return updateOrderStatus(
            id,
            orderStatusTexts.find((status) =>
              status.ids.includes(newOrderStatus?.purchaseStatusesId)
            )?.text,
            newOrderStatus?.name,
            userId
          );
        }
      })
      .then(() => {
        toast.success(`Estado de orden de ${branch?.storeName} actualizado`);
        setUpdateOrderStatusStatus(OrderUpdateStatus.Success);
        onCloseRequest();
      })
      .catch((error) => {
        toast.warning(
          `No se pudo actualizar el estado de orden de ${branch?.storeName}`
        );
        setUpdateOrderStatusStatus(OrderUpdateStatus.Error);
        console.error(error);
      })
      .finally(() => {
        requestGetOrders();
      });
  }

  function updateWholeOrderStatus() {
    setUpdateOrderStatusStatus(OrderUpdateStatus.Saving);

    const userId = cookies[REACT_APP_COOKIES_USER_ID];

    updateOrderStatus(
      id,
      orderStatusTexts.find((status) =>
        status.ids.includes(newOrderStatus?.purchaseStatusesId)
      )?.text,
      newOrderStatus?.name,
      userId
    )
      .then(() =>
        updateOrderDetailsStatus(
          order?.details,
          newOrderStatus?.purchaseStatusesId,
          userId
        )
      )
      .then(() => {
        toast.success('Estado de orden actualizado');
        setUpdateOrderStatusStatus(OrderUpdateStatus.Success);
        onCloseRequest();
      })
      .catch((error) => {
        toast.warning('No se pudo actualizar el estado de la orden');
        console.error(error);
        setUpdateOrderStatusStatus(OrderUpdateStatus.Error);
      })
      .finally(() => {
        requestGetOrders();
      });
  }

  const getDriverDetails = (id, param) => {
    api.drivers
      .getOne(id)
      .then((res) => {
        if (param == 0) {
          setDriverDetail(res.data[0]);
        } else {
          setDriverDetail(res.data[0]);
          assignOrder(id);
        }
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
        console.error(err);
      });
  };

  const assignOrder = (driverID) => {
    setIsAssigningDriver(true);

    api.orders
      .update(id, {
        fk_driverId: driverID,
        updatedBy: cookies[REACT_APP_COOKIES_USER_ID],
      })
      .then(() => {
        toast.success('Orden asignada');
        requestGetOrders();
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
        console.error(err);
      })
      .finally(() => {
        setIsAssigningDriver(false);
      });
  };

  function showAuditChoices(orderId) {
    swal({
      title: '¿Qué quieres hacer?',
      text: 'Puedes aprobar o denegar una orden en efectivo una vez los datos hayan sido verificados',
      icon: 'info',
      buttons: {
        deny: {
          text: 'Denegar',
          value: 'Deny',
          className: 'bg-danger',
        },
        approve: { text: 'Aprobar', value: 'Approved' },
      },
      dangerMode: true,
    }).then((choice) => {
      if (choice) {
        updateOrder(orderId, choice);
      }
    });
  }

  function updateOrder(id, audit) {
    api
      .update('/orders', id, {
        audit,
        updatedBy: cookies[REACT_APP_COOKIES_USER_ID],
      })
      .then(() => {
        swal(audit === 'Approved' ? 'Orden aprobada' : 'Orden denegada', {
          icon: audit === 'Approved' ? 'success' : 'warning',
          button: false,
        });
        onCloseRequest();
        requestGetOrders();
      })
      .catch((error) => {
        toast.warning(`[SERVER_ERROR] ${error}`);
        console.error(error);
      });
  }

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  function createUrbanoOrder(formValues) {
    setUrbanoOrderStatus(OrderUpdateStatus.Saving);

    const { box, giftName, totalToCollect } = formValues;

    api.urbano
      .create({
        boxTypeId: box,
        shipperBranchId: REACT_APP_URBANO_SHIPPER_BRANCH_ID, //Tuyo's office
        isXxl: box == 4 ? 1 : 0,
        isMultiple: 0,
        isGift: giftName ? 1 : 0,
        giftName,
        totalToCollect: totalToCollect || 0,
        fk_ordersId: id,
        createdBy: cookies[REACT_APP_COOKIES_USER_ID],
      })
      .then((response) => {
        setUrbanoOrderStatus(OrderUpdateStatus.Success);
        toast.success('Orden enviada a Urbano');
        history.push(`/admin/urbano/${response.data.urbanOrdersId}/view`);
      })
      .catch((err) => {
        setUrbanoOrderStatus(OrderUpdateStatus.Error);
        console.error(err);
        toast.error('No se pudo enviar la orden a Urbano');
      });
  }

  const updateOrderDetails = async (orderDetailsId, newStatus) => {
    await api.orderDetail.update(orderDetailsId, {
      fk_purchaseStatusesId: newStatus,
      updatedBy: cookies[REACT_APP_COOKIES_USER_ID],
    });

    return api.orderDetail.statusLog.create({
      fk_purchaseStatusesId: newStatus,
      fk_ordersDetailsId: orderDetailsId,
      createdBy: cookies[REACT_APP_COOKIES_USER_ID],
    });
  };

  const updateStatus = (orderId, status) => {
    const newStatus = Object.entries(actions).find((obj) => obj[0] == status);
    setIsSaving(true);
    Promise.allSettled(
      order?.details?.map((detail) =>
        updateOrderDetails(detail.ordersDetailsId, newStatus[1])
      )
    )
      .then((response) => {
        response.forEach((res) => {
          if (res.status === 'rejected') {
            toast.warning(`[SERVER ERROR] ${res.reason}`);
          }
        });
      })
      .then(() =>
        api.orders.statusLog.create({
          status: status,
          fk_ordersId: orderId,
          createdBy: cookies[REACT_APP_COOKIES_USER_ID],
        })
      )
      .then(() =>
        api.orders.update(orderId, {
          orderStatus: status,
          updatedBy: cookies[REACT_APP_COOKIES_USER_ID],
        })
      )
      .then(() => {
        setIsSaving(false);
        toast.success('Orden actualizada');
      })
      .catch((err) => {
        console.error(err);
        toast.warning(`[SERVER_ERROR] ${err}`);
      });
  };

  async function createC807Order(formValues) {
    const now = new Date();

    now.setDate(now.getDate() + 1);

    const { weight, totalToCollect, address } = formValues;
    const userId = cookies[REACT_APP_COOKIES_USER_ID];

    setC807OrderStatus(OrderUpdateStatus.Saving);

    api.c807
      .create({
        collectDate: `${now.getFullYear()}-${(now.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now
          .getHours()
          .toString()
          .padStart(2, '0')}:${(now.getMinutes() + 1)
          .toString()
          .padStart(2, '0')}`,
        createdBy: userId,
        guide: [
          {
            ordersId: order?.ordersId,
            userName: [order?.user?.firstname, order?.user?.lastname]
              .filter((string) => string?.trim())
              .join(' '),
            address,
            phone: order?.user?.phone,
            email: order?.user?.email,
            typeOfDelivery: 'NRML',
            typeOfService: +totalToCollect > 0 ? 'CCE' : 'SER',
            ...(+totalToCollect > 0 ? { totalToCollect: totalToCollect } : {}),
            state: order?.state,
            city: order?.city,
            details: [
              {
                weight: weight ?? 1,
                content: [
                  ...order?.details?.map(
                    (detail) => `${detail?.qty} ${detail?.name}`
                  ),
                ].join(', '),
                measure: 'LB',
              },
            ],
          },
        ],
      })
      .then((response) => {
        toast.success('Orden de C807 creada');
        setC807OrderStatus(OrderUpdateStatus.Success);
        api.c807
          .genPdf({
            guide: response.data.guide,
          })
          .then((res) => openBase64PDFInNewTab(res.data.pdf))
          .catch((err) => {
            console.error(err);
            toast.warning('Error al generar PDF');
          });
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo crear orden de C807');
        setC807OrderStatus(OrderUpdateStatus.Error);
      });
  }

  function genTicket() {
    const ticket = window.open(
      '',
      '_blank',
      'location=yes,height=900,width=1000,scrollbars=yes,status=yes'
    );

    ticket.document.open();

    ticket.document.write(`<html lang="en-US"><head><title>Ticket</title>
      <link href="https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
      <style>
      body{font-family: Muli,sans-serif;}
      @page {
        size: 102mm 152mm; 
        margin: 21mm 16mm 21mm 16mm; 
      }
      </style>
      </head><body>`);
    ticket.document.write(
      ReactDOMServer.renderToString(<Ticket order={order} />)
    );
    ticket.document.write('</body></html>');

    ticket.document.close();

    ticket.onload = () => {
      setTimeout(() => {
        ticket.focus();
        ticket.print();
        setTimeout(function () {
          ticket.close();
        }, 500);
      }, 100);
    };
  }

  function getPaymentMethod() {
    return api.paymentMethods
      .getByType(order?.paymentType)
      .then(({ data }) => {
        setPaymentMethod(data?.[0] ?? {});
      })
      .catch((err) => {
        console.error(err);
        toast.warning('Error al obtener método de pago');
      });
  }

  function showInvoice(id) {
    swal({
      content: (
        <OrderInvoice
          id={id}
          order={order}
          paymentMethod={paymentMethod}
          onClose={() => swal.close()}
        />
      ),
      buttons: false,
    });
  }

  return (
    <ReactModal
      contentStyle={{
        width: '98%',
        margin: '0 auto',
        maxHeight: '98%',
        bottom: 'auto',
        top: '1%',
        left: '1%',
        right: '1%',
        boottom: '1%',
        padding: '1rem 2rem',
      }}
      onCloseRequest={onCloseRequest}
    >
      <div className="row !-mx-[30px]">
        <div className={showStatus ? 'col-12 col-md-4 col-lg-6' : 'col-12'}>
          <p className="font-weight-bold text-dark-blue font-size-2x text-center text-md-left text-center">
            ORDEN {id} (TY-{getOrderCode(id)})
          </p>
        </div>

        {order?.isTurboShipping ? (
          <div className="col-12 col-md-2 col-lg-2 d-flex justify-content-end align-items-center select-none">
            <p className="rounded bg-turbo text-white font-bold w-full m-auto p-1 text-center">
              TURBO ENVÍO
            </p>
          </div>
        ) : (
          <div className="col-12 col-md-2 col-lg-2" />
        )}

        {order?.source && (
          <div className="col-12 col-md-2 col-lg-1 d-flex justify-content-end align-items-center select-none mt-2 mt-md-0">
            <p className="rounded bg-tuyo-green text-white font-bold w-full m-auto p-1 text-center">
              {order?.source?.toUpperCase()}
            </p>
          </div>
        )}

        {order?.status && showStatus && (
          <div className="col-12 col-md-4 col-lg-3 mt-2 mt-md-0 d-flex justify-content-end align-items-center">
            <Status
              className="w-100 select-none cursor-help"
              value={order?.status}
              dataTip={
                'NOTIFICACIONES<br>' +
                notifications
                  ?.map(
                    (notification) =>
                      `[${new Date(notification.createdAt).toLocaleString()}] ${
                        notification.message
                      }`
                  )
                  .join('<br>') +
                '<br><br>CAMBIOS DE ESTADO<br>' +
                order?.orders_histories
                  ?.map(
                    (entry) =>
                      `[${new Date(entry.createdAt).toLocaleString()}] ${
                        entry.status
                      }`
                  )
                  .join('<br>')
              }
            />
          </div>
        )}
      </div>
      <div className="row mt-4 border rounded py-2 space-y-3">
        <p className="col-12 text-indigo-dark font-extrabold">Detalle:</p>
        <div className="col-12 col-md-3">
          <p className="font-weight-bold text-green">Comprador</p>
          <p>
            <Link to={`/admin/users/${order?.user?.usersId}/edit`}>
              {[order?.user?.firstname, order?.user?.lastname].join(' ')}
            </Link>{' '}
            (<a href={`tel:+503${order?.buyerPhone}`}>{order?.buyerPhone}</a>)
          </p>
        </div>

        <div className="col-12 col-md-3">
          <p className="font-weight-bold text-green">Correo</p>
          <p>{order?.user?.email}</p>
        </div>

        <div className="col-12 col-md-6 ">
          <p className="font-weight-bold text-green">Dirección de envío</p>
          <p>
            {order?.referencePoint && <b>{`${order?.referencePoint}, `}</b>}
            {[order?.apartment, order?.address, order?.city, order?.state]
              .filter((string) => string?.trim())
              .join(', ')}
          </p>
        </div>

        {order?.location?.coordinates && (
          <div className="col-6 col-md-3">
            <p className="font-weight-bold text-green">Ubicacion</p>
            <a
              href={getGoogleMapsUrFromCoordinates(
                order?.location?.coordinates[1],
                order?.location?.coordinates[0]
              )}
              target="_blank"
            >
              Ver puntero
            </a>
          </div>
        )}

        {order?.distance > 0 && (
          <div className="col-6 col-md-3">
            <p className="font-weight-bold text-green">Distancia</p>
            <p>{`${order?.distance} Km`}</p>
          </div>
        )}

        {order?.note && order?.note.trim() && (
          <div className="col-12 col-md-6">
            <p className="font-weight-bold text-green">Notas</p>
            {order?.completed === 'gc'
              ? Object.entries(JSON.parse(order?.note))?.map(
                  ([key, value], index) => (
                    <p key={index}>
                      {key}: {value}
                    </p>
                  )
                )
              : order?.note}
          </div>
        )}

        {order?.reason && (
          <div className="col-12 col-md-6">
            <p className="font-weight-bold text-green">
              Razón de reembolso/cambio
            </p>
            <p className="text-red">{order?.reason}</p>
          </div>
        )}

        {order?.eta && (
          <div className="col-12 col-md-3">
            <p className="font-weight-bold text-green">Tiempo de entrega</p>
            <p>
              {order?.eta
                .replace('|1', '')
                .split('|')
                .filter((string) => string?.trim())
                .join(', ')}
            </p>
          </div>
        )}

        <div className="col-12 col-md-3">
          <p className="font-weight-bold text-green">Fecha de creación</p>
          <p>{new Date(order?.createdAt).toLocaleString()}</p>
        </div>
      </div>

      <div className="row mt-4 py-2 border rounded space-y-3">
        <p className="col-12 text-indigo-dark font-extrabold">Montos:</p>

        <div className="col-6 col-md-2">
          <p className="font-weight-bold text-green">Método de pago</p>
          <p>{paymentMethod?.title ?? ''}</p>
        </div>

        {order?.paymentType == 'CARD_INSTALLMENT' && (
          <div className="col-6 col-md-2">
            <p className="font-weight-bold text-green">Meses</p>
            <p>{order?.months}</p>
          </div>
        )}

        <div className="col-6 col-md-2">
          <p className="font-weight-bold text-green">Subtotal</p>
          <p>
            {formatPrice(
              order?.details?.reduce((acc, detail) => {
                const { price, qty, discount } = detail;
                const discountPrice = price * ((parseInt(discount) || 0) / 100);

                return acc + (price - discountPrice) * qty;
              }, 0)
            )}
          </p>
        </div>

        <div className="col-6 col-md-2">
          <p className="font-weight-bold text-green">Costo de envío</p>
          <p>{formatPrice(order?.freeShipping ? 0 : order?.shipping)}</p>
        </div>

        {order?.bookingFee > 0 && (
          <div className="col-6 col-md-2">
            <p className="font-weight-bold text-green">Tarifa de servicios</p>
            <p>{formatPrice(order?.bookingFee)}</p>
          </div>
        )}

        {order?.completed == 'pickup' ? (
          <div className="col-6 col-md-2">
            <p className="font-weight-bold text-green">Booking fee</p>
            <p>{formatPrice(order?.bookingFee)}</p>
          </div>
        ) : (
          ''
        )}

        {order?.coupon && (
          <div className="col-6 col-md-2">
            <p className="font-weight-bold text-green">
              Cupón ({order?.coupon.split('|')[0]})
            </p>
            <p className="text-red">
              - {formatPrice(order?.coupon.split('|')[1])}
            </p>
          </div>
        )}

        {order?.balance ? (
          <div className="col-6 col-md-2">
            <p className="font-weight-bold text-green">Balance</p>
            <p className="text-red">- {formatPrice(order?.balance)}</p>
          </div>
        ) : (
          ''
        )}

        {order?.cardDiscount?.trim() && (
          <div className="col-6 col-md-3">
            <p className="font-weight-bold text-green">
              Descuentos con tarjeta
            </p>
            {order?.cardDiscount
              ?.split('|')
              ?.map((discount) => discount.split(','))
              ?.map((discount) => {
                const [, type, cardType, bank, value, totalDiscount] = discount;
                return (
                  <p className="text-red">{`-$${parseFloat(
                    totalDiscount
                  ).toFixed(2)} ${
                    value < 1 ? `(${value * 100}%) ` : ''
                  } Tarjeta ${cardType} de ${bank} en ${type}`}</p>
                );
              })}
          </div>
        )}

        {order?.millas ? (
          <div className="col-6 col-md-2">
            <p className="font-weight-bold text-green">Millas</p>
            <p>{formatPrice(order?.millas)}</p>
          </div>
        ) : (
          ''
        )}

        <div className="col-6 col-md-2">
          <p className="font-weight-bold text-green">Monto total</p>
          <b>{formatPrice(order?.total)}</b>
        </div>
      </div>

      {!(order?.completed === 'gc') && (
        <>
          {(order?.dui || order?.signature) && (
            <Signature dui={order?.dui} signature={order?.signature} />
          )}

          {order && <UpdateAddressModal order={order} onUpdate={getData} />}
        </>
      )}

      {giftcard && (
        <div className="row mt-4 py-2 border rounded space-y-3">
          <p className="col-12 text-indigo-dark font-extrabold">Giftcard:</p>
          <div className="col-12 col-md-4">
            <p className="font-weight-bold text-green">Giftcard</p>
            <p>{giftcard?.code}</p>
          </div>
          <div className="col-12 col-md-4">
            <p className="font-weight-bold text-green">Monto</p>
            <p>{formatPrice(giftcard?.value)}</p>
          </div>
          <div className="col-12 col-md-4">
            <p className="font-weight-bold text-green">Usado</p>
            <span
              className={`material-icons ${
                giftcard.used ? 'text-green' : 'text-red'
              }`}
            >
              {giftcard?.used ? 'check_circle' : 'cancel'}
            </span>
          </div>
          {giftcard?.used && (
            <>
              <div className="col-12 col-md-4">
                <p className="font-weight-bold text-green">Usado por</p>
                <p>
                  {giftcard?.redeemed?.firstname ||
                    '' + giftcard?.redeemed?.lastname ||
                    ''}
                </p>
              </div>
            </>
          )}
        </div>
      )}

      <InternalNotes cxNotes={order?.cxNotes} ordersId={order?.ordersId} />

      {!(order?.completed === 'gc') && (
        <div className="row mt-4 py-2 border rounded space-y-3">
          <p className="col-12 text-indigo-dark font-extrabold">Conductor:</p>

          {order?.driver?.usersId ? (
            <>
              <div className="col-12 col-md-6">
                <p className="font-weight-bold text-green">
                  Conductor asignado
                </p>
                <p>
                  {[order?.driver?.firstname, order?.driver?.lastname].join(
                    ' '
                  )}
                </p>
              </div>
              <div className="col-12 col-md-6">
                <p className="font-weight-bold text-green">Contacto</p>
                <p>
                  <a href={`tel:+503${order?.driver?.phone}`}>
                    {order?.driver?.phone}
                  </a>
                </p>
              </div>
              <br />
              <div className="col-12 col-md-4  d-flex align-items-center">
                <p className="font-weight-bold text-green">
                  Reasignar conductor
                </p>
                <select
                  name="DriverList"
                  className="w-100 rounded p-2"
                  onChange={(event) => getDriverDetails(event.target.value, 1)}
                >
                  {drivers?.map((driver) => (
                    <option key={driver.usersId} value={driver.usersId}>
                      {driver.firstname} {driver.lastname}
                    </option>
                  ))}
                </select>
              </div>
            </>
          ) : (
            <>
              <div className="col-12 col-md-6 col-lg-4">
                <label
                  className="font-weight-bold text-green"
                  htmlFor="select-driver"
                >
                  Seleccione un conductor
                </label>
                <select
                  id="select-driver"
                  name="DriverList"
                  className="w-100 rounded p-2"
                  onChange={(event) => getDriverDetails(event.target.value, 0)}
                >
                  {drivers?.map((driver) => (
                    <option key={driver.usersId} value={driver.usersId}>
                      {driver.firstname} {driver.lastname}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <p className="font-weight-bold text-green">Información</p>
                <p>
                  Nombre: {driverDetail?.firstname} {driverDetail?.lastname}
                </p>
                <p>
                  Contacto:{' '}
                  <a href={`tel:+503${driverDetail?.phone}`}>
                    {driverDetail?.phone}
                  </a>
                  ,{' '}
                  <a href={`mailto:${driverDetail?.email}`}>
                    {driverDetail?.email}
                  </a>
                </p>
              </div>
              <div className="col-12 col-lg-4 mt-2 mt-lg-0 d-flex flex-column justiy-content-center">
                <button
                  disabled={
                    isAssigningDriver ||
                    !driverDetail ||
                    (order?.paymentType === 'PUNTO_XPRESS' &&
                      order?.paymentStatus === 'PENDIENTE')
                  }
                  type="button"
                  className="d-flex align-items-center justify-content-around bg-purple tuyo-btn w-100 px-4 py-2 rounded text-light font-weight-bold"
                  onClick={() => assignOrder(driverDetail.usersId)}
                >
                  {order?.paymentType === 'PUNTO_XPRESS' &&
                  order?.paymentStatus === 'PENDIENTE'
                    ? 'Pago no completado'
                    : `${
                        isAssigningDriver ? 'Asignando' : 'Asignar'
                      } conductor`}
                  <BounceLoader
                    color="#fff"
                    loading={isAssigningDriver}
                    size="18"
                  />
                </button>
              </div>
            </>
          )}
        </div>
      )}

      {['urbano', 'urbano-amazon'].includes(order?.completed) && (
        <div className="row mt-4 py-2 border rounded">
          <p className="col-12 text-indigo-dark font-extrabold">
            Asignar orden:
          </p>

          <div className="col-12 my-3 col-md-12 d-flex flex-row align-items-center justify-content-around">
            <button
              onClick={() => {
                setIsUrbanOrder((prev) => {
                  if (!prev) {
                    setIsC807Order(false);
                  }
                  return !prev;
                });
              }}
              id="create-urbano-order-button"
              className="d-flex flex-row align-items-center"
            >
              <p className="font-weight-bold text-green mr-3">
                Asignar orden a urbano
              </p>
              <input type="checkbox" checked={isUrbanOrder} />
            </button>
            <button
              onClick={() => {
                setIsC807Order((prev) => {
                  if (!prev) {
                    setIsUrbanOrder(false);
                  }
                  return !prev;
                });
              }}
              id="create-c807-order-button"
              className="d-flex flex-row align-items-center"
            >
              <p className="font-weight-bold text-green mr-3">
                Asignar orden a C807
              </p>
              <input type="checkbox" checked={isC807Order} />
            </button>
          </div>

          {isC807Order && (
            <div className="col-12 col-md-12 d-flex flex-column justiy-content-center mt-2">
              {c807Order.length
                ? c807Order?.map((x) => (
                    <div>
                      <Link
                        to={{
                          pathname: `/admin/c807/${x.c807OrdersId}/view`,
                        }}
                        className="w-100"
                      >
                        {`Orden #${x.c807OrdersId} de C807`}
                      </Link>
                    </div>
                  ))
                : null}
              <Formik
                enableReinitialize={true}
                innerRef={formRef}
                onSubmit={(values) => createC807Order(values)}
                initialValues={{
                  weight: '',
                  address: [
                    order?.apartment,
                    order?.address,
                    order?.referencePoint,
                  ]
                    .filter((string) => string?.trim())
                    .join(', '),
                  totalToCollect:
                    order?.paymentType == 'CASH' ? order?.total : 0,
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.weight) errors.weight = 'Requerido';
                  if (!values.address) errors.address = 'Requerido';
                  if (!values.totalToCollect)
                    errors.totalToCollect = 'Requerido';
                  return errors;
                }}
              >
                <Form>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <p className="font-weight-bold text-green">
                        Ingrese peso del producto (en libras).
                      </p>
                      <Field
                        name="weight"
                        text="text"
                        placeholder="5"
                        className="w-100 rounded p-2"
                      />
                      <ErrorMessage name="weight">
                        {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="font-weight-bold text-green">
                        Total a recolectar
                      </p>
                      <Field
                        name="totalToCollect"
                        text="number"
                        placeholder="9.99"
                        className="w-100 rounded p-2"
                      />
                      <ErrorMessage name="totalToCollect">
                        {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="font-weight-bold text-green">Direccion</p>
                      <Field
                        name="address"
                        text="text"
                        placeholder="Tuyo App"
                        className="w-100 rounded p-2"
                      />
                      <ErrorMessage name="address">
                        {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12 col-md-12 mt-2 mb-2 d-flex align-items-end">
                      <button
                        disabled={c807OrderStatus === OrderUpdateStatus.Saving}
                        type="button"
                        className="d-flex align-items-center justify-content-around bg-purple tuyo-btn w-100 px-4 py-2 rounded text-light font-weight-bold"
                        onClick={handleSubmit}
                      >
                        {c807OrderStatus === OrderUpdateStatus.Saving
                          ? 'Creando...'
                          : 'Crear orden de C807'}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          )}

          {isUrbanOrder && (
            <div className="col-12 col-md-12 d-flex flex-column justiy-content-center mt-2">
              {urbanOrder.length
                ? urbanOrder?.map((x) => (
                    <div>
                      <Link
                        to={{
                          pathname: `/admin/urbano/${x.urbanOrdersId}/view`,
                        }}
                        className="w-100"
                      >
                        {`Orden #${x.urbanOrdersId} de urbano`}
                      </Link>
                    </div>
                  ))
                : null}

              <Formik
                enableReinitialize={true}
                innerRef={formRef}
                onSubmit={(values) => {
                  createUrbanoOrder(values);
                }}
                initialValues={{
                  box: BOXES[0].value,
                  giftName: '',
                  totalToCollect:
                    order?.paymentType == 'CASH' ? order?.total : 0,
                }}
              >
                <Form>
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <p className="font-weight-bold text-green">
                        Seleccione una caja
                      </p>
                      <Field
                        as="select"
                        name="box"
                        className="p-2 rounded w-100"
                      >
                        {BOXES?.map((boxType) => (
                          <option value={boxType?.value} key={boxType?.value}>
                            {boxType?.text}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="col-12 col-md-5">
                      <p className="font-weight-bold text-green">
                        Texto para regalo
                      </p>
                      <Field
                        name="giftName"
                        text="text"
                        placeholder="Un regalo de Tuyo"
                        className="w-100 rounded p-2"
                      />
                    </div>
                    <div className="col-12 col-md-3">
                      <p className="font-weight-bold text-green">
                        Total a recolectar
                      </p>
                      <Field
                        name="totalToCollect"
                        text="number"
                        placeholder="9.99"
                        className="w-100 rounded p-2"
                      />
                    </div>
                    <div className="col-12 col-md-12 mt-2 mb-2 d-flex align-items-end">
                      <button
                        disabled={
                          urbanoOrderStatus === OrderUpdateStatus.Saving
                        }
                        type="button"
                        className="d-flex align-items-center justify-content-around bg-purple tuyo-btn w-100 px-4 py-2 rounded text-light font-weight-bold"
                        onClick={handleSubmit}
                      >
                        {urbanoOrderStatus === OrderUpdateStatus.Saving
                          ? 'Creando...'
                          : 'Crear'}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          )}
        </div>
      )}

      {sortProductsByStore ? (
        order?.branchs?.map((branch) => {
          const { storeName, name, representative, phone, address, available } =
            branch;

          return (
            <div
              key={branch.storesBranchesId}
              className="row mt-4 border rounded py-2"
            >
              <div className="col-12 col-md-8">
                <p>
                  <span className="font-weight-bold text-green">Sucursal:</span>{' '}
                  {storeName} - {name} -{' '}
                  <span className={available ? 'text-green' : 'text-red'}>
                    {available ? 'Comercio abierto' : 'Comercio cerrado'}
                  </span>
                </p>
                <p className="mt-1 mt-md-0">
                  <span className="font-weight-bold text-green">Contacto:</span>{' '}
                  {representative} (<a href={`tel:+503${phone}`}>{phone}</a>)
                </p>
                <p className="mt-1 mt-md-0">
                  <span className="font-weight-bold text-green">
                    Dirección:
                  </span>{' '}
                  {address}
                </p>
              </div>
              <div className="col-12 col-md-4 mt-2 mt-lg-0">
                <select
                  name={`newOrderStatus-${branch.storesBranchesId}`}
                  id={`newOrderStatus-${branch.storesBranchesId}`}
                  className="w-100"
                  value={newOrderStatus?.purchaseStatusesId}
                  onInput={(event) =>
                    setNewOrderStatus(
                      possibleOrderStatus.find(
                        (status) =>
                          status.purchaseStatusesId ===
                          parseInt(event.target.value)
                      )
                    )
                  }
                >
                  {possibleOrderStatus?.map((status) => (
                    <option
                      key={status.purchaseStatusesId}
                      value={status.purchaseStatusesId}
                    >
                      {status.name}
                    </option>
                  ))}
                </select>
                <button
                  disabled={
                    !newOrderStatus ||
                    updateOrderStatusStatus === OrderUpdateStatus.Saving
                  }
                  type="button"
                  className="tuyo-btn w-100 rounded bg-red text-light font-weight-bold py-2 mt-2"
                  onClick={() => {
                    updateStoreOrderStatus(branch);
                  }}
                >
                  {updateOrderStatusStatus === OrderUpdateStatus.Saving
                    ? 'Fijando...'
                    : 'Fijar estado'}
                </button>
              </div>
              <div className="col-12">
                <Table
                  {...productsTable}
                  data={branch?.details}
                  pageSize={10}
                  hideSearchBar
                  onRowClick={(_, row) => {
                    window.open(
                      `https://tuyoapp.com${getWebURL(
                        row.productsId,
                        row.name,
                        'P'
                      )}`
                    );
                  }}
                />
              </div>
            </div>
          );
        })
      ) : (
        <div className="row mt-4">
          <div className="col-12">
            {order?.details && (
              <Table
                onRowClick={(_, row) => {
                  window.open(
                    `https://tuyoapp.com${getWebURL(
                      row.productsId,
                      row.name,
                      'P'
                    )}`
                  );
                }}
                {...productsTable}
                data={order?.details}
                pageSize={10}
                hideSearchBar
              />
            )}
          </div>
        </div>
      )}

      <div className="row space-y-3 !-mx-[30px]">
        <div className="col-12 col-md-6 row px-0 mx-0 mt-4 h-10">
          <div className="col-12 col-md-6 d-flex">
            <select
              name="newOrderStatus"
              id="newOrderStatus"
              className="w-100"
              value={newOrderStatus?.purchaseStatusesId}
              onInput={(event) =>
                setNewOrderStatus(
                  possibleOrderStatus.find(
                    (status) =>
                      status.purchaseStatusesId === parseInt(event.target.value)
                  )
                )
              }
            >
              {possibleOrderStatus?.map((status) => (
                <option
                  key={status.purchaseStatusesId}
                  value={status.purchaseStatusesId}
                >
                  {status.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-6 d-flex">
            <button
              disabled={
                !newOrderStatus ||
                updateOrderStatusStatus === OrderUpdateStatus.Saving
              }
              type="button"
              className="tuyo-btn w-100 rounded bg-red text-light font-weight-bold"
              onClick={updateWholeOrderStatus}
            >
              {updateOrderStatusStatus === OrderUpdateStatus.Saving
                ? 'Fijando...'
                : 'Fijar estado'}
            </button>
          </div>
        </div>
        {order?.paymentType === 'CASH' && (
          <div className="col-12 col-md-6 d-flex flex-column justiy-content-center mt-4 h-10">
            <button
              disabled={order?.audit}
              type="button"
              className="d-flex align-items-center justify-content-around bg-red tuyo-btn w-100 px-4 py-2 rounded text-light font-weight-bold"
              onClick={() => showAuditChoices(id)}
            >
              {order?.audit
                ? order?.audit == 'Approved'
                  ? 'Auditada - Aprobada'
                  : order?.audit == 'Deny'
                  ? 'Auditada - Rechazada'
                  : 'Auditada'
                : 'Auditar'}
            </button>
          </div>
        )}

        <div className="col-12 row px-0 mx-0">
          <div className="col-12 col-md-4">
            <button
              className="bg-green tuyo-btn w-100 px-4 py-2 rounded text-light font-weight-bold"
              onClick={genTicket}
            >
              Imprimir ticket
            </button>
          </div>
          <div className="col-12 col-md-4">
            <button
              className="bg-green tuyo-btn w-100 px-4 py-2 rounded text-light font-weight-bold"
              onClick={() => {
                setShowNotificationModal(true);
              }}
            >
              Enviar notificacion
            </button>
            {showNotificationModal && (
              <SendNotification
                usersIds={[order?.user?.usersId]}
                appURL={`tuyoapp://order/${id}`}
                webURL={`https://tuyoapp.com/orders/TY-${getOrderCode(id)}`}
                onCloseRequest={() => {
                  setShowNotificationModal(false);
                }}
              />
            )}
          </div>
          <div className="col-12 col-md-4">
            <button
              className="bg-green tuyo-btn w-100 px-4 py-2 rounded text-light font-weight-bold"
              onClick={showInvoice}
            >
              Generar comprobante
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default OrderDetail;
export { orderStatusTexts };
