import React from 'react';

function Tabs({ tabs, onChange, activeTab, vertical }) {
  return (
    <div className={['tuyo-tabs', (vertical ? 'vertical' : '')].join(' ')} >
      {tabs.filter(tab => !tab.hidden).map(option => (
        <button
          className={'tab ' + (option.key === activeTab ? 'active' : '')}
          key={option.key}
          style={{ cursor: 'pointer' }}
          onClick={_ => onChange(option.key)}
        >
          {option.text}
          {
            option.badge ?
              (
                <div className={"d-inline-block font-size-08x ml-3 py-1 px-2 rounded text-white " + (option.key === activeTab ? 'bg-purple' : 'bg-light-gray')}>
                  {option.badge}
                </div>
              )
              : null
          }
        </button>
      ))}
    </div>
  );
}

export default Tabs;
