import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import mapboxgl from 'mapbox-gl';

import Card from '../components/Card';
import api from '../utils/api';
import swal from '@sweetalert/with-react';
import DriverMapModal from 'components/drivers/MapModal';
import StoreMapModal from 'components/StoreMapModal';
import Select from 'react-select';

mapboxgl.accessToken =
  'pk.eyJ1IjoiYWlybWluZCIsImEiOiJja2VzMGI4Z3UxMno0MnRteml0NDgzNm9xIn0.x_Wm68TBw_g6ZqlSZg-SFQ';

function Positions() {
  const { REACT_APP_TITLE } = process.env;

  useEffect(() => {
    document.title = `Monitoreo | ${REACT_APP_TITLE}`;
  }, []);

  const [drivers, setDrivers] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [stores, setStores] = useState([]);
  const mapContainer = useRef(null);

  /* Get drivers, drivers' locations & orders */
  useEffect(() => {
    api.drivers
      .getAllWithOrders()
      .then((res) => {
        setDrivers(res.data);
      })
      .catch((err) => {
        toast.warning(`Error al obtener los drivers: ${err.toString()}`);
        console.error(err);
      });
    api.stores
      .getAllWithOrders()
      .then((res) => {
        setStores(res.data);
      })
      .catch((err) => {
        toast.warning(`Error al obtener los comercios: ${err.toString()}`);
        console.error(err);
      });
  }, []);

  const showModal = (data, isStore = false) => {
    const Component = isStore ? StoreMapModal : DriverMapModal;
    swal({
      className: 'lg:min-w-[600px]',
      content: <Component data={data} onClose={() => swal.close()} />,
      buttons: false,
    });
  };

  const renderMarkers = (map, drivers, stores) => {
    drivers.forEach((driver) => {
      const { orders_drivers: orders } = driver;
      // create a HTML element for each feature
      const el = document.createElement('div');
      el.className = `marker after:content-['${orders.length}'] after:absolute after:text-center after:text-white after:text-[10px] after:leading-none after:px-1.5 after:py-0.5 after:mt-[32px] after:min-w-[32px] after:font-bold after:bg-slate-600`;
      el.style.backgroundImage = `url('https://tuyomedia.sfo2.digitaloceanspaces.com/map-pins/delivery_icon.png')`;
      el.addEventListener('click', () => showModal({ ...driver, orders }));

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el)
        .setLngLat(driver.current_location.location.coordinates)
        .addTo(map);
    });

    stores.forEach((store) => {
      const orders =
        store.orders_details?.map((x) => x.order?.ordersId)?.filter((x) => x) ??
        [];
      const el = document.createElement('div');
      el.className = `marker after:content-['${
        orders?.filter((x, i) => orders?.indexOf(x) === i)?.length
      }'] after:absolute after:text-center after:text-white after:text-[10px] after:leading-none after:px-1.5 after:py-0.5 after:mt-[32px] after:min-w-[32px] after:font-bold after:bg-green-600`;
      el.style.backgroundImage = `url('https://tuyomedia.sfo2.digitaloceanspaces.com/map-pins/commerce_icon.png')`;
      el.addEventListener('click', () => showModal(store, true));

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el)
        .setLngLat(store.stores_branches[0].location.coordinates)
        .addTo(map);
    });
  };

  /* Render heatmap on component mount */
  useEffect(
    (_) => {
      if (mapContainer.current && drivers && stores) {
        let renderDrivers = drivers;
        if (selectedDrivers.length > 0) {
          const ids = selectedDrivers.map((driver) => driver.value);
          renderDrivers = drivers.filter((driver) =>
            ids.includes(driver.usersId)
          );
        }
        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [-89.2365921, 13.7034519],
          zoom: 12,
        });
        map.on('load', function () {
          renderMarkers(map, renderDrivers, stores);
        });
        map.addControl(new mapboxgl.FullscreenControl());
      }
    },
    [drivers, stores, selectedDrivers]
  );

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-6 mb-2">
          <label className="d-block">Filtrar drivers</label>
          <Select
            options={drivers.map((driver) => ({
              value: driver?.usersId,
              label: `${driver?.firstname} ${driver?.lastname}`,
            }))}
            isMulti
            isLoading={false}
            loadingMessage={() => 'Cargando...'}
            placeholder="Selecciona..."
            onChange={(value) => setSelectedDrivers([...value])}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Card className="p-0">
            <div
              id="map"
              ref={mapContainer}
              style={{ width: '100%', height: '600px', borderRadius: '1rem' }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Positions;
