import React from 'react';
import BarLoader from 'react-spinners/BarLoader';

import Choice from '../Choice/Choice';
import Status from '../Status';

import { STATUS } from '../../assets/resources/status';
import colors from 'assets/resources/colors';
import { formatPrice, formatAmount } from 'utils/misc';

function Cell({
  columnDef,
  rowId,
  data,
  rowStatus,
  rowIsSaving,
  hideChoices,
  setIDRowSaving,
  onChoiceChange,
  updateOrderStatus
}) {
  return (
    <td
      key={`${columnDef.key}-${rowId}`}
      className={'py-2 px-1 py-md-3 px-md-2 ' + columnDef.className}>
      {
        columnDef.isImage
          ? <div
            style={{ backgroundImage: 'url(\'' + data + '\')', }}
            className="product-thumbnail" />
          : columnDef.isChoice
            ? !columnDef.hidden(rowStatus)
              ? rowIsSaving
                ? (
                  <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <BarLoader
                      size="10"
                      loading={true}
                      color={colors.green} />
                  </div>
                )
                : <Choice
                  hide={hideChoices}
                  text="Aprobar"
                  onChange={(val) => {
                    setIDRowSaving(rowId);
                    onChoiceChange(rowId, val);
                  }} />
              : rowStatus === STATUS.ORDER_ACCEPTED
                ? rowIsSaving
                  ? (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                      <BarLoader
                        size="10"
                        loading={rowIsSaving}
                        color={colors.green} />
                    </div>
                  )
                  : (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIDRowSaving(rowId);
                        updateOrderStatus(rowId, STATUS.READY);
                      }}
                      className="tuyo-btn bg-green rounded text-white font-weight-bold py-1 px-2 w-100">
                      Orden lista
                    </button>
                  )
                : ''
            : columnDef.isButton
              ? !columnDef.hidden(data)
                ? (
                  <button
                    onClick={event => {
                      event.stopPropagation();
                      columnDef.onClick(rowId);
                    }}
                    className={`font-weight-bold bg-light-gray w-100 h-100 rounded py-2 tuyo-btn ${columnDef.className}`}
                    type="button">
                    {columnDef.buttonText}
                  </button>
                )
                : null
              : columnDef.isStatus
                ? <Status
                  className="w-100"
                  value={data} />
                : columnDef.isCurrency
                  ? formatPrice(data)
                  : columnDef.isQty
                    ? formatAmount(data)
                    : columnDef.middleware ? columnDef.middleware(data) : data
      }
    </td>
  )
};

export default Cell;
