import React from 'react';
import ReactTooltip from 'react-tooltip';

import Cell from './Cell';

import viewSVG from '../../assets/icons/eye-regular.svg';
import updateSVG from '../../assets/icons/update.svg';
import deleteSVG from '../../assets/icons/delete.svg';
import disableSVG from '../../assets/icons/eye-slash-regular.svg';

function Row({
  onClick,
  rowId,
  columns,
  options,
  onView,
  onUpdate,
  onDelete,
  onChoiceChange,
  onDisable,
  setIDRowSaving,
  updateOrderStatus,
  hideChoices,
  isSaving,
  status,
  data,
  warning
}) {
  return (
    <tr
      style={{ cursor: onClick ? 'pointer' : 'default' }}
      onClick={() => onClick && onClick(rowId)}
      key={rowId}
      className={`${onClick ? 'hoverable' : ''} ${warning ? 'low-stock' : ''}`}>
      {
        columns.map((column, index) => (
          <Cell
            key={index}
            setIDRowSaving={rowId => setIDRowSaving(rowId)}
            onChoiceChange={(rowId, val) => onChoiceChange(rowId, val)}
            updateOrderStatus={(rowId, val) => updateOrderStatus(rowId, val)}
            hideChoices={hideChoices}
            rowIsSaving={isSaving}
            rowStatus={status}
            data={data[column.key]}
            rowId={rowId}
            columnDef={column} />
        ))
      }
      {
        (options.showViewBtn || options.showUpdateBtn || options.showDelBtn || options.showDisableBtn) &&
        <td key={'options-' + rowId}>
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            {
              options.showViewBtn &&
              <>
                <button
                  type="button"
                  className="p-2 mx-1 opt-btn"
                  data-tip="Ver"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onView(rowId);
                  }}>
                  <img src={viewSVG} alt="" style={{ height: '18px' }} />
                </button>
                <ReactTooltip effect="solid" />
              </>
            }
            {
              options.showUpdateBtn &&
              <>
                <button
                  type="button"
                  className="p-2 mx-1 opt-btn"
                  data-tip="Actualizar"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onUpdate(rowId);
                  }}>
                  <img src={updateSVG} alt="" style={{ height: '18px' }} />
                </button>
                <ReactTooltip effect="solid" />
              </>
            }
            {
              options.showDelBtn &&
              <>
                <button
                  type="button"
                  data-tip="Eliminar"
                  className="p-2 mx-1 opt-btn"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onDelete(rowId);
                  }}>
                  <img src={deleteSVG} alt="" style={{ height: '18px' }} />
                </button>
                <ReactTooltip effect="solid" />
              </>
            }
            {
              options.showDisableBtn &&
              <>
                <button
                  type="button"
                  data-tip={
                    data.active 
                    ? data.active == '1' ?  'Deshabilitar' : 'Habilitar'
                    : data.user.active == '1' ? 'Deshabilitar' : 'Habilitar'
                  }
                  className="p-2 mx-1 opt-btn"
                  onClick={(ev) =>{
                    ev.stopPropagation();
                    onDisable(rowId);
                  }}
                >
                  <img 
                    src={
                      data.active 
                        ? data.active == '1' ?  disableSVG : viewSVG
                        : data.user.active == '1' ? disableSVG : viewSVG
                    } 
                    alt="" style={{ height: '18px' }} 
                  />
                </button>
                <ReactTooltip effect="solid" />
              </>
            }
          </div>
        </td>
      }
    </tr>
  );
};

export default Row;
