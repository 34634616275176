import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import BounceLoader from 'react-spinners/BounceLoader';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import Card from '../../components/Card';

import api from '../../utils/api';
import colors from '../../assets/resources/colors';
import {
  TagSelectorModal,
  TagSelectorModalType,
} from 'components/TagSelectorModal';
import { DatePickerField } from 'components/DateTimeField';
import LimitsTagSelector from 'components/StockLimits/LimitsTagSelector';

function NewStockLimit() {
  const formRef = useRef(null);
  const history = useHistory();

  /* URL params */
  const { id: stockLimitsId } = useParams();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  const [initilizedValues, setInitilizedValues] = useState();
  const [specialDiscount, setSpecialDiscount] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    items: [],
    initialItems: [],
    type: null,
    onSubmit: () => {},
  });

  /* Set page title */
  useEffect(() => {
    document.title = `${
      stockLimitsId ? 'Editar' : 'Nueva'
    } limitacion de compra | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    if (specialDiscount) initializeIds();
  }, [specialDiscount]);

  useEffect(() => {
    if (stockLimitsId) {
      api.stockLimits
        .getOne(stockLimitsId)
        .then(({ data }) => {
          setSpecialDiscount({ ...data });
        })
        .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));
    }
  }, []);

  function groupByLimit(arr) {
    let grouped = [];

    (arr ?? []).forEach((el) => {
      const finded = grouped.findIndex((e) => +e.limit === +el.limit);
      if (finded >= 0) {
        grouped[finded].id.push({
          id: el.id,
          value: el.value,
        });
      } else {
        grouped.push({
          limit: +el.limit,
          id: [
            {
              id: el.id,
              value: el.value,
            },
          ],
        });
      }
    });

    return grouped;
  }

  function transformData(arr) {
    let grouped = [];

    grouped = arr.map((item) => ({
      ...item,
      id: item.id?.map((i) => i.id) || [],
    }));

    return grouped;
  }

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const initializeIds = () => {
    const { stores, products, categories, subcategories } = specialDiscount;

    let initial = {
      stores: [],
      products: [],
      categories: [],
      subcategories: [],
    };

    const tmpStores = (Array.isArray(stores) ? stores : [] || [])
      .map((store) => store.id)
      .flat();
    const tmpProducts = (Array.isArray(products) ? products : [] || [])
      .map((product) => product.id)
      .flat();
    const tmpCategories = (Array.isArray(categories) ? categories : [] || [])
      .map((category) => category.id)
      .flat();
    const tmpSubCategories = (
      Array.isArray(subcategories) ? subcategories : [] || []
    )
      .map((subcategory) => subcategory.id)
      .flat();

    Promise.allSettled([
      api.promotions.getStores(tmpStores),
      api.promotions.getCategories(tmpCategories),
      api.promotions.getProducts(tmpProducts),
      api.promotions.getSubCategories(tmpSubCategories),
    ])
      .then(
        ([
          storesResponse,
          categoriesResponse,
          productsResponse,
          subcategoriesResponse,
        ]) => {
          if (storesResponse.status === 'fulfilled') {
            const res = storesResponse?.value?.data?.map((store) => ({
              id: store.storesId,
              value: store.name,
              limit: stores.find((s) => s.id.includes(store.storesId))?.limit,
            }));

            const _tmp = groupByLimit(res);
            initial.stores = _tmp;
          }

          if (categoriesResponse.status === 'fulfilled') {
            const res = categoriesResponse?.value?.data?.map((category) => ({
              id: category.productsCategoriesId,
              value: category.name,
              limit: categories.find((s) =>
                s.id.includes(category.productsCategoriesId)
              )?.limit,
            }));

            const _tmp = groupByLimit(res);
            initial.categories = _tmp;

            if (productsResponse.status === 'fulfilled') {
              const res = productsResponse?.value?.data?.map((product) => ({
                id: product.productsId,
                value: product.name,
                limit: products.find((s) => s.id.includes(product.productsId))
                  ?.limit,
              }));

              const _tmp = groupByLimit(res);
              initial.products = _tmp;
            }

            if (subcategoriesResponse.status === 'fulfilled') {
              const res = subcategoriesResponse?.value?.data?.map(
                (subcategory) => ({
                  id: subcategory.productsSubcategoriesId,
                  value: subcategory.name,
                  limit: subcategories.find((s) =>
                    s.id.includes(subcategory.productsSubcategoriesId)
                  )?.limit,
                })
              );

              const _tmp = groupByLimit(res);
              initial.subcategories = _tmp;
            }
          }
        }
      )
      .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`))
      .finally(() => setInitilizedValues(initial));
  };

  async function onSubmit(values) {
    setIsSaving(true);

    const userId = cookies[REACT_APP_COOKIES_USER_ID];
    const _startline = new Date(values.startline).toISOString();
    const _deadline = new Date(values.deadline).toISOString();

    const _products = transformData(values.products);
    const _categories = transformData(values.categories);
    const _subcategories = transformData(values.subcategories);
    const _stores = transformData(values.stores);

    toast.success('Espere un momento, la limitacion esta siendo procesado');

    const body = {
      ...values,
      startline: _startline,
      deadline: _deadline,
      products: _products,
      categories: _categories,
      subcategories: _subcategories,
      stores: _stores,
      ...(stockLimitsId
        ? {
            updatedBy: +userId,
          }
        : {
            createdBy: +userId,
          }),
    };

    const action = stockLimitsId
      ? api.stockLimits.update(stockLimitsId, body)
      : api.stockLimits.create(body);

    action
      .then((_) => {
        toast.success(
          `Límite de compra ${stockLimitsId ? 'actualizado' : 'creado'}`
        );
        history.replace('/admin/stock-limits');
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
      })
      .finally((_) => setIsSaving(false));
  }

  return (
    <div className="content">
      <TagSelectorModal
        {...modalInfo}
        onClose={() => {
          setModalInfo({
            isOpen: false,
            items: [],
          });
        }}
      />
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center text-md-left w-100">
            {stockLimitsId ? 'Editar' : 'Nueva'} limitación de compra
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <button
            disabled={isSaving}
            onClick={handleSubmit}
            type="button"
            className="bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSaving ? 'Guardando' : 'Guardar'}
            <BounceLoader color="#fff" loading={isSaving} size="18" />
          </button>
        </div>
      </div>
      <Card className="p-4 mt-3 mt-md-4">
        {(stockLimitsId && specialDiscount) || !stockLimitsId ? (
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            validateOnChange={false}
            validate={(values) => {
              const errors = {};

              if (!values.reason) errors.reason = 'Campo requerido';

              if (!values.startline) errors.startline = 'Campo requerido';

              if (!values.deadline) errors.deadline = 'Campo requerido';

              return errors;
            }}
            initialValues={{
              reason: specialDiscount?.reason || '',
              active: specialDiscount?.active || false,
              startline: specialDiscount?.startline || '',
              deadline: specialDiscount?.deadline || '',
              stores: initilizedValues?.stores || [],
              products: initilizedValues?.products || [],
              categories: initilizedValues?.categories || [],
              subcategories: initilizedValues?.subcategories || [],
            }}
            onSubmit={onSubmit}
          >
            {() => (
              <Form>
                <div className="row">
                  <div className="col-12 col-md-10 mt-2">
                    <label htmlFor="reason" className="d-block">
                      Nombre
                    </label>
                    <Field
                      className="w-100 p-2 rounded"
                      name="reason"
                      type="text"
                      placeholder="Ej. Descuento especial de navidad"
                    />
                    <ErrorMessage
                      name="reason"
                      component="span"
                      className="text-red"
                    />
                  </div>

                  <div className="col-12 col-md-2 d-flex flex-column justify-content-center align-items-center mt-2 col-md-2">
                    <label className="d-block" htmlFor="active">
                      Activo
                    </label>
                    <Field name="active" type="checkbox" id="active" />
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <label className="d-block" htmlFor="startline">
                      Inicio
                    </label>
                    <DatePickerField name="startline" />
                    <ErrorMessage
                      name="startline"
                      component="small"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <label className="d-block" htmlFor="deadline">
                      Fin
                    </label>
                    <DatePickerField name="deadline" />
                    <ErrorMessage
                      name="deadline"
                      component="small"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-12 my-4">
                    <h4 className="text-dark-blue font-weight-bold text-center">
                      Participantes
                    </h4>
                  </div>

                  {/* stores */}
                  <div className="col-12 mt-4">
                    <Field name="stores">
                      {({ field, form: { setFieldValue } }) => (
                        <LimitsTagSelector
                          name="stores"
                          className="w-full md:w-1/2"
                          title="Agregar tiendas"
                          tags={field.value}
                          onClick={(fieldName, initial) => {
                            setModalInfo({
                              title: 'Agregar tiendas',
                              initialItems: initial ?? [],
                              onSubmit: (data) => {
                                const _data = {
                                  id: data,
                                  limit: 0,
                                };

                                if (fieldName) {
                                  setFieldValue(fieldName, _data);
                                } else {
                                  setFieldValue('stores', [
                                    ...field.value,
                                    _data,
                                  ]);
                                }
                              },
                              isOpen: true,
                              type: TagSelectorModalType.STORES,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  {/* categories */}
                  <div className="col-12 mt-4">
                    <Field name="categories">
                      {({ field, form: { setFieldValue } }) => (
                        <LimitsTagSelector
                          name="categories"
                          className="w-full md:w-1/2"
                          title="Agregar categorías"
                          tags={field.value}
                          onClick={(fieldName, initial) => {
                            setModalInfo({
                              title: 'Agregar categorías',
                              initialItems: initial ?? [],
                              onSubmit: (data) => {
                                const _data = {
                                  id: data,
                                  limit: 0,
                                };

                                if (fieldName) {
                                  setFieldValue(fieldName, _data);
                                } else {
                                  setFieldValue('categories', [
                                    ...field.value,
                                    _data,
                                  ]);
                                }
                              },
                              isOpen: true,
                              type: TagSelectorModalType.CATEGORIES,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  {/* subcategories */}
                  <div className="col-12 mt-4">
                    <Field name="subcategories">
                      {({ field, form: { setFieldValue } }) => (
                        <LimitsTagSelector
                          name="subcategories"
                          className="w-full md:w-1/2"
                          title="Agregar subcategorías"
                          tags={field.value}
                          onClick={(fieldName, initial) => {
                            setModalInfo({
                              title: 'Agregar subcategorías',
                              initialItems: initial ?? [],
                              onSubmit: (data) => {
                                const _data = {
                                  id: data,
                                  limit: 0,
                                };

                                if (fieldName) {
                                  setFieldValue(fieldName, _data);
                                } else {
                                  setFieldValue('subcateogies', [
                                    ...field.value,
                                    _data,
                                  ]);
                                }
                              },
                              isOpen: true,
                              type: TagSelectorModalType.SUBCATEGORIES,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  {/* products */}
                  <div className="col-12 mt-4">
                    <Field name="products">
                      {({ field, form: { setFieldValue } }) => (
                        <LimitsTagSelector
                          name="products"
                          className="w-full md:w-1/2"
                          title="Agregar productos"
                          tags={field.value}
                          onClick={(fieldName, initial) => {
                            setModalInfo({
                              title: 'Agregar productos',
                              initialItems: initial ?? [],
                              onSubmit: (data) => {
                                const _data = {
                                  id: data,
                                  limit: 0,
                                };

                                if (fieldName) {
                                  setFieldValue(fieldName, _data);
                                } else {
                                  setFieldValue('products', [
                                    ...field.value,
                                    _data,
                                  ]);
                                }
                              },
                              isOpen: true,
                              type: TagSelectorModalType.PRODUCTS,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </Card>
    </div>
  );
}

export default NewStockLimit;
