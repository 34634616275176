import React, { createRef, useEffect, useState } from 'react';
import { BounceLoader } from 'react-spinners';

import Card from 'components/Card';

import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import api from 'utils/api';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { DatePickerField } from 'components/DateTimeField';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';
import ReactTooltip from 'react-tooltip';

function DeliveryTimes() {
  const formRef = createRef();

  const { REACT_APP_TITLE, REACT_APP_COOKIES_USER_ID, REACT_APP_COOKIES_JWT } =
    process.env;
  const [cookies] = useCookies([
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  ]);
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState({});
  const [isSelectionLoading, setIsSelectionLoading] = useState(false);
  const [tableRemountCount, setTableRemountCount] = useState(0);

  const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];

  const tableProps = {
    rowId: 'deliveryTimesId',
    baseURL: '/deliverytime',
    baseFilter: {
      where: {
        deliveryTimesId: {
          $notIn: [1],
        },
      },
      order: [['deliveryTimesId', 'DESC']],
    },
    pageSize: 10,
    columns: [
      {
        title: 'Id',
        objectProperty: 'deliveryTimesId',
        className: 'text-center',
      },
      {
        title: 'Razón',
        objectProperty: 'reason',
        className: 'text-center',
      },
      {
        title: 'Activo',
        className: 'text-center',
        columnType: 'boolean',
        objectMiddleware: (row) => {
          const startline = Date.parse(row.startline);
          const deadline = Date.parse(row.deadline);
          const now = Date.now();

          return startline <= now && deadline >= now && row.active;
        },
      },
      {
        title: 'Inicio',
        objectProperty: 'startline',
        className: 'text-center',
        middleware: (value) => moment(value).format('DD/MM/YYYY HH:mm A'),
      },
      {
        title: 'Fin',
        objectProperty: 'deadline',
        className: 'text-center',
        isCustom: true,
        middleware: (value) => moment(value).format('DD/MM/YYYY HH:mm A'),
      },
      {
        title: 'Creado',
        objectProperty: 'createdAt',
        className: 'text-center',
        middleware: (value) => moment(value).format('DD/MM/YYYY HH:mm A'),
      },
    ],
  };

  const formikValidate = (values) => {
    const { startline, deadline, reason, tuyo, urbano, weeknd } = values;
    const errors = {};

    if (!startline) errors.startline = 'Requerido';
    if (!deadline) errors.deadline = 'Requerido';
    if (!reason) errors.reason = 'Requerido';

    if (startline && deadline && startline >= deadline) {
      errors.startline = 'La fecha de inicio debe ser menor a la de fin';
      errors.deadline = 'La fecha de fin debe ser mayor a la de inicio';
    }

    tuyo.forEach((value) => {
      if (!(parseInt(value) >= 0)) errors.tuyo = 'Completar todos los campos';
    });

    urbano.forEach((value) => {
      if (!(parseInt(value) >= 0)) errors.urbano = 'Completar todos los campos';
    });

    weeknd.forEach((value) => {
      if (!(parseInt(value) >= 0)) errors.weeknd = 'Completar todos los campos';
    });

    return errors;
  };

  const formikOnSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const _days = ['dom', 'lun', 'mar', 'mie', 'jue', 'vie', 'sab'];

    const createdBy = cookies[REACT_APP_COOKIES_USER_ID];
    const start = new Date(values.startline).toISOString();
    const end = new Date(values.deadline).toISOString();

    const _tuyo = {};
    const _urbano = {};

    values.tuyo.forEach((value, index) => {
      _tuyo[_days[index]] = value;
    });

    values.urbano.forEach((value, index) => {
      _urbano[_days[index]] = value;
    });

    const body = {
      ...(selectedDeliveryTime?.deliveryTimesId
        ? { updatedBy: +createdBy }
        : { createdBy: +createdBy }),
      startline: start,
      deadline: end,
      reason: values.reason,
      tuyo: _tuyo,
      urbano: _urbano,
      weeknd: values.weeknd,
      active: values.active,
    };

    const action = selectedDeliveryTime?.deliveryTimesId
      ? api.deliveryTime.update(selectedDeliveryTime?.deliveryTimesId, {
          ...body,
        })
      : api.deliveryTime.create({
          ...body,
        });

    action
      .then(() => {
        toast.success(
          selectedDeliveryTime?.deliveryTimesId
            ? 'Restricción actualizada con éxito'
            : 'Restricción creada con éxito'
        );

        resetForm();
        setSelectedDeliveryTime({});
        setTableRemountCount(tableRemountCount + 1);
      })
      .catch((err) => {
        const details = err?.response?.data?.response?.details;
        console.error(err);
        toast.error(
          selectedDeliveryTime?.deliveryTimesId
            ? 'Error al actualizar la restricción'
            : 'Error al crear restricción'
        );
        toast.error(details?.map((detail) => detail.message).join(', '));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onDeliveryTimeSelect = (deliveryTime) => {
    setIsSelectionLoading(true);
    api.deliveryTime
      .getOne(deliveryTime.deliveryTimesId)
      .then(async (res) => {
        const { data } = res;

        const _tuyo = Object.values(data.tuyo);
        const _urbano = Object.values(data.urbano);

        setSelectedDeliveryTime({
          ...data,
          tuyo: _tuyo,
          urbano: _urbano,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error al obtener la restricción de cupones');
      })
      .finally(() => {
        setIsSelectionLoading(false);
      });
  };

  const cancelUpdate = () => {
    setSelectedDeliveryTime({});
    if (formRef.current) formRef.current.resetForm();
  };

  useEffect(() => {
    document.title = `Tiempos de entrega - ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <ReactTooltip />
      <div>
        <div className="row">
          <h3 className="text-dark-blue font-size-2x font-weight-bold col-12 mb-2">
            Tiempos de entrega
          </h3>
          <div className="col">
            <Card className="p-md-4 mb-4">
              <Formik
                innerRef={formRef}
                enableReinitialize={true}
                initialValues={{
                  active: selectedDeliveryTime?.active || true,
                  startline: selectedDeliveryTime.startline || '',
                  deadline: selectedDeliveryTime.deadline || '',
                  reason: selectedDeliveryTime.reason || '',
                  tuyo: selectedDeliveryTime.tuyo || [
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                  ],
                  urbano: selectedDeliveryTime.urbano || [
                    ['', ''],
                    ['', ''],
                    ['', ''],
                    ['', ''],
                    ['', ''],
                    ['', ''],
                    ['', ''],
                  ],
                  weeknd: selectedDeliveryTime.weeknd || ['', ''],
                }}
                validate={formikValidate}
                onSubmit={formikOnSubmit}
              >
                {({ isSubmitting, values }) => (
                  <Form>
                    <div className="row">
                      <div className="col-12 col-md-10 mt-2">
                        <label className="d-block" htmlFor="reason">
                          Razón
                        </label>
                        <Field
                          className="w-100 p-2 rounded border border-gray-300"
                          name="reason"
                          type="text"
                          placeholder="Retraso por navidad"
                        />
                        <ErrorMessage
                          name="deadline"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-2 mt-2">
                        <label htmlFor="active" className="d-block text-center">
                          Activo
                        </label>
                        <Field
                          className="w-100 p-2 rounded border border-gray-300"
                          name="active"
                          placeholder="Ej: 0.25"
                          type="checkbox"
                        />
                        <ErrorMessage
                          name="active"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <label className="d-block" htmlFor="startline">
                          Inicio
                        </label>
                        <DatePickerField name="startline" />
                        <ErrorMessage
                          name="startline"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <label className="d-block" htmlFor="deadline">
                          Fin
                        </label>
                        <DatePickerField name="deadline" />
                        <ErrorMessage
                          name="deadline"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="row col-12 mt-4">
                        <p className="font-weight-bold col-12">Tiempos</p>

                        <div className="row col-12">
                          {/* dias */}
                          <div className="col-6 col-md-2 mt-2">
                            <div
                              style={{
                                height: 50,
                              }}
                            >
                              <p className="col-3 font-semibold">Día</p>
                            </div>
                            <div className="mt-2">
                              {' '}
                              {days?.map((day, index) => (
                                <p
                                  key={index}
                                  className="font-semibold mt-2"
                                  style={{
                                    height: 30,
                                  }}
                                >
                                  {day}
                                </p>
                              ))}
                            </div>
                          </div>

                          {/* tuyo */}
                          <div className="row col-6 col-md-2 mt-2 mx-0">
                            <div
                              className="col-12 d-flex flex-column align-items-center"
                              style={{
                                height: 50,
                              }}
                            >
                              <p className="d-block font-semibold">Tuyo</p>
                              <div className="d-block d-flex align-items-end">
                                Dias
                                <span
                                  data-tip="Cantidad de dias que se tarda en entregar el pedido"
                                  className="material-icons cursor-pointer ml-1 text-base"
                                >
                                  info
                                </span>
                              </div>
                            </div>
                            <FieldArray
                              name="tuyo"
                              render={() => (
                                <>
                                  {values?.tuyo?.map((_, index) => (
                                    <div key={index} className="col-12 mt-2">
                                      <Field
                                        key={index}
                                        type="number"
                                        placeholder="Ej: 1"
                                        id={`tuyo[${index}]`}
                                        name={`tuyo.${index}`}
                                        className="w-full text-center"
                                      />
                                    </div>
                                  ))}
                                </>
                              )}
                            />
                            <ErrorMessage
                              name="tuyo"
                              component="small"
                              className="text-danger"
                            />
                          </div>

                          {/* urbano */}
                          <div className="row col-12 col-md-4 mt-2 mx-0">
                            <div
                              className="col-12 d-flex flex-column align-items-center"
                              style={{
                                height: 50,
                              }}
                            >
                              <span className="d-block">Urbano</span>
                              <div className="row w-full">
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                  Minimo
                                  <span
                                    data-tip="Tiempo minimo de espera para que el pedido sea entregado (en dias)"
                                    className="material-icons cursor-pointer ml-1 text-base"
                                  >
                                    info
                                  </span>
                                </div>
                                <div className="d-block col-6 d-flex align-items-center justify-content-center">
                                  Final
                                  <span
                                    data-tip="Tiempo final de entrega estimado (en dias)"
                                    className="material-icons cursor-pointer ml-1 text-base"
                                  >
                                    info
                                  </span>
                                </div>
                              </div>
                            </div>

                            <FieldArray
                              name="urbano"
                              render={() => (
                                <>
                                  {values?.urbano?.map((_, index) => (
                                    <FieldArray
                                      key={index}
                                      name={`urbano.${index}`}
                                      render={() => (
                                        <div className="row col-12 mt-2 mx-0">
                                          {values.urbano?.[index]?.map(
                                            (_, index2) => (
                                              <div
                                                key={index2}
                                                className="col-6"
                                              >
                                                <Field
                                                  key={index2}
                                                  type="number"
                                                  placeholder="Ej: 1"
                                                  id={`urbano[${index}][${index2}]`}
                                                  name={`urbano.${index}.${index2}`}
                                                  className="w-full text-center"
                                                />
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                    />
                                  ))}
                                </>
                              )}
                            />
                            <ErrorMessage
                              name="urbano"
                              component="small"
                              className="text-danger"
                            />
                          </div>

                          {/* weeknd */}
                          <div className="row col-12 col-md-3 mt-2 mx-0">
                            <div
                              className="col-12 d-flex flex-column align-items-center"
                              style={{
                                height: 50,
                              }}
                            >
                              <p className="d-block font-semibold">
                                Fin de semana
                              </p>
                              <div className="d-block d-flex align-items-end">
                                Dias
                                <span
                                  data-tip="Dias extra que se suman a los dias de entrega cuando el pedido tiene tiempo de preparacion y la orden se realiza en fin de semana"
                                  className="material-icons cursor-pointer ml-1 text-base"
                                >
                                  info
                                </span>
                              </div>
                            </div>
                            <div className="col-6 text-center">Sábado</div>
                            <div className="col-6 text-center">Domingo</div>
                            <div className="row col-12 mx-0 h-full">
                              <FieldArray
                                name="weeknd"
                                render={() => (
                                  <>
                                    {values?.weeknd?.map((_, index) => (
                                      <div key={index} className="col-6 mt-2">
                                        <Field
                                          key={index}
                                          type="number"
                                          placeholder="Ej: 1"
                                          id={`weeknd[${index}]`}
                                          name={`weeknd.${index}`}
                                          className="w-full text-center"
                                        />
                                      </div>
                                    ))}
                                  </>
                                )}
                              />
                              <ErrorMessage
                                name="weeknd"
                                component="small"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row w-full col-12">
                        {isSelectionLoading ? (
                          <div className="col-12 d-flex justify-content-center">
                            <BounceLoader color="#01d781" size="26" />
                          </div>
                        ) : (
                          <>
                            <div className="col-12 col-md-6 mt-3">
                              <button
                                type="reset"
                                onClick={cancelUpdate}
                                className="w-100 bg-red tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                              >
                                Limpiar
                              </button>
                            </div>

                            {selectedDeliveryTime?.deliveryTimesId ? (
                              <div className="col-12 col-md-6 mt-3">
                                <button
                                  type="submit"
                                  className="w-100 bg-green tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                                >
                                  Editar
                                  <BounceLoader
                                    color="#fff"
                                    loading={isSubmitting}
                                    size="18"
                                  />
                                </button>
                              </div>
                            ) : (
                              <div className="col-12 col-md-6  mt-3">
                                <button
                                  type="submit"
                                  className="w-100 bg-green tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                                >
                                  Crear nuevo
                                  <BounceLoader
                                    color="#fff"
                                    loading={isSubmitting}
                                    size="18"
                                  />
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>

            <Card className="p-md-4">
              <PaginatedTable
                columns={tableProps.columns}
                pageSize={tableProps.pageSize}
                baseURL={tableProps.baseURL}
                baseFilter={tableProps.baseFilter}
                rowId={tableProps.rowId}
                onRowClick={onDeliveryTimeSelect}
                remountCount={tableRemountCount}
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryTimes;
