import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import BounceLoader from 'react-spinners/BounceLoader';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Card from '../../components/Card';

import api from '../../utils/api';
import colors from '../../assets/resources/colors';

const BANKS = {
  AGRICOLA: { key: 'AGRICOLA', text: 'Banco Agrícola' },
  BAC: { key: 'BAC', text: 'BAC Credomatic' },
  CUSCATLAN: { key: 'CUSCATLAN', text: 'Banco Cuscatlán' },
  DAVIVIENDA: { key: 'DAVIVIENDA', text: 'Davivienda' },
};

function NewBankAvailableMonths() {
  const formRef = useRef(null);
  const history = useHistory();

  /* URL params */
  const { id: banksAvailableFeesId } = useParams();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  useEffect(() => {
    document.title = `${
      banksAvailableFeesId ? 'Editar' : 'Nuevo'
    } banco | ${REACT_APP_TITLE}`;
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [bankAvailableFees, setBankAvailableFees] = useState();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (banksAvailableFeesId) {
      api
        .findOne('/banksavailablefees', banksAvailableFeesId)
        .then((response) => {
          setBankAvailableFees(response.data);
        })
        .catch((error) => toast.warning('[SERVER_ERROR] ' + error));
    }
  }, []);

  function save(formValues) {
    setIsSaving(true);
    const { bank, availableMonths } = formValues;

    const body = {
      bank,
      availableMonths: availableMonths
        .replace(' ', '')
        .split(',')
        .filter((months) => months?.trim())
        .join(','),
    };

    body[banksAvailableFeesId ? 'updatedBy' : 'createdBy'] =
      cookies[REACT_APP_COOKIES_USER_ID];
    const action = banksAvailableFeesId
      ? api.update('/banksavailablefees', banksAvailableFeesId, body)
      : api.create('/banksavailablefees', body);

    action
      .then(() => {
        toast.success(
          `Banco ${banksAvailableFeesId ? 'actualizado' : 'creado'}`
        );
        history.push('/admin/banks-available-months');
      })
      .catch((error) => {
        toast.warning(`[SERVER_ERROR] ${error}`);
      })
      .finally((_) => setIsSaving(false));
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center text-md-left w-100">
            {banksAvailableFeesId ? 'Editar' : 'Nuevo'} banco
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <button
            disabled={isSaving}
            onClick={handleSubmit}
            type="button"
            className="bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
          >
            {isSaving ? 'Guardando' : 'Guardar'}
            <BounceLoader color="#fff" loading={isSaving} size="18" />
          </button>
        </div>
      </div>
      <Card className="p-4 mt-3 mt-md-4">
        {(banksAvailableFeesId && bankAvailableFees) ||
        !banksAvailableFeesId ? (
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            validationSchema={Yup.object().shape({
              availableMonths: Yup.string().trim().required('Campo requerido'),
            })}
            initialValues={{
              bank: bankAvailableFees
                ? bankAvailableFees.bank
                : BANKS.AGRICOLA.key,
              availableMonths: bankAvailableFees
                ? bankAvailableFees.availableMonths
                : '',
            }}
            onSubmit={(values) => {
              save(values);
            }}
          >
            {() => (
              <Form>
                <div className="row">
                  <div className="col-12 col-md-6 mt-2">
                    <label className="d-block" htmlFor="bank">
                      Banco
                    </label>
                    <Field
                      as="select"
                      name="bank"
                      className="p-2 rounded w-100"
                    >
                      {Object.entries(BANKS).map((bank, index) => (
                        <option key={index} value={bank[1].key}>
                          {bank[1].text}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="availableMonths" className="d-block">
                      Plazos disponibles
                    </label>
                    <Field name="availableMonths">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            className="p-2 rounded w-100"
                            placeholder="3,6,9"
                            onChange={(event) => {
                              setFieldValue(
                                'availableMonths',
                                event.target.value
                              );
                            }}
                          />
                          <small className="font-style-italic text-gray d-block">
                            Ingrese los plazos disponibles separados por coma
                            (,)
                          </small>
                          <ErrorMessage
                            name="availableMonths"
                            component="span"
                            className="text-red"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </Card>
    </div>
  );
}

export default NewBankAvailableMonths;
