import React, { useEffect } from 'react';

import Card from 'components/Card';
import Table from 'components/TableV2/Table';

const tableProps = {
  rowId: 'auditLogsId',
  baseURL: '/auditLogs',
  baseFilter: {
    attributes: [
      'auditLogsId',
      'table',
      'actionType',
      'prevValues',
      'newValues',
      'createdAt',
      'createdBy',
    ],
    include: [
      {
        association: 'user',
        attributes: ['usersId', 'username', 'firstname', 'lastname'],
      },
    ],
    order: [['auditLogsId', 'desc']],
  },
  pageSize: 10,
  columns: [
    {
      title: 'Tabla',
      objectProperty: 'table',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Acción',
      objectProperty: 'actionType',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Valores previos',
      objectProperty: 'prevValues',
      searchable: true,
      searchType: 'text',
      middleware: (prevValues) => (
        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
          {prevValues?.replace(/["{}]/g, '').replace(/,/g, '\n')}
        </pre>
      ),
    },
    {
      title: 'Nuevos valores',
      objectProperty: 'newValues',
      searchable: true,
      searchType: 'text',
      middleware: (newValues) => (
        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
          {newValues.replace(/["{}]/g, '').replace(/,/g, '\n')}
        </pre>
      ),
    },
    {
      title: 'Creado',
      objectProperty: 'createdAt',
      searchable: true,
      searchType: 'timestamp',
      middleware: (createdAt) => new Date(createdAt).toLocaleString(),
      sortable: true,
    },
    {
      title: 'Nombre',
      objectProperty: 'user',
      gatewayObjectProperty: '$user.firstname$',
      searchable: true,
      searchType: 'text',
      middleware: (user) => user?.firstname,
    },
    {
      title: 'Apellido',
      objectProperty: 'user',
      gatewayObjectProperty: '$user.lastname$',
      searchable: true,
      searchType: 'text',
      middleware: (user) => user?.lastname,
    },
  ],
};

function Log() {
  const { REACT_APP_TITLE } = process.env;

  // Set title
  useEffect(() => {
    document.title = `Log | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <h3 className="text-dark-blue font-size-2x font-weight-bold">Log</h3>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            <Table
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Log;
