const SERFINSA_ERRORS = {
  'ERROR 400': 'BAD REQUEST',
  'ERROR 409': 'NO SE PUEDE PROCESAR',
  'ERROR 403': 'NO PERMITIDO',
  'ERROR 500': 'SERVER ERROR',
  'ERROR 00': 'AUTORIZADO',
  'ERROR REV': 'REVERTIDO',
  'ERROR 01': 'LLAMAR AL EMISOR',
  'ERROR 02': 'LLAMAR AL EMISOR',
  'ERROR 03': 'LLAMAR AL EMISOR',
  'ERROR 04': 'TARJETA BLOQUEADA',
  'ERROR 05': 'LLAMAR AL EMISOR',
  'ERROR 07': 'TARJETA BLOQUEADA',
  'ERROR 12': 'TRANSACCION INVALIDA',
  'ERROR 13': 'MONTO INVALIDO',
  'ERROR 14': 'LLAMAR AL EMISOR',
  'ERROR 15': 'EMISOR NO DISPONIBLE',
  'ERROR 19': 'REINTENTE TRANSACCION',
  'ERROR 25': 'LLAMAR AL EMISOR',
  'ERROR 30': 'ERROR DE FORMATO',
  'ERROR 39': 'NO ES CUENTA DE CREDITO',
  'ERROR 31': 'BANCO NO SOPORTADO',
  'ERROR 41': 'TARJETA BLOQUEADA',
  'ERROR 43': 'TARJETA BLOQUEADA',
  'ERROR 48': 'CREDENCIAL INVALIDA',
  'ERROR 50': 'LLAMAR AL EMISOR',
  'ERROR 51': 'FONDOS INSUFICIENTES',
  'ERROR 52': 'NO ES CUENTA DE CHEQUES',
  'ERROR 53': 'NO ES CUENTA DE AHORROS',
  'ERROR 54': 'TARJETA EXPIRADA',
  'ERROR 55': 'PIN INCORRECTO',
  'ERROR 56': 'TARJETA NO VALIDA',
  'ERROR 57': 'TRANSACCION NO PERMITIDA',
  'ERROR 58': 'TRANSACCION NO PERMITIDA',
  'ERROR 59': 'SOSPECHA DE FRAUDE',
  'ERROR 61': 'ACTIVIDAD DE LIMITE EXCEDIDO',
  'ERROR 62': 'TARJETA RESTRINGIDA',
  'ERROR 65': 'MAXIMO PERMITIDO ALCANZADO',
  'ERROR 75': 'INTENTOS DE PIN EXCEDIDO',
  'ERROR 82': 'NO HSM',
  'ERROR 83': 'CUENTA NO EXISTE',
  'ERROR 84': 'CUENTA NO EXISTE',
  'ERROR 85': 'REGISTRO NO ENCONTRADO',
  'ERROR 86': 'AUTORIZACION NO VALIDA',
  'ERROR 87': 'CVV2 INVALIDO',
  'ERROR 88': 'ERROR EN LOG DE TRANSACCIONES',
  'ERROR 89': 'RUTA DE SERVICIO NO VALIDA',
  'ERROR 91': 'EMISOR NO DISPONIBLE',
  'ERROR 92': 'EMISOR NO DISPONIBLE',
  'ERROR 93': 'TRANSACCION NO PUEDE SER PROCESADA',
  'ERROR 94': 'TRANSACCION DUPLICADA',
  'ERROR 96': 'SISTEMA NO DISPONIBLE',
  'ERROR 97': 'TOKEN DE SEGURIDAD INVALIDO',
  'ERROR D0': 'SISTEMA NO DISPONIBLE',
  'ERROR D1': 'COMERCIO INVALIDO',
  'ERROR H0': 'FOLIO YA EXISTE',
  'ERROR H1': 'CHECK IN EXISTENTE',
  'ERROR H2': 'SERVICIO DE RESERVACION NO PERMITIDO',
  'ERROR H3': 'RESERVA NO ENCONTRADA EN EL SISTEMA',
  'ERROR H4': 'TARJETA NO ENCONTRADA CHECK IN',
  'ERROR H5': 'EXCEDE SOBREGIRO DE CHECK IN',
  'ERROR N0': 'AUTORIZACION INHABILITADA',
  'ERROR N1': 'TARJETA INVALIDA',
  'ERROR N2': 'PREAUTORIZACIONES COMPLETAS',
  'ERROR N3': 'MONTO MAXIMO ALCANZADO',
  'ERROR N4': 'MONTO MAXIMO ALCANZADO',
  'ERROR N5': 'MAXIMO DEVOLUCIONES ALCANZADO',
  'ERROR N6': 'MAXIMO PERMITIDO ALCANZADO',
  'ERROR N7': 'LLAMAR AL EMISOR',
  'ERROR N8': 'CUENTA SOBREGIRADA',
  'ERROR N9': 'INTENTOS PERMITIDOS ALCANZADO',
  'ERROR O0': 'LLAMAR AL EMISOR',
  'ERROR O1': 'NEG FILE PROBLEM',
  'ERROR O2': 'MONTO DE RETIRO NO PERMITIDO',
  'ERROR O3': 'DELINQUENT',
  'ERROR O4': 'LIMITE EXCEDIDO',
  'ERROR O7': 'FORCE POST',
  'ERROR O8': 'SIN CUENTA',
  'ERROR O5': 'PIN REQUERIDO',
  'ERROR O6': 'DIGITO VERIFICADOR INVALIDO',
  'ERROR R8': 'TARJETA BLOQUEADA',
  'ERROR T1': 'MONTO INVALIDO',
  'ERROR T2': 'FECHA DE TRANSACCION INVALIDA',
  'ERROR T5': 'LLAMAR AL EMISOR',
};

export default SERFINSA_ERRORS;
