import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';

import Card from '../../components/Card';

import api from '../../utils/api';
import colors from '../../assets/resources/colors';

import chevronLeftSVG from '../../assets/icons/chevron-left.svg';

function NewRole() {
  const formRef = useRef(null);
  const history = useHistory();

  /* URL params */
  const { id: rolesId } = useParams();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  const [isSaving, setIsSaving] = useState(false);
  const [role, setRole] = useState();

  useEffect(() => {
    document.title = `${rolesId ? 'Editar' : 'Nuevo'} rol | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    if (rolesId) {
      api.roles
        .getOne(rolesId)
        .then((res) => {
          setRole(res.data);
        })
        .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));
    }
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const createRole = (formValues) => {
    const { name } = formValues;

    let body = { name: name };

    rolesId
      ? (body = {
          ...body,
          updatedBy: userID,
        })
      : (body = {
          ...body,
          createdBy: userID,
        });

    setIsSaving(true);

    const action = rolesId
      ? api.roles.updateOne(rolesId, body)
      : api.roles.create(body);

    action
      .then((_) => {
        toast.success(`Rol ${rolesId ? 'actualizado' : 'creado'}`);
        history.push('/admin/roles');
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
      })
      .finally((_) => setIsSaving(false));
  };

  return (
    <div className="content">
      <div className="row">
        <div className="col-6 col-md-3 col-xl-2">
          <Link to="/admin/roles">
            <button
              type="button"
              className="w-100 bg-beige tuyo-btn px-3 py-2 mr-2 rounded-pill border d-inline-flex align-items-center justify-content-center"
            >
              <img src={chevronLeftSVG} alt="" className="mr-2" />
              Regresar
            </button>
          </Link>
        </div>
        <div className="col-6 col-md-3 order-md-3 col-xl-2">
          <button
            disabled={isSaving}
            type="button"
            className="w-100 bg-purple tuyo-btn px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
            onClick={handleSubmit}
          >
            {isSaving ? (rolesId ? 'Actualizando' : 'Creando') : 'Guardar'}
            <BounceLoader color="#fff" loading={isSaving} size="18" />
          </button>
        </div>
        <div className="col-12 col-md-6 col-xl-8 mt-3 mt-md-0">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center">
            {rolesId ? 'Editar rol' : 'Nuevo rol'}
          </h3>
        </div>
      </div>
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          {(rolesId && role) || !rolesId ? (
            <Formik
              enableReinitialize={true}
              innerRef={formRef}
              validationSchema={Yup.object().shape({
                name: Yup.string().trim().required('Campo requerido'),
              })}
              initialValues={{
                name: rolesId ? role?.name : '',
              }}
              onSubmit={(values) => {
                createRole(values);
              }}
            >
              {() => (
                <Form>
                  <Card className="p-3 p-md-4">
                    <div className="row">
                      <div className="col-12 col-md-12 mt-3 mt-md-0">
                        <label htmlFor="name" className="d-block">
                          Nombre
                        </label>
                        <Field
                          name="name"
                          text="text"
                          placeholder="admin"
                          className="w-100 rounded p-2"
                        />
                        <ErrorMessage
                          name="name"
                          component="span"
                          className="text-red"
                        />
                      </div>
                    </div>
                  </Card>
                </Form>
              )}
            </Formik>
          ) : (
            <div
              className="p-5 m-5 d-flex justify-content-center align-items-center"
              style={{
                flexGrow: '1',
              }}
            >
              <ClimbingBoxLoader color={colors.green} size="25" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewRole;
