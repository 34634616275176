const status = [
  {
    id: 1,
    text: 'Pendiente',
    className: 'bg-yellow',
  },
  {
    id: 2,
    text: 'Aceptado',
    className: 'bg-purple',
  },
  {
    id: 3,
    text: 'Recolectado',
    className: 'bg-orange',
  },
  {
    id: 4,
    text: 'Finalizado',
    className: 'bg-green',
  },
  {
    id: 5,
    text: 'No recolectado',
    className: 'bg-green',
  },
  {
    id: 6,
    text: 'No entregado',
    className: 'bg-green',
  },
  {
    id: 7,
    text: 'Cancelado (U)',
    className: 'bg-red',
  },
  {
    id: 8,
    text: 'Cancelado (C)',
    className: 'bg-red',
  },
  {
    id: 9,
    text: 'Cambio',
    className: 'bg-light-gray',
  },
  {
    id: 10,
    text: 'Reembolso',
    className: 'bg-pink',
  },
  {
    id: 11,
    text: 'Cambio aprobado',
    className: 'bg-green',
  },
  {
    id: 12,
    text: 'Cambio denegado',
    className: 'bg-red',
  },
  {
    id: 13,
    text: 'Reembolso aprobado',
    className: 'bg-green',
  },
  {
    id: 14,
    text: 'Reembolso denegado',
    className: 'bg-red',
  },
  {
    id: 15,
    text: 'Lista',
    className: 'bg-green',
  },
  {
    id: 16,
    text: 'Entregado en bodega',
    className: 'bg-orange',
  },
  {
    id: 17,
    text: 'En ruta',
    className: 'bg-green',
  },
  {
    id: 18,
    text: 'Cancelado en punto de entrega',
    className: 'bg-red',
  },
  {
    id: 19,
    text: 'De camino al comercio',
    className: 'bg-purple',
  },
  {
    id: 20,
    text: 'Espera en comercio',
    className: 'bg-orange',
  },
];

const STATUS = {
  ORDER_PENDING: 1,
  ORDER_ACCEPTED: 2,
  PICKED_UP: 3,
  FINISHED: 4,
  NON_PICKED_UP: 5,
  NON_FINISHED: 6,
  ORDER_U_CANCELED: 7,
  ORDER_S_CANCELED: 8,
  RETURN_PENDING: 9,
  RETURN_APPROVED: 11,
  RETURN_DENIED: 12,
  REFUND_PENDING: 10,
  REFUND_APPROVED: 13,
  REFUND_DENIED: 14,
  READY: 15,
  DELIVERED_IN_WAREHOUSE: 16,
  ON_ROUTE: 17,
  CANCELED_IN_DELIVERY_POINT: 18,
  ON_THE_WAY_TO_STORE: 19,
  WAITING_IN_STORE: 20,


  FOR_INCOME_AND_SALES: [3, 4, 12, 14, 15],
  FOR_RETURNS: [9, 11, 12],
  FOR_REFUNDS: [10, 13, 14],
};

export { status, STATUS };
