import React, { useEffect, useState } from 'react';

import Card from '../../components/Card';
import { useCookies } from 'react-cookie';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';
import api from 'utils/api';
import { toast } from 'react-toastify';
import { TagSelectorModal } from 'components/TagSelectorModal';
import TagSelectorModalButton from 'components/TagSelectorModalButton';
import { TagSelectorModalType } from 'components/TagSelectorModal';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router';

function TurboStores() {
  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userId = cookies[REACT_APP_COOKIES_USER_ID];

  const history = useHistory();

  const [storesRemount, setStoresRemount] = useState(0);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    items: [],
    initialItems: [],
    type: null,
    onSubmit: () => {},
    onRemove: null,
  });

  const onRowClick = (row) => {
    history.push(`/admin/turbo-products/${row.storesId}/${row.name}`);
  };

  const turboButton = (value, row, field) => (
    <button
      onClick={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        api.stores
          .updateOne(row.storesId, {
            [field]: !value,
            ...(field === 'isTurboShipping' && { isTurboAvailable: !value }),
            updatedBy: userId,
          })
          .then(() => {
            toast.success('Tienda actualizada');
            setStoresRemount((prev) => prev + 1);
          })
          .catch(() => {
            toast.error('No se pudo actualizar la tienda');
          });
      }}
    >
      <span
        data-tip={value ? 'Desactivar' : 'Activar'}
        className={`material-icons ${value ? 'text-turbo' : 'text-grey'}`}
      >
        {value ? 'check_box' : 'check_box_outline_blank'}
      </span>
      <ReactTooltip effect="solid" />
    </button>
  );

  const storesTable = {
    pageSize: 10,
    baseURL: '/stores',
    rowId: 'storesId',
    baseFilter: {
      attributes: [
        'storesId',
        'name',
        'image',
        'isTurboShipping',
        'isTurboAvailable',
        'representative',
      ],
      order: [
        ['storesId', 'DESC'],
        ['isTurboShipping', 'DESC'],
      ],
    },
    columns: [
      {
        title: 'Id',
        objectProperty: 'storesId',
        searchable: true,
        searchType: 'number-equal',
        columnClass: '!w-12',
        sortable: true,
      },
      {
        title: 'Pertenece a Turbo',
        objectProperty: 'isTurboShipping',
        className: 'w-16 text-center',
        middleware: (value, row) => turboButton(value, row, 'isTurboShipping'),
        sortable: true,
      },
      {
        title: 'Disponible para Turbo',
        objectProperty: 'isTurboAvailable',
        className: 'w-16 text-center',
        middleware: (value, row) => turboButton(value, row, 'isTurboAvailable'),
        sortable: true,
      },
      {
        title: '',
        columnClass: '!w-16',
        objectProperty: 'image',
        middleware: (value) => (
          <img
            className="img-fluid !w-12 !h-12 rounded-xl border border-gray-300 object-contain"
            src={value}
            alt="Imagen de la tienda"
          />
        ),
      },
      {
        title: 'Nombre',
        objectProperty: 'name',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
      {
        title: 'Representante',
        objectProperty: 'representative',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
    ],
  };

  const updateStores = (stores, enable) => {
    api.turbo
      .updateStores({
        stores: stores.map((store) => store.id),
        isTurboAvailable: enable,
        isTurboShipping: enable,
      })
      .then(() => {
        toast.success('Tiendas actualizadas');
        setStoresRemount((prev) => prev + 1);
      })
      .catch(() => {
        toast.error('No se pudo actualizar las tiendas');
      });
  };

  useEffect(() => {
    document.title = `Tiendas - Turbo Envios | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <TagSelectorModal
        {...modalInfo}
        onClose={() =>
          setModalInfo({
            isOpen: false,
            items: [],
          })
        }
      />
      <h3 className="text-dark-blue font-size-2x font-weight-bold">
        Tiendas - Turbo Envios
      </h3>
      <div className="row mt-3 -mx-[15px]">
        <div className="col-12">
          <Card className="row">
            <div className="col-12 col-sm-5 col-md-4 col-lg-3 mx-[-15px]">
              <TagSelectorModalButton
                title="Agregar tiendas"
                tags={[]}
                onClick={() => {
                  setModalInfo({
                    title: 'Agregar tiendas',
                    initialItems: [],
                    onSubmit: (data) => updateStores(data, true),
                    onRemove: (data) => updateStores(data, false),
                    isOpen: true,
                    type: TagSelectorModalType.STORES,
                  });
                }}
              />
            </div>
            <PaginatedTable
              columns={storesTable.columns}
              pageSize={storesTable.pageSize}
              baseURL={storesTable.baseURL}
              baseFilter={storesTable.baseFilter}
              rowId={storesTable.rowId}
              onRowClick={onRowClick}
              remountCount={storesRemount}
              cleanReload={false}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default TurboStores;
