import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { useCookies } from 'react-cookie';

import Card from '../../components/Card';
import Table from '../../components/Table/Table';

import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';

import api from '../../utils/api';
import colors from '../../assets/resources/colors';

import addSVG from '../../assets/icons/add.svg';
import { ROLES_ID } from 'utils/misc';

const rolesTable = {
  rootOptions: {
    id: 'rolesId',
    showDelBtn: true,
  },
  options: {
    id: 'roledId'
  },
  columns: [
    { text: 'ID', key: 'rolesId', className: 'text-center' },
    { text: 'Nombre', key: 'name', className: 'text-center' }
  ]
}

function RolesList() {
  const { REACT_APP_TITLE, REACT_APP_COOKIES_USER_ID } = process.env;
  const history = useHistory();
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  const [roles, setRoles] = useState();
  const [updateRoles, setUpdateRoles] = useState(0);
  const [user, setUser] = useState();

  useEffect(() =>{
    document.title = `Roles | ${REACT_APP_TITLE}`
  }, []);

  useEffect(() =>{
    api.roles.getAll()
    .then(res =>{
      setRoles(res.data)
    })
    .catch(err => {
      toast.warning('[SERVER_ERROR] No se pudo obtener los roles');
      console.error(err);
    })
  }, [updateRoles]);

  useEffect(() => {
    api.users.getOne(userID)
      .then(res => {
        setUser(res.data[0])
      })
      .catch(err => console.error(err))
  }, [])

  const onDelete = (id) =>{
    swal({
      buttons: ['Cancelar', 'Eliminar'],
      dangerMode: true,
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">
            ¿Deseas eliminar este rol?
          </p>
          <p className="mt-3">Esto podría tener efectos no esperados</p>
        </div>
      )
    })
    .then((res) =>{
      if (res) {
        api.roles.deleteOne(id)
        .then(() =>{
          setUpdateRoles(updateRoles + 1);
          toast.success('Rol eliminado')
        })
        .catch(err =>{
          toast.warning('[SERVER_ERROR] No se pudo eliminar el rol');
          console.error(err);
        })
      }
    })
  }

  return(
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">Roles</h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <Link to="/admin/roles/new" className="w-100">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold">
              <img src={addSVG} alt="" className="d-inline-block mr-2" style={{ height: "16px" }} />
              Nuevo rol
            </button>
          </Link>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: "1" }}>
        <div className="col-12">
          <Card className="p-md-4">
            { roles && user
              ? <Table
                  showFilters
                  options={ROLES_ID.ROOT === user.role.rolesId ? rolesTable.rootOptions : rolesTable.options}
                  columns={rolesTable.columns}
                  data={roles}
                  pageSize={10}
                  onRowClick={id => history.push(`/admin/roles/${id}/edit`)}
                  onDelete={onDelete}
                />
              : <div
                  className="p-5 m-5 d-flex justify-content-center align-items-center"
                  style={{ flexGrow: "1" }}
                >
                  <ClimbingBoxLoader color={colors.green} size='25' />
                </div>
            }
          </Card>
        </div>
      </div>
    </div>
  )

}

export default RolesList;