import React, { useState, useEffect, useRef } from 'react';
import * as moment from 'moment';

import Row from './Row';
import Filters from './Filters';

const todayMoment = moment(new Date()).format('YYYY-MM-DD');

function Table({
  columns,
  data,
  options,
  pageSize,
  onView,
  onUpdate,
  onDelete,
  onChoiceChange,
  onRowClick,
  onDisable,
  updateOrderStatus,
  isSaving,
  showDateFilter,
  showFilters,
  rowWarning,
  hideSearchBar,
}) {
  const pageRef = useRef(null);
  const [itemsQty, setItemsQty] = useState(data.length);

  const [rows, setRows] = useState(data);
  const [showing, setShowing] = useState({
    lower: 1,
    higher: rows.length > pageSize ? pageSize : rows.length,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [sorting, setSorting] = useState(null);
  const [hideChoices, setHideChoices] = useState(true);
  const [IDRowSaving, setIDRowSaving] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [filters, setFilters] = useState([]);

  /* Date filter */
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.value = currentPage + 1;
    }
  }, [currentPage, pageRef.current]);

  /* Slice the data array in pages */
  useEffect(() => {
    const tmpPages = [];
    const tmpData = [...rows];

    while (tmpData.length > pageSize) {
      tmpPages.push(tmpData.splice(0, pageSize));
      tmpData.slice(0, pageSize);
    }

    if (tmpData.length > 0) {
      tmpPages.push(tmpData.splice(0, tmpData.length));
    }

    setPages(tmpPages);
    updateShowing();
  }, [rows]);

  useEffect(() => {
    setRows(data);
    setItemsQty(data.length);
  }, [data]);

  useEffect(() => {
    if (isSaving === false) {
      setIDRowSaving(null);
    }
  }, [isSaving]);

  /* Update the "Showing x - y of z" text */
  const updateShowing = () => {
    if (!pages[currentPage]) {
      return;
    }

    const lower = currentPage * pageSize + 1;
    const higher = lower + pages[currentPage].length - 1;

    setShowing({ higher, lower });
  };

  useEffect(updateShowing, [currentPage, pages]);

  /* Set the sorting column and order (desc or asc) */
  const updateSorting = (column) => {
    if (sorting) {
      if (sorting.column === column) {
        if (sorting.order === 'asc') {
          setSorting({ column, order: 'desc' });
        } else if (sorting.order === 'desc') {
          setSorting(null);
        }
      } else {
        setSorting({ column, order: 'asc' });
      }
    } else {
      setSorting({ column, order: 'asc' });
    }
  };

  /* Sort the data array by the column and order set */
  const sortData = (rows) => {
    let sortedRows = [...rows];

    if (sorting) {
      const { column, order } = sorting;

      sortedRows = sortedRows.sort((a, b) => {
        if (a[column] > b[column]) {
          return order === 'asc' ? 1 : -1;
        } else if (a[column] < b[column]) {
          return order === 'asc' ? -1 : 1;
        } else {
          return 0;
        }
      });
    }

    return sortedRows;
  };

  useEffect((_) => setRows(sortData(rows)), [sorting]);

  /* Update table content based on search term, date filter and other filters */
  useEffect(() => {
    let tmpRows = [...data];

    if (searchTerm && searchTerm.trim()) {
      tmpRows = data.filter((row) =>
        columns.some((column) => {
          return column.key
            ? row[column.key]
              ? row[column.key].toString().toLowerCase().indexOf(searchTerm) !==
                -1
              : false
            : false;
        })
      );
    }

    const since = startDate ? startDate.trim() : '';
    const until = endDate ? endDate.trim() : '';

    const sinceDate = new Date(since);
    const untilDate = new Date(until);
    untilDate.setDate(untilDate.getDate() + 1);

    tmpRows = tmpRows.filter((row) => {
      const createdAtDate = new Date(row.createdAt);

      if (since && until) {
        return createdAtDate >= sinceDate && createdAtDate <= untilDate;
      } else if (since) {
        return createdAtDate >= sinceDate;
      } else if (until) {
        return createdAtDate <= untilDate;
      } else {
        return true;
      }
    });

    if (filters && filters.length > 0) {
      tmpRows = tmpRows.filter((row) => {
        return filters.every((filter) => {
          const { key, min, max } = filter;

          return min.trim() || max.trim()
            ? (min.trim() ? row[key] >= parseFloat(min) : true) &&
                (max.trim() ? row[key] <= parseFloat(max) : true)
            : true;
        });
      });
    }

    setItemsQty(tmpRows.length);
    setCurrentPage(0);
    setRows(sortData(tmpRows));
  }, [searchTerm, startDate, endDate, filters]);

  return (
    <div>
      <div className="row mb-4 align-items-end">
        {columns && !hideSearchBar && (
          <div
            className={`col-12 ${showFilters ? 'col-md-6 col-lg-8' : ''} ${
              showDateFilter ? 'col-md-4 col-lg-6' : ''
            }`}
          >
            <input
              onChange={(event) =>
                setSearchTerm(event.target.value.toLowerCase())
              }
              placeholder="Buscar..."
              type="text"
              className="w-100 px-2 py-1 rounded"
            />
          </div>
        )}
        {showDateFilter && (
          <>
            <div className="col-12 col-md-4 col-lg-3 mt-2 mt-md-0">
              <input
                max={endDate || todayMoment}
                onChange={(event) => setStartDate(event.target.value)}
                className="rounded w-100 p-1"
                type="date"
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3 mt-2 mt-md-0">
              <input
                min={startDate}
                max={todayMoment}
                onChange={(event) => setEndDate(event.target.value)}
                className="rounded w-100 p-1"
                type="date"
              />
            </div>
          </>
        )}
        {columns && showFilters && (
          <div className="col-12 col-md-6 col-lg-4 mt-2">
            <Filters
              onChange={(filters) => setFilters([...filters])}
              columns={columns}
            />
          </div>
        )}
      </div>
      <div className="pb-3 pb-lg-0" style={{ overflowX: 'auto' }}>
        <table className="tuyo-table">
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index} onClick={(_) => updateSorting(column.key)}>
                  <button
                    style={{ width: '100%', height: '100%' }}
                    className="text-black font-weight-bold"
                  >
                    {column.text}
                    {sorting &&
                      sorting.column === column.key &&
                      (sorting.order === 'asc' ? (
                        <i className="ml-1 fa fa-chevron-up"></i>
                      ) : (
                        <i className="ml-1 fa fa-chevron-down"></i>
                      ))}
                  </button>
                </th>
              ))}
              {(options.showViewBtn ||
                options.showUpdateBtn ||
                options.showDelBtn ||
                options.showDisableBtn) && <th></th>}
            </tr>
          </thead>
          <tbody>
            {pages[currentPage] &&
              pages[currentPage].map((row) => {
                const rowId = Array.isArray(options.id)
                  ? options.id.map((id) => row[id]).join('-')
                  : row[options.id];

                return (
                  <Row
                    warning={
                      rowWarning
                        ? row[rowWarning.field] < rowWarning.value
                        : null
                    }
                    key={rowId}
                    data={row}
                    status={row.status}
                    isSaving={IDRowSaving === row[options.id]}
                    updateOrderStatus={(rowId, val) =>
                      updateOrderStatus(rowId, val)
                    }
                    setIDRowSaving={(rowId) => setIDRowSaving(rowId)}
                    onChoiceChange={(rowId, val) => onChoiceChange(rowId, val)}
                    onDelete={(rowId) => onDelete(rowId)}
                    onUpdate={(rowId) => onUpdate(rowId)}
                    onView={(rowId) => onView(rowId)}
                    onDisable={(rowId) => onDisable(rowId)}
                    options={options}
                    columns={columns}
                    rowId={rowId}
                    hideChoices={hideChoices}
                    onClick={
                      onRowClick
                        ? (rowId) => {
                            onRowClick(rowId, row);
                            setHideChoices(hideChoices + 1);
                          }
                        : null
                    }
                  />
                );
              })}
          </tbody>
        </table>
      </div>
      {rows && rows.length > 0 ? (
        <div
          className="row pager mt-4 font-size-075x text-gray"
          style={{ opacity: '0.7' }}
        >
          <div className="col-12 col-md-4 text-center text-md-left">
            Resultados {showing.lower} - {showing.higher} de {itemsQty}
          </div>
          <div className="col-12 col-md-8 d-flex justify-content-end mt-3 mt-md-0">
            <button className="px-2" onClick={() => setCurrentPage(0)}>
              Primero
            </button>
            <button
              className="px-2"
              onClick={() =>
                currentPage === 0 ? null : setCurrentPage(currentPage - 1)
              }
            >
              Anterior
            </button>
            <span className="px-2 text-green">
              Página
              <input
                className="current-page rounded"
                ref={pageRef}
                type="text"
                onChange={(event) => {
                  const newPage = event.target.value.trim();
                  newPage &&
                    newPage > 0 &&
                    newPage <= pages.length &&
                    setCurrentPage(event.target.value - 1);
                }}
              />
              de {pages.length}
            </span>
            <button
              className="px-2"
              onClick={() =>
                currentPage === pages.length - 1
                  ? null
                  : setCurrentPage(currentPage + 1)
              }
            >
              Siguiente
            </button>
            <button
              className="px-2"
              onClick={() => setCurrentPage(pages.length - 1)}
            >
              Último
            </button>
          </div>
        </div>
      ) : (
        <p className="text-center pt-4 pb-1">No hay resultados que mostrar</p>
      )}
    </div>
  );
}

export default Table;
