import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { BounceLoader } from 'react-spinners';
import Card from 'components/Card';
import Table from 'components/Table/Table';
import api from 'utils/api';
import { toast } from 'react-toastify';

import UpdateModal from 'components/UpdateProduct/UpdateModal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getWebURL } from 'utils/misc';
import { config } from 'utils/config';
import { deconvertId } from 'utils/misc';
import { createRef } from 'react';

function UpdateProducts() {
  const ReactSwal = withReactContent(Swal);
  const formRef = createRef(null);

  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchByURL, setSearchByURL] = useState(false);
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);

  const tableProps = {
    options: {
      id: 'productsId',
      pageSize: 10,
    },
    columns: [
      {
        text: '',
        key: 'image',
        width: '5%',
        className: 'text-center',
        isImage: true,
      },
      {
        text: 'Nombre',
        key: 'name',
        className: 'text-center',
      },
      {
        text: 'Tienda',
        key: 'store',
        className: 'text-center',
      },
      {
        text: 'Link',
        className: 'text-center',
        isCustom: true,
        render: (item, _) => {
          const link = `${config.tuyo_web_url}${getWebURL(
            item?.productsId,
            item?.name,
            'P'
          )}`;

          return (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-indigo-dark d-flex text-center justify-content-center"
            >
              <span className="material-icons mx-1">link</span>
              Ver producto
            </a>
          );
        },
      },
    ],
  };

  const formikValidate = (values) => {
    const { keywords, storesId, searchByStore } = values;
    const errors = {};

    if (!storesId) {
      if (!keywords) errors.keywords = 'Requerido';
    }

    if (searchByStore && !storesId && !searchByURL) {
      errors.storesId = 'Requerido';
    }

    return errors;
  };

  const formikOnSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const { keywords, storesId, searchByName, searchByStore } = values;
    let _id;

    if (searchByURL) {
      let _part = keywords;
      if (keywords[keywords.length - 1] === '/') {
        _part = keywords.slice(0, keywords.length - 1);
      }
      _part = _part?.split('/').pop();
      _id = deconvertId(_part);
      if (!_id || isNaN(_id)) {
        toast.error('El link no es válido');
        setSubmitting(false);
        return;
      }
    }

    if (!searchByURL && !searchByName && !searchByStore) {
      toast.error('Debes seleccionar al menos una opción de búsqueda');
      setSubmitting(false);
      return;
    }

    if (searchByStore && !storesId && !searchByURL) {
      toast.error('Debes seleccionar una tienda');
      setSubmitting(false);
      return;
    }

    api.products
      .findByName({
        ...(!searchByURL && searchByName && { name: keywords }),
        attributes: [
          'active',
          'canRefund',
          'cargoType',
          'description',
          'fk_categoriesId',
          'fk_storesId',
          'fk_subcategoriesId',
          'name',
          'nationwide',
          'onlyCard',
          'onlyCash',
          'preparationTime',
          'productsId',
          'regularPrice',
          'tags',
          'warehouse',
          'brand',
        ],
        filter: {
          limit: 50,
          include: [
            {
              association: 'images',
              attributes: ['url'],
              limit: 1,
            },
            {
              required: true,
              association: 'store',
              attributes: ['name'],
            },
            {
              association: 'variants',
              attributes: [
                'image',
                'price',
                'values',
                'attributes',
                'productsVariantsId',
              ],
              include: [
                {
                  association: 'stock',
                  attributes: ['stock', 'productsVariantsStocksId'],
                },
              ],
            },
          ],
        },
        ...(searchByURL
          ? {
              where: {
                productsId: _id,
              },
            }
          : {
              ...(storesId &&
                searchByStore && {
                  where: {
                    fk_storesId: storesId,
                  },
                }),
            }),

        active: false,
      })
      .then((response) => {
        const _products = response.data.map((product) => ({
          ...product,
          image: product?.images?.[0]?.url || '',
          store: product?.store?.name || '',
        }));

        if (!_products?.length) {
          toast.warning('No se encontraron productos');
          return;
        }

        if (searchByURL) {
          showModal(_products[0]);
        } else {
          setSearchedProducts(_products);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setSubmitting(false));
  };

  const showModal = (product) => {
    ReactSwal.fire({
      showCloseButton: true,
      html: <UpdateModal product={product} categories={categories} />,
      showConfirmButton: false,
      width: '80%',
    }).finally(() => {
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    });
  };

  useEffect(() => {
    document.title = `Actualizar productos | ${config.title}`;
  }, []);

  useEffect(() => {
    api.stores
      .getAll({
        where: {
          active: true,
        },
        attributes: ['name', 'storesId'],
        order: [['name', 'ASC']],
      })
      .then((res) => setStores(res.data))
      .catch((err) => console.error(err));

    api.categories.getCategoriesWithSubCategories().then((res) => {
      setCategories(res.data);
    });
  }, []);

  return (
    <div className="content">
      <div>
        <div className="row">
          <div className="col">
            <div className="col-12 d-flex align-items-center">
              <h3 className="col-6 col-md-8 col-xl-9 text-dark-blue font-size-2x font-weight-bold">
                Actualizar productos
                <p>
              {config.tuyo_web_url}
            </p>
              </h3>
              <div className="col-6 col-md-4 col-xl-3 order-md-3 order-2 switch-button d-flex align-items-center justify-content-end">
                <label htmlFor="switch-label" className="mr-2">
                  Buscar por URL
                </label>
                <input
                  value={searchByURL}
                  onChange={() => setSearchByURL(!searchByURL)}
                  type="checkbox"
                  name="switch-button"
                  id="switch-label"
                  className="switch-button__checkbox"
                />
                <label
                  htmlFor="switch-label"
                  className="switch-button__label"
                />
              </div>
            </div>
          
            <Card className="p-md-4 mb-4">
              <Formik
                innerRef={formRef}
                enableReinitialize={true}
                initialValues={{
                  keywords: '',
                  storesId: '',
                  searchByStore: false,
                  searchByName: true,
                }}
                validate={formikValidate}
                onSubmit={formikOnSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="row">
                      <div className="col-12 mt-2">
                        <label htmlFor="keywords" className="d-block">
                          {searchByURL
                            ? 'URL del producto'
                            : 'Nombre del producto'}
                        </label>
                        <Field
                          className="w-100 p-2 rounded"
                          name="keywords"
                          type="text"
                          placeholder={
                            searchByURL
                              ? 'https://tuyoapp.com/p/...'
                              : 'Ej. Camisa de vestir azul'
                          }
                        />
                        <ErrorMessage
                          name="keywords"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      {!searchByURL && (
                        <div className="col-12 mt-2">
                          <label htmlFor="storesId" className="d-block">
                            Tienda
                          </label>
                          <Field
                            className="w-100 p-2 rounded"
                            name="storesId"
                            type="text"
                            as="select"
                          >
                            <option value="">Ninguna</option>
                            {stores.map((store) => (
                              <option value={store.storesId}>
                                {store.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="storesId"
                            component="small"
                            className="text-danger"
                          />
                        </div>
                      )}

                      <div className="col-12 mt-3 row mx-0">
                        {!searchByURL && (
                          <>
                            <div className="col-12 col-sm-6 col-md-3 col-xl-2 mb-2 d-flex flex-column align-items-center">
                              <label htmlFor="searchByName" className="d-block">
                                Buscar por nombre
                              </label>
                              <Field
                                className="w-100 p-2 rounded"
                                name="searchByName"
                                type="checkbox"
                              />
                            </div>
                            <div className="col-12 col-sm-6 col-md-3 col-xl-2 mb-2 d-flex flex-column align-items-center">
                              <label
                                htmlFor="searchByStore"
                                className="d-block"
                              >
                                Buscar por tienda
                              </label>
                              <Field
                                className="w-100 p-2 rounded"
                                name="searchByStore"
                                type="checkbox"
                              />
                            </div>
                          </>
                        )}
                        <button
                          className={`py-2 bg-purple tuyo-btn rounded text-light font-weight-bold d-flex align-items-center justify-content-around ${
                            !searchByURL ? 'col-12 col-md-6 col-xl-8' : 'col-12'
                          }`}
                        >
                          Buscar
                          <BounceLoader
                            color="#fff"
                            loading={isSubmitting}
                            size="18"
                          />
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
            {searchedProducts.length > 0 && (
              <Card className="p-md-4">
                <Table
                  options={tableProps.options}
                  columns={tableProps.columns}
                  data={searchedProducts}
                  pageSize={10}
                  onRowClick={(_, row) => showModal(row)}
                />
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProducts;
