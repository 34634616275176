import React, { useEffect, useState } from 'react';

import Card from '../../components/Card';
import OrderDetail, { orderStatusTexts } from '../../components/OrderDetail';
import Status from '../../components/Status';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { getOrderCode, getOrderStatus, formatPrice } from '../../utils/misc';
import api from '../../utils/api';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const tableProps = {
  pageSize: 10,
  baseURL: '/orders',
  rowId: 'ordersId',
  baseFilter: {
    subQuery: false,
    distinct: true,
    attributes: [
      'ordersId',
      'address',
      'apartment',
      'city',
      'state',
      'total',
      'createdAt',
      'paymentType',
      'paymentStatus',
      'shippingType',
    ],
    include: [
      {
        paranoid: false,
        association: 'user',
        attributes: ['firstname', 'lastname'],
      },
      {
        paranoid: false,
        separate: true,
        association: 'details',
        attributes: ['fk_purchaseStatusesId'],
        include: [
          {
            association: 'store',
            attributes: ['name'],
          },
        ],
      },
    ],
    order: [['ordersId', 'DESC']],
    where: {
      shippingType: "TURBO",
    },
  },
  columns: [
    {
      title: '',
      objectProperty: 'createdAt',
      middleware: (createdAt) => {
        const nowTime = new Date().getTime();
        const createdAtTime = new Date(createdAt).getTime();
        const difference = nowTime - createdAtTime;

        const diffHours = Math.ceil(difference / (1000 * 3600));

        return (
          <>
            <div
              data-tip={moment(createdAt).locale(moment.locale()).fromNow()}
              data-for={createdAt}
              className={
                diffHours > 24
                  ? 'bg-red'
                  : diffHours > 5
                  ? 'bg-orange'
                  : 'bg-green'
              }
              style={{
                padding: 2,
                width: 28,
                height: 28,
                borderRadius: 6,
              }}
            >
              <i className="material-icons-round text-white rounded">
                {diffHours > 24
                  ? 'hourglass_disabled'
                  : diffHours > 5
                  ? 'hourglass_bottom'
                  : 'hourglass_empty'}
              </i>
            </div>
            <ReactTooltip effect="solid" id={createdAt} />
          </>
        );
      },
    },
    {
      title: 'Estado',
      objectProperty: 'orderStatus',
      useAllObject: true,
      searchable: true,
      searchType: 'select',
      searchOptions: [
        {
          value: '',
          label: 'TODOS',
        },
        ...orderStatusTexts.map((status, index) => ({
          value: status.text,
          label: status.text.toUpperCase(),
        })),
      ],
      middleware: (order) => (
        <Status className="w-100" value={getOrderStatus(order.details)} />
      ),
    },
    {
      title: 'ID',
      objectProperty: 'ordersId',
      searchable: true,
      searchType: 'number-equal',
      sortable: true,
    },
    {
      title: 'Codigo',
      objectProperty: 'ordersId',
      searchable: true,
      searchType: 'number-equal',
      middleware: (ordersId) => getOrderCode(ordersId),
      searchMiddleware: (searchTerm) => parseInt(searchTerm, 16) / 64,
    },
    {
      title: 'Tipo de Envio',
      objectProperty: 'shippingType',
      middleware: (shippingType) => {
        const isTurbo = shippingType.includes('TURBO');
        const isScheduled = shippingType.includes('SCHEDULED');

        return (
          <>
            <span
              data-tip={
                isTurbo
                  ? 'Turbo Envio'
                  : isScheduled
                  ? 'Envio Programado'
                  : 'Envio Normal'
              }
              data-for="turbo"
              className={`material-icons-round ${
                isTurbo
                  ? 'text-turbo'
                  : isScheduled
                  ? 'text-green'
                  : 'text-stone-400'
              }`}
            >
              {isTurbo
                ? 'pedal_bike'
                : isScheduled
                ? 'schedule'
                : 'local_shipping'}
            </span>
            <ReactTooltip effect="solid" id="turbo" />
          </>
        );
      },
    },
    {
      title: 'Comercios',
      hasMany: 'details',
      objectProperty: 'details',
      searchable: true,
      searchType: 'text',
      gatewayObjectProperty: '$details.store.name$',
      middleware: (details) =>
        Array.from(new Set(details?.map((detail) => detail?.store?.name))).join(
          ', '
        ),
    },
    {
      title: 'Nombre',
      objectProperty: 'user',
      searchable: true,
      searchType: 'text',
      gatewayObjectProperty: '$user.firstname$',
      middleware: (user) => user?.firstname,
    },
    {
      title: 'Apellido',
      objectProperty: 'user',
      searchable: true,
      searchType: 'text',
      gatewayObjectProperty: '$user.lastname$',
      middleware: (user) => user?.lastname,
    },
    {
      title: 'Casa #',
      objectProperty: 'apartment',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Direccion',
      objectProperty: 'address',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Municipio',
      objectProperty: 'city',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Dpto.',
      objectProperty: 'state',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Monto',
      objectProperty: 'total',
      searchable: true,
      searchType: 'number',
      sortable: true,
      middleware: (total) => formatPrice(total),
    },
    {
      title: 'Creado',
      objectProperty: 'createdAt',
      searchable: true,
      searchType: 'date',
      sortable: true,
      middleware: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: 'Pago',
      middleware: (order) => (
        <>
          {order?.paymentStatus === 'COMPLETADO' ? (
            <span
              data-tip="Pago completado"
              data-for="status"
              className="material-icons-round text-green"
            >
              check_circle
            </span>
          ) : order?.paymentType === 'CASH' ? (
            <span
              data-tip="Pago en efectivo"
              data-for="status"
              className="material-icons-round text-warning"
            >
              payments
            </span>
          ) : null}
          <ReactTooltip effect="solid" id="status" />
        </>
      ),
    },
  ],
};

function TurboOrders() {
  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  useEffect(() => {
    document.title = `Órdenes finalizadas | ${REACT_APP_TITLE}`;
  }, []);

  const [orderOnViewId, setOrderOnViewId] = useState();
  const [tableRemountCount, setTableRemountCount] = useState(0);
  const [turboDisabled, setTurboDisabled] = useState(false);

  function getTurboStatus() {
    api.redis
      .getTurboStatus()
      .then((res) => {
        const value = res?.data?.value ?? false;
        setTurboDisabled(value);
      })
      .catch((err) => {
        console.error(err);
        setTurboDisabled(false);
      });
  }

  function handleTurboActivation() {
    const userId = cookies[REACT_APP_COOKIES_USER_ID];

    const action = turboDisabled
      ? api.redis.activateTurbo()
      : api.redis.deactivateTurbo(+userId);

    action
      .then(() => {})
      .catch((err) => {
        console.error(err);
        toast.warning('No se pudo activar/desactivar los cupones');
      })
      .finally(() => getTurboStatus());
  }

  useEffect(() => {
    getTurboStatus();
  }, []);

  return (
    <div className="content">
      <h3 className="text-dark-blue font-size-2x font-weight-bold">
        Ordenes Turbo Envio
      </h3>
      <div className="mt-3 pl-md-2 col-12 col-md-6 col-lg-4 col-xl-3">
        <button
          type="button"
          onClick={handleTurboActivation}
          className={`d-flex align-items-center justify-content-center tuyo-btn w-100 h-100 p-2 rounded text-light font-weight-bold ${
            turboDisabled ? 'bg-green' : 'bg-red'
          }`}
        >
          {turboDisabled ? (
            <>
              <i className="material-icons mr-2">visibility</i>
              Activar Turbo
            </>
          ) : (
            <>
              <i className="material-icons mr-2">visibility_off</i>
              Desactivar Turbo
            </>
          )}
        </button>
      </div>
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          <Card className="p-md-4">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
              onRowClick={(order) => {
                setOrderOnViewId(order?.ordersId);
              }}
              remountCount={tableRemountCount}
            />
          </Card>
        </div>
      </div>

      {orderOnViewId && (
        <OrderDetail
          requestGetOrders={() => {
            setTableRemountCount(tableRemountCount + 1);
          }}
          showStatus
          sortProductsByStore
          id={orderOnViewId}
          onCloseRequest={() => setOrderOnViewId(null)}
        />
      )}
    </div>
  );
}

export default TurboOrders;
