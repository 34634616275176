import CryptoJS from 'crypto-js';
import axios from 'axios';

const { REACT_APP_API_URL, REACT_APP_COOKIES_JWT, REACT_APP_AMAZON_API } =
  process.env;

axios.interceptors.request.use((req) => {
  const JWTCookie = document.cookie
    .split(';')
    .find((cookie) => cookie.indexOf(REACT_APP_COOKIES_JWT) !== -1);
  const JWT = JWTCookie ? JWTCookie.split('=')[1] : '';

  const timestamp = new Date().getTime();
  const origin = 'logistics';

  const signature = {
    timestamp: `${timestamp}${origin}`,
    origin,
  };

  req.params = {
    ...req.params,
    signature: CryptoJS.SHA1(JSON.stringify(signature)).toString(),
    timestamp,
    app: origin,
  };

  req.url = `${REACT_APP_API_URL}${req.url}`;
  req.headers.authorization = JWT ? `Bearer ${JWT}` : '';

  return req;
});

const api = {
  count: (endpoint) => axios.get('/count' + endpoint),
  findOne: (endpoint, id) => axios.get(`${endpoint}/${id}`),
  findAll: (endpoint, config = {}) => axios.get(endpoint, config),
  create: (endpoint, body, config = {}) => axios.post(endpoint, body, config),
  update: (endpoint, id, body) => axios.patch(`${endpoint}/${id}`, body),
  delete: (endpoint, id) => axios.delete(`${endpoint}/${id}`),
  deleteWithBody: (endpoint, body) =>
    axios.delete(endpoint, {
      data: body,
    }),

  login: (body) => {
    return axios.post('/auth', body);
  },

  categories: {
    getAll: () => {
      return axios.get('/categories');
    },
    create: (body) => axios.post('/categories', body),
    getOne: (id) => axios.get(`/categories/${id}`),
    updateOne: (id, body) => axios.patch(`/categories/${id}`, body),
    deleteOne: (id) => axios.delete(`/categories/${id}`),
    findByName: (name, attributes, where) =>
      axios.get(
        `/categories?filter=${encodeURI(
          JSON.stringify({
            where: {
              name: {
                $iLike: `%${name}%`,
              },
              ...(where ? where : {}),
            },
            ...(attributes ? { attributes } : {}),
          })
        )}`
      ),
    getCategoriesWithSubCategories: () => {
      const filter = {
        attributes: ['productsCategoriesId', 'name', 'fk_productsTypesId'],
        include: [
          {
            association: 'subcategories',
            attributes: ['productsSubcategoriesId', 'name'],
          },
        ],
      };

      return axios.get(`/categories?filter=${JSON.stringify(filter)}`);
    },
  },
  users: {
    getAll: (filter) =>
      axios.get(`/users${filter ? `?filter=${JSON.stringify(filter)}` : ''}`),
    getOne: (id) =>
      axios.get(
        `/users?filter=` +
          JSON.stringify({
            where: { usersId: id },
            paranoid: false,
            include: ['orders_users', 'role'],
          })
      ),
    createUserWithStore: (body) => axios.post('/users/store', body),
    updateOne: (id, body) => axios.patch(`/users/${id}`, body),
    deleteOne: (id) => axios.delete(`/users/${id}`),
    createInternalUser: (body) => axios.post('/users', body),
    getInternalUsers: () => {
      return axios.get(
        `/users?filter=${JSON.stringify({
          attributes: [
            'email',
            'firstname',
            'lastname',
            'usersId',
            'username',
            'createdAt',
            'active',
          ],
          paranoid: false,
          include: [
            {
              association: 'role',
              where: {
                rolesId: { $notIn: [1, 2, 3, 4, 5, 9] },
              },
            },
          ],
        })}`
      );
    },
  },

  subcategories: {
    getAll: () => {
      return axios.get('/subcategories');
    },

    getByCategory: (categoryId) => {
      return axios.get(
        `/subcategories?filter={"where":{"fk_categoriesId":${categoryId}}}`
      );
    },
    createOne: (body) => axios.post('/subcategories', body),
    updateOne: (id, body) => axios.patch(`/subcategories/${id}`, body),
    deleteOne: (id) => axios.delete(`/subcategories/${id}`),
    findByName: (name, attributes, where) =>
      axios.get(
        `/subcategories?filter=${encodeURI(
          JSON.stringify({
            where: {
              name: {
                $iLike: `%${name}%`,
              },
              ...(where ? where : {}),
            },
            ...(attributes ? { attributes } : {}),
          })
        )}`
      ),
  },

  drivers: {
    create: (body) => axios.post('/users', body),
    updateOne: (id, body) => axios.patch(`/users/${id}`, body),
    deleteOne: (id) => axios.delete(`/users/${id}`),

    getOne: (id) =>
      axios.get(
        `/users?filter=` +
          JSON.stringify({
            where: { usersId: id },
            paranoid: false,
            include: ['documents', 'vehicle'],
          })
      ),

    getAll: () =>
      axios.get(
        '/users?filter=' +
          JSON.stringify({
            where: {
              fk_rolesId: 4,
              active: '1',
            },
            include: ['vehicle', 'current_location'],
          })
      ),

    getAllWithOrders: () =>
      axios.get('/users', {
        params: {
          filter: JSON.stringify({
            attributes: [
              'usersId',
              'firstname',
              'lastname',
              'username',
              'photo',
              'active',
              'createdAt',
            ],
            where: {
              fk_rolesId: 4,
              active: '1',
            },
            include: [
              {
                association: 'current_location',
                attributes: ['location'],
                required: true,
              },
              {
                association: 'orders_drivers',
                attributes: [
                  'ordersId',
                  'orderStatus',
                  'total',
                  'address',
                  'apartment',
                  'location',
                  'city',
                  'state',
                  'createdAt',
                  'isTurboShipping',
                ],
                include: [
                  {
                    association: 'details',
                    attributes: ['name', 'qty', 'image', 'price', 'discount'],
                    include: [
                      {
                        association: 'variant',
                        attributes: ['attributes', 'values'],
                        paranoid: false,
                      },
                      {
                        association: 'store',
                        attributes: ['name'],
                        paranoid: false,
                      },
                    ],
                  },
                  {
                    association: 'user',
                    attributes: ['firstname', 'lastname'],
                    paranoid: false,
                  },
                ],
                required: false,
                where: {
                  $not: [
                    {
                      orderStatus: {
                        $or: [
                          { $iLike: 'orden entregada' },
                          { $iLike: 'orden cancelada' },
                          { $iLike: 'orden no entregada' },
                          { $iLike: 'orden no recolectada' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          }),
        },
      }),

    vehicles: {
      deleteOne: (id) => axios.delete(`/usersvehicles/${id}`),
      create: (body) => axios.post('/usersvehicles', body),
      update: (id, body) => axios.patch(`/usersvehicles/${id}`, body),
      getByUser: (userID) =>
        axios.get(
          '/usersvehicles?filter=' +
            JSON.stringify({
              where: {
                fk_usersId: userID,
              },
            })
        ),
    },

    documents: {
      deleteOne: (id) => axios.delete(`/usersdocuments/${id}`),
      create: (body) => axios.post('/usersdocuments', body),
      update: (id, body) => axios.patch(`/usersdocuments/${id}`, body),
      getByUser: (userID) =>
        axios.get(
          '/usersdocuments?filter=' +
            JSON.stringify({
              where: {
                fk_usersId: userID,
              },
            })
        ),
    },

    locations: {
      getAll: () => axios.get('/userscurrentlocations'),
    },
  },

  productImages: {
    deleteOne: (id) => {
      return axios.delete(`/productimages/${id}`);
    },

    createOne: (body) => {
      return axios.post('/productimages', body);
    },
  },

  productVariants: {
    getOne: (id) => {
      return axios.get(
        '/productsvariants?filter=' +
          JSON.stringify({
            where: {
              fk_productsId: id,
            },
          })
      );
    },
    updateOne: (id, body) => axios.patch(`/productsvariants/${id}`, body),
    deleteOne: (id) => {
      return axios.delete(`/productsvariants/${id}`);
    },

    update: (id, body) => {
      return axios.patch(`/productsvariants/${id}`, body);
    },

    create: (body) => {
      return axios.post('/productsvariants', body);
    },
  },

  orderDetail: {
    getAll: (filters = null) => {
      const { dateRange, status } = filters || {};

      return axios.get(
        '/orderdetails?filter=' +
          JSON.stringify({
            where: {
              ...(status
                ? {
                    fk_purchaseStatusesId: status,
                  }
                : null),
              ...(dateRange
                ? {
                    createdAt: {
                      $gt: dateRange.gt,
                      $lt: dateRange.lt,
                    },
                  }
                : null),
            },
            attributes: [
              'fk_storesId',
              'ordersDetailsId',
              'fk_ordersId',
              'price',
              'qty',
              'fk_purchaseStatusesId',
              'discount',
              'name',
              'image',
              'cesc',
              'createdAt',
            ],
            paranoid: false,
            include: [
              {
                association: 'details_histories',
                attributes: ['fk_purchaseStatusesId', 'createdAt'],
              },
              {
                association: 'store',
                attributes: ['storesId', 'name'],
              },
              {
                association: 'store_branch',
                attributes: [
                  'storesBranchesId',
                  'name',
                  'phone',
                  'representative',
                  'address',
                ],
                include: [
                  {
                    association: 'city',
                    attributes: ['citiesId', 'name'],
                  },
                  {
                    association: 'state',
                    attributes: ['statesId', 'name'],
                  },
                ],
              },
              {
                association: 'variant',
                attributes: ['productsVariantsId', 'sku', 'values'],
                include: [
                  {
                    association: 'product',
                    attributes: [
                      'productsId',
                      'cargoType',
                      'isFragile',
                      'freeShipping',
                    ],
                  },
                ],
              },
              {
                paranoid: false,
                association: 'order',
                include: [
                  {
                    paranoid: false,
                    association: 'user',
                    attributes: ['usersId', 'firstname', 'lastname', 'phone'],
                  },
                  {
                    paranoid: false,
                    association: 'driver',
                    attributes: ['usersId', 'firstname', 'lastname', 'phone'],
                  },
                  {
                    paranoid: false,
                    association: 'orders_histories',
                    attributes: ['ordersHistoriesId', 'status', 'createdAt'],
                  },
                ],
              },
            ],
          })
      );
    },
    download: (body) =>
      axios.post('/orderdetails/download', body, { responseType: 'blob' }),
    update: (id, body) => axios.patch(`/orderdetails/${id}`, body),
    statusLog: {
      create: (body) => axios.post(`/ordersdetailshistories`, body),
    },
  },

  orders: {
    getRoute: (body) => axios.post('/geo/driver', body),
    update: (id, body) => axios.patch(`/orders/${id}`, body),
    get: (id) => axios.get(`/orders/${id}`),
    getWithFilter: (filter) =>
      axios.get(`/orders?filter=${JSON.stringify(filter)}`),

    getAll: () =>
      axios.get(
        '/orders?filter=' +
          JSON.stringify({
            paranoid: false,
            include: [
              'user',
              {
                association: 'details',
                include: ['store'],
              },
            ],
          })
      ),

    details: {
      getAll: (filters = null) => {
        const { dateRange } = filters || {};

        return axios.get(
          '/orderdetails?filter=' +
            JSON.stringify({
              where: {
                ...(dateRange
                  ? {
                      createdAt: {
                        $gt: dateRange.gt,
                        $lt: dateRange.lt,
                      },
                    }
                  : null),
              },
              attributes: ['ordersDetailsId', 'fk_purchaseStatusesId'],
              include: [
                {
                  association: 'order',
                  attributes: [
                    'ordersId',
                    'fk_driverId',
                    'shipping',
                    'paymentType',
                    'total',
                  ],
                  include: [
                    {
                      association: 'driver',
                      attributes: ['usersId', 'firstname', 'lastname'],
                    },
                  ],
                },
              ],
            })
        );
      },
    },

    urbanoOrders: () =>
      axios.get(
        '/orders?filter=' +
          JSON.stringify({
            paranoid: false,
            where: { completed: ['urbano', 'urbano-amazon'], orderStatus: 'Orden lista' },
            include: ['user'],
          })
      ),

    statusLog: {
      create: (body) => axios.post('/ordershistories', body),
    },
  },

  stores: {
    getAll: (filter) => {
      let _filter = filter || {
        include: ['user'],
      };

      return axios.get(`/stores?filter=${JSON.stringify(_filter)}`);
    },
    getAllWithOrders: () => {
      return axios.get('/stores', {
        params: {
          filter: JSON.stringify({
            attributes: [
              'storesId',
              'name',
              'image',
              'available',
              'isTurboShipping',
              'isTurboAvailable',
            ],
            include: [
              {
                association: 'stores_branches',
                attributes: ['location'],
                required: true,
              },
              {
                association: 'orders_details',
                attributes: ['name', 'qty', 'image', 'price', 'discount'],
                include: [
                  {
                    association: 'store',
                    attributes: ['name'],
                    paranoid: false,
                  },
                  {
                    association: 'variant',
                    attributes: ['attributes', 'values'],
                    paranoid: false,
                  },
                  {
                    association: 'order',
                    attributes: [
                      'ordersId',
                      'orderStatus',
                      'total',
                      'address',
                      'apartment',
                      'location',
                      'city',
                      'state',
                      'createdAt',
                      'shippingType',
                    ],
                    include: [
                      {
                        association: 'user',
                        attributes: ['firstname', 'lastname'],
                        paranoid: false,
                      },
                    ],
                    where: {
                      fk_driverId: {
                        $is: null,
                      },
                      isTurboShipping: true,
                      $not: [
                        {
                          orderStatus: {
                            $or: [
                              { $iLike: 'orden entregada' },
                              { $iLike: 'orden cancelada' },
                              { $iLike: 'orden no entregada' },
                              { $iLike: 'orden no recolectada' },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
                required: false,
              },
            ],
            where: {
              isTurboShipping: true,
              active: true,
            },
          }),
        },
      });
    },
    getOne: (id) =>
      axios.get(
        `/stores?filter=` +
          JSON.stringify({
            where: { storesId: id },
            include: [
              {
                association: 'user',
                include: [
                  {
                    association: 'payment_stores',
                    attributes: ['paymentStoresId'],
                  },
                ],
              },
              {
                association: 'stores_branches',
                attributes: [
                  'storesBranchesId',
                  'name',
                  'phone',
                  'representative',
                  'email',
                  'address',
                  'location',
                ],
                include: [
                  { association: 'state', attributes: ['name', 'statesId'] },
                  { association: 'city', attributes: ['name', 'citiesId'] },
                ],
              },
            ],
          })
      ),
    updateOne: (id, body) => axios.patch(`/stores/${id}`, body),
    deleteOne: (id) => axios.delete(`/stores/${id}`),
    deactivateStore: (body) => axios.post('/deactivate/store', body),
    documents: {
      create: (body) => axios.post('/storesdocuments', body),

      getByStore: (storeID) =>
        axios.get(
          '/storesdocuments?filter=' +
            JSON.stringify({
              where: {
                fk_storesId: storeID,
              },
            })
        ),
      updateOne: (id, body) => axios.patch(`/storesdocuments/${id}`, body),
    },
    freeShipping: (body) => axios.post('/activate/freeshipping', body),
    bulkUpdate: (body) => axios.patch('/stores', body),
    findByName: (name, attributes, include, where) =>
      axios.get(
        `/stores?filter=${encodeURI(
          JSON.stringify({
            where: {
              name: {
                $iLike: `%${name}%`,
              },
              active: true,
              ...(where ? where : {}),
            },
            ...(include ? include : {}),
            ...(attributes ? { attributes } : {}),
          })
        )}`
      ),
    getNamesAndUsers: () =>
      axios.get(
        `/stores?filter=${JSON.stringify({
          attributes: ['storesId', 'name', 'fk_usersId', 'active'],
          order: ['name'],
          where: {
            active: true,
          },
        })}`
      ),
    create: (body) => axios.post('/stores', body),
  },

  roles: {
    getAll: () => {
      return axios.get('/roles');
    },
    getOne: (id) => axios.get(`/roles/${id}`),
    create: (body) => axios.post('/roles', body),
    updateOne: (id, body) => axios.patch(`/roles/${id}`, body),
    deleteOne: (id, body) => axios.delete(`/roles/${id}`, body),
  },

  products: {
    findByName: ({ name, attributes, filter, active = true, where }) =>
      axios.get(
        `/products?filter=${encodeURI(
          JSON.stringify({
            where: {
              ...(name ? { name: { $iLike: `%${name}%` } } : {}),
              ...(active ? { active: '1' } : {}),
              ...where,
            },
            ...(attributes ? { attributes } : {}),
            ...(filter ? filter : {}),
          })
        )}`
      ),
    getByCategory: (categoriesId) => {
      return axios.get(
        '/products?filter=' +
          JSON.stringify({
            where: {
              fk_categoriesId: categoriesId,
            },
          })
      );
    },
    getByStore: (storeId) => {
      return axios.get(
        '/products?filter=' +
          JSON.stringify({
            where: {
              fk_storesId: storeId,
            },
          })
      );
    },
    updateOne: (id, body) => axios.patch(`/products/${id}`, body),
    getById: (id) => axios.get(`/products/${id}`),
    productsReport: (body) => axios.post('/report/orders/histories', body),
    amazonReport: (body) =>
      axios.post('/amazon/shopping', body, { responseType: 'blob' }),
  },

  urbano: {
    getAll: () => {
      return axios.get('/urbanorders');
    },
    getOne: (id) => axios.get(`/urbanorders/${id}`),
    create: (body) => axios.post('/urbanorders', body),
    stickerUrl: (id) => axios.get(`/bulletpoint/${id}`),
    getByOrder: (id) =>
      axios.get(
        `/urbanorders?filter=` +
          JSON.stringify({
            where: { fk_ordersId: id },
          })
      ),
  },

  c807: {
    getAll: () => axios.get('/c807/orders'),
    getOne: (id) => axios.get(`/c807orders/${id}`),
    create: (body) => axios.post('/c807/orders', body),
    genPdf: (body) => axios.post('/c807/pdf', body),
    getShippingStatus: (body) => axios.post('/c807/status', body),
    getByOrder: (id) =>
      axios.get(
        `/c807/orders?filter=` +
          JSON.stringify({
            where: { fk_ordersId: id },
          })
      ),
  },

  amazon: {
    searchProducts: (term) =>
      fetch(`${REACT_APP_AMAZON_API}serachbyKeywords?keyswords=${term}`).then(
        (res) => res.json()
      ),
    searchByCategory: (categoryId, term) =>
      fetch(
        `${REACT_APP_AMAZON_API}serachbyCategories?category_id=${categoryId}&name=${term}`
      ).then((res) => res.json()),
    getCategories: () =>
      fetch(`${REACT_APP_AMAZON_API}categories`).then((res) => res.json()),
    getProductByAsin: (asin) =>
      fetch(`${REACT_APP_AMAZON_API}productdetails?asin=${asin}`).then((res) =>
        res.json()
      ),
    uploadProducts: (body) =>
      fetch(`${REACT_APP_AMAZON_API}bulk`, {
        method: 'POST',
        body: JSON.stringify(body),
      }).then((res) => res.json()),
  },

  specialOffers: {
    getAll: () => axios.get('/specialoffers'),
    getOne: (id) => axios.get(`/specialoffers/${id}`),
    create: (body) => axios.post('/specialoffers', body),
    update: (id, body) => axios.patch(`/specialoffers/${id}`, body),
    findOne: (id) =>
      axios.get(
        `/specialoffers?filter=` +
          JSON.stringify({
            where: {
              specialOffersId: id,
            },
          })
      ),
  },

  redis: {
    deactivateCoupons: (createdBy) =>
      axios.post('/redis', {
        key: 'coupons',
        value: 'true',
        createdBy,
      }),
    getDeactivatedCoupons: () => axios.get('/redis/deactivated-coupons'),
    activateCoupons: () => axios.delete('/redis/coupons'),
    deactivateRefers: (createdBy) =>
      axios.post('/redis', {
        key: 'referrals',
        value: 'true',
        createdBy,
      }),
    getDeactivatedRefers: () => axios.get('/redis/deactivated-referrals'),
    activateRefers: () => axios.delete('/redis/referrals'),

    activateTurbo: () => axios.delete('/redis/turboshipping'),
    getTurboStatus: () => axios.get('/redis/deactivated-turboshipping'),
    deactivateTurbo: (createdBy) =>
      axios.post('/redis', {
        key: 'turboshipping',
        value: 'true',
        createdBy,
      }),
  },

  bulk: {
    products: (body) => axios.post('/bulk/products', body),
  },

  oneSignal: {
    getUsersByFilter: (filter = []) => {
      return axios.get(`/onesignal/search/users?${filter.join('&')}`);
    },
    sendNotification: (body) => axios.post('/onesignal/send', body),
  },

  cart: {
    findById: (userId) =>
      axios.get(
        `/shoppingcarts?filter=${JSON.stringify({
          where: {
            fk_usersId: userId,
          },
        })}`
      ),
  },

  banners: {
    getAll: (where) =>
      axios.get(`/storesbanners?filter=
    ${JSON.stringify({
      attributes: [
        'storesBannersId',
        'active',
        'url',
        'image',
        'clicks',
        'title',
        'subtitle',
        'cta',
        'type',
        'isSales',
        'isFlashSales',
        'createdAt',
        'updatedAt',
      ],
      order: [['createdAt', 'desc']],
      where,
    })}`),
  },

  pay: {
    getOne: (id) =>
      axios.get(
        `/paymentstores?filter=${JSON.stringify({
          where: {
            paymentStoresId: id,
          },
          include: [
            {
              association: 'user',
              include: [
                {
                  association: 'store',
                  attributes: ['storesId'],
                },
              ],
            },
          ],
        })}`
      ),
    update: (id, body) => axios.patch(`/paymentstores/${id}`, body),
    create: (body) => axios.post('/paymentstores', body),
  },

  paymentMovements: {
    getAll: (filter) =>
      axios.get(`/paymentmovements?filter=${JSON.stringify(filter)}`),
    refund: (body) => axios.post('/paymentmovements/refund', body),
  },

  countries: {
    getAll: (countriesId) =>
      axios.get(
        `/countries?filter=${JSON.stringify({
          attributes: ['countriesId', 'name'],
          ...(countriesId && {
            where: {
              countriesId,
            },
          }),
          include: [
            {
              association: 'states',
              attributes: ['statesId', 'name'],
              include: [
                {
                  association: 'cities',
                  attributes: ['citiesId', 'name'],
                },
              ],
            },
          ],
        })}`
      ),
  },

  fees: {
    getAll: () =>
      axios.get(
        `/fees?filter=${JSON.stringify({
          order: [['minimun', 'asc']],
        })}`
      ),
    create: (body) => axios.post('/fees', body),
    update: (id, body) => axios.patch(`/fees/${id}`, body),
    delete: (id) => axios.delete(`/fees/${id}`),
  },

  paymentvouchers: {
    download: (body) => axios.post('/paymentvouchers/download', body),
  },

  searches: {
    download: (body) =>
      axios.post('/searches/report', body, { responseType: 'blob' }),
  },

  storeBranch: {
    update: (id, body) => axios.patch(`/storesbranches/${id}`, body),
  },

  productVariantStocks: {
    updateOne: (id, body) => axios.patch(`/productsvariantsstocks/${id}`, body),
  },

  paymentMethods: {
    getByType: (type) =>
      axios.get(
        `/paymentmethods?filter=${JSON.stringify({
          where: {
            type,
            active: true,
          },
        })}`
      ),
    getByID: (id) => axios.get(`/paymentmethods/${id}`),
    switchPaymentGateway: (gateway,userId) => axios.patch('/paymentmethods/1', {
      gateway,
      updatedBy: userId,
    }),
  },

  bannedCoupons: {
    getAll: () =>
      axios.get(
        `/bannedcoupons?filter=${JSON.stringify({
          order: [['createdAt', 'desc']],
        })}`
      ),
    getOne: (id) => axios.get(`/bannedcoupons/${id}`),
    create: (body) => axios.post('/bannedcoupons', body),
    delete: (id) => axios.delete(`/bannedcoupons/${id}`),
    update: (id, body) => axios.patch(`/bannedcoupons/${id}`, body),
  },

  deliveryTime: {
    getAll: () =>
      axios.get(
        `/deliverytime?filter=${JSON.stringify({
          order: [['createdAt', 'desc']],
        })}`
      ),
    getOne: (id) => axios.get(`/deliverytime/${id}`),
    create: (body) => axios.post('/deliverytime', body),
    delete: (id) => axios.delete(`/deliverytime/${id}`),
    update: (id, body) => axios.patch(`/deliverytime/${id}`, body),
  },

  stockLimits: {
    getOne: (id) => axios.get(`/stocklimits/${id}`),
    update: (id, body) => axios.patch(`/stocklimits/${id}`, body),
    create: (body) => axios.post('/stocklimits', body),
    delete: (id) => axios.delete(`/stocklimits/${id}`),
  },

  promotions: {
    getStores: (stores) =>
      api.findAll(
        `/stores?filter=${JSON.stringify({
          attributes: ['storesId', 'name'],
          order: [['name', 'asc']],
          where: {
            active: true,
            storesId: stores,
          },
        })}`
      ),
    getCategories: (categories) =>
      api.findAll(
        `/categories?filter=${JSON.stringify({
          attributes: ['productsCategoriesId', 'name'],
          order: [['name', 'asc']],
          where: {
            productsCategoriesId: categories,
          },
        })}`
      ),
    getProducts: (products) =>
      api.findAll(
        `/products?filter=${JSON.stringify({
          attributes: ['productsId', 'name'],
          order: [['name', 'asc']],
          where: {
            active: '1',
            productsId: products,
          },
        })}`
      ),
    getSubCategories: (subcategories) =>
      api.findAll(
        `/subcategories?filter=${JSON.stringify({
          attributes: ['productsSubcategoriesId', 'name'],
          order: [['name', 'asc']],
          where: {
            productsSubcategoriesId: subcategories,
          },
        })}`
      ),
  },

  specialDiscounts: {
    getAll: () =>
      axios.get(
        `/specialdiscounts?filter=${JSON.stringify({
          order: [['createdAt', 'desc']],
        })}`
      ),
    getOne: (id) => axios.get(`/specialdiscounts/${id}`),
    create: (body) => axios.post('/specialdiscounts', body),
    delete: (id) => axios.delete(`/specialdiscounts/${id}`),
    update: (id, body) => axios.patch(`/specialdiscounts/${id}`, body),
  },

  settings: {
    updateAlgolia: (body) => axios.post('/update-algolia', body),
  },

  giftcards: {
    getOne: (id) =>
      axios.get(
        `/giftcards?filter=${JSON.stringify({
          where: { fk_ordersId: id },
          attributes: ['giftcardsId', 'code', 'value', 'redeemedBy', 'used'],
          include: [
            {
              association: 'redeemed',
              paranoid: false,
              attributes: ['firstname', 'lastname'],
            },
          ],
        })}`
      ),
  },

  turbo: {
    updateProducts: (body) => axios.post('/activate/turboshipping', body),
    updateStores: (body) => axios.post('/activate/turbo/stores', body),
    updateCategories: (body) => axios.post('/activate/turbo/categories', body),
  },

  transactions: {
    refund: (body) => axios.post('/amex/refund', body),
  },

  emails: {
    logs: (body) => axios.post('/sendgrid/log', body),
  },

  liquidationReports: {
    getOne: (id) => axios.get(`/paymentreports/${id}`, {
      responseType: 'blob',
    }),
  },

  shippingtype: {
    getAll: () => axios.get('/shippingtypes?filter={"order": [["shippingTypesId", "asc"]]}'),
    getOne: (id) => axios.get(`/shippingtypes/${id}`),
    update: (id, body) => axios.patch(`/shippingtypes/${id}`, body),
  },

  affiliations: {
    getOne: (id) =>
      axios.get(
        `/affiliation-request?filter=` +
          JSON.stringify({
            where: { affiliationRequestsId: id },
          })
      ),
    update: (id, body) => axios.patch(`/affiliation-request/${id}`, body),
  }
};

export default api;
