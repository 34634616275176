import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import download from 'downloadjs';
import { BounceLoader } from 'react-spinners';

import Card from 'components/Card';

import api from '../../utils/api';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';

const reportTypes = {
  unusedGiftCards: {
    display: 'Tarjetas de regalo no usadas',
    fileName: 'UnusedGiftCardsReport',
    HTTPRequestMethod: 'GET',
    endpoint: '/giftcards/unused/report',
    showDateSelectors: false,
  },
  usersBalance: {
    display: 'Balance de usuarios',
    fileName: 'UsersBalanceReport',
    HTTPRequestMethod: 'POST',
    endpoint: '/users/balance/report',
    showDateSelectors: true,
  },
  users: {
    display: 'Usuarios',
    fileName: 'Users',
    HTTPRequestMethod: 'GET',
    endpoint: '/report/users',
    showDateSelectors: false,
  },
  usersShoppingCarts: {
    display: 'Carritos de compra',
    fileName: 'UsersShoppingCarts',
    HTTPRequestMethod: 'GET',
    endpoint: '/report/usersshoppingcarts',
    showDateSelectors: false,
  },
};

const tableProps = {
  rowId: 'usersId',
  baseURL: '/users',
  baseFilter: {
    attributes: [
      'usersId',
      'firstname',
      'lastname',
      'username',
      'fk_rolesId',
      'email',
      'phone',
      'createdAt',
      'referralCode',
      'verify',
    ],
    include: [{ association: 'role', attributes: ['rolesId', 'name'] }],
    order: [['usersId', 'desc']],
  },
  pageSize: 10,
  columns: [
    {
      title: 'ID',
      objectProperty: 'usersId',
      searchable: true,
      searchType: 'number-equal',
      sortable: true,
    },
    {
      title: 'Nombre',
      objectProperty: 'firstname',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Apellido',
      objectProperty: 'lastname',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Usuario',
      objectProperty: 'username',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Referido',
      objectProperty: 'referralCode',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Rol',
      objectProperty: 'role',
      gatewayObjectProperty: 'fk_rolesId',
      searchable: true,
      searchType: 'number-equal',
      middleware: (role) => `${role?.rolesId} - ${role?.name}`,
    },
    {
      title: 'Email',
      objectProperty: 'email',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Verificado',
      objectProperty: 'verify',
      columnType: 'check',
      objectMiddleware: (verify) => verify === 1,
      className: 'text-center',
    },
    {
      title: 'Telefono',
      objectProperty: 'phone',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Creado',
      objectProperty: 'createdAt',
      searchable: true,
      searchType: 'timestamp',
      sortable: true,
      middleware: (createdAt) => new Date(createdAt).toLocaleString(),
    },
  ],
};

function UsersList() {
  const history = useHistory();
  const { REACT_APP_TITLE } = process.env;

  useEffect(() => {
    document.title = `Usuarios | ${REACT_APP_TITLE}`;
  }, []);

  const [reportType, setReportType] = useState('unusedGiftCards');
  const [reportStartDate, setReportStartDate] = useState();
  const [reportEndDate, setReportEndDate] = useState();
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  function generateReport() {
    setIsGeneratingReport(true);

    const selectedReportType = reportTypes[reportType];

    const action =
      selectedReportType.HTTPRequestMethod === 'GET'
        ? api.findAll(selectedReportType.endpoint, { responseType: 'blob' })
        : api.create(
            selectedReportType.endpoint,
            {
              startDate: reportStartDate,
              endDate: reportEndDate,
            },
            { responseType: 'blob' }
          );

    action
      .then((response) => {
        download(
          response.data,
          `${selectedReportType.fileName} - ${
            selectedReportType.showDateSelectors
              ? `${reportStartDate} to ${reportEndDate}`
              : new Date().toLocaleString()
          }.csv`,
          response.headers['content-type']
        );
      })
      .catch((error) => {
        console.error(error);
        toast.warning('[SERVER_ERROR] ' + error);
      })
      .finally(() => {
        setIsGeneratingReport(false);
      });
  }

  return (
    <div className="content">
      <div>
        <h3 className="text-dark-blue font-size-2x font-weight-bold col-12">
          Usuarios
        </h3>
        <div className="row">
          <div className="col">
            <Card className="p-md-4 mb-4">
              <div className="row">
                <div className="col d-flex flex-column">
                  <label htmlFor="report">Tipo de reporte</label>
                  <select
                    className="p-2"
                    name="report"
                    id="report"
                    defaultValue={reportType}
                    onChange={(event) => {
                      setReportType(event.target.value);
                    }}
                  >
                    {Object.entries(reportTypes).map(([key, value]) => (
                      <option value={key} key={key}>
                        {value.display}
                      </option>
                    ))}
                  </select>
                </div>
                {reportTypes[reportType].showDateSelectors && (
                  <div className="col">
                    <div className="row">
                      <div className="col d-flex flex-column">
                        <label htmlFor="report">Inicio</label>
                        <input
                          className="rounded p-2"
                          type="date"
                          value={reportStartDate}
                          onChange={(event) => {
                            setReportStartDate(event.target.value);
                          }}
                        />
                      </div>
                      <div className="col d-flex flex-column">
                        <label htmlFor="report">Final</label>
                        <input
                          className="rounded p-2"
                          type="date"
                          value={reportEndDate}
                          onChange={(event) => {
                            setReportEndDate(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <button
                onClick={generateReport}
                disabled={
                  isGeneratingReport ||
                  (reportTypes[reportType].showDateSelectors &&
                    (!reportStartDate || !reportEndDate))
                }
                className="w-100 bg-purple tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around mt-2"
              >
                Generar
                <BounceLoader
                  color="#fff"
                  loading={isGeneratingReport}
                  size="18"
                />
              </button>
            </Card>
            <Card className="p-md-4">
              <PaginatedTable
                columns={tableProps.columns}
                pageSize={tableProps.pageSize}
                baseURL={tableProps.baseURL}
                baseFilter={tableProps.baseFilter}
                rowId={tableProps.rowId}
                onRowClick={(user) => {
                  history.push(`/admin/users/${user.usersId}/edit`);
                }}
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsersList;
