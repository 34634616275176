import ReactModal from 'components/Modal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import api from 'utils/api';

let map;

function OrderAddressModal({
  initialValues,
  title,
  isOpen,
  onClose,
  onChange,
}) {
  const [location, setLocation] = useState();
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => renderMap(), 300);
    }
    api.countries
      .getAll(59)
      .then(({ data }) => setStates(data[0]?.states || []))
      .catch(() => toast.error('SERVER ERROR, No se pudo obtener los estados'));
  }, [isOpen]);

  const renderMap = () => {
    const coordinates = initialValues?.location?.coordinates || [0, 0];
    const mapContainer = document.getElementById('mapContainer');

    map = new window.google.maps.Map(mapContainer, {
      zoom: 16,
      center: coordinates
        ? { lat: coordinates[1], lng: coordinates[0] }
        : { lng: -89.2365921, lat: 13.7034519 },
      disableDefaultUI: true,
    });

    map.addListener('center_changed', () => {
      setLocation(map.getCenter().toJSON());
    });

    const input = document.getElementById('pac-input');
    const searchBox = new window.google.maps.places.SearchBox(input);
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

    searchBox.addListener('places_changed', () => {
      map.panTo(searchBox.getPlaces()[0].geometry.location.toJSON());
    });
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    if (!location) {
      toast.error('Seleccione una ubicación');
      setSubmitting(false);
      return;
    }

    onChange({
      ...values,
      state:
        states.find((state) => +state.statesId === +values.fk_statesId)?.name ||
        '',
      city:
        states
          .find((state) => +state.statesId === +values.fk_statesId)
          ?.cities.find((city) => +city.citiesId === +values.fk_citiesId)
          ?.name || '',
      location: {
        type: 'Point',
        coordinates: [location.lng, location.lat],
      },
    });
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onCloseRequest={() => {
        if (onClose) onClose();
      }}
    >
      <div className="d-flex flex-column">
        <h4 className="text-dark-blue font-size-x font-weight-bold whitespace-pre-line">
          {title}
        </h4>
        <div className="map-wrapper" style={{ position: 'relative' }}>
          <input
            id="pac-input"
            placeholder="Buscar"
            type="text"
            style={{ width: '300px', marginTop: '10px', marginLeft: '10px' }}
            className="rounded px-2 py-1"
          />
          <div
            id="map-center"
            style={{
              position: 'absolute',
              zIndex: '99',
              height: '40px',
              width: '40px',
              top: '50%',
              left: '50%',
              marginLeft: '-21px',
              marginTop: '-41px',
            }}
          >
            <span className="material-icons">place</span>
          </div>
          <div
            style={{ width: '100%', height: '400px', borderRadius: '1rem' }}
            id="mapContainer"
          />
        </div>

        <Formik
          initialValues={{
            address: initialValues?.address || '',
            fk_statesId:
              states.find((s) => s.name.includes(initialValues?.state))
                ?.statesId || '',
            fk_citiesId:
              states
                .find((s) => s.name.includes(initialValues?.state))
                ?.cities.find((c) => c.name.includes(initialValues?.city))
                ?.citiesId || '',
            apartment: initialValues?.apartment || '',
            referencePoint: initialValues?.referencePoint || '',
          }}
          validate={(value) => {
            const errors = {};
            if (!value.address) errors.address = 'Campo requerido';
            if (!value.apartment) errors.apartment = 'Campo requerido';
            if (!value.referencePoint)
              errors.referencePoint = 'Campo requerido';
            if (!value.fk_statesId) errors.fk_statesId = 'Campo requerido';
            if (!value.fk_citiesId) errors.fk_citiesId = 'Campo requerido';

            return errors;
          }}
          onSubmit={onSubmit}
        >
          {({ values, isSubmitting }) => (
            <Form className="row">
              <div className="col-12 col-md-6">
                <p className="font-weight-bold text-green">Direccion</p>
                <Field
                  name="address"
                  text="text"
                  placeholder="Tuyo App"
                  className="w-100 rounded p-2"
                />
                <ErrorMessage
                  name="referencePoint"
                  component="small"
                  className="text-danger"
                />
              </div>

              <div className="col-12 col-md-6">
                <p className="font-weight-bold text-green">
                  Punto de referencia
                </p>
                <Field
                  name="referencePoint"
                  text="text"
                  placeholder="Tuyo App"
                  className="w-100 rounded p-2"
                />
                <ErrorMessage
                  name="referencePoint"
                  component="small"
                  className="text-danger"
                />
              </div>

              <div className="col-12 col-md-6">
                <p className="font-weight-bold text-green">
                  Número de casa / apartamento
                </p>
                <Field
                  name="apartment"
                  text="text"
                  placeholder="Tuyo App"
                  className="w-100 rounded p-2"
                />
                <ErrorMessage
                  name="apartment"
                  component="small"
                  className="text-danger"
                />
              </div>

              <div className="col-12 col-md-6">
                <p className="font-weight-bold text-green">Departamento</p>
                <Field
                  as="select"
                  name="fk_statesId"
                  className="p-2 rounded w-100"
                >
                  <option value="Seleccione un departamento"></option>
                  {states.map((state, index) => (
                    <option key={index} value={state.statesId}>
                      {state.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="fk_statesId">
                  {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                </ErrorMessage>
              </div>

              <div className="col-12 col-md-6">
                <p className="font-weight-bold text-green">Municipio</p>
                <Field
                  as="select"
                  name="fk_citiesId"
                  className="p-2 rounded w-100"
                >
                  <option value="Seleccione un municipio"></option>
                  {(states?.filter(
                    (state) => +state.statesId === +values.fk_statesId
                  ) || [])?.[0]?.cities?.map((city, index) => (
                    <option key={index} value={city.citiesId}>
                      {city.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="fk_citiesId">
                  {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                </ErrorMessage>
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                style={{
                  margin: 'auto !important',
                  zIndex: 10,
                }}
                className="bg-green tuyo-btn my-3 mx-2 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around w-full"
              >
                Editar
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </ReactModal>
  );
}

export { OrderAddressModal };
// address
// apartment
// referencePoint
// location
// city
// state
