import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { toast } from 'react-toastify';


import colors from '../../assets/resources/colors';
import api from '../../utils/api';

import { formatTimestamp } from '../../utils/misc';

import Card from 'components/Card';
import Table from 'components/Table/Table';

const c807Table = {
  options: {
    id: 'c807OrdersId',
    pageSize: 10,
  },
  columns: [
    { text: 'ID', key: 'c807OrdersId', className: 'text-center' },
    { text: 'Orden', key: 'fk_ordersId', className: 'text-center' },
    { text: 'Total recolectado', key: 'amountCCE', className: 'text-right', isCurrency: true, },
    { text: 'Fecha y hora', key: 'createdAtDisplay', className: 'text-center' },
  ]
}

function C807LIst() {
  const { REACT_APP_TITLE } = process.env;
  const history = useHistory();

  const [c807Orders, setC807Orders] = useState();

  useEffect(() => {
    document.title = `C807 | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    api.c807.getAll()
      .then(res => {
        setC807Orders(res.data.map(order => {
          return {
            ...order,
            createdAtDisplay: formatTimestamp(order.createdAt),
          }
        }));
      })
      .catch(err => {
        toast.warning('[SERVER_ERROR] No se pudo obtener las ordenes de Urbano');
        console.error(err)
      });
  }, []);

  return (
    <div className="content">
      <div className="row">
        <div className='col-12 col-md-8 col-xl-9 d-flex align-items-center'>
          <h3 className='text-dark-blue font-size-2x font-weight-bold'>C807</h3>
        </div>
        <div className='col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0'>
          {/* <Link to='/admin/urbano/new' className='w-100'>
            <button
              type='button'
              className='d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold'>
              <img src={addSVG} alt='' className='d-inline-block mr-2' style={{ height: '16px' }} />
              Nueva Orden
            </button>
          </Link> */}
        </div>
      </div>
      <div className='row mt-3 mt-md-4' style={{ flexGrow: '1' }}>
        <div className='col-12'>
          <Card className="p-md-4">
            {
              c807Orders
                ? <Table
                  showFilters
                  options={c807Table.options}
                  columns={c807Table.columns}
                  data={c807Orders}
                  pageSize={10}
                  onRowClick={id => history.push(`/admin/c807/${id}/view`)}
                />
                : <div
                  className='p-5 m-5 d-flex justify-content-center align-items-center'
                  style={{
                    flexGrow: '1',
                  }}>
                  <ClimbingBoxLoader color={colors.green} size='25' />
                </div>
            }
          </Card>
        </div>
      </div>
    </div>
  )
}

export default C807LIst;