import React, { useEffect, useState } from 'react';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { toast } from 'react-toastify';

import Card from '../Card';
import Table from '../Table/Table';

import api from '../../utils/api';
import { getDaysDates } from '../../utils/misc';

import circleCheckSVG from '../../assets/icons/check-circle-solid-green.svg';

const table = {
  options: {
    id: 'usersId'
  },
  columns: [
    { text: 'ID', key: 'usersId', className: 'text-center' },
    { text: 'Nombre', key: 'name', className: 'text-center' },
    { text: 'Vehículo', key: 'vehicle', className: 'text-center' },
    { text: 'Viajes finalizados', key: 'finishedTrips', className: 'text-center' },
    { text: 'Monto recolectado', key: 'collectedCash', className: 'text-center', isCurrency: true },
    { text: 'Pago', key: 'payment', className: 'text-center', isCurrency: true },
    { text: 'Activo', key: 'active', className: 'text-center' }
  ]
}

function DaySummary() {
  const [drivers, setDrivers] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    if (selectedDate) {
      const { currPeriod1stDay, currPeriodLastDay } = getDaysDates(selectedDate);
      getDrivers(currPeriod1stDay, currPeriodLastDay);
    }
  }, [selectedDate]);

  function getDrivers(startDate, endDate) {
    api.findAll('/orders?filter=' + JSON.stringify({
      where: {
        $not: {
          fk_driverId: null
        },
        createdAt: {
          $gt: startDate,
          $lt: endDate
        },
        orderStatus: {
          $or: [
            { $iLike: 'orden no recolectada' },
            { $iLike: 'orden en camino' },
            { $iLike: 'orden entregada' },
            { $iLike: 'orden no entregada' },
            { $iLike: 'peticion de cambio' },
            { $iLike: 'peticion de reembolso' },
          ]
        }
      },
      attributes: ['ordersId', 'total', 'paymentType', 'orderStatus', 'shipping'],
      include: [{
        association: 'driver',
        attributes: ['usersId', 'firstname', 'lastname', 'active'],
        include: [{
          association: 'vehicle',
          attributes: ['usersVehiclesId', 'type']
        }]
      }]
    }))
      .then(res => {
        const tmpDrivers = [];

        res.data.forEach(order => {
          const existentDriver = tmpDrivers.find(driver => driver.usersId === order.driver.usersId);

          if (existentDriver) {
            existentDriver.orders.push({ ...order });
            existentDriver.finishedTrips += 1;
            existentDriver.payment += order.shipping - 1;
            existentDriver.collectedCash += order.paymentType.split('|')[0] === 'CASH' ? order.total : 0;
          } else {
            tmpDrivers.push({
              ...order.driver,
              name: `${order.driver.firstname} ${order.driver.lastname}`,
              vehicle: order.driver.vehicle?.type,
              finishedTrips: 1,
              collectedCash: order.paymentType.split('|')[0] === 'CASH' ? order.total : 0,
              payment: order.shipping - 1,
              active: Number(order.driver.active) ? <img src={circleCheckSVG} alt="" style={{ height: '18px' }} /> : null,
              orders: [{ ...order }],
            });
          }
        });

        setDrivers([...tmpDrivers]);
      })
      .catch(err => toast.warning(`[SERVER_ERROR] ${err}`));
  }

  return <div className='row mt-3 mt-md-4' style={{ flexGrow: '1' }}>
    <div className='col-12'>
      <h3 className='text-dark-blue font-size-2x font-weight-bold'>Resumen diario</h3>
    </div>
    <div className='col-12 mt-3'>
      <Card>
        <input
          type="date"
          className="p-1 w-100 rounded mb-2"
          value={`${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`}
          onChange={event => {
            const notUTCDate = event.target.valueAsDate;
            const UTCDate = new Date(notUTCDate.getUTCFullYear(), notUTCDate.getUTCMonth(), notUTCDate.getUTCDate(), 0, 0, 0);
            setSelectedDate(UTCDate);
          }} />
        {
          drivers
            ? <>
              <Table
                columns={table.columns}
                data={drivers}
                options={table.options}
                pageSize={10} />
            </>
            : <div className='p-5 m-5 d-flex justify-content-center align-items-center' style={{ flexGrow: '1' }}>
              <ClimbingBoxLoader color='#01D781' size='25' />
            </div>
        }
      </Card>
    </div>
  </div>
};

export default DaySummary;
