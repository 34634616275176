import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import BounceLoader from 'react-spinners/BounceLoader';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import Card from '../../components/Card';

import api from '../../utils/api';
import colors from '../../assets/resources/colors';
import {
  fileToBase64URL,
  uploadPictureToS3,
  deleteFromS3,
  appUrls,
} from '../../utils/misc';
import CustomBanner from 'components/BannerComponent';
import moment from 'moment';
import Datetime from 'react-datetime';
import ReactTooltip from 'react-tooltip';

function NewPopup() {
  const formRef = useRef(null);
  const history = useHistory();

  /* URL params */
  const { id: popupId } = useParams();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  const [popup, setPopup] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const [webPopUp, setWebPopUp] = useState();
  const [mobilePopUp, setMobilePopUp] = useState();

  const [mobileRedirectUrl, setMobileRedirectUrl] = useState({
    url: '',
    id: '',
    selected: -1,
    idRequired: false,
  });

  /* Set page title */
  useEffect(() => {
    document.title = `${
      popupId ? 'Editar' : 'Nuevo'
    } popup | ${REACT_APP_TITLE}`;
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  /* Get banner */
  useEffect((_) => {
    popupId &&
      api
        .findOne('/popups', popupId)
        .then((res) => {
          setPopup(res.data);
        })
        .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));
  }, []);

  useEffect(
    (_) => {
      if (popup) {
        const { redirectMobileUrl: redirectUrl } = popup;

        setWebPopUp({ URL: popup.desktopUrl });
        setMobilePopUp({ URL: popup.mobileUrl });

        if (redirectUrl) {
          const matches = redirectUrl.split('/');
          const id = matches.length > 1 ? matches[1] : '';
          const url = matches.length > 1 ? redirectUrl.replace(id, 'ID') : redirectUrl;

          setMobileRedirectUrl({
            id,
            url,
            selected: appUrls.findIndex((u) => u.url === url),
            idRequired: redirectUrl.includes('ID'),
          });
        }
      }
    },
    [popup]
  );

  async function changeImage(event, imageType) {
    const file = event.target.files[0];
    if (file) {
      if (file.type.includes('gif')) {
        if (file.size / 1024 >= 4096) {
          toast.warning('Error: El gif supera el peso maximo permitido');
          event.target.value = '';
          return;
        }
      } else {
        if (file.size / 1024 >= 2048) {
          toast.warning('Error: La imagen supera el peso maximo permitido');
          event.target.value = '';
          return;
        }
      }

      const { type, name } = file;

      if (imageType === 'mobile') {
        setMobilePopUp({
          base64: await fileToBase64URL(file),
          type,
          name,
          ...(popupId && popup ? { oldURL: mobilePopUp.URL } : {}),
        });
      }

      if (imageType === 'web') {
        setWebPopUp({
          base64: await fileToBase64URL(file),
          type,
          name,
          ...(popupId && popup ? { oldURL: webPopUp.URL } : {}),
        });
      }
    }
  }

  async function save(formValues) {
    const { mobileId } = formValues;

    if (!webPopUp) {
      toast.error('Debes subir una imagen para el popup web');
      return;
    }

    if (!mobilePopUp) {
      toast.error('Debes subir una imagen para el popup mobile');
      return;
    }

    toast.success('Espere un momento, el popup esta siendo procesado');

    setIsSaving(true);

    const urlContainsId = mobileRedirectUrl.url.includes('ID');

    const body = {
      ...formValues,
      redirectMobileUrl: urlContainsId
        ? mobileRedirectUrl.url.replace('ID', mobileId)
        : mobileRedirectUrl.url,
      mobileUrl: mobilePopUp.base64
        ? await uploadPictureToS3(
            mobilePopUp.base64,
            mobilePopUp.type,
            mobilePopUp.name,
            'popups'
          )
        : mobilePopUp.URL,
      desktopUrl: webPopUp.base64
        ? await uploadPictureToS3(
            webPopUp.base64,
            webPopUp.type,
            webPopUp.name,
            'popups'
          )
        : webPopUp.URL,
    };

    if (webPopUp.oldURL) {
      deleteFromS3(
        webPopUp.oldURL.substring(webPopUp.oldURL.lastIndexOf('popups/'))
      );
    }

    if (mobilePopUp.oldURL) {
      deleteFromS3(
        mobilePopUp.oldURL.substring(mobilePopUp.oldURL.lastIndexOf('popups/'))
      );
    }

    body[popupId ? 'updatedBy' : 'createdBy'] =
      cookies[REACT_APP_COOKIES_USER_ID];
    const action = popupId
      ? api.update('/popups', popupId, body)
      : api.create('/popups', body);

    action
      .then((_) => {
        toast.success(`Popup ${popupId ? 'actualizado' : 'creado'}`);
        history.push('/admin/popup');
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
      })
      .finally((_) => setIsSaving(false));
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center text-md-left w-100">
            {popupId ? 'Editar' : 'Nuevo'} popup
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <button
            disabled={isSaving}
            onClick={handleSubmit}
            type="button"
            className="bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
          >
            {isSaving ? 'Guardando' : 'Guardar'}
            <BounceLoader color="#fff" loading={isSaving} size="18" />
          </button>
        </div>
      </div>
      <Card className="p-4 mt-3 mt-md-4">
        {(popupId && popup) || !popupId ? (
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={{
              name: popup?.name || '',
              cta: popup?.cta || '',
              redirectDesktopUrl: popup?.redirectDesktopUrl || '',
              redirectMobileUrl: '',
              active: popup?.active || false,
              needAuth: popup?.needAuth || false,
              withoutAuth: popup?.withoutAuth || false,
              authRedirectUrl: popup?.authRedirectUrl || '',
              desktopUrl: popup?.desktopUrl || '',
              mobileUrl: popup?.mobileUrl || '',
              startline: popup?.startline || '',
              deadline: popup?.deadline || '',
              invasive: popup?.invasive || false,
              disposable: popup?.disposable || false,

              mobileId: mobileRedirectUrl.id || '',
            }}
            validate={(values) => {
              const errors = {};

              if (!values.name) {
                errors.name = 'El nombre es requerido';
              }

              if (!values.startline?.trim()?.length > 0) {
                errors.startline = 'La fecha de inicio es requerida';
              }

              if (!values.deadline?.trim()?.length > 0) {
                errors.deadline = 'La fecha de finalización es requerida';
              }

              if (values.needAuth && values.withoutAuth) {
                errors.needAuth = 'No puedes tener ambas opciones activas';
                errors.withoutAuth = 'No puedes tener ambas opciones activas';
              }

              if (values.disposable) {
                if (mobileRedirectUrl?.url == '')
                  errors.redirectMobileUrl =
                    'Si obligatorio esta activo, se requiere una url';

                if (values.redirectDesktopUrl?.trim() == '')
                  errors.redirectDesktopUrl =
                    'Si obligatorio esta activo, se requiere una url';

                if (values.cta?.trim() == '')
                  errors.cta = 'Si obligatorio esta activo, se requiere un CTA';
              }

              if (mobileRedirectUrl.url != '') {
                if (mobileRedirectUrl.url?.includes('ID') && !values.mobileId)
                  errors.mobileId = 'El ID es requerido';

                if (values.cta?.trim() == '') errors.cta = 'CTA es requerido';
              }

              return errors;
            }}
            onSubmit={(values) => {
              save(values);
            }}
          >
            {() => (
              <Form>
                <div
                  style={{
                    minHeight: '10rem',
                    justifyContent: 'center',
                    margin: '0 auto',
                  }}
                  className="row w-full"
                >
                  <CustomBanner
                    onChange={(event) => changeImage(event, 'web')}
                    className="mx-4 col-8 col-md-6 col-xl-5"
                    id="web"
                    aspectRatio={9 / 16}
                    aspectRatioText="2:1"
                    image={webPopUp}
                    title="Imagen web"
                    acceptGif
                  />

                  <CustomBanner
                    onChange={(event) => changeImage(event, 'mobile')}
                    className="mx-4 col-8 col-md-4 col-xl-3"
                    id="mobile"
                    aspectRatio={4 / 3}
                    aspectRatioText="1:2"
                    image={mobilePopUp}
                    title="Imagen movil"
                    acceptGif
                  />
                </div>

                <div className="row">
                  <div className="col-12 col-md-5 mt-2">
                    <label htmlFor="name" className="d-block">
                      Nombre
                    </label>
                    <Field
                      className="w-100 p-2 rounded"
                      name="name"
                      type="text"
                      placeholder="Identificador del popup"
                    />
                    <ErrorMessage name="name">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-6 d-flex flex-column justify-content-center align-items-center mt-2 col-md-1">
                    <label className="d-block" htmlFor="active">
                      Activo
                    </label>
                    <Field name="active" type="checkbox" id="active" />
                  </div>

                  <div className="col-6 d-flex flex-column justify-content-center align-items-center mt-2 col-md-1">
                    <label className="d-block" htmlFor="invasive">
                      Invasivo
                    </label>
                    <Field
                      data-tip="Si esta activo el popup aparecerá siempre que se entre a la app/web o se recargue de lo contrario solo aparecerá una vez por sesion"
                      name="invasive"
                      type="checkbox"
                      id="invasive"
                    />
                    <ReactTooltip effect="solid" />
                  </div>

                  <div className="col-6 d-flex flex-column justify-content-center align-items-center mt-2 col-md-1">
                    <label className="d-block" htmlFor="disposable">
                      Obligatorio
                    </label>

                    <Field
                      data-tip="Es obligatorio que el usuario presione el popup de lo contrario no se ocultará"
                      name="disposable"
                      type="checkbox"
                      id="disposable"
                    />
                    <ReactTooltip effect="solid" />
                  </div>

                  <div className="col-6 d-flex flex-column justify-content-center align-items-center mt-2 col-md-2">
                    <label className="d-flex text-center" htmlFor="needAuth">
                      Solo usuarios logueados
                    </label>
                    <Field
                      data-tip="Si esta activo el popup solo aparecerá unicamente si el usuario ha iniciado sesion"
                      name="needAuth"
                      type="checkbox"
                      id="needAuth"
                    />
                    <ReactTooltip effect="solid" />
                    <ErrorMessage name="needAuth">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-6 d-flex flex-column justify-content-center text-center align-items-center mt-2 col-md-2">
                    <label className="d-flex text-center" htmlFor="withoutAuth">
                      Solo usuarios no logueados
                    </label>
                    <Field
                      data-tip="Si esta activo el popup solo aparecerá unicamente si el usuario NO ha iniciado sesion"
                      name="withoutAuth"
                      type="checkbox"
                      id="withoutAuth"
                    />
                    <ReactTooltip effect="solid" />
                    <ErrorMessage name="withoutAuth">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <label className="d-block" htmlFor="startline">
                      Inicio
                    </label>
                    <Field name="startline">
                      {({ field, form: { setFieldValue } }) => (
                        <Datetime
                          value={moment(field.value)}
                          onChange={(data) =>
                            setFieldValue('startline', moment(data).toString())
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage name="startline">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <label className="d-block" htmlFor="deadline">
                      Fin
                    </label>
                    <Field name="deadline">
                      {({ field, form: { setFieldValue } }) => (
                        <Datetime
                          value={moment(field.value)}
                          onChange={(data) =>
                            setFieldValue('deadline', moment(data).toString())
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage name="deadline">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 mt-2 col-md-6">
                    <label htmlFor="redirectDesktopUrl" className="d-block">
                      Link de redirección (Web)
                    </label>
                    <Field
                      className="w-100 p-2 rounded"
                      name="redirectDesktopUrl"
                      type="text"
                      placeholder="/s/ASIT/56"
                    />
                    <ErrorMessage name="redirectDesktopUrl">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 mt-2 col-md-6">
                    <label htmlFor="authRedirectUrl" className="d-block">
                      Link de redirección sin autenticación
                    </label>
                    <Field
                      className="w-100 p-2 rounded"
                      name="authRedirectUrl"
                      as="select"
                    >
                      <option value="">Seleccion una opcion</option>
                      <option value="login">Iniciar sesión</option>
                      <option value="register">Registrarse</option>
                    </Field>
                    <ErrorMessage name="authRedirectUrl">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 mt-2 col-md-6">
                    <label htmlFor="redirectMobileUrl" className="d-block">
                      Link de redirección (Móvil)
                    </label>

                    <Field
                      className="w-100 p-2 rounded"
                      name="mobileRedirectUrl"
                      id="mobileRedirectUrl"
                      as="select"
                      value={mobileRedirectUrl.url}
                      onChange={(event) =>
                        setMobileRedirectUrl({
                          ...mobileRedirectUrl,
                          url: event.target.value,
                          id: '',
                          idRequired: event.target.value.includes('ID'),
                          selected: appUrls.findIndex(
                            (url) => url.url === event.target.value
                          ),
                        })
                      }
                    >
                      <option value="">Seleccione una opción</option>
                      {appUrls.map((url, i) => (
                        <option key={i} value={url.url}>
                          {url.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="redirectMobileUrl">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  {mobileRedirectUrl.url?.includes('ID') && (
                    <div className="col-12 mt-2 col-md-3">
                      <label htmlFor="mobileId" className="d-block">
                        Ingrese Id
                      </label>
                      <Field
                        className="w-100 p-2 rounded"
                        name="mobileId"
                        type="text"
                        placeholder="45"
                      />
                      <ErrorMessage name="mobileId">
                        {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                      </ErrorMessage>
                    </div>
                  )}

                  <div className="col-12 col-md-3 mt-2">
                    <label htmlFor="cta" className="d-block">
                      CTA
                    </label>
                    <Field
                      className="w-100 p-2 rounded"
                      name="cta"
                      type="text"
                      placeholder="Texto del boton"
                    />
                    <ErrorMessage name="cta">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </Card>
    </div>
  );
}

export default NewPopup;
