import ReactModal from 'components/Modal';
import React from 'react';
import { ClimbingBoxLoader } from 'react-spinners';
import colors from 'assets/resources/colors';
import Table from './Table/Table';
import { formatPrice } from 'utils/misc';

const tableProps = {
  options: {
    id: 'usersShoppingCartsId',
    pageSize: 10,
  },
  columns: [
    {
      text: '',
      key: 'image',
      isImage: true,
      className: 'text-center',
    },
    {
      text: 'Nombre',
      key: 'name',
      className: 'text-center',
    },
    {
      text: 'Cantidad',
      key: 'qty',
      className: 'text-center',
    },
    {
      text: 'Precio',
      key: 'price',
      className: 'text-center',
      isCurrency: true,
    },
    {
      text: 'Descuento',
      key: 'discount',
      className: 'text-center',
    },
  ],
};

function CartDetailCard({ data, isOpen, onClose, isLoading }) {
  return (
    <ReactModal
      isOpen={isOpen}
      onCloseRequest={() => {
        if (onClose) onClose();
      }}
    >
      {!isLoading ? (
        <>
          <p className="mb-3">
            Total de carrito:{' '}
            <b>
              {formatPrice(
                data?.reduce((acc, item) => acc + item.price * item.qty, 0) || 0
              )}
            </b>
          </p>
          <Table
            options={tableProps.options}
            columns={tableProps.columns}
            data={data ?? []}
            pageSize={10}
          />
        </>
      ) : (
        <div className="d-flex flex-column align-items-center pb-4">
          <h5 className="text-gray font-weight-bold text-center mb-4">
            Cargando carrito, espere un momento...
          </h5>
          <ClimbingBoxLoader color={colors.green} size="25" />
        </div>
      )}
    </ReactModal>
  );
}

export default CartDetailCard;
