import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Card from '../../components/Card';

import addSVG from '../../assets/icons/add.svg';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';
import moment from 'moment';

const tableProps = {
  rowId: 'specialDiscountsId',
  baseURL: '/specialdiscounts',
  baseFilter: {
    order: [['specialDiscountsId', 'desc']],
  },
  pageSize: 10,
  columns: [
    {
      title: 'Id',
      objectProperty: 'specialDiscountsId',
      className: 'text-center',
    },
    {
      title: 'Nombre',
      objectProperty: 'name',
      sortable: true,
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Activo',
      className: 'text-center',
      columnType: 'boolean',
      objectMiddleware: (row) => {
        const startline = Date.parse(row.startline);
        const deadline = Date.parse(row.deadline);
        const now = Date.now();

        return startline <= now && deadline >= now && row.active;
      },
    },
    {
      title: 'Inicio',
      objectProperty: 'startline',
      className: 'text-center',
      middleware: (value) => moment(value).format('DD/MM/YYYY HH:mm A'),
      searchable: true,
      searchType: 'date',
    },
    {
      title: 'Fin',
      objectProperty: 'deadline',
      className: 'text-center',
      isCustom: true,
      middleware: (value) => moment(value).format('DD/MM/YYYY HH:mm A'),
      searchable: true,
      searchType: 'date',
    },
    {
      title: 'Creado',
      objectProperty: 'createdAt',
      className: 'text-center',
      middleware: (value) => moment(value).format('DD/MM/YYYY HH:mm A'),
      searchable: true,
      searchType: 'date',
    },
  ],
};

function SpecialDiscountsList() {
  const { REACT_APP_TITLE } = process.env;
  const history = useHistory();

  useEffect(() => {
    document.title = `Descuentos Especiales | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Descuentos Especiales
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <Link to="/admin/special-discounts/new" className="w-100">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold"
            >
              <img
                src={addSVG}
                alt=""
                className="d-inline-block mr-2"
                style={{ height: '16px' }}
              />
              Nuevo descuento especial
            </button>
          </Link>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
              onRowClick={(row) => {
                history.push(
                  `/admin/special-discounts/${row.specialDiscountsId}/edit`
                );
              }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default SpecialDiscountsList;
