import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import mapboxgl from 'mapbox-gl';

import Card from '../components/Card';
import api from '../utils/api';
import { getOrderCode } from 'utils/misc';
import download from 'downloadjs';
import ReportGenerator from 'components/ReportGenerator';

mapboxgl.accessToken =
  'pk.eyJ1IjoiYWlybWluZCIsImEiOiJja2VzMGI4Z3UxMno0MnRteml0NDgzNm9xIn0.x_Wm68TBw_g6ZqlSZg-SFQ';

function ProductReports() {
  const { REACT_APP_URL, REACT_APP_TITLE } = process.env;

  useEffect(() => {
    document.title = `Reporte de productos | ${REACT_APP_TITLE}`;
  }, []);

  const generateReport = (values, { setSubmitting }) => {
    const { startDate, endDate, type } = values;

    api.products
      .productsReport({
        startDate,
        endDate,
      })
      .then((response) => {
        const data = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        download(
          data,
          `OrdersHistories - ${startDate} to ${endDate}.csv`,
          response.headers['content-type']
        );
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo generar el reporte de historial de ordenes');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const generateAmazonReport = (values, { setSubmitting }) => {
    const { startDate, endDate, type } = values;

    api.products
      .amazonReport({
        startDate,
        endDate,
        type,
      })
      .then((response) => {
        const data = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        download(
          data,
          `AmazonOrders - ${startDate} to ${endDate}.${type}`,
          response.headers['content-type']
        );
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo generar el reporte de historial de ordenes');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="content">
      <div className="row mt-1 mt-md-2">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Reporte de productos
          </h3>
        </div>
        <div className="col-12 mt-2 mt-md-3">
          <ReportGenerator
            className="mt-4"
            onSubmit={generateReport}
            title="Reporte de historial de ordenes"
          />
          <ReportGenerator
            className="mt-4"
            onSubmit={generateAmazonReport}
            typeSelector
            title="Reporte de historial de ordenes Amazon"
          />
        </div>
      </div>
    </div>
  );
}

export default ProductReports;
