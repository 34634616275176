import S3 from 'aws-sdk/clients/s3';
import { STATUS } from '../assets/resources/status';
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const amountFormatter = new Intl.NumberFormat('en-US');

const genRandomData = (qty) => {
  let data = [];

  /* Fill an array of length 31 with random data between 0 and 100 */
  for (let i = 1; i <= qty; i++) {
    data.push(parseInt(Math.random() * 100));
  }

  /* Constrain data bewteen 30 and 70 */
  data = data.map((point) => (point < 30 ? point + 30 : point));
  data = data.map((point) => (point > 70 ? point - 30 : point));

  return data;
};

const validSKU = (value) => {
  const SKUMaxLength = 10;

  return value.length <= SKUMaxLength
    ? value.toUpperCase()
    : value.slice(0, 10);
};

const validPrice = (value) => value.replace(/[^0-9.]/g, '');
const validQty = (value) => value.replace(/[^0-9]/g, '');

const validPctg = (value) => {
  const numbers = value.replace(/[^0-9.]/g, '');
  return numbers > 100 ? numbers.substring(0, numbers.length - 1) : numbers;
};

function validCouponCode(value) {
  return value.replace(/\s/g, '').toUpperCase();
}

const fileToBase64URL = async (file) => {
  const maxFileSize = 2;
  const maxCompressionSize = 0.9;

  const _imageSize = file.size / 1024 / 1024;

  if (_imageSize > maxFileSize) {
    toast.error('El tamaño de la imagen no puede ser mayor a 2MB');
    return Promise.reject('Image too big');
  }

  const _compresedFile = await compressImage(file);
  const _compresedFileSize = _compresedFile.size / 1024 / 1024;

  if (_compresedFileSize > maxCompressionSize) {
    toast.error(
      'Imagen demasiado grande, comprime la imagen y vuelve a intentarlo'
    );
    return Promise.reject('Image too big');
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(_compresedFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject('Error converting file to base64 URL');
  });
};

const uploadPictureToS3 = (base64file, type, name, path = '') => {
  const {
    REACT_APP_IMAGE_KIT_URL,
    REACT_APP_SPACES_ENDPOINT,
    REACT_APP_SPACES_ACCESS_KEY_ID,
    REACT_APP_SPACES_SECRET_ACCESS_KEY,
  } = process.env;

  const spacesBucket = new S3({
    endpoint: REACT_APP_SPACES_ENDPOINT,
    accessKeyId: REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: REACT_APP_SPACES_SECRET_ACCESS_KEY,
  });

  const fileKey = `${path?.length > 0 ? `${path}/` : ''}${new Date()
    .getTime()
    .toString()}-${name.trim().replace(/\s/g, '-')}`;

  return new Promise(async (resolve, reject) => {
    spacesBucket.putObject(
      {
        Bucket: 'tuyomedia',
        Key: fileKey,
        Body: await (await fetch(base64file)).blob(),
        ACL: 'public-read',
        ContentType: type,
      },
      (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(`https://${REACT_APP_IMAGE_KIT_URL}/${fileKey}`);
        }
      }
    );
  });
};

const deleteFromS3 = (objectKey) => {
  const {
    REACT_APP_SPACES_NAME,
    REACT_APP_SPACES_ENDPOINT,
    REACT_APP_SPACES_CDN,
    REACT_APP_SPACES_ACCESS_KEY_ID,
    REACT_APP_SPACES_SECRET_ACCESS_KEY,
  } = process.env;

  const spacesBucket = new S3({
    endpoint: REACT_APP_SPACES_ENDPOINT,
    accessKeyId: REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: REACT_APP_SPACES_SECRET_ACCESS_KEY,
  });

  return new Promise((resolve, reject) => {
    const imageToDelete = objectKey.split(
      `https://${REACT_APP_SPACES_NAME}.${REACT_APP_SPACES_CDN}/`
    );
    spacesBucket.deleteObject(
      {
        Bucket: 'tuyomedia',
        Key: imageToDelete[0],
      },
      (err, data) => {
        if (err) {
          reject(err);
          console.error(err);
        } else {
          resolve(data);
        }
      }
    );
  });
};

const formatPrice = (value) => {
  return currencyFormatter.format(value);
};

const formatAmount = (value) => {
  return amountFormatter.format(value);
};

const formatTimestamp = (timestamp) => {
  return new Date(timestamp).toLocaleString(undefined, {
    dateStyle: 'medium',
    timeStyle: 'short',
    hour12: true,
  });
};

const parseDate = (
  date,
  { timeStyle = 'short', dateStyle = 'short', hour12 = true } = {}
) => {
  return new Date(date).toLocaleString(undefined, {
    ...(dateStyle && { dateStyle }),
    ...(timeStyle && { timeStyle }),
    hour12: hour12,
  });
};

/* Set date's time to  00:00:00 */
const setTimeTo0 = (date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
};

/* Return today, yesterday and tomorrow date */
const getDaysDates = (date) => {
  setTimeTo0(date);

  const tomorrow = new Date(date);
  tomorrow.setDate(date.getDate() + 1);

  const yesterday = new Date(date);
  yesterday.setDate(date.getDate() - 1);

  return {
    currPeriod1stDay: date,
    currPeriodLastDay: tomorrow,
    prevPeriod1stDay: yesterday,
  };
};

const getOrderStatus = (details) => {
  const {
    ORDER_PENDING,
    ORDER_ACCEPTED,
    PICKED_UP,
    FINISHED,
    NON_PICKED_UP,
    NON_FINISHED,
    ORDER_U_CANCELED,
    ORDER_S_CANCELED,
    RETURN_PENDING,
    RETURN_APPROVED,
    RETURN_DENIED,
    REFUND_PENDING,
    REFUND_APPROVED,
    REFUND_DENIED,
    READY,
    DELIVERED_IN_WAREHOUSE,
    ON_ROUTE,
    CANCELED_IN_DELIVERY_POINT,
    ON_THE_WAY_TO_STORE,
    WAITING_IN_STORE,
  } = STATUS;

  const statuses = [
    ORDER_PENDING,
    ORDER_ACCEPTED,
    PICKED_UP,
    FINISHED,
    NON_PICKED_UP,
    NON_FINISHED,
    ORDER_U_CANCELED,
    ORDER_S_CANCELED,
    RETURN_PENDING,
    RETURN_APPROVED,
    RETURN_DENIED,
    REFUND_PENDING,
    REFUND_APPROVED,
    REFUND_DENIED,
    READY,
    DELIVERED_IN_WAREHOUSE,
    ON_ROUTE,
    CANCELED_IN_DELIVERY_POINT,
    ON_THE_WAY_TO_STORE,
    WAITING_IN_STORE,
  ];
  let orderStatus;
  statuses.forEach((status) => {
    if (details.some((detail) => detail.fk_purchaseStatusesId === status)) {
      orderStatus = status;
    }
  });

  if (!orderStatus) orderStatus = STATUS.ORDER_S_CANCELED;

  return orderStatus;
};

const getOrderCode = (orderId) =>
  (orderId * 64).toString(16).padStart(6, '0').toUpperCase();

const parseOrderCode = (orderCode) => {
  //remove PTY- prefix or TY- prefix
  const code = orderCode.replace(/PTY-|TY-/g, '');

  return parseInt(code, 16) / 64;
};

const getDelivery = (time = 0, createdAt = null) => {
  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];
  const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  const now = createdAt ? new Date(createdAt).getTime() : new Date().getTime();
  const delivery = new Date(now + time);
  return `${days[delivery.getDay()]} ${delivery.getDate()} de ${
    months[delivery.getMonth()]
  } entre 8 a.m. y 12 p.m.`;
};

/*
  id: object id
  title: object title
  type: P for Product, G for Giftcard, S for tore
*/
function getWebURL(id, title, type) {
  return `/${type?.toLowerCase()}/${encodeURI(
    title?.toString()?.replace(/\s/g, '-')?.replace('/', '')
  )}/${(Number(id) + 32).toString(16)}/`;
}

function base64toBlob(base64Dat = '') {
  const sliceSize = 1024;
  const byteCharacters = window.atob(base64Dat);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: 'application/pdf' });
}

function openBase64PDFInNewTab(base64Pdf = '') {
  var blob = base64toBlob(base64Pdf);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, 'pdfBase64.pdf');
  } else {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }
}

function tuyoPriceFormula(price = 0) {
  const shippingTax = 1.107;
  let ottoTax = 1.2;
  let tuyoTax = 1.07;
  const shippingCost = 3;

  if (price >= 0 && price < 100) {
    tuyoTax = 1.2;
  } else if (price >= 100 && price < 250) {
    tuyoTax = 1.15;
  } else if (price >= 250 && price < 400) {
    ottoTax = 1.15;
    tuyoTax = 1.15;
  } else if (price >= 400 && price < 900) {
    ottoTax = 1.15;
    tuyoTax = 1.1;
  } else if (price >= 900) {
    ottoTax = 1.15;
  }

  return (
    +Math.round(
      (price * shippingTax * ottoTax * tuyoTax + shippingCost) * 100
    ) / 100
  );
}

function getPermutations(obj) {
  return Object.entries(obj).reduce(
    (r, [k, v]) => {
      let temp = [];
      r.map((s) =>
        (Array.isArray(v) ? v : [v]).forEach((w) =>
          (w && typeof w === 'object' ? getPermutations(w) : [w]).forEach(
            (x) => {
              temp.push(Object.assign({}, s, { [k]: x }));
            }
          )
        )
      );
      return temp;
    },
    [{}]
  );
}

function getASINbyURL(url) {
  const regex = /\/[dg]p\/([^/\?]+)/g;
  const match = url.match(regex);

  if (match) {
    return match[0].replace('/dp/', '').replace('/', '');
  }

  return null;
}

function deconvertId(id) {
  return parseInt(id, 16) - 32;
}

const appUrls = [
  {
    label: 'Inicio',
    url: 'home',
  },
  {
    label: 'Ofertas',
    url: 'offers',
  },
  {
    label: 'Flash Sales',
    url: 'flash-sales',
  },
  {
    label: 'Categorías',
    url: 'categories',
  },
  {
    label: 'Categoría',
    url: 'category/ID',
  },
  {
    label: 'Secciones especiales',
    url: 'promo/ID',
  },
  {
    label: 'Carrito',
    url: 'cart',
  },
  {
    label: 'Buscador',
    url: 'search',
  },
  {
    label: 'Busqueda personalizada',
    url: 'search/ID',
  },
  {
    label: 'Metodos de pago',
    url: 'payment-methods',
  },
  {
    label: 'Iniciar sesión',
    url: 'login',
  },
  {
    label: 'Registrarse',
    url: 'register',
  },
  {
    label: 'Perfil',
    url: 'profile',
  },
  {
    label: 'Cupón de Referidos',
    url: 'friends-code',
  },
  {
    label: 'Comercio en específicos',
    url: 'store/ID',
  },
  {
    label: 'Producto en específico',
    url: 'product/ID',
  },
  {
    label: 'Tarjeta de regalo',
    url: 'giftcard/ID',
  },
  {
    label: 'Ordenes',
    url: 'orders',
  },
  {
    label: 'Notificaciones',
    url: 'notifications',
  },
  {
    label: 'Favoritos',
    url: 'wishlist',
  },
  {
    label: 'Resumen de la Orden',
    url: 'order/ID',
  },
  {
    label: 'Turbo Envíos',
    url: 'turbo-home',
  },
];

const ROLES = {
  ROOT: 'root',
  GERENCY: 'gerency',
  FINANCE: 'finance',
  LOGISTIC: 'logistic',
  MARKETING: 'marketing',
  MARKETING_LEAD: 'marketing-lead',
  KEY_ACCOUNT_MANAGER: 'key-account-manager',
  CUSTOMER_EXPERIENCE: 'customer-experience',
  CUSTOMER_EXPERIENCE_LEAD: 'customer-experience-lead',
  BUYER: 'buyer',
  PAYMENTS_KAM: 'payments',
};

const ROLES_ID = {
  ROOT: 1,
  LOGISTIC: 6,
  LOGISTIC_USER: 7,
  GERENCY: 10,
  FINANCE: 11,
  MARKETING_LEAD: 12,
  MARKETING: 13,
  KEY_ACCOUNT_MANAGER: 14,
  CUSTOMER_EXPERIENCE: 15,
  CUSTOMER_EXPERIENCE_LEAD: 16,
  BUYER: 17,
  PAYMENTS_KAM: 18,
};

const verifyAmazonASIN = (asin = '') => {
  const regex = /B[0-9]{2}[0-9A-Z]{7}|[0-9]{9}(X|0-9])/;
  return regex.test(asin);
};

const VOUCHER_STATUS = {
  COMPLETED: 'COMPLETADO',
  PENDING: 'PENDIENTE',
  UNALLOWED: 'NO PERMITIDO',
  REFUNDED: 'RECHAZADO',
  ERRORED: 'ERROR',
};

const VOUCHER_ICONS = {
  COMPLETED: {
    name: 'check_circle',
    color: 'var(--green)',
  },
  PENDING: {
    name: 'hourglass_bottom',
    color: 'var(--orange)',
  },
  UNALLOWED: {
    name: 'warning',
    color: 'var(--orange)',
  },
  REFUNDED: {
    name: 'change_circle',
    color: 'var(--pink)',
  },
  ERRORED: {
    name: 'error',
    color: 'var(--red)',
  },
};

const TRANSACTION_TYPE = {
  CASH: 'EFECTIVO',
  CARD: 'TARJETA',
  CARD_INSTALLMENT: 'CUOTAS',
  MILLAS: 'MILLAS',
  BALANCE: 'SALDO',
};

const formatTaxRanges = (ranges) => {
  return ranges.map((item, i) => {
    const range = ranges[i + 1];

    return {
      ...item,
      min: item.minimum || item.minimun || 0,
      max: range ? range.minimum || range.minimun : null,
    };
  });
};

function getGoogleMapsUrFromCoordinates(lat, lng) {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
}

async function compressImage(file) {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(file, options);

    return compressedFile;
  } catch (error) {
    console.error(error);
    return file;
  }
}

export {
  verifyAmazonASIN,
  tuyoPriceFormula,
  getPermutations,
  genRandomData,
  validSKU,
  validPrice,
  validPctg,
  validQty,
  fileToBase64URL,
  uploadPictureToS3,
  formatPrice,
  formatAmount,
  formatTimestamp,
  getDaysDates,
  getOrderStatus,
  deleteFromS3,
  validCouponCode,
  getOrderCode,
  parseOrderCode,
  getDelivery,
  getWebURL,
  openBase64PDFInNewTab,
  base64toBlob,
  getASINbyURL,
  deconvertId,
  appUrls,
  parseDate,
  ROLES,
  ROLES_ID,
  VOUCHER_STATUS,
  VOUCHER_ICONS,
  TRANSACTION_TYPE,
  formatTaxRanges,
  getGoogleMapsUrFromCoordinates,
  compressImage,
};
