import React from 'react';
import Modal from 'react-modal';

import closeSVG from '../assets/icons/times-solid.svg';

function ReactModal(props) {
  return (
    <Modal
      style={{
        overlay: {
          zIndex: '9998',
          backgroundColor: 'rgba(0, 0, 0, 0.16)',
          backdropFilter: 'blur(5px)',
        },
        content: props.contentStyle || {
          borderRadius: '1rem',
          width: '80%',
          margin: '0 auto',
          maxHeight: '90%',
          bottom: 'auto',
        },
      }}
      onRequestClose={props?.onCloseRequest}
      isOpen={props?.isOpen ?? true}
    >
      <div
        onClick={props.onCloseRequest}
        className="d-flex justify-content-end mb-2"
      >
        <button className="hover-scale">
          <img
            src={closeSVG}
            alt=""
            className="mx-1 mx-md-3"
            style={{ height: '32px', width: '32px' }}
          />
        </button>
      </div>
      {props.children}
    </Modal>
  );
}

export default ReactModal;
