import Card from 'components/Card';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import api from 'utils/api';

import Table from '../components/Table/Table';
import ReportGenerator from 'components/ReportGenerator';

const table = {
  options: { id: 'blackListId' },
  columns: [
    { text: 'Email', key: 'to_email', className: 'text-center' },
    { text: 'Titulo', key: 'subject', className: 'text-center' },
    { text: 'Abierto', key: 'opens_count', className: 'text-center' },
    { text: 'Clicks', key: 'clicks_count', className: 'text-center' },
    { text: 'Ultimo evento', key: 'last_event_time', className: 'text-center' },
  ],
};

function EmailLogs() {
  const [emailsLog, setEmailsLog] = useState([]);

  // Set window title
  useEffect(() => {
    document.title = `Historial de corres | ${process.env.REACT_APP_TITLE}`;
  }, []);

  const onSubmit = (values, { setSubmitting }) => {
    const { startDate, endDate, limit } = values;

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (end.getTime() - start.getTime() > 259200000) {
      toast.warning('El rango de fechas no puede ser mayor a 3 dias');
      setSubmitting(false);
      return;
    }

    api.emails
      .logs({
        startDate: start.toISOString(),
        endDate: end.toISOString(),
        limit: limit,
      })
      .then((response) => {
        let data = response.data?.response?.messages ?? [];

        data = data.map((item) => ({
          ...item,
          last_event_time: new Date(item.last_event_time).toLocaleString(),
        }));

        setEmailsLog(data);
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener el historial de correos');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="content">
      <div className="row">
        <h3 className="col-12 col-md-9 text-dark-blue font-size-2x font-weight-bold">
          Historial de correos
        </h3>
      </div>
      <ReportGenerator
        className="mt-3 mt-md-4"
        title="Selecciona el rango de fechas para"
        limitSelector
        onSubmit={onSubmit}
      />
      <Card className="mt-3 mt-md-4">
        <Table
          pageSize={10}
          options={table.options}
          columns={table.columns}
          data={emailsLog ?? []}
        />
      </Card>
    </div>
  );
}

export default EmailLogs;
