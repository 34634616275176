import React from 'react';
import { ErrorMessage, Field, FieldArray } from 'formik';

function OffersTagSelector({ title, onClick, tags, name, className }) {
  return (
    <>
      <button
        onClick={() => {
          if (onClick) onClick(tags);
        }}
        type="button"
        className={`tuyo-btn bg-green text-white px-4 py-2 rounded w-full mb-2 ${
          className || ''
        }`}
      >
        {title}
      </button>
      <div>
        <ErrorMessage name={name} component="div" className="text-red" />
        <FieldArray
          name={name}
          render={() => (
            <>
              {tags?.length > 0 && (
                <div className="flex mt-2">
                  <div className="w-full md:w-1/2 row mx-0 ml-auto">
                    <span className="col-4 text-center">
                      % Descuento (Tuyo)
                    </span>
                    <span className="col-4 text-center">
                      % Descuento (Comercio)
                    </span>
                    <span className="col-4 text-center">Envío Gratis</span>
                  </div>
                </div>
              )}

              {tags?.map((item, index) => (
                <div key={index} className="flex mt-2 row mx-0">
                  <button
                    type="button"
                    className="rounded-lg bg-light-gray col-12 col-md-6 h-10"
                    key={item.id}
                  >
                    {item.value}
                  </button>
                  <div className="row mx-0 h-10 col-12 col-md-6 mt-3 mt-md-0">
                    <div className="col-4">
                      <Field
                        type="number"
                        placeholder="Ej: 15"
                        name={`${name}.${index}.discount.0`}
                        className="w-full h-full rounded text-center"
                      />
                    </div>
                    <div className="col-4">
                      <Field
                        type="number"
                        placeholder="Ej: 10"
                        name={`${name}.${index}.discount.1`}
                        className="w-full h-full rounded text-center"
                      />
                    </div>
                    <Field
                      type="checkbox"
                      name={`${name}.${index}.freeDelivery`}
                      className="col-4 rounded h-5 w-5 my-auto"
                    />
                  </div>
                </div>
              ))}
            </>
          )}
        />
      </div>
    </>
  );
}

export default OffersTagSelector;
