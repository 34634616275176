import React from 'react';

function CustomBanner({
  className,
  aspectRatio,
  aspectRatioText,
  acceptGif,
  onChange,
  image,
  title,
  id,
}) {
  return (
    <div className={className}>
      <div className="h-full justify-content-center my-3">
        <div className="w-full position-relative col">
          <p className="text-center">{title}</p>
          <button
            type="button"
            className="w-100 add-picture-btn"
            style={{
              border: '2px solid rgba(0,0,0,0.16)',
              paddingTop: `${aspectRatio * 100}%`,
              borderRadius: '1rem',
              maxHeight: '15rem',
            }}
          >
            <input
              accept={`.jpg,.jpeg,.png${acceptGif ? ',.gif' : ''}`}
              id={`banner-input-${id}`}
              type="file"
              className="d-none"
              onChange={onChange}
            />
            <label
              htmlFor={`banner-input-${id}`}
              className="mb-0 cursor-pointer rounded"
              style={{
                backgroundImage: image
                  ? `url('${image.base64 || image.URL}')`
                  : '',
                backgroundSize: 'cover',
                overflow: 'hidden',
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
          </button>
        </div>
        <div className="d-flex justify-content-center w-full">
          <small style={{ fontStyle: 'italic' }}>
            Relación de aspecto recomendada: {aspectRatioText ?? aspectRatio}
          </small>
        </div>
      </div>
    </div>
  );
}

export default CustomBanner;
