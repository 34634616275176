import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import eyeSVG from '../assets/icons/eye-regular-black.svg';
import eyeSlashSVG from '../assets/icons/eye-slash-regular.svg';

function PasswordInput({ id, autoComplete, onChange, onBlur, value }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="rounded password-input">
      <input
        value={value}
        id={id}
        onChange={event => onChange(event.target.value)}
        onBlur={onBlur}
        className="p-2 rounded"
        type={showPassword
          ? 'text'
          : 'password'}
        autoComplete={autoComplete} />
      <button
        data-tip={`${showPassword ? 'Ocultar' : 'Mostrar'} contraseña`}
        className="px-3 show-hide-switch"
        onClick={() => setShowPassword(!showPassword)}
        type="button">
        <img
          className={!showPassword
            ? 'hidden'
            : ''}
          src={eyeSlashSVG}
          alt="" />
        <img
          className={showPassword
            ? 'hidden'
            : ''}
          src={eyeSVG}
          alt="" />
      </button>
      <ReactTooltip effect="solid" />
    </div>
  )
};

export default PasswordInput;
