import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';

import Card from '../../components/Card';
import Table from 'components/TableV2/Table';

import api from '../../utils/api';

import addSVG from '../../assets/icons/add.svg';
import circleCheckSVG from '../../assets/icons/check-circle-solid-green.svg';

function CategoriesList() {
  const { REACT_APP_TITLE, REACT_APP_COOKIES_USER_ID } = process.env;
  const history = useHistory();
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  const [tableRemountCount, setTableRemountCount] = useState(0);
  const [userRoleId, setUserRoleId] = useState();

  function deleteCategory(id) {
    swal({
      buttons: ['Cancelar', 'Eliminar'],
      dangerMode: true,
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">
            ¿Deseas eliminar esta categoria?
          </p>
          <p className="mt-3">Esto podría tener efectos no esperados</p>
        </div>
      ),
    }).then((res) => {
      if (res) {
        api
          .findAll(
            '/subcategories?filter=' +
              JSON.stringify({
                attributes: ['productsSubcategoriesId'],
                where: {
                  fk_categoriesId: id,
                },
              })
          )
          .then(
            (response) =>
              new Promise((resolve) => {
                resolve(response.data);
              })
          )
          .then((subcategories) =>
            Promise.all(
              subcategories.map((subcategory) =>
                api.delete(
                  '/subcategories',
                  subcategory.productsSubcategoriesId
                )
              )
            )
          )
          .then(() => api.delete('/categories', id))
          .then(() => {
            toast.success('Categoría eliminada');
          })
          .catch((error) => {
            console.error(error);
            toast.warning(error);
          })
          .finally(() => {
            setTableRemountCount(tableRemountCount + 1);
          });
      }
    });
  }

  function toggleActive(category) {
    const active = category?.active === '1';

    swal({
      buttons: ['Cancelar', active ? 'Desactivar' : 'Activar'],
      dangerMode: true,
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">
            ¿Deseas {active ? 'desactivar' : 'activar'} esta categoria?
          </p>
          <p className="mt-3">Esto podría tener efectos no esperados</p>
        </div>
      ),
    }).then((res) => {
      if (res) {
        api
          .update('/categories', category?.productsCategoriesId, {
            active: active ? '0' : '1',
            updatedBy: userID,
          })
          .then(() => {
            toast.success('Categoría ' + (active ? 'desactivada' : 'activada'));
          })
          .catch((error) => {
            console.error(error);
            toast.warning(
              `No se pudo ${active ? 'desactivar' : 'activar'} esta categoría`
            );
          })
          .finally(() => {
            setTableRemountCount(tableRemountCount + 1);
          });
      }
    });
  }

  const tableProps = {
    rowId: 'productsCategoriesId',
    baseURL: '/categories',
    baseFilter: {
      attributes: ['productsCategoriesId', 'name', 'name_en', 'active'],
      include: [{ association: 'product_type', attributes: ['name'] }],
      order: [['createdAt', 'desc']],
    },
    pageSize: 10,
    columns: [
      {
        title: 'ID',
        objectProperty: 'productsCategoriesId',
        searchable: true,
        searchType: 'number-equal',
        sortable: true,
      },
      {
        title: 'Activa',
        objectProperty: 'active',
        middleware: (active) =>
          active === '1' ? (
            <img src={circleCheckSVG} alt="" style={{ height: '18px' }} />
          ) : null,
      },
      {
        title: 'Nombre',
        objectProperty: 'name',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Nombre en inglés',
        objectProperty: 'name_en',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Tipo de producto',
        objectProperty: 'product_type',
        middleware: (productType) => productType?.name,
        searchable: true,
        searchType: 'text',
        gatewayObjectProperty: '$product_type.name$',
      },
      {
        objectProperty: 'productsCategoriesId',
        middleware: (productsCategoriesId) =>
          userRoleId === 1 && (
            <>
              <button
                data-tip="Eliminar"
                onClick={(event) => {
                  event.stopPropagation();
                  deleteCategory(productsCategoriesId);
                }}
              >
                🗑️
              </button>
              <ReactTooltip effect="solid" />
            </>
          ),
      },
      {
        middleware: (category) => (
          <>
            <button
              data-tip={category.active === '1' ? 'Desactivar' : 'Activar'}
              onClick={(event) => {
                event.stopPropagation();
                toggleActive(category);
              }}
            >
              {category.active === '1' ? '🙈' : '🐵'}
            </button>
            <ReactTooltip effect="solid" />
          </>
        ),
      },
    ],
  };

  useEffect(() => {
    document.title = `Categorias | ${REACT_APP_TITLE}`;
  }, []);

  // Get user role
  useEffect(() => {
    api
      .findAll(
        '/users?filter=' +
          JSON.stringify({
            where: { usersId: userID },
            attributes: ['fk_rolesId'],
          })
      )
      .then((response) => {
        setUserRoleId(response.data[0]?.fk_rolesId);
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener detalle de usuario');
      });
  }, []);

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Categorias
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <Link to="/admin/categories/new" className="w-100">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold"
            >
              <img
                src={addSVG}
                alt=""
                className="d-inline-block mr-2"
                style={{ height: '16px' }}
              />
              Nueva categoria
            </button>
          </Link>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            <Table
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
              onRowClick={(category) => {
                history.push(
                  `/admin/categories/${category.productsCategoriesId}/edit`
                );
              }}
              remountCount={tableRemountCount}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default CategoriesList;
