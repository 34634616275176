import Card from 'components/Card';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ClimbingBoxLoader } from 'react-spinners';

import api from 'utils/api';
import colors from 'assets/resources/colors';

import Table from '../components/Table/Table';

const table = {
  options: { id: 'blackListId' },
  columns: [
    { text: 'ID', key: 'blackListId', className: 'text-center' },
    { text: 'Teléfono', key: 'phone', className: 'text-center' },
    { text: 'Email', key: 'email', className: 'text-center' },
    { text: 'ID Usuario', key: 'usersId', className: 'text-center' },
    { text: 'Razón', key: 'reason', className: 'text-center' },
    { text: 'Creado', key: 'createdAtDisplay', className: 'text-center' },
  ],
};

function Blacklist() {
  const [blacklist, setBlacklist] = useState();

  // Set window title
  useEffect(() => {
    document.title = `Lista negra | ${process.env.REACT_APP_TITLE}`;
  }, []);

  // Get blacklist
  useEffect(() => {
    api
      .findAll('/blacklist')
      .then((response) => {
        console.table(response.data);
        setBlacklist(response.data);
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener la lista negra');
      });
  }, []);

  return (
    <div className="content">
      <h3 className="text-dark-blue font-size-2x font-weight-bold">
        Lista negra
      </h3>
      <Card className="mt-3 mt-md-4">
        {blacklist ? (
          <Table
            pageSize={10}
            options={table.options}
            columns={table.columns}
            data={blacklist}
          />
        ) : (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </Card>
    </div>
  );
}

export default Blacklist;
