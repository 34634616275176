import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import addSVG from '../../assets/icons/add.svg';
import circleCheckSVG from '../../assets/icons/check-circle-solid-green.svg';

import Card from '../../components/Card';
import PaginatedTable from '../../components/PaginatedTable/Table';

import { formatTimestamp, formatPrice } from 'utils/misc';
import api from '../../utils/api';

const now = new Date();

const table = {
  options: {
    id: 'cardDiscountsId',
    pageSize: 10,
  },
  columns: [
    {
      text: 'Tipo',
      key: 'type',
      className: 'text-center',
      searchable: true,
      searchType: 'text',
      middleware: type => (type === 'TOTAL' ? 'Total' : type === 'PRODUCTS' ? 'Productos' : type === 'SHIPPING' ? 'Envío' : ''),
    },
    {
      text: 'Banco',
      key: 'bank',
      className: 'text-center',
      searchable: true,
      searchType: 'text',
    },
    {
      text: 'Tipo de tarjeta',
      key: 'cardType',
      className: 'text-center',
      searchable: true,
      searchType: 'text',
    },
    {
      text: 'Valor',
      key: 'value',
      className: 'text-right',
      middleware: value => (value < 1 ? value * 100 + '%' : formatPrice(value)),
    },
    {
      text: 'Máximo por usuario',
      key: 'maxDiscountPerUser',
      className: 'text-right',
      middleware: maxDiscountPerUser => (maxDiscountPerUser === 0 ? 'Sin máximo' : formatPrice(maxDiscountPerUser)),
    },
    {
      text: 'Activo',
      key: 'active',
      className: 'text-center',
    },
    {
      text: 'Inicio',
      key: 'startline',
      className: 'text-center',
      middleware: startline => (startline ? formatTimestamp(startline) : 'Sin fecha de inicio'),
    },
    {
      text: 'Fin',
      key: 'deadline',
      className: 'text-center',
      middleware: deadline => (deadline ? formatTimestamp(deadline) : 'Sin fecha de fin'),
    },
  ],
};

function CardDiscountsList() {
  const { REACT_APP_TITLE } = process.env;
  const history = useHistory();

  useEffect(() => {
    document.title = `Card discounts | ${REACT_APP_TITLE}`;
  }, []);

  function getPage(pageNumber, setter, filter) {
    api
      .findAll(
        '/carddiscounts?filter=' +
        JSON.stringify({
          order: [filter?.order || ['cardDiscountsId', 'desc']],
          where: filter?.where || {},
          limit: table.options.pageSize,
          offset: table.options.pageSize * (pageNumber - 1),
        })
      )
      .then(res => {
        setter(
          res.data.map(coupon => ({
            ...coupon,
            active:
              coupon.active && now > new Date(coupon.startline) && now < new Date(coupon.deadline) ? (
                <img src={circleCheckSVG} alt='' style={{ height: '18px' }} />
              ) : null,
          }))
        );
      })
      .catch(err => {
        console.error(err);
        toast.warning('[SERVER_ERROR] ' + err);
      });
  }

  function getCount(setter) {
    api
      .count('/carddiscounts')
      .then(res => {
        setter(res.data.count);
      })
      .catch(err => {
        console.error(err);
        toast.warning('[SERVER_ERROR] ' + err);
      });
  }

  function getFilteredCount(where, setter) {
    api.findAll('/carddiscounts?filter=' + JSON.stringify({
      where: { ...where },
      attributes: ['createdBy']
    }))
      .then(res => {
        setter(res.data.length);
      })
      .catch(err => {
        console.error(err);
        toast.warning('[SERVER_ERROR] ' + err);
      });
  };

  return (
    <div className='content'>
      <div className='row'>
        <div className='col-12 col-md-8 col-xl-9 d-flex align-items-center'>
          <h3 className='text-dark-blue font-size-2x font-weight-bold'>Descuentos con tarjeta</h3>
        </div>
        <div className='col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0'>
          <Link to='/admin/card-discounts/new' className='w-100'>
            <button
              type='button'
              className='d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold'>
              <img src={addSVG} alt='' className='d-inline-block mr-2' style={{ height: '16px' }} />
              Nuevo descuento
            </button>
          </Link>
        </div>
      </div>
      <div className='row mt-3 mt-md-4' style={{ flexGrow: '1' }}>
        <div className='col-12'>
          <Card className='p-md-4'>
            <PaginatedTable
              getCount={getCount}
              getFilteredCount={getFilteredCount}
              getPage={getPage}
              columns={table.columns}
              options={table.options}
              onRowClick={id => history.push(`/admin/card-discounts/${id}/edit`)}
              pageSize={table.options.pageSize}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default CardDiscountsList;
