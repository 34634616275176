import React from 'react';

function ParentItem({ icon, label, children, isOpen, onClick, isActive }) {
  return (
    <div
      className={
        'transition-all item parent position-relative select-none' +
        (isActive ? ' active' : '') +
        (isOpen ? ' open' : '')
      }
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <div className="transition-all label">
        <i className="ml-3 material-icons">{icon}</i>
        <p>{label}</p>
        <i className="ml-auto material-icons">
          {!isOpen ? 'chevron_right' : 'expand_more'}
        </i>
      </div>
      {isOpen && children}
    </div>
  );
}

export default ParentItem;
