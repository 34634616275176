import { ClimbingBoxLoader } from 'react-spinners';
import React from 'react';
import SERFINSA_ERRORS from 'assets/resources/serfinsa_errors';
import { TRANSACTION_TYPE } from 'utils/misc';
import colors from 'assets/resources/colors';
import { formatPrice } from 'utils/misc';
import { parseDate } from 'utils/misc';

function TransactionModal({ data, title, subtitle, isLoading, onClose }) {
  const transactions = data?.map((item) => ({
    title: `PAGO POR ${TRANSACTION_TYPE[item.type] || ''}`,
    items: [
      {
        show: true,
        icon: 'payments',
        title: 'Monto',
        value: formatPrice(+item?.amount),
      },
      {
        show: true,
        icon: 'info',
        title: 'Estado',
        value:
          item?.resCode == '00' ? (
            'Procesado'
          ) : (
            <span className="text-red">
              {`Error ${item.resCode} - ${
                SERFINSA_ERRORS[`ERROR ${item.resCode}`] || ''
              }`}
            </span>
          ),
      },
      {
        show: item?.type == 'CARD_INSTALLMENT',
        icon: 'info',
        title: 'Mese(s)',
        value: item?.months,
      },
      {
        show: !!item?.card,
        icon: 'credit_card',
        title: 'Tarjeta',
        value: item?.card,
      },
      {
        show: !!item?.ref,
        icon: 'integration_instructions',
        title: 'Referencia',
        value: item?.ref,
      },
      {
        show: !!item?.auth,
        icon: 'verified_user',
        title: 'Autorización',
        value: item?.auth,
      },
      {
        show: true,
        icon: 'date_range',
        title: 'Fecha',
        value: parseDate(item?.createdAt),
      },
    ],
  }));

  return (
    <>
      {!isLoading ? (
        <div className="my-4 d-relative">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="w-full text-dark-blue font-weight-bold text-center ">
              {title}
            </h4>
            <button className="border-full bg-grey" onClick={onClose}>
              <i className="material-icons">close</i>
            </button>
          </div>

          <h6 className="w-full text-gray font-weight-bold text-center mb-4">
            {subtitle}
          </h6>

          <div className="my-4 w-full">
            {transactions?.map((transaction, index) => (
              <>
                <div key={index} className="row mb-4">
                  <h5 className="font-weight-bold text-green mb-3 col-12">
                    {transaction?.title}
                  </h5>

                  {transaction.items?.map((item) => {
                    if (!item.show) return <></>;

                    return (
                      <>
                        <div className="col-12 col-md-5 d-flex my-2">
                          <i className="mr-2 material-icons text-gray">
                            {item?.icon}
                          </i>
                          <p className="text-gray font-weight-semi">
                            {item?.title}
                          </p>
                        </div>

                        <div className="col-12 col-md-7 my-2 font-weight-semi text-left text-md-right">
                          <p>{item?.value}</p>
                        </div>
                      </>
                    );
                  })}
                </div>

                <hr />
              </>
            ))}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center pb-4">
          <h5 className="text-gray font-weight-bold text-center mb-4">
            Cargando carrito, espere un momento...
          </h5>
          <ClimbingBoxLoader color={colors.green} size="25" />
        </div>
      )}
    </>
  );
}

export default TransactionModal;
