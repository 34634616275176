import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

import Card from '../components/Card';
import Table from '../components/Table/Table';
import OrderDetail from '../components/OrderDetail';
import Status from '../components/Status';

import api from '../utils/api';
import { STATUS, status } from '../assets/resources/status';
import colors from '../assets/resources/colors';
import { formatTimestamp, getOrderCode, getDelivery } from '../utils/misc';

import circleCheckSVG from 'assets/icons/check-circle-solid-green.svg';

const ordersTable = {
  columns: [
    { text: 'ID', key: 'fk_ordersId', className: 'text-center' },
    { text: 'Código', key: 'code', className: 'text-center' },
    { text: 'Comercio', key: 'storeName' },
    { text: 'Sucursal', key: 'branchName' },
    { text: 'Dirección de recolección', key: 'pickupAddress' },
    {
      text: 'Fecha de ingreso',
      key: 'createdAtDisplay',
      className: 'text-center',
    },
    { text: 'Teléfono', key: 'branchPhone', className: 'text-center' },
    { text: 'Pago', key: 'paymentStatusDisplay', className: 'text-center' },
  ],
  options: { id: ['fk_ordersId', 'fk_storesId'] },
};

const PendingOrders = () => {
  const { REACT_APP_TITLE } = process.env;

  useEffect(() => {
    document.title = `Órdenes pendientes | ${REACT_APP_TITLE}`;
  }, []);

  const [pendingOrders, setOrders] = useState();
  const [getOrders, setGetOrders] = useState(1);
  const [orderOnViewId, setOrderOnViewId] = useState();

  useEffect(() => {
    api.orderDetail
      .getAll({ status: STATUS.ORDER_PENDING })
      .then((res) => {
        let storeOrders = [];
        res.data.forEach((orderDetail) => {
          const {
            fk_storesId,
            fk_ordersId,
            store,
            store_branch,
            order,
            price,
            qty,
            discount,
            cesc,
            createdAt,
          } = orderDetail;
          const {
            name: branchName,
            address: branchAddress,
            city: branchCity,
            state: branchState,
            phone,
          } = store_branch;
          const {
            user,
            apartment,
            address,
            city,
            state,
            paymentType,
            driver,
            shipping,
            bookingFee,
            freeShipping,
            coupon,
            note,
            eta,
            paymentStatus,
            cardDiscount,
            millas,
            balance,
          } = order;
          const { firstname, lastname, phone: buyerPhone } = user ?? {};
       
          const {
            usersId,
            firstname: driverFirstname,
            lastname: driverLastName,
            phone: driverPhone,
          } = driver || {};

          const existentStoreOrder = storeOrders.find(
            (storeOrder) =>
              fk_storesId === storeOrder.fk_storesId &&
              fk_ordersId === storeOrder.fk_ordersId
          );

          if (existentStoreOrder) {
            existentStoreOrder.details.push(orderDetail);
            existentStoreOrder.amount += price * (1 - discount / 100) * qty;
          } else {
            storeOrders.push({
              code: getOrderCode(fk_ordersId),
              coupon,
              shipping,
              freeShipping,
              bookingFee,
              driver: {
                usersId,
                name: `${driverFirstname} ${driverLastName}`,
                phone: driverPhone,
              },
              buyer: `${firstname} ${lastname}`,
              buyerPhone,
              deliveryAddress: [apartment, address, city, state]
                .filter((string) => string?.trim())
                .join(', '),
              paymentType: paymentType.split('|')[0],
              amount:
                price * (1 - discount / 100) * qty +
                (freeShipping ? 0 : shipping) +
                bookingFee,
              fk_storesId,
              fk_ordersId,
              details: [orderDetail],
              storeName: store?.name,
              branchName: branchName,
              pickupAddress: [
                branchAddress,
                branchCity.name,
                branchState.name,
              ].join(', '),
              createdAtDisplay: formatTimestamp(order.createdAt),
              branchPhone: phone,
              note,
              eta,
              cardDiscount,
              paymentStatus,
              millas,
              balance,
              paymentStatusDisplay:
                paymentStatus === 'COMPLETADO' || paymentType === 'CASH' ? (
                  <img src={circleCheckSVG} alt="" style={{ height: '18px' }} />
                ) : null,
              cesc:
                parseInt(cesc, 10) >= 0 &&
                parseInt(cesc, 10) >= 24 * 60 * 60 * 1000
                  ? getDelivery(parseInt(cesc, 10), createdAt)
                  : '',
              createdAt,
            });
          }
        });

        storeOrders.forEach((order) => {
          order.details = order.details.map((detail) => {
            const {
              ordersDetailsId,
              variant,
              image,
              name,
              price,
              discount,
              store,
              store_branch,
              qty,
              fk_purchaseStatusesId,
              cesc,
              createdAt,
            } = detail;
            const { sku, product, values } = variant || {};
            const { cargoType, isFragile, freeShipping } = product || {};
            const { storesId, name: storeName } = store || {};
            const {
              name: branchName,
              phone,
              representative,
              storesBranchesId,
              address,
              city,
              state,
            } = store_branch;

            return {
              status: (
                <Status
                  className="w-100"
                  value={fk_purchaseStatusesId}
                  dataTip={detail.details_histories
                    .map(
                      (entry) =>
                        status.find(
                          (status) => entry.fk_purchaseStatusesId === status.id
                        ).text +
                        ' - ' +
                        new Date(entry.createdAt).toLocaleString()
                    )
                    .join('<br>')}
                />
              ),
              branch: {
                storesId,
                storesBranchesId,
                storeName,
                name: branchName,
                phone,
                representative,
                address: [address, city.name, state.name].join(', '),
              },
              ordersDetailsId,
              sku,
              image,
              name,
              variant: values
                ?.split(',')
                .filter((string) => string && string.trim())
                .join(' '),
              price,
              discount: `${discount || 0}%`,
              cargoType,
              isFragile: isFragile ? (
                <img src={circleCheckSVG} alt="" style={{ height: '18px' }} />
              ) : null,
              freeShipping: freeShipping ? (
                <img src={circleCheckSVG} alt="" style={{ height: '18px' }} />
              ) : null,
              qty,
              fk_purchaseStatusesId,
              cesc:
                parseInt(cesc, 10) >= 0 &&
                parseInt(cesc, 10) >= 24 * 60 * 60 * 1000
                  ? getDelivery(parseInt(cesc, 10), createdAt)
                  : '',
              createdAt,
              productsId: detail?.variant?.product?.productsId,
              type: (
                <span
                  className="px-2 py-1 font-weight-bold rounded"
                  style={{
                    backgroundColor:
                      detail?.variant?.product?.flavor?.productsTypesId === 1
                        ? colors.green
                        : colors.goodies_pink,
                  }}
                >
                  {detail?.variant?.product?.flavor?.name.toUpperCase()}
                </span>
              ),
            };
          });
        });

        storeOrders = storeOrders.sort((a, b) =>
          a.fk_ordersId > b.fk_ordersId
            ? 1
            : a.fk_ordersId < b.fk_ordersId
            ? -1
            : 0
        );

        setOrders(storeOrders);
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
        console.error(err);
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
        console.error(err);
      });
  }, [getOrders]);

  return (
    <div className="content">
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          <Card>
            {pendingOrders ? (
              <Table
                onRowClick={(rowID) => {
                  const [fk_ordersId] = rowID
                    .split('-')
                    .map((id) => parseInt(id));
                  setOrderOnViewId(fk_ordersId);
                }}
                columns={ordersTable.columns}
                data={pendingOrders}
                options={ordersTable.options}
                pageSize={10}
              />
            ) : (
              <div
                className="p-5 m-5 d-flex justify-content-center align-items-center"
                style={{ flexGrow: '1' }}
              >
                <ClimbingBoxLoader color={colors.green} size="25" />
              </div>
            )}
          </Card>
        </div>
      </div>

      {orderOnViewId && (
        <OrderDetail
          requestGetOrders={() => setGetOrders(getOrders + 1)}
          id={orderOnViewId}
          onCloseRequest={() => setOrderOnViewId(null)}
        />
      )}
    </div>
  );
};

export default PendingOrders;
