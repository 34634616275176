import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';

import AdminNavbar from 'components/Navbars/AdminNavbar';
import Sidebar from '../components/sidebar/sidebar';
import { routes, sidebarRoutes } from 'routes.js';
class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _notificationSystem: null,
      color: 'jappi',
      hasImage: false,
      fixedClasses: 'dropdown show-dropdown open',
      arePushNotificationsEnabled: null,
      userID: null,
    };
  }

  getRoutes = (routes) => {
    const { JWT } = this.state;
    const decodedJWT = jwt_decode(JWT);
    const { user } = decodedJWT;

    const allRoutes = [
      ...sidebarRoutes.filter(
        (route) =>
          route.estrict === false || (route?.roles || [])?.includes(user.role.name)
      ),
      ...[].concat(
        ...sidebarRoutes
          .map((route) => route.children)
          .filter((children) => children)
      ),
      ...routes,
      // ...routes.filter(
      //   (route) =>
      //     route.estrict === false || route?.roles?.includes(user.role.name)
      // ),
    ];

    return allRoutes
      .map((prop, key) => {
        if (prop.layout === '/admin') {
          return (
            <Route
              exact
              path={prop.layout + prop.path}
              render={(props) => <prop.component {...props} />}
              key={key}
            />
          );
        } else {
          return null;
        }
      })
      .concat([
        <Route key="redirect" exact path="/admin">
          <Redirect to="/admin/dashboard" />
        </Route>,
      ]);
  };

  componentDidMount() {
    const { REACT_APP_COOKIES_JWT, REACT_APP_COOKIES_USER_ID } = process.env;
    const { cookies } = this.props;
    const JWT = cookies.get(REACT_APP_COOKIES_JWT);
    const userID = cookies.get(REACT_APP_COOKIES_USER_ID);
    this.setState({ JWT, userID });
    if (!JWT || new Date().getTime() / 1000 > jwt_decode(JWT).exp) {
      this.props.history.push({
        pathname: '/login',
        state: { nextLocation: this.props.history.location.pathname },
      });
    }
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 1025 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  render() {
    return this.state.JWT ? (
      <>
        <div className="wrapper">
          <Sidebar
            location={this.props.location}
            userID={this.state.userID}
            initialRoutes={sidebarRoutes.map((route) => ({
              ...route,
              isOpen: false,
            }))}
          />
          <div id="main-panel" className="main-panel" ref="mainPanel">
            <AdminNavbar {...this.props} />
            <div
              className="p-3 p-xl-4 d-flex flex-column"
              style={{ flexGrow: '1' }}
            >
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
          </div>
        </div>
      </>
    ) : null;
  }
}

export default withCookies(Admin);
