import React, { useState } from 'react';

const Signature = ({ signature, dui }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="row mt-4 py-2 border rounded space-y-3">
      <div className="col-12 d-flex">
        <p className="mr-3 text-indigo-dark font-extrabold">Recepcion:</p>
        <button
          className="font-weight-bold text-green underline-button"
          onClick={() => setShow(!show)}
        >
          {show ? 'Ocultar' : 'Mostrar'}
        </button>
      </div>
      {show && (
        <>
          <div className="col-12 col-md-6 mt-3 mt-md-0">
            <p className="font-weight-bold text-green">DUI</p>
            <p>{dui || 'No se ha registrado'}</p>
          </div>
          <div className="col-12 col-md-6 mt-3 mt-md-0">
            <p className="font-weight-bold text-green">Firma</p>
            {signature ? (
              <img src={signature} alt="signature" />
            ) : (
              <p>No se ha registrado</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Signature;
