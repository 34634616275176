import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Card from 'components/Card';
import Table from 'components/TableV2/Table';
import SearchReport from 'components/SearchReport';
import api from 'utils/api';
import download from 'downloadjs';

function Searches() {
  const { REACT_APP_TITLE } = process.env;

  const tableProps = {
    rowId: 'usersSearchesId',
    baseURL: '/userssearches',
    baseFilter: {
      attributes: ['usersSearchesId', 'text', 'fk_usersId', 'createdAt'],
      include: [
        {
          association: 'user',
          attributes: ['usersId', 'firstname', 'lastname'],
        },
      ],
      order: [['usersSearchesId', 'desc']],
    },
    pageSize: 10,
    columns: [
      {
        title: 'Busqueda',
        objectProperty: 'text',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Fecha de busqueda',
        objectProperty: 'createdAt',
        searchable: true,
        searchType: 'timestamp',
        middleware: (createdAt) => new Date(createdAt).toLocaleString(),
        sortable: true,
      },
      {
        title: 'Nombre',
        objectProperty: 'user',
        gatewayObjectProperty: '$user.firstname$',
        searchable: true,
        searchType: 'text',
        middleware: (user) => user?.firstname,
      },
      {
        title: 'Apellido',
        objectProperty: 'user',
        gatewayObjectProperty: '$user.lastname$',
        searchable: true,
        searchType: 'text',
        middleware: (user) => user?.lastname,
      },
    ],
  };

  const onSubmit = (values, { setSubmitting }) => {
    api.searches
      .download({
        startDate: values.startDate,
        endDate: values.endDate,
      })
      .then((res) => {
        download(
          res.data,
          `Búsquedas- ${values.startDate} - ${values.endDate}.csv`,
          res.headers['content-type']
        );
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    document.title = `Búsquedas | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <div>
        <div className="row">
          <div className="col">
            <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
              <h3 className="text-dark-blue font-size-2x font-weight-bold">
                Búsquedas
              </h3>
            </div>
            <SearchReport className="mt-4" onSubmit={onSubmit} />
            <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
              <div className="col-12">
                <Card className="p-md-4">
                  <Table
                    columns={tableProps.columns}
                    pageSize={tableProps.pageSize}
                    baseURL={tableProps.baseURL}
                    baseFilter={tableProps.baseFilter}
                    rowId={tableProps.rowId}
                  />
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Searches;
