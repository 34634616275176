import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

import Card from '../../components/Card';
import Table from '../../components/Table/Table';

import colors from '../../assets/resources/colors';
import api from '../../utils/api';

import addSVG from '../../assets/icons/add.svg';

const table = {
  options: {
    id: 'popUpsId',
  },
  columns: [
    { text: 'ID', key: 'popUpsId', className: 'text-center' },
    { text: '', key: 'mobileUrl', isImage: true, className: 'text-center' },
    { text: '', key: 'desktopUrl', isImage: true, className: 'text-center' },
    { text: 'Nombre', key: 'name', className: 'text-center' },
    { text: 'CTA', key: 'cta', className: 'text-center' },
    {
      text: 'Activo',
      isBoolean: true,
      key: 'active',
      className: 'text-center',
    },
    {
      text: 'Solo logueados',
      isBoolean: true,
      key: 'needAuth',
      className: 'text-center',
    },
    {
      text: 'Solo no logueados',
      isBoolean: true,
      key: 'withoutAuth',
      className: 'text-center',
    },
    {
      text: 'Obligatorio',
      isBoolean: true,
      key: 'disposable',
      className: 'text-center',
    },
    {
      text: 'Invasivo',
      isBoolean: true,
      key: 'invasive',
      className: 'text-center',
    },
  ],
};

function PopupList() {
  const { REACT_APP_TITLE } = process.env;
  const history = useHistory();

  useEffect(() => {
    document.title = `Popups | ${REACT_APP_TITLE}`;
  }, []);

  useEffect((_) => {
    api
      .findAll('/popups')
      .then((res) => {
        setBanners(
          res.data
            .map((popup) => {
              return {
                ...popup,
                createdAt: new Date(popup.createdAt).toLocaleString(),
                updatedAt: new Date(popup.updatedAt).toLocaleString(),
              };
            })
        );
      })
      .catch((err) => console.error(err));
  }, []);

  const [banners, setBanners] = useState();

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Popups
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <Link to="/admin/popup/new" className="w-100">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold"
            >
              <img
                src={addSVG}
                alt=""
                className="d-inline-block mr-2"
                style={{ height: '16px' }}
              />
              Nuevo popup
            </button>
          </Link>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            {banners ? (
              <Table
                columns={table.columns}
                data={banners}
                options={table.options}
                pageSize={10}
                onRowClick={(id) => history.push(`/admin/popup/${id}/edit`)}
              />
            ) : (
              <div
                className="p-5 m-5 d-flex justify-content-center align-items-center"
                style={{
                  flexGrow: '1',
                }}
              >
                <ClimbingBoxLoader color={colors.green} size="25" />
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PopupList;
