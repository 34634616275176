import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

import Card from '../../components/Card';
import Table from '../../components/Table/Table';

import colors from '../../assets/resources/colors';
import api from '../../utils/api';

import circleCheckSVG from '../../assets/icons/check-circle-solid-green.svg';
import addSVG from '../../assets/icons/add.svg';

const table = {
  options: {
    id: 'storesBannersId',
  },
  columns: [
    { text: 'ID', key: 'storesBannersId', className: 'text-center' },
    { text: '', key: 'image', isImage: true, className: 'text-center' },
    { text: 'Título', key: 'title' },
    { text: 'URL', key: 'url', className: 'text-center' },
    { text: 'Clicks', key: 'clicks', className: 'text-center' },
    { text: 'Subtítulo', key: 'subtitle' },
    { text: 'CTA', key: 'cta', className: 'text-center' },
    { text: 'Tipo', key: 'type', className: 'text-center' },
    {
      text: 'Activo',
      key: 'active',
      className: 'text-center',
      isBoolean: true,
    },
    {
      text: 'Oferta',
      key: 'isSales',
      className: 'text-center',
      isBoolean: true,
    },
    {
      text: 'Oferta relámpago',
      key: 'isFlashSales',
      className: 'text-center',
      isBoolean: true,
    },
    { text: 'Creado', key: 'createdAt', className: 'text-center' },
    { text: 'Actualizado', key: 'updatedAt', className: 'text-center' },
  ],
};

function BannersList() {
  const { REACT_APP_TITLE } = process.env;
  const history = useHistory();
  const [onlyActive, setOnlyActive] = useState(false);

  useEffect(() => {
    document.title = `Banners | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(
    (_) => {
      setBanners([]);

      api.banners
        .getAll({
          ...(onlyActive && { active: '1' }),
        })
        .then((res) => {
          setBanners(
            res.data
              .filter((banner) => banner.type !== 'feature')
              .map((banner) => {
                return {
                  ...banner,
                  active: banner.active === '1' ? true : false,
                  isSales: banner.isSales === '1' ? true : false,
                  isFlashSales: banner.isFlashSales === '1' ? true : false,
                  createdAt: new Date(banner.createdAt).toLocaleString(),
                  updatedAt: new Date(banner.updatedAt).toLocaleString(),
                };
              })
          );
        })
        .catch((err) => console.error(err));
    },
    [onlyActive]
  );

  const [banners, setBanners] = useState();

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center row">
          <h3 className="col-6 col-md-8 text-dark-blue font-size-2x font-weight-bold">
            Banners
          </h3>
          <div className="col-6 col-md-4 switch-button d-flex align-items-center justify-content-end">
            <label htmlFor="switch-label" className="mr-2">
              SOLO ACTIVOS
            </label>
            <input
              value={onlyActive}
              onChange={(val) => setOnlyActive(val.target.checked)}
              type="checkbox"
              name="switch-button"
              id="switch-label"
              className="switch-button__checkbox"
            />
            <label htmlFor="switch-label" className="switch-button__label" />
          </div>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <Link to="/admin/banners/new" className="w-100">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold"
            >
              <img
                src={addSVG}
                alt=""
                className="d-inline-block mr-2"
                style={{ height: '16px' }}
              />
              Nuevo banner
            </button>
          </Link>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            {banners ? (
              <Table
                columns={table.columns}
                data={banners}
                options={table.options}
                pageSize={10}
                onRowClick={(id) => history.push(`/admin/banners/${id}/edit`)}
              />
            ) : (
              <div
                className="p-5 m-5 d-flex justify-content-center align-items-center"
                style={{
                  flexGrow: '1',
                }}
              >
                <ClimbingBoxLoader color={colors.green} size="25" />
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default BannersList;
