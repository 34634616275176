import Card from 'components/Card';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ClimbingBoxLoader } from 'react-spinners';

import api from 'utils/api';
import colors from 'assets/resources/colors';

import Table from 'components/Table/Table';
import { formatTaxRanges } from 'utils/misc';
import { parseDate } from 'utils/misc';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { useCookies } from 'react-cookie';
import ReactTooltip from 'react-tooltip';

function ShippingCostsList() {
  const history = useHistory();

  const { REACT_APP_TITLE, REACT_APP_COOKIES_USER_ID } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  const [items, setItems] = useState();
  const userId = cookies[REACT_APP_COOKIES_USER_ID];

  useEffect(() => {
    document.title = `Costo de envío | ${REACT_APP_TITLE}`;
  }, []);

  const table = {
    options: { id: 'shippingTypesId' },
    columns: [
      { text: 'Id', key: 'shippingTypesId', className: 'text-center' },
      { text: 'Tipo', key: 'title', className: 'text-center' },
      {
        text: 'Activo',
        key: 'active',
        className: 'text-center',
        isCustom: true,
        render: (value) => (
          <i
            className={`material-icons-round ${
              value ? 'text-green' : 'text-gray'
            }`}
          >
            check_circle
          </i>
        ),
      },
      {
        text: 'Creado',
        key: 'createdAt',
        className: 'text-center',
        isCustom: true,
        render: (value) => parseDate(value),
      },
      {
        text: 'Acciones',
        isCustom: true,
        fullData: true,
        render: (value) => (
          <div className="d-flex justify-content-center">
            <button
              className="material-icons"
              onClick={() =>
                history.push(`/admin/shipping-costs/${value.shippingTypesId}`)
              }
            >
              edit
            </button>
            <button
              data-tip={value.active ? 'Desactivar' : 'Activar'}
              className={`material-icons ${value.active ? 'text-green' : 'text-gray'}`}
              onClick={() => handleActive(value)}
            >
              {value.active ? 'toggle_on' : 'toggle_off'}
            </button>
            <ReactTooltip />
          </div>
        ),
      },
    ],
  };

  const getData = () => {
    api.shippingtype
      .getAll()
      .then(({ data }) => {
        const _fees = formatTaxRanges(data);
        setItems(_fees);
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener los tipos de envío');
      });
  };

  const handleActive = (type) => {
    const active = type.active;
    swal({
      title: active
        ? '¿Estás seguro de desactivar?'
        : '¿Estás seguro de activar?',
      text: `Estás a punto de ${active ? 'desactivar' : 'activar'} ${
        type.title
      }.`,
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
      dangerMode: true,
    }).then((willDelete) => {
      api.shippingtype
        .update(type.shippingTypesId, { active: !active, updatedBy: userId })
        .then(() => getData());
    });
  };

  useEffect(() => getData(), []);

  return (
    <div className="content">
      <div className="row">
        <h3 className="col-12 col-md-9 text-dark-blue font-size-2x font-weight-bold">
          Costos de envío
        </h3>
      </div>
      <Card className="mt-3 mt-md-4">
        {items ? (
          <Table
            pageSize={10}
            options={table.options}
            columns={table.columns}
            data={items}
          />
        ) : (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </Card>
    </div>
  );
}

export default ShippingCostsList;
