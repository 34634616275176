import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import DatePicker from 'react-datetime';
import moment from 'moment';

export const DatePickerField = ({ ...props }) => {
  const [field, , { setValue }] = useField(props);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (field.value == '') {
      setShow(false);
      setTimeout(() => setShow(true), 50);
    }
  }, [field.value]);
  return (
    <>
      {show && (
        <DatePicker
          {...field}
          {...props}
          value={(field.value && moment(field.value)) || null}
          onChange={(val) => setValue(val)}
        />
      )}
    </>
  );
};
