import React, { useEffect } from 'react';

import Card from '../components/Card';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';
import TransactionModal from 'components/TransactionModal';
import { VOUCHER_ICONS } from 'utils/misc';
import { VOUCHER_STATUS } from 'utils/misc';
import { formatPrice } from 'utils/misc';
import { getOrderCode } from 'utils/misc';
import { parseDate } from 'utils/misc';
import swal from '@sweetalert/with-react';
import PayInvoice from 'components/PayInvoice';

function VoucherTemplateList() {
  const { REACT_APP_TITLE } = process.env;

  const tableProps = {
    rowId: 'voucherTemplatesId',
    baseURL: '/vouchertemplates',
    baseFilter: {
      order: [['createdAt', 'desc']],
      include: [
        {
          association: 'store',
          attributes: ['paymentStoresId', 'name'],
        },
      ],
    },
    pageSize: 10,
    columns: [
      {
        title: 'Estado',
        objectProperty: 'active',
        middleware: (value) => (
          <i
            className={`material-icons ${
              value ? 'text-success' : 'text-danger'
            }`}
          >
            {value ? 'check_circle' : 'cancel'}
          </i>
        ),
      },
      {
        title: 'ID',
        objectProperty: 'voucherTemplatesId',
        sortable: true,
        searchable: true,
        searchType: 'number-equal',
      },
      {
        title: 'Codigo',
        objectProperty: 'voucherTemplatesId',
        searchable: true,
        searchType: 'number-equal',
        sortable: true,
        middleware: (value) => `PTY${getOrderCode(value)}`,
        searchMiddleware: (searchTerm) =>
          parseInt(searchTerm.replace(/PTY-/, ''), 16) / 64,
      },
      {
        title: 'Cantidad',
        objectProperty: 'amount',
        sortable: true,
        searchable: true,
        searchType: 'number',
        middleware: (value) => formatPrice(value),
      },
      {
        title: 'Concepto',
        objectProperty: 'concept',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
      {
        title: 'Tienda',
        objectProperty: 'store',
        middleware: (store) => store?.name || '',
      },
      {
        title: 'Usos Disponibles',
        middleware: (row) => {
          if (row.maxUses === 0) {
            return 'Ilimitado';
          }
          return `${row.remainUses}/${row.maxUses}`;
        },
      },
      {
        title: 'Creacion',
        objectProperty: 'createdAt',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (createdAt) => parseDate(createdAt),
      },
      {
        title: 'Inicio',
        objectProperty: 'startline',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (startline) => (startline ? parseDate(startline) : ''),
      },
      {
        title: 'Fin',
        objectProperty: 'deadline',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (deadline) => (deadline ? parseDate(deadline) : ''),
      },
    ],
  };

  const showVoucher = (voucher) => {
    swal({
      content: <PayInvoice voucher={voucher} onClose={() => swal.close()} />,
      buttons: false,
    });
  };

  const openModal = (title, subtitle, data) => {
    swal({
      content: (
        <TransactionModal
          title={title}
          subtitle={subtitle}
          data={data}
          onClose={() => swal.close()}
        />
      ),
      buttons: false,
    });
  };

  useEffect(() => {
    document.title = `Links Multipago | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Links Multipago
          </h3>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default VoucherTemplateList;
