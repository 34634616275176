import React, { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import api from 'utils/api';

const PAYMENT_GATEWAYS = {
  FAC: 'fac',
  BOTH: 'both',
};

function RootSettings() {
  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userId = cookies[REACT_APP_COOKIES_USER_ID];

  const _updateAlgolia = async (index) => {
    _switchLoading(index, true);

    try {
      const res = await api.settings.updateAlgolia({
        createdBy: userId,
      });

      if (res.status === 200) {
        toast.success('Se ha actualizado el motor de búsqueda');
      }
    } catch (error) {
      console.error(error);
      toast.error(
        'Error al actualizar el motor de búsqueda, revisa la consola'
      );
    } finally {
      _switchLoading(index, false);
    }
  };

  const _switchPaymentGateway = async (index) => {
    _switchLoading(index, true);

    try {
      const newGateway =
        currentGateway === PAYMENT_GATEWAYS.FAC
          ? PAYMENT_GATEWAYS.BOTH
          : PAYMENT_GATEWAYS.FAC;

      const res = await api.paymentMethods.switchPaymentGateway(
        newGateway,
        userId
      );

      if (res.status === 200) {
        toast.success('Se ha actualizado la pasarela de pagos');
        setCurrentGateway(newGateway);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al actualizar la pasarela de pagos');
    } finally {
      _setDefaultPaymentGateway();
    }
  };

  const _setDefaultPaymentGateway = async () => {
    let hasError = false;
    let gateway = null;

    try {
      const res = await api.paymentMethods.getByID(1);

      if (res.data && res.data.gateway && res.status === 200) {
        gateway = res.data.gateway;
        console.log(gateway, 'gateway');
      } else {
        toast.error('Error al obtener la pasarela de pagos');
      }
    } catch (error) {
      console.error(error);
      gateway = null;
      toast.error('Error al obtener la pasarela de pagos, revisa la consola');
    } finally {
      if (gateway) {
        setCurrentGateway(gateway);
      } else {
        hasError = true;
      }
    }

    setItems((prev) => {
      const _items = [...prev];
      const _updatePaymentGatewayIndex = _items.findIndex(
        (item) => item.id === 'update-payment-gateway'
      );

      _items[_updatePaymentGatewayIndex] = {
        ..._items[_updatePaymentGatewayIndex],
        loading: false,
        enabled: !hasError,
        disabledReason: hasError ? 'No disponible por el momento' : null,
        subdescription: hasError
          ? 'No se pudo obtener la pasarela de pagos'
          : `Actualmente se usa ${gateway.toUpperCase()} ("both" = serfinsa y "fac" = bac)`,
        enableReason: hasError
          ? null
          : `Cambiar a ${
              gateway === PAYMENT_GATEWAYS.FAC ? 'serfinsa' : 'bac'
            }`,
      };

      return _items;
    });
  };

  const _switchLoading = (index, loading) => {
    setItems((prev) => {
      const _items = [...prev];
      _items[index].loading = loading;
      return _items;
    });
  };

  const _defaulIitems = [
    {
      id: 'update-algolia',
      icon: 'manage_search',
      title: 'Actualizar Algolia',
      description:
        'Actualiza los registros de los productos en el motor de búsqueda.',
      subdescription: null,
      actionText: 'Reindexar',
      actionIcon: 'refresh',
      action: 'algolia_action',
      enabled: false,
      disabledReason: 'No disponible por el momento',
      enableReason: 'Actualizar registros de productos',
      loading: false,
    },
    {
      id: 'update-payment-gateway',
      icon: 'toll',
      title: 'Actualizar pasarela de pagos',
      description:
        'Cambiar la pasarela de pagos de la aplicación entre BAC y Serfinsa.',
      subdescription: null,
      actionText: 'Cambiar',
      actionIcon: 'currency_exchange',
      action: 'switch_payment_action',
      enabled: false,
      disabledReason: null,
      enableReason: null,
      loading: false,
    },
  ];

  const [items, setItems] = useState(_defaulIitems);
  const [currentGateway, setCurrentGateway] = useState('');

  const _actionsMap = useMemo(
    () => ({
      algolia_action: _updateAlgolia,
      switch_payment_action: _switchPaymentGateway,
    }),
    [_updateAlgolia, _switchPaymentGateway]
  );

  const _memoizedItems = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        action: _actionsMap[item.action],
      })),
    [items, _actionsMap]
  );

  const _onButtonClick = useCallback(
    (index) => {
      const _item = _memoizedItems[index];

      if (_item.enabled) _item.action(index);

      return;
    },
    [_memoizedItems]
  );

  useEffect(() => {
    document.title = `Root 💻 | ${REACT_APP_TITLE}`;

    _setDefaultPaymentGateway();
  }, []);

  return (
    <div className="content">
      <div className="row">
        <h3 className="col-12 text-dark-blue font-size-2x font-weight-bold">
          Configuraciónes administrativas
        </h3>
        <small className="col-12 text-dark-blue font-size-md">
          Actuar con precaución. Recuerda, un gran poder conlleva una gran
          responsabilidad.
        </small>
      </div>

      <div className="w-full md:w-4/5 lg:w-3/5 xl:w-1/2 mx-auto flex flex-col space-y-5 my-6">
        {_memoizedItems.map((item, index) => (
          <div
            key={index}
            className="flex justify-between items-center border py-2 px-2 md:px-4 rounded"
          >
            <span className="material-icons-round !text-4xl">{item.icon}</span>
            <div className="w-full mx-3 md:mx-6 flex flex-col space-y-1">
              <p className="text-lg font-bold">{item.title}</p>
              <small>{item.description}</small>
              {item.subdescription && (
                <small className="text-red">{item.subdescription}</small>
              )}
            </div>
            <button
              data-tip={item.enabled ? item.enableReason : item.disabledReason}
              type="button"
              disabled={item.loading || !item.enabled}
              onClick={() => _onButtonClick(index)}
              className="bg-green tuyo-btn px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center disabled:opacity-50"
            >
              {item.loading ? (
                <span className="material-icons-round animate-spin">
                  refresh
                </span>
              ) : (
                <>
                  <span className="material-icons-outlined">
                    {item.actionIcon}
                  </span>
                  <span className="d-none d-md-block md:ml-1 text-white">
                    {item.actionText}
                  </span>
                </>
              )}
            </button>
            <hr />
          </div>
        ))}
      </div>
      <ReactTooltip />
    </div>
  );
}

export default RootSettings;
