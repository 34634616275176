import Card from 'components/Card';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ClimbingBoxLoader } from 'react-spinners';

import api from 'utils/api';
import colors from 'assets/resources/colors';

import Table from '../components/Table/Table';
import swal from '@sweetalert/with-react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useCookies } from 'react-cookie';
import { formatTaxRanges } from 'utils/misc';
import { parseDate } from 'utils/misc';
import ReactTooltip from 'react-tooltip';

function Fees() {
  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userId = cookies[REACT_APP_COOKIES_USER_ID];
  const [fees, setFees] = useState();

  useEffect(() => {
    document.title = `Tarifa de servicio | ${REACT_APP_TITLE}`;
  }, []);

  const table = {
    options: { id: 'feesId' },
    columns: [
      { text: 'Id', key: 'feesId', className: 'text-center' },
      { text: 'Mínimo', key: 'min', className: 'text-center' },
      {
        text: 'Maximo',
        key: 'max',
        className: 'text-center',
        isCustom: true,
        render: (value) => value ?? 'Sin límite',
      },
      {
        text: 'Valor',
        key: 'value',
        className: 'text-center',
        isCustom: true,
        render: (value) => (
          <div>
            <span
              style={{
                borderRadius: 25,
              }}
              data-tip="Valores menores a 1 son porcentajes"
              className={`px-4 py-2  ${
                value > 1 ? 'bg-success text-white' : 'bg-danger text-white'
              }`}
            >
              {value < 1 ? `${value * 100}%` : `$${value}`}
            </span>
            <ReactTooltip effect="solid" />
          </div>
        ),
      },
      {
        text: 'Creado',
        key: 'createdAt',
        className: 'text-center',
        isCustom: true,
        render: (value) => parseDate(value),
      },
      {
        text: 'Acciones',
        isCustom: true,
        fullData: true,
        render: (value) => (
          <div className="d-flex justify-content-center">
            <button
              className="material-icons"
              onClick={() =>
                showSwal({
                  initialValues: value,
                  isEdit: true,
                })
              }
            >
              edit
            </button>
            <button
              className="material-icons"
              onClick={() => deleteFee(value.feesId)}
            >
              delete
            </button>
          </div>
        ),
      },
    ],
  };

  const getData = () => {
    api.fees
      .getAll()
      .then(({ data }) => {
        const _fees = formatTaxRanges(data);
        setFees(_fees);
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener la lista negra');
      });
  };

  const deleteFee = (id) => {
    swal({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esta acción',
      icon: 'warning',
      buttons: ['Cancelar', 'Eliminar'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        api.fees
          .delete(id)
          .then(() => {
            swal('Eliminado', 'La tarifa ha sido eliminada', 'success');
            getData();
          })
          .catch((error) => {
            console.error(error);
            swal('Error', 'No se pudo eliminar la tarifa', 'error');
          });
      }
    });
  };

  const updateFee = (values, id) => {
    const body = {
      ...values,
      updatedBy: userId,
    };

    api.fees
      .update(id, body)
      .then(() => {
        getData();
        swal('¡Éxito!', 'Tarifa actualizada', 'success');
      })
      .catch((error) => {
        console.error(error);
        swal('¡Error!', 'No se pudo actualizar la tarifa', 'error');
      });
  };

  const createFee = (values) => {
    const body = {
      ...values,
      createdBy: userId,
    };

    api.fees
      .create(body)
      .then(() => {
        getData();
        swal(
          'Tarifa creada',
          'La tarifa se ha creado correctamente',
          'success'
        );
      })
      .catch((error) => {
        console.error(error);
        swal('Error', 'No se pudo crear la tarifa', 'error');
      });
  };

  const showSwal = ({ initialValues, isEdit }) => {
    swal({
      title: 'Nueva tarifa',
      buttons: false,
      content: (
        <Formik
          initialValues={{
            minimun: initialValues?.minimun ?? 0,
            value: initialValues?.value ?? 0,
          }}
          validate={(values) => {
            const errors = {};
            if (!(values.minimun >= 0)) errors.minimun = 'Campo requerido';
            if (!(values.value > 0)) errors.value = 'Campo requerido';
            return errors;
          }}
          onSubmit={(values) => {
            isEdit
              ? updateFee(values, initialValues.feesId)
              : createFee(values);
          }}
        >
          {() => (
            <Form>
              <div className="form-group">
                <label htmlFor="minimun">Mínimo</label>
                <Field className="form-control" name="minimun" type="number" />
                <ErrorMessage
                  name="minimun"
                  component="span"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="value">Valor</label>

                <Field type="number" name="value" className="form-control" />
                <small className="form-text text-muted">
                  Valores menor a 1 se consideran como porcentaje
                </small>
                <ErrorMessage
                  name="value"
                  component="span"
                  className="text-danger"
                />
              </div>
              <button
                type="submit"
                className="bg-green tuyo-btn px-4 py-2 mt-4 rounded text-light font-weight-bold d-flex align-items-center justify-content-around col-12 "
              >
                Guardar
              </button>
            </Form>
          )}
        </Formik>
      ),
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="content">
      <div className="row">
        <h3 className="col-12 col-md-9 text-dark-blue font-size-2x font-weight-bold">
          Tarifa de servicio
        </h3>
        <div className="col-12 col-md-3 d-flex">
          <button
            type="button"
            className="w-100 bg-purple tuyo-btn px-3 py-2 mr-2 rounded text-light font-weight-bold d-inline-flex align-items-center justify-content-center"
            onClick={showSwal}
          >
            Nuevo tarifa
          </button>
        </div>
      </div>
      <Card className="mt-3 mt-md-4">
        {fees ? (
          <Table
            pageSize={10}
            options={table.options}
            columns={table.columns}
            data={fees}
          />
        ) : (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </Card>
    </div>
  );
}

export default Fees;
