import React from 'react';

function UserCard({ picture, name, store, role }) {
  return (
    <div className="user-card d-flex align-items-center">
      <div className="desc p-2 text-right">
        <p className="text-dark-blue font-weight-bold">{name}</p>
        <small className="text-gray text-uppercase">{role}</small>
      </div>
      <div className="avatar p-2 d-flex align-items-center">
        <img
          src={picture}
          className="rounded"
          alt=""
          style={{ height: '40px' }}
        />
      </div>
    </div>
  );
}

export default UserCard;
