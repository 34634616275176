import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import ChildItem from './childItem';
import ParentItem from './parentItem';

import logo from 'assets/icons/imagotype-white.svg';

import api from '../../utils/api';

function Sidebar({ initialRoutes, location, userID }) {
  const [routes] = useState([...initialRoutes]);
  const [userRole, setUserRole] = useState();
  const [authRoutes, setAuthRoutes] = useState([]);

  useEffect(() => {
    api.users
      .getOne(userID)
      .then((res) => {
        setUserRole(res.data[0].role.name);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const newRoutes = routes
      .map((route) => {
        let tmpRoute = { ...route };
        const filterChildren = filterRoute(route, userRole);
        if (tmpRoute.children) tmpRoute.children = filterChildren || [];
        return tmpRoute;
      })
      .filter((route) => {
        if (route.children) return route.children.length > 0;
        return true;
      });

    setAuthRoutes(newRoutes);
  }, [userRole]);

  function filterRoute(route, userRole) {
    if (route.children) {
      const childrenRoutes = route.children.filter((child) =>
        child.roles.includes(userRole)
      );
      if (childrenRoutes.length != 0) return childrenRoutes;
    } else if (route?.roles?.includes(userRole) || route?.roles === '*') {
      return route;
    }
  }

  function toggleRouteIsOpen(routeIndex) {
    setAuthRoutes([
      ...authRoutes.slice(0, routeIndex),
      {
        ...authRoutes[routeIndex],
        isOpen: !authRoutes[routeIndex].isOpen,
      },
      ...authRoutes.slice(routeIndex + 1),
    ]);
  }

  function closeAll() {
    setAuthRoutes([
      ...authRoutes.map((route) => ({
        ...route,
        isOpen: false,
      })),
    ]);
  }

  return (
    <div className="sidebar bg-tuyo-green">
      <div className="logo bg-tuyo-green ">
        <NavLink to="/admin/dashboard">
          <div className="logo-img">
            <img
              src={logo}
              alt="logo_image"
              height={50}
              style={{
                objectFit: 'contain',
              }}
            />
          </div>
        </NavLink>
      </div>
      <div className="sidebar-wrapper bg-tuyo-green">
        <div className="nav h-100 flex-column" style={{ flexWrap: 'nowrap' }}>
          {authRoutes.map((route, index) =>
            route.children ? (
              <ParentItem
                isActive={route.children.some(
                  (child) =>
                    location.pathname.indexOf(child.layout + child.path) !== -1
                )}
                onClick={() => {
                  closeAll();
                  toggleRouteIsOpen(index);
                }}
                isOpen={route.isOpen}
                key={index}
                label={route.name}
                icon={route.icon}
              >
                {route.children.map((childRoute, childIndex) => (
                  <ChildItem
                    isActive={
                      location.pathname.indexOf(
                        childRoute.layout + childRoute.path
                      ) !== -1
                    }
                    path={childRoute.layout + childRoute.path}
                    key={childIndex}
                    label={childRoute.name}
                    icon={childRoute.icon}
                    className="w-100"
                  />
                ))}
              </ParentItem>
            ) : (
              <ChildItem
                flexGrow={route.flexGrow}
                className={route.className}
                isActive={
                  location.pathname.indexOf(route.layout + route.path) !== -1
                }
                onClick={closeAll}
                path={route.layout + route.path}
                key={index}
                label={route.name}
                icon={route.icon}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
