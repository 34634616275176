import React, { useState, useEffect } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';

import Table from './Table/Table';
import Card from './Card';

import { validPrice, formatTimestamp, formatPrice } from '../utils/misc';
import api from '../utils/api';
import colors from '../assets/resources/colors';

const table = {
  options: {
    id: 'usersTransactionsId',
  },
  columns: [
    { text: 'ID', key: 'usersTransactionsId', className: 'text-center' },
    { text: 'Orden', key: 'orderReference', className: 'text-center' },
    { text: 'Tipo', key: 'typeDisplay', className: 'text-center' },
    {
      text: 'Monto',
      key: 'amount',
      className: 'text-center',
      isCurrency: true,
    },
    {
      text: 'Modificado por',
      key: 'creator',
      className: 'text-center',
      isCustom: true,
      render(data, _) {
        return (
          <span>{`${data?.firstname ?? ''} ${data?.lastname ?? ''}`}</span>
        );
      },
    },
    { text: 'Hora y fecha', key: 'createdAtDisplay', className: 'text-center' },
  ],
};

function UserBalance({ userID }) {
  const { REACT_APP_COOKIES_USER_ID } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  const [isLoading, setIsLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [balanceUpdateAmount, setBalanceUpdateAmount] = useState();
  const [isValidBalanceUpdateAmount, setIsValidBalanceUpdateAmount] =
    useState(false);
  const [getBalance, setGetBalance] = useState(1);
  const [selectedTransactionType, setSelectedTransactionType] = useState('add');

  /* Get user's balance and related transactions */
  useEffect(
    (_) => {
      if (userID) {
        api
          .findAll(
            '/users?filter=' +
              JSON.stringify({
                where: { usersId: userID },
                paranoid: false,
                attributes: ['usersId', 'balance'],
              })
          )
          .then(({ data }) => {
            setBalance(data[0].balance);
            return api.findAll(
              '/userstransactions?filter=' +
                JSON.stringify({
                  where: {
                    fk_usersId: userID,
                    $or: [{ type: 'BALANCE_ADD' }, { type: 'BALANCE_REMOVE' }],
                  },
                  attributes: [
                    'usersTransactionsId',
                    'orderReference',
                    'type',
                    'amount',
                    'createdAt',
                  ],
                  include: [
                    {
                      paranoid: false,
                      association: 'creator',
                      attributes: ['firstname', 'lastname'],
                    },
                  ],
                  order: [['usersTransactionsId', 'desc']],
                })
            );
          })
          .then(({ data }) =>
            setTransactions(
              data.map((transaction) => ({
                ...transaction,
                createdAtDisplay: formatTimestamp(transaction.createdAt),
                typeDisplay:
                  transaction.type.replace('BALANCE_', '') === 'ADD'
                    ? 'Abono'
                    : 'Cargo',
              }))
            )
          )
          .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`))
          .finally(() => setIsLoading(false));
      }
    },
    [userID, getBalance]
  );

  /* Validate balanceUpdateAmount whenever it changes */
  useEffect(() => {
    setIsValidBalanceUpdateAmount(
      balanceUpdateAmount &&
        balanceUpdateAmount.trim() !== '' &&
        /^\d+\.?\d*$/.test(balanceUpdateAmount)
    );
  }, [balanceUpdateAmount]);

  function updateBalance() {
    setIsSaving(true);

    api
      .update('/users', userID, {
        balance:
          selectedTransactionType === 'update'
            ? balanceUpdateAmount
            : selectedTransactionType === 'add'
            ? Number(balance) + Number(balanceUpdateAmount)
            : selectedTransactionType === 'remove'
            ? balance - balanceUpdateAmount
            : balance,
        updatedBy: cookies[REACT_APP_COOKIES_USER_ID],
      })
      .then((_) =>
        api.create('/balancetransactions', {
          type:
            selectedTransactionType === 'update'
              ? balanceUpdateAmount > balance
                ? 'BALANCE_ADD'
                : 'BALANCE_REMOVE'
              : selectedTransactionType === 'add'
              ? 'BALANCE_ADD'
              : selectedTransactionType === 'remove'
              ? 'BALANCE_REMOVE'
              : 'BALANCE_ADD',
          fk_usersId: parseInt(userID),
          amount:
            selectedTransactionType === 'update'
              ? Math.abs(balanceUpdateAmount - balance)
              : selectedTransactionType === 'add' ||
                selectedTransactionType === 'remove'
              ? balanceUpdateAmount
              : 0,
          createdBy: parseInt(cookies[REACT_APP_COOKIES_USER_ID]),
        })
      )
      .then((_) => {
        toast.success('Balance actualizado');
        setGetBalance(getBalance + 1);
        setBalanceUpdateAmount('');
      })
      .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`))
      .finally((_) => setIsSaving(false));
  }

  return (
    <div className="row mt-2">
      <div className="col-12">
        <p className="text-dark-blue font-size-2x font-weight-bold text-center my-2">
          Balance: {formatPrice(balance)}
        </p>
      </div>
      <div className="col-12 col-md-4 col-lg-6">
        <input
          value={balanceUpdateAmount}
          onChange={({ target }) =>
            setBalanceUpdateAmount(validPrice(target.value))
          }
          type="text"
          className="w-100 p-2 rounded"
          placeholder="Ingresa una cantidad válida"
        />
      </div>
      <div className="col-12 col-md-4 col-lg-3 mt-2 mt-md-0">
        <select
          value={selectedTransactionType}
          onChange={({ target }) => setSelectedTransactionType(target.value)}
          id="transactionType"
          className="w-100 p-2"
        >
          <option value="add">Abonar</option>
          <option value="remove">Cargar</option>
          <option value="update">Fijar valor</option>
        </select>
      </div>
      <div className="col-12 col-md-4 col-lg-3 mt-2 mt-md-0">
        <button
          onClick={updateBalance}
          disabled={isSaving || isLoading || !isValidBalanceUpdateAmount}
          type="button"
          className="bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
        >
          {isSaving ? 'Actualizando' : 'Actualizar'}
          <BounceLoader color="#fff" loading={isSaving} size="18" />
        </button>
      </div>
      <div className="col-12 mt-4">
        <Card>
          {transactions ? (
            <Table
              showFilters
              showDateFilter
              columns={table.columns}
              data={transactions}
              options={table.options}
              pageSize={10}
            />
          ) : (
            <div
              className="p-5 m-5 d-flex justify-content-center align-items-center"
              style={{
                flexGrow: '1',
              }}
            >
              <ClimbingBoxLoader color={colors.green} size="25" />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}

export default UserBalance;
