import React, { useEffect, useState } from 'react';

import ReactTooltip from 'react-tooltip';
import api from 'utils/api';
import { formatPrice } from 'utils/misc';
import { getOrderCode } from 'utils/misc';
import { parseDate } from 'utils/misc';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';

function RecurringPayments() {
  const tableProps = {
    rowId: 'id',
    baseURL: '/invoicing',
    baseFilter: {
      include: ['vouchers','store'],
      order: [['createdAt', 'desc']],
    },
    pageSize: 10,
    columns: [
      {
        title: '',
        objectProperty: 'active',
        middleware: (value) => (
          <i
            className={`material-icons ${
              value ? 'text-success' : 'text-danger'
            }`}
          >
            {value ? 'check_circle' : 'cancel'}
          </i>
        ),
      },
      {
        title: 'ID',
        objectProperty: 'serial',
        sortable: true,
      },
      {
        title: 'Código',
        objectProperty: 'serial',
        searchable: true,
        searchType: 'number-equal',
        sortable: true,
        middleware: (value) => <p>{`RPTY-${getOrderCode(value)}`}</p>,
        searchMiddleware: (searchTerm) =>
          parseInt(searchTerm.replace(/RPTY-/, ''), 16) / 64,
      },
      {
        title: 'Cantidad',
        objectProperty: 'amount',
        searchable: true,
        searchType: 'number',
        middleware: (value) => <p>{formatPrice(value)}</p>,
      },
      {
        title: 'Concepto',
        objectProperty: 'title',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
      {
        title: 'Descripción',
        objectProperty: 'description',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
      {
        title: 'Teléfono',
        objectProperty: 'phone',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Día de cobro',
        objectProperty: 'paymentDay',
        sortable: true,
      },
      {
        title: 'Nombre',
        objectProperty: 'firstname',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Apellido',
        objectProperty: 'lastname',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Email',
        objectProperty: 'email',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Recurrencia',
        objectProperty: 'recurrence',
        middleware: (value) => <p>{JSON.stringify(value)}</p>,
      },
      {
        title: 'Comercio',
        objectProperty: 'store',
        middleware: (value) => <p>{value?.name || 'N/A'}</p>,
      },
      {
        title: 'Último de cobro',
        objectProperty: 'lastDate',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (createdAt) => parseDate(createdAt),
      },
    ],
  };

  // Set title
  useEffect(() => {
    document.title = `Pagos recurrentes | ${process.env.REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content d-flex flex-column" style={{ flexGrow: 1 }}>
      <div className="row">
        <div className="col-12 row d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold col-12 col-md-7 col-lg-8">
            Pagos recurrentes
          </h3>
        </div>
      </div>
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          <div className="pt-md-2">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecurringPayments;
