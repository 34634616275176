import React, { useState, useRef, useEffect } from 'react';
import Datetime from 'react-datetime';
import * as moment from 'moment';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import BounceLoader from 'react-spinners/BounceLoader';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import Card from '../../components/Card';

import { validPrice, validQty, validCouponCode } from '../../utils/misc';
import api from '../../utils/api';
import colors from '../../assets/resources/colors';
import TagSelector from 'components/TagSelector';
import TagSelectorModalButton from 'components/TagSelectorModalButton';
import { TagSelectorModalType } from 'components/TagSelectorModal';
import { TagSelectorModal } from 'components/TagSelectorModal';

const TYPES = {
  TOTAL: { key: 'TOTAL', text: 'Total' },
  PRODUCTS: { key: 'PRODUCTS', text: 'Productos' },
  SHIPPING: { key: 'SHIPPING', text: 'Envío' },
  CUSTOM_SHIPPING: { key: 'CUSTOM_SHIPPING', text: 'Envío personalizado' },
};

const PAYMENT_METHODS = {
  BOTH: { key: 'BOTH', text: 'Ambos' },
  CARD: { key: 'CARD', text: 'Tarjeta' },
  CASH: { key: 'CASH', text: 'Efectivo' },
  TIGO_MONEY: { key: 'TIGO_MONEY', text: 'Tigo Money' },
};

function NewCoupon() {
  const formRef = useRef(null);
  const history = useHistory();

  /* URL params */
  const { id: couponId } = useParams();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  useEffect(() => {
    document.title = `${
      couponId ? 'Editar' : 'Nuevo'
    } cupón | ${REACT_APP_TITLE}`;
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [coupon, setCoupon] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({
    stores: [],
    categories: [],
    subcategories: [],
    products: [],
  });

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    items: [],
    initialItems: [],
    type: null,
    onSubmit: () => {},
  });

  useEffect(() => {
    couponId &&
      api
        .findOne('/coupons', couponId)
        .then((res) => {
          setCoupon(res.data);
        })
        .catch((error) => {
          toast.warning('No se pudo obtener info. de cupón');
          console.error(error);
        });
  }, []);

  useEffect(() => {
    if (coupon) initializeIds();
  }, [coupon]);

  const initializeIds = () => {
    const { stores, categories, subcategories, products } = coupon;

    let initial = {
      stores: [],
      categories: [],
      subcategories: [],
      products: [],
    };

    Promise.allSettled([
      api.findAll(
        `/stores?filter=${JSON.stringify({
          attributes: ['storesId', 'name'],
          order: [['name', 'asc']],
          where: {
            active: true,
            storesId: {
              $in: stores,
            },
          },
        })}`
      ),
      api.findAll(
        `/categories?filter=${JSON.stringify({
          attributes: ['productsCategoriesId', 'name'],
          order: [['name', 'asc']],
          where: {
            productsCategoriesId: {
              $in: categories,
            },
          },
        })}`
      ),
      api.findAll(
        `/subcategories?filter=${JSON.stringify({
          attributes: ['productsSubcategoriesId', 'name'],
          order: [['name', 'asc']],
          where: {
            productsSubcategoriesId: {
              $in: subcategories,
            },
          },
        })}`
      ),
      api.findAll(
        `/products?filter=${JSON.stringify({
          attributes: ['productsId', 'name'],
          order: [['name', 'asc']],
          where: {
            productsId: {
              $in: products,
            },
          },
        })}`
      ),
    ])
      .then(
        ([
          storesResponse,
          categoriesResponse,
          subcategoriesResponse,
          productsResponse,
        ]) => {
          if (storesResponse.status === 'fulfilled') {
            const res = storesResponse?.value?.data?.map((store) => ({
              id: store.storesId,
              value: store.name,
            }));

            initial.stores = res;
          }

          if (categoriesResponse.status === 'fulfilled') {
            const res = categoriesResponse?.value?.data?.map((category) => ({
              id: category.productsCategoriesId,
              value: category.name,
            }));

            initial.categories = res;
          }

          if (subcategoriesResponse.status === 'fulfilled') {
            const res = subcategoriesResponse?.value?.data?.map(
              (subcategory) => ({
                id: subcategory.productsSubcategoriesId,
                value: subcategory.name,
              })
            );

            initial.subcategories = res;
          }

          if (productsResponse.status === 'fulfilled') {
            const res = productsResponse?.value?.data?.map((product) => ({
              id: product.productsId,
              value: product.name,
            }));

            initial.products = res;
          }
        }
      )
      .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`))
      .finally(() => setInitialValues(initial));
  };

  async function save(formValues) {
    setIsSaving(true);
    const {
      couponCode,
      type,
      paymentMethod,
      value,
      minCost,
      maxDiscount,
      remainingUses,
      startline,
      deadline,
      active,
      onlyFirstOrder,
      usesPerUser,
      stores,
      categories,
      subcategories,
      products,
      maxQty,
    } = formValues;

    const existentCoupon = (
      await api.findAll(
        '/coupons?filter=' +
          JSON.stringify({
            where: {
              couponCode,
            },
          })
      )
    ).data[0];

    if (existentCoupon && existentCoupon.couponsId !== parseInt(couponId)) {
      toast.error('Ya existe un cupón con ese código');
      setIsSaving(false);
    } else {
      const body = {
        couponCode,
        type,
        paymentMethod,
        value,
        maxQty: value > 0 && value <= 1 ? maxQty : 0,
        minCost: minCost ? minCost : 0,
        maxDiscount: maxDiscount ? maxDiscount : 0,
        stores: stores.map((store) => store.id),
        categories: categories.map((category) => category.id),
        subcategories: subcategories.map((subcategory) => subcategory.id),
        products: products.map((product) => product.id),
        remainingUses: isNaN(parseInt(remainingUses))
          ? -1
          : parseInt(remainingUses),
        active: active ? 1 : 0,
        onlyFirstOrder,
        startline:
          !startline?.trim() || startline === 'Invalid date' ? null : startline,
        deadline:
          !deadline?.trim() || deadline === 'Invalid date' ? null : deadline,
        usesPerUser: isNaN(parseInt(usesPerUser)) ? -1 : parseInt(usesPerUser),
      };

      body[couponId ? 'updatedBy' : 'createdBy'] =
        cookies[REACT_APP_COOKIES_USER_ID];
      const action = couponId
        ? api.update('/coupons', couponId, body)
        : api.create('/coupons', body);

      action
        .then(() => {
          toast.success(`Cupón ${couponId ? 'actualizado' : 'creado'}`);
          history.push('/admin/coupons');
        })
        .catch((error) => {
          toast.warning(
            `No se pudo ${couponId ? 'actualizar' : 'crear'} el cupón`
          );
          console.error(error);
        })
        .finally(() => setIsSaving(false));
    }
  }

  return (
    <div className="content">
      <TagSelectorModal
        {...modalInfo}
        onClose={() => {
          setModalInfo({
            isOpen: false,
            items: [],
          });
        }}
      />
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center text-md-left w-100">
            {couponId ? 'Editar' : 'Nuevo'} cupón
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <button
            disabled={isSaving}
            onClick={handleSubmit}
            type="button"
            className="bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
          >
            {isSaving ? 'Guardando' : 'Guardar'}
            <BounceLoader color="#fff" loading={isSaving} size="18" />
          </button>
        </div>
      </div>
      <Card className="p-4 mt-3 mt-md-4">
        {(couponId && coupon) || !couponId ? (
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            validationSchema={Yup.object().shape({
              couponCode: Yup.string().trim().required('Campo requerido'),
              value: Yup.string().trim().required('Campo requerido'),
              minCost: Yup.string().trim().required('Campo requerido'),
            })}
            initialValues={{
              couponCode: coupon ? coupon.couponCode : '',
              type: coupon ? coupon.type : TYPES.TOTAL.key,
              paymentMethod: coupon
                ? coupon.paymentMethod
                : PAYMENT_METHODS.BOTH.key,
              value: coupon ? coupon.value : '',
              minCost: coupon ? coupon.minCost : 0,
              maxDiscount: coupon ? coupon.maxDiscount : 0,
              remainingUses: coupon
                ? coupon.remainingUses !== -1
                  ? coupon.remainingUses
                  : ''
                : '',
              startline: coupon ? coupon.startline : '',
              deadline: coupon ? coupon.deadline : '',
              active: coupon ? coupon.active === 1 : false,
              onlyFirstOrder: coupon ? coupon.onlyFirstOrder : false,
              usesPerUser: coupon
                ? coupon.usesPerUser !== -1
                  ? coupon.usesPerUser
                  : ''
                : '',
              stores:
                coupon && coupon.stores?.length > 0 ? initialValues.stores : [],
              categories:
                coupon && coupon.categories?.length > 0
                  ? initialValues.categories
                  : [],
              subcategories:
                coupon && coupon.subcategories?.length > 0
                  ? initialValues.subcategories
                  : [],
              products:
                coupon && coupon.products?.length > 0
                  ? initialValues.products
                  : [],
              maxQty: coupon ? coupon.maxQty : 0,
            }}
            onSubmit={(values) => {
              save(values);
            }}
          >
            {() => (
              <Form>
                <div className="row">
                  <div className="col-12 col-md-4 mt-2">
                    <label htmlFor="couponCode" className="d-block">
                      Código
                    </label>
                    <Field name="couponCode">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            className="p-2 rounded w-100"
                            placeholder="ELEXITOESTUYO"
                            onChange={(event) => {
                              setFieldValue(
                                'couponCode',
                                validCouponCode(event.target.value)
                              );
                            }}
                          />
                          <ErrorMessage
                            name="couponCode"
                            component="span"
                            className="text-red"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="col-12 col-md-2 mt-2">
                    <label className="d-block" htmlFor="type">
                      Aplica a
                    </label>
                    <Field
                      as="select"
                      name="type"
                      className="p-2 rounded w-100"
                    >
                      {Object.entries(TYPES).map((type, index) => (
                        <option key={index} value={type[1].key}>
                          {type[1].text}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-12 col-md-2 mt-2">
                    <label className="d-block" htmlFor="paymentMethod">
                      Método de pago
                    </label>
                    <Field
                      as="select"
                      name="paymentMethod"
                      className="p-2 rounded w-100"
                    >
                      {Object.entries(PAYMENT_METHODS).map((method, index) => (
                        <option key={index} value={method[1].key}>
                          {method[1].text}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-12 col-md-2 d-flex flex-column justify-content-start align-items-center mt-2">
                    <label className="d-block" htmlFor="active">
                      Activo
                    </label>
                    <Field name="active" type="checkbox" id="active" />
                  </div>
                  <div className="col-12 col-md-2 d-flex flex-column justify-content-start align-items-center mt-2">
                    <label className="d-block" htmlFor="onlyFirstOrder">
                      Solo primera compra
                    </label>
                    <Field
                      type="checkbox"
                      name="onlyFirstOrder"
                      id="onlyFirstOrder"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="value" className="d-block">
                      Valor
                    </label>
                    <Field name="value">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            className="p-2 rounded w-100"
                            placeholder="15"
                            onChange={(event) => {
                              setFieldValue(
                                'value',
                                validPrice(event.target.value)
                              );
                            }}
                          />
                          <small className="font-style-italic text-gray">
                            Valores menores a 1 serán tomados como porcentaje
                          </small>
                          <ErrorMessage
                            name="value"
                            component="div"
                            className="text-red"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="minCost" className="d-block">
                      Gasto mínimo
                    </label>
                    <Field name="minCost">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            className="p-2 rounded w-100"
                            placeholder="25"
                            onChange={(event) => {
                              setFieldValue(
                                'minCost',
                                validPrice(event.target.value)
                              );
                            }}
                          />
                          <ErrorMessage
                            name="minCost"
                            component="span"
                            className="text-red"
                          />
                        </>
                      )}
                    </Field>
                    <small className="font-style-italic text-gray">
                      Campo no requerido, si no se especifica, el cupón no
                      aplica al gasto mínimo
                    </small>
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="maxDiscount" className="d-block">
                      Gasto maximo
                    </label>
                    <Field name="maxDiscount">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            className="p-2 rounded w-100"
                            placeholder="25"
                            onChange={(event) => {
                              setFieldValue(
                                'maxDiscount',
                                validPrice(event.target.value)
                              );
                            }}
                          />
                          <small className="font-style-italic text-gray">
                            Campo no requerido, si no se especifica, el cupón no
                            tendrá limite
                          </small>
                          <ErrorMessage
                            name="maxDiscount"
                            component="span"
                            className="text-red"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="maxQty" className="d-block">
                      Limite de productos
                    </label>
                    <Field name="maxQty">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            className="p-2 rounded w-100"
                            placeholder="25"
                            onChange={(event) => {
                              setFieldValue(
                                'maxQty',
                                validPrice(event.target.value)
                              );
                            }}
                          />
                          <ErrorMessage
                            name="maxQty"
                            component="span"
                            className="text-red"
                          />
                        </>
                      )}
                    </Field>
                    <small className="font-style-italic text-gray">
                      Campo no requerido si el descuento se aplica sin
                      restricciones de cantidad de productos
                    </small>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <label className="d-block" htmlFor="startline">
                      Fecha inicial
                    </label>
                    <Field name="startline">
                      {({ field, form: { setFieldValue } }) => (
                        <Datetime
                          value={moment(field.value)}
                          onChange={(data) =>
                            setFieldValue('startline', moment(data).toString())
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="startline"
                      component="span"
                      className="text-red"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label className="d-block" htmlFor="deadline">
                      Fecha final
                    </label>
                    <Field name="deadline">
                      {({ field, form: { setFieldValue } }) => (
                        <Datetime
                          value={moment(field.value)}
                          onChange={(data) =>
                            setFieldValue('deadline', moment(data).toString())
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="deadline"
                      component="span"
                      className="text-red"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="remainingUses" className="d-block">
                      Cantidad de usos
                    </label>
                    <Field name="remainingUses">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            className="p-2 rounded w-100"
                            placeholder="300"
                            onChange={(event) => {
                              setFieldValue(
                                'remainingUses',
                                validQty(event.target.value)
                              );
                            }}
                          />
                          <small className="font-style-italic text-gray">
                            Campo no requerido si el cupón tiene usos ilimitados
                          </small>
                          <ErrorMessage
                            name="remainingUses"
                            component="div"
                            className="text-red"
                          />
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="usesPerUser" className="d-block">
                      Limite por usuario
                    </label>
                    <Field name="usesPerUser">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <input
                            {...field}
                            type="text"
                            className="p-2 rounded w-100"
                            placeholder="5"
                            onChange={(event) => {
                              setFieldValue(
                                'usesPerUser',
                                validQty(event.target.value)
                              );
                            }}
                          />
                          <small className="font-style-italic text-gray">
                            Campo no requerido si el cupón tiene usos ilimitados
                            por usuario
                          </small>
                          <ErrorMessage
                            name="usesPerUser"
                            component="div"
                            className="text-red"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <h3 className="text-dark-blue font-size-x font-weight-bold text-center my-4 w-100">
                    Limitar cupón
                  </h3>

                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="categories" className="d-block">
                      Categorías
                    </label>
                    <Field name="categories">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <TagSelectorModalButton
                            title={
                              field?.value?.length > 0
                                ? 'Editar categorías'
                                : 'Agregar categorías'
                            }
                            tags={field.value}
                            onClick={() => {
                              setModalInfo({
                                title: 'Agregar categorías',
                                initialItems: field.value || [],
                                onSubmit: (data) =>
                                  setFieldValue('categories', data),
                                isOpen: true,
                                type: TagSelectorModalType.CATEGORIES,
                              });
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="subcategories" className="d-block">
                      Subcategorías
                    </label>
                    <Field name="subcategories">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <TagSelectorModalButton
                            title={
                              field?.value?.length > 0
                                ? 'Editar subcategorías'
                                : 'Agregar subcategorías'
                            }
                            tags={field.value}
                            onClick={() => {
                              setModalInfo({
                                title: 'Agregar subcategorías',
                                initialItems: field.value || [],
                                onSubmit: (data) =>
                                  setFieldValue('subcategories', data),
                                isOpen: true,
                                type: TagSelectorModalType.SUBCATEGORIES,
                              });
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="stores" className="d-block">
                      Tiendas
                    </label>
                    <Field name="stores">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <TagSelectorModalButton
                            title={
                              field?.value?.length > 0
                                ? 'Editar tiendas'
                                : 'Agregar tiendas'
                            }
                            tags={field.value}
                            onClick={() => {
                              setModalInfo({
                                title: 'Agregar tiendas',
                                initialItems: field.value || [],
                                onSubmit: (data) =>
                                  setFieldValue('stores', data),
                                isOpen: true,
                                type: TagSelectorModalType.STORES,
                              });
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <label htmlFor="products" className="d-block">
                      Productos
                    </label>
                    <Field name="products">
                      {({ field, form: { setFieldValue } }) => (
                        <>
                          <TagSelectorModalButton
                            title={
                              field?.value?.length > 0
                                ? 'Editar productos'
                                : 'Agregar productos'
                            }
                            tags={field.value}
                            onClick={() => {
                              setModalInfo({
                                title: 'Agregar productos',
                                initialItems: field.value || [],
                                onSubmit: (data) =>
                                  setFieldValue('products', data),
                                isOpen: true,
                                type: TagSelectorModalType.PRODUCTS,
                              });
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </Card>
      <div
        style={{
          margin: '10rem 0',
        }}
      />
    </div>
  );
}

export default NewCoupon;
