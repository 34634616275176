import React from 'react';
import { useEffect, useRef, useState } from 'react';

import { formatPrice } from 'utils/misc';
import { getOrderCode } from 'utils/misc';
import * as htmlToImage from 'html-to-image';
import { BounceLoader } from 'react-spinners';

function OrderInvoice({ order, className, isLoading, onClose, paymentMethod }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [stores, setStores] = useState([]);
  const refModal = useRef(null);

  const amounts = [
    {
      label: 'Método de pago',
      value: paymentMethod?.title ?? '',
      skip: true,
    },
    {
      label: 'Subtotal',
      value: order?.details?.reduce((acc, detail) => {
        const { price, qty, discount } = detail;
        const discountPrice = price * ((parseInt(discount) || 0) / 100);

        return acc + (price - discountPrice) * qty;
      }, 0),
    },
    {
      label: 'Mese(s)',
      value: order?.months,
    },
    {
      label: 'Costo de envío',
      value: order?.freeShipping ? 0 : order?.shipping,
    },
    {
      label: 'Tarifa de servicios',
      value: order?.bookingFee,
    },
    {
      label: 'Cupón',
      value: order?.coupon?.split('|')?.[1],
      prefix: '-',
    },
    {
      label: 'Balance',
      value: order?.balance,
      prefix: '-',
    },
    {
      label: 'Millas',
      value: order?.millas,
      prefix: '-',
    },
    {
      isTotal: true,
      label: 'Monto total',
      value: order?.total,
    },
  ];

  useEffect(() => {
    const _stores = order?.branchs?.map((el) => el?.storeName) || [];
    setStores([...new Set(_stores)]);
  }, [order]);

  function printInvoice() {
    if (refModal?.current) {
      setIsDownloading(true);

      setTimeout(() => {
        htmlToImage
          .toPng(refModal?.current, {
            width: refModal?.current?.offsetWidth,
            height: refModal?.current?.offsetHeight,
          })
          .then((dataUrl) => {
            setIsDownloading(false);
            const link = document.createElement('a');
            link.download = `PTY-${getOrderCode(order?.paymentVouchersId)}.png`;
            link.href = dataUrl;
            link.click();
          });
      }, 100);
    }
  }

  return (
    <div
      ref={refModal}
      className={`position-relative bg-white p-4 ${className}`}
    >
      <img
        src="/images/invoice-textures.svg"
        className="position-absolute top-0 left-0 z-0 w-1/4"
        alt=""
      />
      <img
        style={{
          maxHeight: '800px',
        }}
        src="/images/invoice-tuyo.svg"
        className="position-absolute bottom-0 right-0 top-0 z-0 h-full"
        alt=""
      />
      <div className="w-full h-full z-10 position-relative flex flex-col justify-center">
        <span className="material-icons-round text-green text-center text-7xl">
          check_circle
        </span>
        <p className="text-indigo-dark text-center font-extrabold text-3xl py-2">
          Comprobante de compra
        </p>
        <p className="text-indigo-dark text-center font-bold text-xl">
          Gracias por usar Tuyo.
        </p>

        <p className="text-indigo-dark text-center font-normal text-lg">
          A continuación te compartimos los detalles de tu compra:
        </p>

        <hr className="mt-4" style={{ borderTop: '1px dashed lightgrey' }} />

        <div className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center">
          <span className="font-light text-indigo-dark mr-8">Orden</span>
          <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right">
            {`TY-${getOrderCode(order?.ordersId)}`}
          </span>
        </div>

        <div className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center">
          <span className="font-light text-indigo-dark">
            {stores.length > 1 ? 'Comercios' : 'Comercio'}
          </span>
          <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right">
            {stores.join('\n ')}
          </span>
        </div>

        {order?.user?.firstname && order?.user?.lastname && (
          <div className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center">
            <span className="font-light text-indigo-dark mr-8">Nombre</span>
            <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right">
              {`${order?.user?.firstname} ${order?.user?.lastname}`}
            </span>
          </div>
        )}

        {order?.driver?.firstname && order?.driver?.lastname && (
          <div className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center">
            <span className="font-light text-indigo-dark mr-8">Conductor</span>
            <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right">
              {`${order?.driver?.firstname} ${order?.driver?.lastname}`}
            </span>
          </div>
        )}

        {order?.dui && (
          <div className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center">
            <span className="font-light text-indigo-dark mr-8">Dui</span>
            <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right">
              {order?.dui}
            </span>
          </div>
        )}

        {order?.signature && (
          <div className="mt-2 d-flex flex-column justify-content-between whitespace-pre-wrap text-center">
            <span className="font-light text-indigo-dark mr-8">Firma</span>
            <img
              className="border rounded"
              src={order?.signature}
              alt="signature"
            />
          </div>
        )}

        <hr className="mt-4" style={{ borderTop: '1px dashed lightgrey' }} />

        <div className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center">
          <span className="font-light text-indigo-dark mr-8">Detalle</span>
        </div>

        {order?.details?.map((el, index) => (
          <div
            key={index}
            className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center"
          >
            <span className="font-light text-indigo-dark text-left mr-8">
              {el?.name?.length > 20
                ? `${el?.name?.substring(0, 20)}...`
                : el?.name}
            </span>
            <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right flex-shrink-0">
              {`${formatPrice(el?.price)} x ${el?.qty}`}
            </span>
          </div>
        ))}

        <hr className="mt-4 mb-2" />

        <div className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center">
          <span className="font-light text-indigo-dark mr-8">
            Resumen de cobro
          </span>
        </div>

        {amounts
          ?.filter((el) => +el?.value > 0 || el?.skip || el?.isTotal)
          ?.map((el, index) => (
            <div
              className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center"
              key={index}
            >
              <span
                className={`text-indigo-dark mr-8 ${
                  el?.isTotal ? 'font-extrabold text-xl' : 'font-light'
                }`}
              >
                {el?.label}
              </span>
              <span
                className={`whitespace-pre-wrap text-right ${
                  el?.isTotal ? 'font-extrabold text-xl' : 'font-bold'
                } ${el?.prefix === '-' ? 'text-red' : 'text-indigo-dark'}`}
              >
                {el?.prefix || ''}
                {!el?.skip ? formatPrice(el?.value) : el?.value}
              </span>
            </div>
          ))}

        {!isDownloading && (
          <div>
            <button
              type="button"
              disabled={isLoading || isDownloading}
              className="rounded-2xl bg-green transitionable w-full py-3 px-4 font-extrabold text-white text-lg d-flex justify-content-center justify-items-items-center mt-4"
              onClick={printInvoice}
            >
              <span className="material-icons-round">save_alt</span>
              <p className="mx-2">Descargar comprobante</p>
              {isLoading ? <BounceLoader className="text-grey" /> : ''}
            </button>
            <button
              type="button"
              disabled={isLoading || isDownloading}
              className="rounded-2xl pale-grey-button transitionable w-full py-3 px-4 font-extrabold text-indigo-dark text-lg flex justify-center mt-2"
              onClick={onClose}
            >
              Cerrar
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderInvoice;
