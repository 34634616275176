import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { toast } from 'react-toastify';
import download from 'downloadjs';

import Card from '../../components/Card';
import Table from '../../components/Table/Table';
import DaySummary from '../../components/drivers/day-summary';

import api from '../../utils/api';
import { formatTimestamp } from '../../utils/misc';

import addSVG from '../../assets/icons/add.svg';
import { useCookies } from 'react-cookie';
import swal from 'sweetalert';

function DriversList() {
  const { REACT_APP_TITLE, REACT_APP_COOKIES_USER_ID } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userId = cookies[REACT_APP_COOKIES_USER_ID];

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  useEffect(() => {
    document.title = `Conductores | ${REACT_APP_TITLE}`;
  }, []);

  const history = useHistory();

  const driversTable = {
    options: {
      id: 'usersId',
    },
    columns: [
      { text: '', key: 'photo', isImage: true, className: 'text-center' },
      { text: 'Usuario', key: 'username', className: 'text-center' },
      { text: 'Nombre', key: 'firstname', className: 'text-center' },
      { text: 'Apellido', key: 'lastname', className: 'text-center' },
      { text: 'Estado', key: 'status', className: 'text-center' },
      { text: 'Fecha', key: 'createdAtDisplay', className: 'text-center' },
      {
        text: 'Acciones',
        isCustom: true,
        render: (_, rowId) => {
          return (
            <div className="d-flex justify-content-center">
              <button
                className="mx-2"
                onClick={(evt) => {
                  evt.stopPropagation();
                  restorePassword(rowId);
                }}
              >
                <span className="material-icons">lock_reset</span>
              </button>
              <button
                className="mx-2"
                onClick={(evt) => {
                  evt.stopPropagation();
                  onDelete(rowId);
                }}
              >
                <span className="material-icons">delete</span>
              </button>
            </div>
          );
        },
      },
    ],
  };

  const [drivers, setDrivers] = useState();
  const [getDrivers, setGetDrivers] = useState(1);

  const onDelete = (id) => {
    api.drivers.documents
      .getByUser(id)
      .then((res) => {
        if (res.data.length > 0) {
          return api.drivers.documents.deleteOne(res.data[0].usersDocumentsId);
        }
      })
      .then(() => api.drivers.vehicles.getByUser(id))
      .then((res) => {
        if (res.data.length > 0) {
          return api.drivers.vehicles.deleteOne(res.data[0].usersVehiclesId);
        }
      })
      .then(() => api.drivers.deleteOne(id))
      .then(() => {
        setGetDrivers(getDrivers + 1);
        toast.success('¡Conductor eliminado!');
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
        console.error(err);
      });
  };

  const restorePassword = (usersId) => {
    swal({
      buttons: ['Cancelar', 'Restaurar'],
      title: 'Cambiar contraseña',
      content: {
        element: 'input',
        attributes: {
          type: 'password',
        },
      },
    })
      .then((newPassword) => {
        if (newPassword) {
          api.users
            .updateOne(usersId, {
              password: newPassword,
              updatedBy: userId,
            })
            .then(() => toast.success('Contraseña actualizada'));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /* Get products */
  useEffect(() => {
    api.drivers
      .getAll()
      .then((res) => {
        const drivers = res.data.map((driver) => ({
          ...driver,
          name: `${driver.firstname} ${driver.lastname}`,
          vehicleType: driver.vehicle?.type,
          status: parseInt(driver.active) ? 'Activo' : 'Inactivo',
          createdAtDisplay: formatTimestamp(driver.createdAt),
          finished: 0,
          collectedCash: 0,
        }));

        setDrivers(drivers);
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
        console.error(err);
      });
  }, [getDrivers]);

  function generateDriversSettlementReport() {
    setIsGeneratingReport(true);

    api
      .create(
        '/drivers/settlement/report',
        {
          startDate,
          endDate,
        },
        { responseType: 'blob' }
      )
      .then((response) => {
        download(
          response.data,
          `DriversSettlementReport - ${startDate} to ${endDate}.csv`,
          response.headers['content-type']
        );
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo generar el reporte');
      })
      .finally(() => {
        setIsGeneratingReport(false);
      });
  }

  return (
    <div className="content">
      <DaySummary />
      <div className="row mt-4">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Conductores
          </h3>
        </div>
        <div className="col-12 mt-3">
          <div className="row">
            <div className="col-6 col-md-3">
              <input
                className="rounded p-2 w-100 h-100"
                type="date"
                onChange={(event) => {
                  setStartDate(event.target.value);
                }}
              />
            </div>
            <div className="col-6 col-md-3">
              <input
                className="rounded p-2 w-100 h-100"
                type="date"
                onChange={(event) => {
                  setEndDate(event.target.value);
                }}
              />
            </div>
            <div className="col-12 col-md-6 mt-3 mt-md-0">
              <button
                className="tuyo-btn px-4 py-2 bg-purple rounded text-light font-weight-bold w-100 h-100"
                disabled={!startDate || !endDate || isGeneratingReport}
                onClick={generateDriversSettlementReport}
              >
                {isGeneratingReport
                  ? 'Generando...'
                  : 'Generar reporte de liquidación'}
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end mt-3">
          <Link to="/admin/drivers/new" className="w-100">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold"
            >
              <img
                src={addSVG}
                alt=""
                className="d-inline-block mr-2"
                style={{ height: '16px' }}
              />
              Agregar nuevo
            </button>
          </Link>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card>
            {drivers ? (
              <Table
                onRowClick={(id) => history.push(`/admin/drivers/${id}/edit`)}
                columns={driversTable.columns}
                data={drivers}
                options={driversTable.options}
                pageSize={10}
              />
            ) : (
              <div
                className="p-5 m-5 d-flex justify-content-center align-items-center"
                style={{ flexGrow: '1' }}
              >
                <ClimbingBoxLoader color="#01D781" size="25" />
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default DriversList;
