import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BounceLoader } from 'react-spinners';

import Card from '../components/Card';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';
import { parseDate } from 'utils/misc';
import api from '../utils/api';

function PaymentDailyLiquidations() {
  const { REACT_APP_TITLE } = process.env;
  const [isDownloading, setIsDownloading] = useState('');

  const downloadPdf = (report) => {
    setIsDownloading(report);
    api.liquidationReports
      .getOne(report)
      .then((res) => {
        const win = window.open(URL.createObjectURL(res.data), '_blank');
        win.focus();
        setIsDownloading('');
      })
      .catch((err) => {
        toast.warning(`[ERROR] No se pudo descargar el reporte`);
        console.error(err);
        setIsDownloading('');
      });
  };

  const tableProps = {
    rowId: 'paymentReportsId',
    baseURL: '/paymentreports',
    baseFilter: {
      order: [['createdAt', 'desc']],
      attributes: ['paymentReportsId', 'data', 'store', 'email', 'createdAt'],
    },
    pageSize: 10,
    columns: [
      {
        title: 'Transacciones',
        objectProperty: 'data',
        searchable: false,
        middleware: (value) => {
          const x = JSON.parse(value);
          return x?.transactions?.total?.qty;
        },
      },
      {
        title: 'Completadas',
        objectProperty: 'data',
        searchable: false,
        middleware: (value) => {
          const x = JSON.parse(value);
          return x?.transactions?.completed?.qty;
        },
      },
      {
        title: 'Error',
        objectProperty: 'data',
        searchable: false,
        middleware: (value) => {
          const x = JSON.parse(value);
          return x?.transactions?.errored?.qty;
        },
      },
      {
        title: 'Alerta Fraude',
        objectProperty: 'data',
        searchable: false,
        middleware: (value) => {
          const x = JSON.parse(value);
          return x?.transactions?.fraud?.qty;
        },
      },
      {
        title: 'Pago Comercio',
        objectProperty: 'data',
        searchable: false,
        middleware: (value) => {
          const x = JSON.parse(value);
          return `$ ${x?.grandTotal}`;
        },
      },
      {
        title: 'Comisión',
        objectProperty: 'data',
        searchable: false,
        middleware: (value) => {
          const x = JSON.parse(value);
          return `$ ${x?.ivaPlusComission}`;
        },
      },
      {
        title: 'Nombre de Comercio',
        objectProperty: 'store',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
      {
        title: 'Dirección de envío',
        objectProperty: 'email',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
      {
        title: 'Fecha de creación',
        objectProperty: 'createdAt',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (createdAt) => parseDate(createdAt),
      },
      {
        title: 'Acción',
        middleware: (report) => (
          <div>
            <button
              onClick={() => downloadPdf(report?.paymentReportsId)}
              disabled={isDownloading === report?.paymentReportsId}
              className="bg-orange tuyo-btn text-sm py-1 px-1.5 rounded text-white font-weight-bold m-1 w-full flex justify-around align-items-center"
            >
              {isDownloading === report?.paymentReportsId ? (
                <BounceLoader className="text-grey" size={15} color="white" />
              ) : (
                ''
              )}
              {isDownloading === report?.paymentReportsId
                ? ' Descargando'
                : 'Descargar'}
            </button>
          </div>
        ),
      },
    ],
  };

  useEffect(() => {
    document.title = `Reportes de Liquidaciones Diarias | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Reporte de Liquidaciones Diarias
          </h3>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PaymentDailyLiquidations;
