import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import api from 'utils/api';

import Pagination from './Pagination';

function Table(props) {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [filterWhere, setFilterWhere] = useState(props.baseFilter.where || {});
  const [filterOrder, setFilterOrder] = useState(props.baseFilter.order);

  useEffect(() => {
    if (props.remountCount > 0) {
      setFilterWhere({ ...filterWhere });
    }
  }, [props.remountCount]);

  // Request data for each page
  useEffect(() => {
    setRows(null);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    api
      .findAll(
        props.baseURL +
          '?filter=' +
          JSON.stringify({
            ...props.baseFilter,
            limit: props.pageSize,
            offset: page * props.pageSize - props.pageSize,
            where: filterWhere,
            order: filterOrder,
          }),
        {
          cancelToken: source.token,
        }
      )
      .then((response) => {
        setRows(response.data);
      })
      .catch((error) => {
        if (error.message !== 'Not necessary anymore') {
          console.error(error);
          toast.warning('No se pudo obtener la página');
        }
      });

    return () => {
      source.cancel('Not necessary anymore');
    };
  }, [page, filterWhere, filterOrder]);

  // Get count of results when search change
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    api
      .findAll(
        '/count' +
          props.baseURL +
          '?filter=' +
          JSON.stringify({
            where: filterWhere,
            include: props.baseFilter?.include,
          }),
        {
          cancelToken: source.token,
        }
      )
      .then((response) => {
        setTotalRows(response.data.count);
      })
      .catch((error) => {
        if (error.message !== 'Not necessary anymore') {
          console.error(error);
          toast.warning('No se pudo obtener el conteo de productos');
        }
      });

    return () => {
      source.cancel('Not necessary anymore');
    };
  }, [filterWhere]);

  // Go to first page when search or sort change
  useEffect(() => {
    setPage(1);
  }, [filterWhere, filterOrder]);

  // Calculate totalPages based on totalRows
  useEffect(() => {
    setTotalPages(Math.ceil(totalRows / props.pageSize));
  }, [totalRows]);

  function updateWhereText(field, newValue, isNumber) {
    const currentValue = filterWhere[field];

    if (newValue) {
      if (
        !currentValue ||
        (isNumber ? newValue : encodeURI('%' + newValue + '%')) !==
          (isNumber ? currentValue : currentValue['$iLike'])
      ) {
        setFilterWhere({
          ...filterWhere,
          [field]: isNumber
            ? newValue
            : {
                $iLike: encodeURI('%' + newValue + '%'),
              },
        });
      }
    } else {
      if (currentValue) {
        const tmpWhere = { ...filterWhere };
        delete tmpWhere[field];

        setFilterWhere(tmpWhere);
      }
    }
  }

  function updateWhereRange(field, newValue, operator, isDate) {
    if (newValue) {
      setFilterWhere({
        ...filterWhere,
        [field]: {
          ...filterWhere[field],
          [operator]: newValue + (isDate ? '-06:00' : ''),
        },
      });
    } else {
      const tmpWhere = { ...filterWhere };

      if (tmpWhere[field] && tmpWhere[field][operator]) {
        delete tmpWhere[field][operator];

        if (Object.keys(tmpWhere[field]).length < 1) {
          delete tmpWhere[field];
        }

        setFilterWhere(tmpWhere);
      }
    }
  }

  return (
    <div style={{ overflowX: 'scroll' }} className="w-100">
      <Pagination
        page={page}
        pageSize={props.pageSize}
        totalRows={totalRows}
        totalPages={totalPages}
        setPage={setPage}
      />
      <table style={{ fontSize: '0.875rem', minWidth: '100%' }}>
        <thead>
          <tr>
            {props.columns.map((column, index) => (
              <th
                key={`${column.objectProperty}-${index}`}
                className="px-2 pt-2"
                style={{ backgroundColor: '#fbf5f1' }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p className="flex-grow-1 text-center">{column.title}</p>
                  {column.sortable && (
                    <button
                      onClick={() => {
                        const [orderBy, orderMode] = filterOrder[0];
                        const newOrderBy =
                          column.gatewayObjectProperty || column.objectProperty;

                        if (orderBy === newOrderBy) {
                          if (orderMode === 'asc') {
                            setFilterOrder([[newOrderBy, 'desc']]);
                          } else {
                            setFilterOrder(props.baseFilter.order);
                          }
                        } else {
                          setFilterOrder([[newOrderBy, 'asc']]);
                        }
                      }}
                    >
                      {(column.gatewayObjectProperty ||
                        column.objectProperty) === filterOrder[0][0]
                        ? filterOrder[0][1] === 'asc'
                          ? '🔼'
                          : '🔽'
                        : '↕️'}
                    </button>
                  )}
                </div>
              </th>
            ))}
          </tr>
          <tr>
            {props.columns.map((column, index) =>
              column.searchable ? (
                <th
                  key={`${column.objectProperty}-${index}`}
                  className="pb-2 px-2"
                  style={{
                    backgroundColor: '#fbf5f1',
                  }}
                >
                  {column.searchType === 'text' ||
                  column.searchType === 'number-equal' ? (
                    <input
                      className="w-100"
                      type="text"
                      onKeyPress={(event) => {
                        const trimmedValue = event.target.value.trim();

                        if (event.key === 'Enter') {
                          updateWhereText(
                            column.gatewayObjectProperty ||
                              column.objectProperty,
                            column.searchMiddleware
                              ? column.searchMiddleware(trimmedValue)
                              : trimmedValue,
                            column.searchType === 'number-equal'
                          );
                        }
                      }}
                      onBlur={(event) => {
                        const trimmedValue = event.target.value.trim();

                        updateWhereText(
                          column.gatewayObjectProperty || column.objectProperty,
                          column.searchMiddleware
                            ? column.searchMiddleware(trimmedValue)
                            : trimmedValue,
                          column.searchType === 'number-equal'
                        );
                      }}
                    />
                  ) : column.searchType === 'timestamp' ||
                    column.searchType === 'number' ? (
                    <div
                      className={
                        column.searchType === 'number'
                          ? 'd-flex justify-content-center w-100'
                          : ''
                      }
                    >
                      <input
                        type={
                          column.searchType === 'timestamp'
                            ? 'datetime-local'
                            : 'number'
                        }
                        style={
                          column.searchType === 'number'
                            ? { width: '4rem' }
                            : {}
                        }
                        className={column.searchType === 'number' ? 'mr-2' : ''}
                        onChange={(event) => {
                          updateWhereRange(
                            column.objectProperty,
                            event.target.value,
                            '$gte',
                            column.searchType === 'timestamp'
                          );
                        }}
                      />
                      <input
                        type={
                          column.searchType === 'timestamp'
                            ? 'datetime-local'
                            : 'number'
                        }
                        style={
                          column.searchType === 'number'
                            ? { width: '4rem' }
                            : {}
                        }
                        onChange={(event) => {
                          updateWhereRange(
                            column.objectProperty,
                            event.target.value,
                            '$lte',
                            column.searchType === 'timestamp'
                          );
                        }}
                      />
                    </div>
                  ) : null}
                </th>
              ) : (
                <th
                  key={`${column.objectProperty}-${index}`}
                  style={{
                    backgroundColor: '#fbf5f1',
                  }}
                />
              )
            )}
          </tr>
        </thead>
        <tbody>
          {rows ? (
            rows.length > 0 ? (
              rows.map((row, index) => (
                <tr
                  key={row[props.rowId]}
                  style={{
                    backgroundColor: index % 2 !== 0 ? 'rgba(0,0,0,0.07)' : '',
                    cursor: props.onRowClick ? 'pointer' : '',
                  }}
                  onClick={() => {
                    if (props.onRowClick) {
                      props.onRowClick(row);
                    }
                  }}
                >
                  {props.columns.map((column, index) => (
                    <td
                      className="p-2"
                      key={`${column.objectProperty}-${index}`}
                      style={{
                        maxWidth: (1 / props.columns.length) * 100 + '%',
                        minWidth: '10px',
                      }}
                    >
                      {column.middleware ? (
                        column.middleware(
                          column.objectProperty
                            ? row[column.objectProperty]
                            : row
                        )
                      ) : column.columnType === 'check' ? (
                        <i
                          style={{
                            color: row[column.objectProperty]
                              ? 'var(--tuyo-green)'
                              : 'gray',
                          }}
                          className="material-icons"
                        >
                          verified
                        </i>
                      ) : column.columnType === 'boolean' ? (
                        <i
                          style={{
                            color: row[column.objectProperty]
                              ? 'var(--tuyo-green)'
                              : 'gray',
                          }}
                          className="material-icons"
                        >
                          check_circle
                        </i>
                      ) : column.objectProperty ? (
                        row[column.objectProperty]
                      ) : (
                        JSON.stringify(row)
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={props.columns.length}
                  className="p-2 text-center"
                  style={{
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                  }}
                >
                  No results
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td
                colSpan={props.columns.length}
                className="p-2 text-center"
                style={{
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                }}
              >
                Loading...
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        page={page}
        pageSize={props.pageSize}
        totalRows={totalRows}
        totalPages={totalPages}
        setPage={setPage}
      />
    </div>
  );
}

export default Table;
