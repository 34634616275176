import React, { useEffect } from 'react';

import Card from '../components/Card';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';
import TransactionModal from 'components/TransactionModal';
import { VOUCHER_ICONS } from 'utils/misc';
import { VOUCHER_STATUS } from 'utils/misc';
import { formatPrice } from 'utils/misc';
import { getOrderCode } from 'utils/misc';
import { parseDate } from 'utils/misc';
import swal from '@sweetalert/with-react';
import PayInvoice from 'components/PayInvoice';

function PaymentVoucherList() {
  const { REACT_APP_TITLE } = process.env;

  const tableProps = {
    rowId: 'paymentVouchersId',
    baseURL: '/paymentvouchers',
    baseFilter: {
      order: [['createdAt', 'desc']],
      include: [
        {
          association: 'transactions',
        },
      ],
    },
    pageSize: 10,
    columns: [
      {
        title: 'Estado',
        objectProperty: 'status',
        searchable: true,
        searchType: 'select',
        searchOptions: [
          {
            value: '',
            label: 'TODOS',
          },
          ...Object.keys(VOUCHER_STATUS).map((key) => ({
            value: key,
            label: VOUCHER_STATUS[key],
          })),
        ],
        middleware: (status) => (
          <div
            style={{
              backgroundColor: VOUCHER_ICONS[status].color,
              fontWeight: 600,
            }}
            className="rounded p-1 text-white text-center w-100"
          >
            {VOUCHER_STATUS[status] || ''}
          </div>
        ),
      },
      {
        title: 'ID',
        objectProperty: 'paymentVouchersId',
        sortable: true,
        searchable: true,
        searchType: 'number-equal',
      },
      {
        title: 'Codigo',
        objectProperty: 'paymentVouchersId',
        searchable: true,
        searchType: 'number-equal',
        sortable: true,
        middleware: (value) => `PTY-${getOrderCode(value)}`,
        searchMiddleware: (searchTerm) =>
          parseInt(searchTerm.replace(/PTY-/, ''), 16) / 64,
      },
      {
        title: 'Cantidad',
        objectProperty: 'amount',
        searchable: true,
        searchType: 'number',
        middleware: (value) => formatPrice(value),
      },
      {
        title: 'Concepto',
        objectProperty: 'concept',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
      {
        title: 'Tienda',
        objectProperty: 'store',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Telefono',
        objectProperty: 'phone',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Creacion',
        objectProperty: 'createdAt',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (createdAt) => parseDate(createdAt),
      },
      {
        title: 'Pagado',
        objectProperty: 'payedAt',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (payedAt) => (payedAt ? parseDate(payedAt) : ''),
      },
      {
        title: 'Nombre',
        objectProperty: 'firstname',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Apellido',
        objectProperty: 'lastname',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Correo',
        objectProperty: 'email',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Acción',
        middleware: (voucher) => (
          <div>
            <button
              onClick={() => {
                if (voucher?.status === 'COMPLETED') showVoucher(voucher);
                else
                  swal({
                    title: 'Pago no procesado',
                    text: 'El comprobante ligado a esta transacción no ha sido completado aún.',
                    icon: 'info',
                  });
              }}
              className="bg-turbo tuyo-btn text-sm py-1 px-1.5 rounded text-white font-weight-bold m-1 w-full"
            >
              Comprobante
            </button>
            <button
              onClick={() => {
                let tmpTransactions = voucher.transactions;

                tmpTransactions = tmpTransactions.map((transaction) => ({
                  ...transaction,
                  amount: +transaction.amount / 100,
                }));

                tmpTransactions.sort(
                  (a, b) => b.paymentMovementsId - a.paymentMovementsId
                );

                if (tmpTransactions?.length > 0) {
                  openModal(
                    `Comprobante PTY-${getOrderCode(voucher.paymentVouchersId)}`,
                    voucher?.concept,
                    tmpTransactions
                  );
                } else {
                  swal({
                    title: 'Sin transacciones',
                    text: 'Este comprobante no posee transacciones',
                    icon: 'info',
                  });
                }
              }}
              className="bg-dark-green tuyo-btn text-sm py-1 px-1.5 rounded text-white font-weight-bold m-1 w-full"
            >
              Transacciones
            </button>
          </div>
        ),
      },
    ],
  };

  const showVoucher = (voucher) => {
    swal({
      content: <PayInvoice voucher={voucher} onClose={() => swal.close()} />,
      buttons: false,
    });
  };

  const openModal = (title, subtitle, data) => {
    swal({
      content: (
        <TransactionModal
          title={title}
          subtitle={subtitle}
          data={data}
          onClose={() => swal.close()}
        />
      ),
      buttons: false,
    });
  };

  useEffect(() => {
    document.title = `Comprobantes | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Comprobantes
          </h3>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PaymentVoucherList;
