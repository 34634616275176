import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import BounceLoader from 'react-spinners/BounceLoader';

import Card from '../../components/Card';

import api from '../../utils/api';

import chevronLeftSVG from '../../assets/icons/chevron-left.svg';

const BOXES = {
  PEQUEÑA: 1,
  MEDIANA: 2,
  GRANDE: 3,
  XXL: 4,
};

function NewUrbano() {
  const formRef = useRef(null);
  const history = useHistory();

  /* URL params */
  const { id: urbanOrdersId } = useParams();

  const {
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_TITLE,
    REACT_APP_URBANO_SHIPPER_BRANCH_ID,
  } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  const [isSaving, setIsSaving] = useState(false);
  const [urbanoOrdes, setUrbanoOrders] = useState([]);
  const [urbanoOrder, setUrbanoOrder] = useState([]);
  const [orderId, setOrderId] = useState();
  const [totalToCollect, setTotalToCollect] = useState();

  useEffect(() => {
    document.title = `Nueva orden Urbano | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    if (urbanOrdersId) {
      api.urbano
        .getOne(urbanOrdersId)
        .then((res) => {
          setUrbanoOrder(res.data);
        })
        .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));
    }
  }, []);

  useEffect(() => {
    api.orders
      .urbanoOrders()
      .then((res) => {
        setUrbanoOrders(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (orderId) {
      const order = urbanoOrdes.find((order) => order.ordersId == orderId);
      setTotalToCollect(order.total);
    }
  }, [orderId]);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const createUrbanoOrder = (formValues) => {
    const { box, giftName } = formValues;

    const body = {
      boxTypeId: box,
      shipperBranchId: REACT_APP_URBANO_SHIPPER_BRANCH_ID, //Tuyo's office
      isXxl: box == 4 ? 1 : 0,
      isMultiple: 0,
      isGift: giftName ? 1 : 0,
      giftName: giftName,
      totalToCollect: totalToCollect ? totalToCollect : 0,
      fk_ordersId: orderId,
      createdBy: userID,
    };

    setIsSaving(true);
    api.urbano
      .create(body)
      .then((_) => {
        toast.success('Order de Urbano creada');
        history.push('/admin/urbano');
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
      })
      .finally((_) => setIsSaving(false));
  };

  const printSticker = () => {
    if (urbanOrdersId) {
      api.urbano
        .stickerUrl(urbanoOrder.shipmentId)
        .then((res) => {
          const win = window.open(res.data.data.stickers_url, '_blank');
          win.focus();
        })
        .catch((err) => {
          toast.warning(
            `[SERVER_ERROR] No se puso descargar el sticker de la orden`
          );
          console.error(err);
        });
    }
  };

  return (
    <div className="content">
      <div className="row">
        <div className="col-6 col-md-3 col-xl-2">
          <Link to="/admin/urbano">
            <button
              type="button"
              className="w-100 bg-beige tuyo-btn px-3 py-2 mr-2 rounded-pill border d-inline-flex align-items-center justify-content-center"
            >
              <img src={chevronLeftSVG} alt="" className="mr-2" />
              Regresar
            </button>
          </Link>
        </div>
        <div className="col-6 col-md-3 order-md-3 col-xl-2">
          {urbanOrdersId ? (
            <div />
          ) : (
            <button
              disabled={isSaving}
              type="button"
              className="w-100 bg-purple tuyo-btn px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
              onClick={handleSubmit}
            >
              {isSaving ? 'Creando' : 'Guardar'}
              <BounceLoader color="#fff" loading={isSaving} size="18" />
            </button>
          )}
        </div>
        <div className="col-12 col-md-6 col-xl-8 mt-3 mt-md-0">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center">
            {urbanOrdersId ? 'Orden Urbano' : 'Crear orden Urbano'}
          </h3>
        </div>
      </div>
      <div className="">
        <Card className="p-4 mt-3 mt-md-4">
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={{
              userOrder: urbanoOrder ? urbanoOrder.fk_ordersId : orderId,
              giftName: urbanoOrder ? urbanoOrder.giftName : '',
              totalToCollect: urbanoOrder
                ? urbanoOrder.totalToCollect
                : totalToCollect,
            }}
            onSubmit={(values) => {
              createUrbanoOrder(values);
            }}
          >
            {() => (
              <Form>
                <div className="row">
                  <div className="col-12 col-md-8 mt-2">
                    <label className="d-block" htmlFor="userOrder">
                      Orden
                    </label>
                    <select
                      name="userOrder"
                      className="p-2 rounded w-100"
                      onChange={({ target }) => {
                        setOrderId(target.value);
                      }}
                    >
                      <option>Seleccione una orden</option>
                      {urbanoOrdes?.map((urbano) => (
                        <option key={urbano.ordersId} value={urbano.ordersId}>
                          {`${urbano.ordersId} - ${urbano?.user?.firstname} ${urbano?.user?.lastname} `}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 mt-2">
                    <label className="d-block" htmlFor="box">
                      Tipo de caja
                    </label>
                    <Field as="select" name="box" className="p-2 rounded w-100">
                      <option value="">Tipo de caja</option>
                      {Object.entries(BOXES).map((box, index) => (
                        <option key={index} value={box[1]}>
                          {box[0]}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-12 col-md-8 mt-3 mt-md-0">
                    <label htmlFor="giftName" className="d-block">
                      Texto para Regalo
                    </label>
                    <Field
                      name="giftName"
                      text="text"
                      placeholder="Un regalo de Tuyo"
                      className="w-100 rounded p-2"
                    />
                  </div>
                  <div className="col-12 col-md-4 mt-3 mt-md-0">
                    <label htmlFor="totalToCollect" className="d-block">
                      Total a recolectar
                    </label>
                    <Field
                      name="totalToCollect"
                      type="number"
                      placeholder="9.99"
                      className="w-100 rounded p-2"
                      value={totalToCollect}
                    />
                  </div>
                  {urbanOrdersId ? (
                    <div className="col-12 col-md-4 mt-2">
                      <label className="d-block" htmlFor="box">
                        Sticker
                      </label>
                      <button
                        type="button"
                        className="w-100 bg-purple tuyo-btn px-4 py-2 mt-1 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                        onClick={() => {
                          printSticker();
                        }}
                      >
                        Imprimir Sticker
                      </button>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    </div>
  );
}

export default NewUrbano;
