import React, { useRef, useEffect, useState } from 'react';
import ReactModal from './Modal';
import mapboxgl from 'mapbox-gl';
import turf from 'turf';

import api from '../utils/api';

let map;

function Route({ onCloseRequest, ordersId }) {
  const mapContainer = useRef(null);

  const [route, setRoute] = useState();

  useEffect(_ => {
    setTimeout(_ => {
      if (mapContainer.current) {
        map = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [-89.2365921, 13.7034519],
          zoom: 12,
        });
      }
    }, 0);
  }, []);

  useEffect(() => {
    ordersId &&
      api.orders
        .getRoute({ ordersId })
        .then(response => {
          setRoute(response.data.route);
        })
        .catch(error => {
          console.error(error);
        });
  }, [ordersId]);

  useEffect(() => {
    if (route) {
      route.forEach((point, index) => {
        const [lon, lat] = point.coordinates;

        const element = document.createElement('div');
        element.className = 'marker route';
        element.innerHTML = `<span><b>${index + 1}</b></span>`;

        new mapboxgl.Marker(element).setLngLat({ lon, lat }).addTo(map);
      });

      fetch(
        `https://api.mapbox.com/optimized-trips/v1/mapbox/driving/${route.map(point => point.coordinates.join(',')).join(';')}?distributions=${route
          .map((point, index) => (index !== route.length - 1 ? `${index},${route.length - 1}` : ``))
          .filter(distro => distro.trim())
          .join(
            ';'
          )}&overview=full&steps=true&geometries=geojson&source=first&destination=last&roundtrip=false&access_token=${`pk.eyJ1IjoiYWlybWluZCIsImEiOiJja2VzMGI4Z3UxMno0MnRteml0NDgzNm9xIn0.x_Wm68TBw_g6ZqlSZg-SFQ`}`
      )
        .then(res => res.json())
        .then(data => {
          var routeGeoJSON = turf.featureCollection([turf.feature(data.trips[0].geometry)]);

          if (data.trips[0]) {
            map.addSource('route', {
              type: 'geojson',
              data: routeGeoJSON,
            });

            map.addLayer(
              {
                id: 'routeline-active',
                type: 'line',
                source: 'route',
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                paint: {
                  'line-color': '#06c965',
                  'line-width': ['interpolate', ['linear'], ['zoom'], 12, 3, 22, 12],
                },
              },
              'waterway-label'
            );

            map.addLayer(
              {
                id: 'routearrows',
                type: 'symbol',
                source: 'route',
                layout: {
                  'symbol-placement': 'line',
                  'text-field': '▶',
                  'text-size': ['interpolate', ['linear'], ['zoom'], 12, 24, 22, 60],
                  'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 6, 15, 11, 80],
                  'text-keep-upright': false,
                },
                paint: {
                  'text-color': '#06c965',
                  'text-halo-color': 'hsl(55, 11%, 96%)',
                  'text-halo-width': 3,
                },
              },
              'waterway-label'
            );
          }
        })
        .catch(err => console.error(err));
    }
  }, [route]);

  return (
    <ReactModal onCloseRequest={onCloseRequest}>
      <div
        id='map'
        ref={mapContainer}
        style={{
          width: '100%',
          height: '600px',
          borderRadius: '1rem',
        }}
      />
    </ReactModal>
  );
}

export default Route;
