import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import Card from '../../components/Card';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';
import PayInvoice from 'components/PayInvoice';
import SERFINSA_ERRORS from 'assets/resources/serfinsa_errors';
import {
  formatPrice,
  getOrderCode,
  parseDate,
  parseOrderCode,
} from 'utils/misc';
import api from '../../utils/api';

import swal from '@sweetalert/with-react';
import Swal from 'sweetalert2';

function RecurringTransactionList() {
  const { REACT_APP_TITLE } = process.env;

  const showModal = (transaction) => {
    let isVoid = false;
    Swal.fire({
      title: '¿Qué quieres hacer?',
      text: `Puedes ANULAR o REEMBOLSAR la transacción ${
        transaction.paymentMovementsId
      } con monto ${formatPrice(
        parseFloat(
          transaction.amount / (transaction.amount?.includes('.') ? 1 : 100)
        )
      )} del comercio ${transaction?.voucher?.store}`,
      showCancelButton: true,
      showDenyButton: true,
      cancelButtonText: 'Cerrar',
      confirmButtonText: 'Anular transacción',
      denyButtonText: `Reembolsar transacción`,
      reverseButtons: true,
      closeOnClickOutside: false,
      dangerMode: true,
      className: 'md:min-w-[625px]',
    }).then((choice) => {
      if (choice.isDismissed) return null;
      isVoid = choice.isConfirmed;
      Swal.fire({
        title: `¿Estás seguro de ${
          choice.isConfirmed ? 'anular' : 'reembolsar'
        } la transacción ${transaction.paymentMovementsId}?`,
        text: `Estás a punto de ${
          choice.isConfirmed ? 'anular' : 'reembolsar'
        } la transacción con monto ${formatPrice(
          parseFloat(
            transaction.amount / (transaction.amount?.includes('.') ? 1 : 100)
          )
        )} del comercio ${transaction?.voucher?.store}.\nEsta acción es irreversible.`,
        icon: 'warning',
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: `${
          choice.isConfirmed ? 'Anular' : 'Reembolsar'
        } la transacción`,
        closeOnClickOutside: false,
        showLoaderOnConfirm: true,
        reverseButtons: true,
      })
        .then((result) => {
          if (result.isDismissed) return result;
          return api.paymentMovements.refund({
            isVoid,
            amount: parseFloat(
              transaction.amount / (transaction.amount?.includes('.') ? 1 : 100)
            ),
            identifier: transaction.identifier,
            transactionId: transaction.paymentMovementsId,
            usersId: transaction?.voucher?.fk_usersId,
          });
        })
        .then((results) => {
          if (results.isDismissed) return toast.warning(`Acción no completada`);
          Swal.hideLoading();
          return Swal.fire('Acción realizada!', '', 'success');
        })
        .catch((err) => {
          if (err) {
            let msg = '';
            if (err.response) {
              msg = `${err?.response?.data?.resCode} - ${err?.response?.data?.resMsg}`;
            }
            toast.warning(`Error al realizar la acción ${msg}`);
            console.error(err);
          }
          Swal.hideLoading();
        });
    });
  };

  const tableProps = {
    rowId: 'paymentMovementsId',
    baseURL: '/paymentmovements',
    baseFilter: {
      order: [['createdAt', 'desc']],
      include: [
        {
          association: 'voucher',
          attributes: [
            'paymentVouchersId',
            'status',
            'store',
            'firstname',
            'lastname',
            'concept',
            'amount',
          ],
          where: {
            npe: { $ne: null },
          },
          required: true,
        },
      ],
    },
    pageSize: 10,
    columns: [
      {
        title: 'Pago',
        middleware: (transaction) => (
          <>
            <div
              data-tip={
                transaction?.resCode == '00'
                  ? 'Procesado'
                  : `Error ${transaction.resCode} - ${
                      SERFINSA_ERRORS[`ERROR ${transaction.resCode}`] || ''
                    }`
              }
              style={{ height: '18px' }}
            >
              {transaction?.resCode == '00' ? (
                <span className="material-icons-round text-green">
                  check_circle
                </span>
              ) : (
                <span className="material-icons-round text-red">warning</span>
              )}
            </div>
            <ReactTooltip effect="solid" />
          </>
        ),
      },
      {
        title: 'ID',
        objectProperty: 'paymentMovementsId',
        sortable: true,
      },
      {
        title: 'Voucher',
        objectProperty: 'voucher',
        gatewayObjectProperty: '$voucher.paymentVouchersId$',
        sortable: true,
        searchable: true,
        searchType: 'number-equal',
        searchMiddleware: (searchTerm) => parseOrderCode(searchTerm),
        middleware: (value) => `PTY-${getOrderCode(value.paymentVouchersId)}`,
      },
      {
        title: 'Cantidad',
        objectProperty: 'amount',
        searchable: true,
        searchType: 'text',
        middleware: (value) => formatPrice(+value / 100),
      },
      {
        title: 'Tienda',
        objectProperty: 'voucher',
        gatewayObjectProperty: '$voucher.store$',
        searchable: true,
        searchType: 'text',
        sortable: true,
        middleware: (value) => value.store,
      },
      {
        title: 'Autorizacion',
        objectProperty: 'auth',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Referencia',
        objectProperty: 'ref',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Tarjeta',
        objectProperty: 'card',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
      {
        title: 'Documento',
        objectProperty: 'dui',
        searchable: true,
        searchType: 'text',
        middleware: (value)=>{
          if(!value) return 'N/A';
          const document = value?.toUpperCase()?.split('|')?.reverse();
          if (document?.length === 1) return 'N/A';
          return document?.join(' - ');
        },
      },
      {
        title: 'Telefono',
        objectProperty: 'phone',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Tarjetahabiente',
        objectProperty: 'cardholder',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Creado',
        objectProperty: 'createdAt',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (createdAt) => parseDate(createdAt),
      },
      {
        title: 'Tipo',
        objectProperty: 'type',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Origen',
        objectProperty: 'source',
        searchable: true,
        // searchType: 'text',
        searchType: 'select',
        searchOptions: [
          {
            value: '',
            label: 'TODOS',
          },
          {
            value: 'link',
            label: 'LINK',
          },
          {
            value: 'api',
            label: 'API',
          },
          {
            value: 'widget',
            label: 'WIDGET',
          },
          {
            value: 'pos',
            label: 'POS/SCAN',
          },
          {
            value: 'template',
            label: 'MULTIPAGO',
          },
        ],
        middleware: (source) => (source || 'N/A').toUpperCase(),
      },
      {
        title: 'Error',
        middleware: (transaction) => (
          <span className="text-red">
            {transaction?.resCode == '00'
              ? ''
              : `Error ${transaction?.resCode} - ${
                  transaction?.resMsg ||
                  SERFINSA_ERRORS[`ERROR ${transaction?.resCode}`] ||
                  ''
                }`}
          </span>
        ),
      },
      {
        title: 'Acción',
        middleware: (transaction) => (
          <div>
            {transaction?.identifier?.trim() &&
            transaction?.resCode == '00' &&
            !['VOID', 'REFUND'].includes(transaction?.type) ? (
              <button
                onClick={() => showModal(transaction)}
                className="bg-red tuyo-btn text-sm py-1 px-1.5 rounded text-white font-weight-bold m-1 w-full"
              >
                Anular
              </button>
            ) : (
              ''
            )}
            <button
              onClick={() => {
                if (transaction?.voucher?.status === 'COMPLETED')
                  showVoucher(transaction?.voucher);
                else
                  swal({
                    title: 'Pago no procesado',
                    text: 'El comprobante ligado a esta transacción no ha sido completado aún.',
                    icon: 'info',
                  });
              }}
              className="bg-orange tuyo-btn text-sm py-1 px-1.5 rounded text-white font-weight-bold m-1 w-full"
            >
              Comprobante
            </button>
          </div>
        ),
      },
    ],
  };

  const showVoucher = (voucher) => {
    swal({
      content: <PayInvoice voucher={voucher} onClose={() => swal.close()} />,
      buttons: false,
    });
  };

  useEffect(() => {
    document.title = `Transacciones | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Transacciones
          </h3>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default RecurringTransactionList;
