import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import Card from '../components/Card';
import OrderDetail from '../components/OrderDetail';
import DashboardTotalCard from '../components/DashboardTotalCard';
import Route from '../components/Route';
import Status from '../components/Status';
import Table from '../components/TableV2/Table';

import api from '../utils/api';
import { getOrderCode, getOrderStatus, formatPrice } from '../utils/misc';

const totalCards = [
  { title: 'Aprobados', key: 'accepted' },
  { title: 'Recolectados', key: 'collected' },
  { title: 'Entregados', key: 'complete' },
];

const activeOrdersPurchaseStatus = [
  { orderStatus: { $iRegexp: 'orden aceptada' } },
  { orderStatus: { $iRegexp: 'aceptado' } },
  { orderStatus: { $iRegexp: 'orden lista' } },
  { orderStatus: { $iRegexp: 'lista' } },
  { orderStatus: { $iRegexp: 'orden en camino' } },
  { orderStatus: { $iRegexp: 'recolectado' } },
  { orderStatus: { $iRegexp: 'orden entregada' } },
  { orderStatus: { $iRegexp: 'finalizado' } },
];

function ActiveOrders() {
  const { REACT_APP_TITLE } = process.env;

  useEffect(() => {
    document.title = `Órdenes activas | ${REACT_APP_TITLE}`;
  }, []);

  const [counters, setCounters] = useState({
    accepted: 0,
    collected: 0,
    complete: 0,
  });
  const [orderOnViewId, setOrderOnViewId] = useState();
  const [routeOnView, setRouteOnView] = useState();
  const [tableRemountCount, setTableRemountCount] = useState(0);

  const tableProps = {
    pageSize: 10,
    baseURL: '/stores-dashboard/orders',
    rowId: 'ordersId',
    baseFilter: {
      where: {
        $or: activeOrdersPurchaseStatus,
      },
      attributes: ['ordersId', 'total'],
      include: [
        {
          association: 'details',
          attributes: ['fk_purchaseStatusesId'],
          include: [{ association: 'store', attributes: ['storesId', 'name'] }],
        },
        { association: 'user', attributes: ['firstname', 'lastname'] },
        { association: 'driver', attributes: ['firstname', 'lastname'] },
      ],
      order: [['ordersId', 'DESC']],
    },
    columns: [
      {
        title: 'ID',
        objectProperty: 'ordersId',
        searchable: true,
        searchType: 'number-equal',
        sortable: true,
      },
      {
        title: 'Codigo',
        objectProperty: 'ordersId',
        searchable: true,
        searchType: 'number-equal',
        middleware: (ordersId) => getOrderCode(ordersId),
        searchMiddleware: (searchTerm) => parseInt(searchTerm, 16) / 64,
      },
      {
        title: 'Comercios',
        objectProperty: 'details',
        searchable: true,
        searchType: 'text',
        gatewayObjectProperty: '$details.store.name$',
        middleware: (details) =>
          Array.from(
            new Set(details.map((detail) => detail?.store?.name))
          ).join(', '),
      },
      {
        title: 'Nombre Comprador',
        objectProperty: 'user',
        searchable: true,
        searchType: 'text',
        gatewayObjectProperty: '$user.firstname$',
        middleware: (user) => user?.firstname,
      },
      {
        title: 'Apellido Comprador',
        objectProperty: 'user',
        searchable: true,
        searchType: 'text',
        gatewayObjectProperty: '$user.lastname$',
        middleware: (user) => user?.lastname,
      },
      {
        title: 'Nombre Conductor',
        objectProperty: 'driver',
        searchable: true,
        searchType: 'text',
        gatewayObjectProperty: '$driver.firstname$',
        middleware: (driver) => driver?.firstname,
      },
      {
        title: 'Apellido Conductor',
        objectProperty: 'driver',
        searchable: true,
        searchType: 'text',
        gatewayObjectProperty: '$driver.lastname$',
        middleware: (driver) => driver?.lastname,
      },
      {
        title: 'Monto',
        objectProperty: 'total',
        searchable: true,
        searchType: 'number',
        sortable: true,
        middleware: (total) => formatPrice(total),
      },
      {
        title: 'Estado',
        middleware: (order) => (
          <Status className="w-100" value={getOrderStatus(order.details)} />
        ),
      },
      {
        objectProperty: 'ordersId',
        middleware: (ordersId) => (
          <>
            <button
              data-tip-="Ver ruta"
              onClick={(event) => {
                event.stopPropagation();
                setRouteOnView(ordersId);
              }}
            >
              🗺️
            </button>
            <ReactTooltip effect="solid" />
          </>
        ),
      },
    ],
  };

  useEffect(() => {
    api
      .findAll(
        '/orders?filter=' +
          JSON.stringify({
            paranoid: false,
            where: {
              $or: activeOrdersPurchaseStatus,
            },
            attributes: ['ordersId', 'orderStatus'],
          })
      )
      .then((response) => {
        setCounters({
          accepted: response.data.filter((order) =>
            /(orden aceptada|aceptado)/gi.test(order?.orderStatus)
          ).length,
          collected: response.data.filter((order) =>
            /(orden en camino|recolectado)/gi.test(order?.orderStatus)
          ).length,
          complete: response.data.filter((order) =>
            /(orden entregada|finalizado)/gi.test(order?.orderStatus)
          ).length,
        });
      })
      .catch((error) => {
        console.error(error);
        toast.warning('[SERVER_ERROR] ' + error);
      });
  }, []);

  return (
    <div className="content">
      <div className="row">
        {totalCards.map((card, index) => (
          <div key={index} className="col-12 col-md-6 col-lg-4 mb-3">
            <DashboardTotalCard desc={card.title} amount={counters[card.key]} />
          </div>
        ))}
      </div>
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          <Card className="p-md-4">
            <Table
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
              onRowClick={(order) => {
                setOrderOnViewId(order?.ordersId);
              }}
              remountCount={tableRemountCount}
            />
          </Card>
        </div>
      </div>

      {routeOnView && (
        <Route
          onCloseRequest={() => setRouteOnView(null)}
          ordersId={routeOnView}
        />
      )}

      {orderOnViewId && (
        <OrderDetail
          requestGetOrders={() => {
            setTableRemountCount(tableRemountCount + 1);
          }}
          showStatus
          sortProductsByStore
          id={orderOnViewId}
          onCloseRequest={() => setOrderOnViewId(null)}
        />
      )}
    </div>
  );
}

export default ActiveOrders;
