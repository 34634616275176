import React, { useState, useRef, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import S3 from 'aws-sdk/clients/s3';
import { Link, useHistory, useParams } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';

import Card from '../../components/Card';
import PasswordInput from '../../components/PasswordInput';

import {
  fileToBase64URL,
  uploadPictureToS3,
  deleteFromS3,
} from '../../utils/misc';
import api from '../../utils/api';

import chevronLeftSVG from '../../assets/icons/chevron-left.svg';

function NewDriver() {
  const formRef = useRef(null);

  const history = useHistory();
  const { id } = useParams();

  const [picturesToDelete, setPicturesToDelete] = useState([]);
  const [driver, setDriver] = useState();
  const [documents, setDocuments] = useState();
  const [vehicle, setVehicle] = useState();

  const [isSaving, setIsSaving] = useState(false);
  const [pictures, setPictures] = useState({
    photo: null,
    driversLicense: { URL: 'empty' },
    vehicleRegistration: { URL: 'empty' },
    criminalRecord: { URL: 'empty' },
    policeSolvency: { URL: 'empty' },
  });

  const vehicleTypeOptions = ['Motocicleta', 'Sedan', 'Panel'];

  const {
    REACT_APP_S3_REGION,
    REACT_APP_S3_BUCKET_NAME,
    REACT_APP_S3_ACCESS_KEY_ID,
    REACT_APP_S3_SECRET_ACCESS_KEY,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_TITLE,
  } = process.env;

  useEffect(() => {
    document.title = `Nuevo conductor | ${REACT_APP_TITLE}`;
  }, []);

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  /* Initialize S3 bucket object */
  const S3Bucket = new S3({
    region: REACT_APP_S3_REGION,
    params: {
      Bucket: REACT_APP_S3_BUCKET_NAME,
    },
    credentials: {
      accessKeyId: REACT_APP_S3_ACCESS_KEY_ID,
      secretAccessKey: REACT_APP_S3_SECRET_ACCESS_KEY,
    },
  });

  useEffect(() => {
    if (id) {
      api.drivers
        .getOne(id)
        .then((res) => {
          setDriver({ ...res.data[0] });
        })
        .catch((err) => {
          toast.warning(
            '[SERVER_ERROR] No se pudo obtener la info. del conductor'
          );
          console.error(err);
        });

      api.drivers.documents
        .getByUser(id)
        .then((res) => {
          setDocuments({ ...res.data[0] });
        })
        .catch((err) => {
          toast.warning(
            '[SERVER_ERROR] No se pudo obtener los documentos del conductor'
          );
          console.error(err);
        });

      api.drivers.vehicles
        .getByUser(id)
        .then((res) => {
          setVehicle({ ...res.data[0] });
        })
        .catch((err) => {
          toast.warning(
            '[SERVER_ERROR] No se pudo obtener la info. del vehículo'
          );
          console.error(err);
        });
    }
  }, []);

  useEffect(() => {
    if (driver && documents && vehicle) {
      setPictures({
        photo: { URL: driver.photo },
        criminalRecord: { URL: documents.criminalRecord },
        driversLicense: { URL: documents.license },
        policeSolvency: { URL: documents.policeSolvency },
        vehicleRegistration: { URL: vehicle.circulationCard },
      });
    }
  }, [driver, documents, vehicle]);

  const addPicture = async (field, event) => {
    const file = event.target.files[0];
    if (file.size / 1024 >= 2048) {
      toast.warning('Error: La imagen supera el peso maximo permitido');
      event.target.value = '';
    } else {
      const prevURL = pictures[field] ? pictures[field].URL : null;
      prevURL && setPicturesToDelete([...picturesToDelete, prevURL]);
      file &&
        setPictures({
          ...pictures,
          [field]: {
            base64: await fileToBase64URL(file),
            type: file.type,
            name: file.name,
          },
        });
    }
  };

  const handleSubmit = () => {
    formRef.current && formRef.current.handleSubmit();
  };

  const createDriver = async (formValues) => {
    if (!pictures.photo) {
      toast.error('La foto del conductor es requerida');
      return;
    }

    setIsSaving(true);

    const {
      photo,
      vehicleRegistration,
      policeSolvency,
      criminalRecord,
      driversLicense,
    } = pictures;
    const {
      email,
      passwordConfirmation,
      givenName,
      familyName,
      phone,
      plateNumber,
      vehicleType,
      vehicleBrand,
      vehicleModel,
      vehicleYear,
      vehicleColor,
      dui,
      nit,
    } = formValues;

    if (id) {
      picturesToDelete.forEach(async (URL) => {
        await deleteFromS3(URL.substring(URL.lastIndexOf('/') + 1));
      });

      api.drivers
        .updateOne(id, {
          firstname: givenName,
          lastname: familyName,
          email,
          phone: phone.replace(' ', ''),
          photo: photo.base64
            ? await uploadPictureToS3(photo.base64, photo.type, photo.name)
            : photo.URL,
          updatedBy: userID,
        })
        .then(async () => {
          const documentsID = documents.usersDocumentsId;

          const body = {
            dui,
            nit,
            ...(documentsID
              ? {
                  policeSolvency: policeSolvency.base64
                    ? await uploadPictureToS3(
                        policeSolvency.base64,
                        policeSolvency.type,
                        policeSolvency.name
                      )
                    : policeSolvency.URL,
                  criminalRecord: criminalRecord.base64
                    ? await uploadPictureToS3(
                        criminalRecord.base64,
                        criminalRecord.type,
                        criminalRecord.name
                      )
                    : criminalRecord.URL,
                  license: driversLicense.base64
                    ? await uploadPictureToS3(
                        driversLicense.base64,
                        driversLicense.type,
                        driversLicense.name
                      )
                    : driversLicense.URL,
                  updatedBy: userID,
                }
              : {
                  policeSolvency: await uploadPictureToS3(
                    policeSolvency.base64,
                    policeSolvency.type,
                    policeSolvency.name
                  ),
                  criminalRecord: await uploadPictureToS3(
                    criminalRecord.base64,
                    criminalRecord.type,
                    criminalRecord.name
                  ),
                  license: await uploadPictureToS3(
                    driversLicense.base64,
                    driversLicense.type,
                    driversLicense.name
                  ),
                  fk_usersId: id,
                  createdBy: userID,
                }),
          };

          return documentsID
            ? api.drivers.documents.update(documentsID, body)
            : api.drivers.documents.create(body);
        })
        .then(async () => {
          const vehicleID = vehicle.usersVehiclesId;

          const body = {
            carRegistration: plateNumber,
            type: vehicleType,
            brand: vehicleBrand,
            model: vehicleModel,
            year: vehicleYear,
            color: vehicleColor,
            ...(vehicleID
              ? {
                  circulationCard: vehicleRegistration.base64
                    ? await uploadPictureToS3(
                        vehicleRegistration.base64,
                        vehicleRegistration.type,
                        vehicleRegistration.name
                      )
                    : vehicleRegistration.URL,
                  updatedBy: userID,
                }
              : {
                  circulationCard: await uploadPictureToS3(
                    vehicleRegistration.base64,
                    vehicleRegistration.type,
                    vehicleRegistration.name
                  ),
                  fk_usersId: id,
                  createdBy: userID,
                }),
          };

          return vehicleID
            ? api.drivers.vehicles.update(vehicleID, body)
            : api.drivers.vehicles.create(body);
        })
        .then(() => {
          toast.success('¡Conductor actualizado!');
          history.push('/admin/drivers');
        })
        .catch((err) => {
          toast.warning(`[SERVER_ERROR] ${err}`);
          console.error(err);
          setIsSaving(false);
        });
    } else {
      api.drivers
        .create({
          email,
          photo: await uploadPictureToS3(photo.base64, photo.type, photo.name),
          password: passwordConfirmation,
          active: 1,
          firstname: givenName,
          lastname: familyName,
          phone: phone.replace(' ', ''),
          fk_rolesId: 4,
          createdBy: userID,
        })
        .then(async (res) => {
          const createdDriverID = res.data.usersId;

          return api.drivers.vehicles.create({
            carRegistration: plateNumber,
            circulationCard: vehicleRegistration.base64
              ? await uploadPictureToS3(
                  vehicleRegistration.base64,
                  vehicleRegistration.type,
                  vehicleRegistration.name
                )
              : vehicleRegistration.URL,
            type: vehicleType,
            brand: vehicleBrand,
            model: vehicleModel,
            year: vehicleYear,
            color: vehicleColor,
            fk_usersId: createdDriverID,
            createdBy: userID,
          });
        })
        .then(async (res) => {
          const createdDriverID = res.data.fk_usersId;

          return api.drivers.documents.create({
            policeSolvency: policeSolvency.base64
              ? await uploadPictureToS3(
                  policeSolvency.base64,
                  policeSolvency.type,
                  policeSolvency.name
                )
              : policeSolvency.URL,
            criminalRecord: criminalRecord.base64
              ? await uploadPictureToS3(
                  criminalRecord.base64,
                  criminalRecord.type,
                  criminalRecord.name
                )
              : criminalRecord.URL,
            license: driversLicense.base64
              ? await uploadPictureToS3(
                  driversLicense.base64,
                  driversLicense.type,
                  driversLicense.name
                )
              : driversLicense.URL,
            dui: dui,
            nit: nit,
            fk_usersId: createdDriverID,
            createdBy: userID,
          });
        })
        .then(() => {
          toast.success('¡Conductor creado!');
          history.push('/admin/drivers');
        })
        .catch((err) => {
          console.error(err);
          toast.warning(`[SERVER_ERROR] ${err}`);
          setIsSaving(false);
        });
    }
  };

  return (
    <div className="content">
      <div className="row">
        <div className="col-6 col-md-3 col-xl-2">
          <Link to="/admin/drivers">
            <button
              type="button"
              className="w-100 bg-beige tuyo-btn px-3 py-2 mr-2 rounded-pill border d-inline-flex align-items-center justify-content-center"
            >
              <img src={chevronLeftSVG} alt="" className="mr-2" />
              Regresar
            </button>
          </Link>
        </div>
        <div className="col-6 col-md-3 order-md-3 col-xl-2">
          <button
            disabled={isSaving}
            type="button"
            className="w-100 bg-purple tuyo-btn px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
            onClick={handleSubmit}
          >
            {isSaving ? (id ? 'Actualizando' : 'Creando') : 'Guardar'}
            <BounceLoader color="#fff" loading={isSaving} size="18" />
          </button>
        </div>
        <div className="col-12 col-md-6 col-xl-8 mt-3 mt-md-0">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center">
            {id ? 'Editar conductor' : 'Nuevo conductor'}
          </h3>
        </div>
      </div>
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            validationSchema={Yup.object().shape({
              givenName: Yup.string().trim().required('Campo requerido'),
              familyName: Yup.string().trim().required('Campo requerido'),
              email: Yup.string()
                .trim()
                .required('Campo requerido')
                .email('Email no válido'),
              phone: Yup.string()
                .trim()
                .required('Campo requerido')
                .matches(
                  /([267])[0-9]{3}[ ]?[0-9]{4}/,
                  'Número de teléfono no válido'
                ),
              ...(!id
                ? {
                    password: Yup.string()
                      .trim()
                      .min(10, 'Longitud minima: ${min} caracteres')
                      .required('Campo requerido'),
                    passwordConfirmation: Yup.string()
                      .trim()
                      .required('Campo requerido')
                      .min(10, 'Longitud minima: ${min} caracteres')
                      .test(
                        'passwords-match',
                        'Las contraseñas no coinciden',
                        function (value) {
                          return this.parent.password === value;
                        }
                      ),
                  }
                : {}),
              dui: Yup.string()
                .trim()
                .required('Campo requerido')
                .matches(/[0-9]{8}[ ]?[0-9]/, 'DUI no válido'),
              nit: Yup.string()
                .trim()
                .required('Campo requerido')
                .matches(
                  /[0-9]{4}[ ]?[0-9]{6}[ ]?[0-9]{3}[ ]?[0-9]/,
                  'NIT no válido'
                ),
              plateNumber: Yup.string()
                .trim()
                .required('Campo requerido')
                .min(2, 'Placa debe ser al menos ${min} caracter')
                .max(7, 'Placa debe ser máximo ${max} caracteres'),
              vehicleColor: Yup.string().trim().required('Campo requerido'),
              vehicleType: Yup.string().trim().required('Campo requerido'),
              vehicleBrand: Yup.string().trim().required('Campo requerido'),
              vehicleModel: Yup.string().trim().required('Campo requerido'),
              vehicleYear: Yup.string()
                .trim()
                .required('Campo requerido')
                .length(4, 'Año debe ser de ${length} caracteres'),
            })}
            initialValues={{
              givenName: id ? driver?.firstname : '',
              familyName: id ? driver?.lastname : '',
              email: id ? driver?.email : '',
              phone: id ? driver?.phone : '',
              password: '',
              passwordConfirmation: '',
              dui: id ? documents?.dui : '',
              nit: id ? documents?.nit : '',
              plateNumber: id ? vehicle?.carRegistration : '',
              vehicleColor: id ? vehicle?.color : '',
              vehicleType: id ? vehicle?.type : '1',
              vehicleBrand: id ? vehicle?.brand : '',
              vehicleModel: id ? vehicle?.model : '',
              vehicleYear: id ? vehicle?.year : '',
            }}
            onSubmit={(values) => {
              createDriver(values);
            }}
          >
            {() => (
              <Form>
                <Card className="p-3 p-md-4">
                  <div className="row">
                    <div className="col-12 col-md-3">
                      <div className="col-12">
                        <button
                          type="button"
                          className="w-100 add-picture-btn"
                          style={{
                            backgroundSize: pictures.photo ? 'cover' : '',
                            border: '2px solid rgba(0,0,0,0.16)',
                            paddingTop: '100%',
                            borderRadius: '1rem',
                            backgroundImage: pictures.photo
                              ? "url('" +
                                (pictures.photo.base64 || pictures.photo.URL) +
                                "')"
                              : '',
                          }}
                        >
                          <input
                            accept=".jpg,.jpeg,.png"
                            id="photo-img"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event) => addPicture('photo', event)}
                          />

                          <label
                            htmlFor="photo-img"
                            className="mb-0"
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                            }}
                          />
                        </button>
                      </div>
                      <small className="font-style-italic text-gray mt-1">
                        Peso máximo por imagen: 2mb
                      </small>
                    </div>
                    <div className="col-12 col-md-9">
                      <div className="row h-100 align-items-center">
                        <div className="col-12 col-md-4">
                          <label htmlFor="givenName" className="d-block">
                            Nombre
                          </label>
                          <Field
                            name="givenName"
                            type="text"
                            placeholder="Juan"
                            className="w-100 rounded p-2"
                          />
                          <ErrorMessage
                            name="givenName"
                            component="span"
                            className="text-red"
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <label htmlFor="familyName" className="d-block">
                            Apellido
                          </label>
                          <Field
                            name="familyName"
                            type="text"
                            placeholder="Perez"
                            className="w-100 rounded p-2"
                          />
                          <ErrorMessage
                            name="familyName"
                            component="span"
                            className="text-red"
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <label htmlFor="email" className="d-block">
                            Correo electrónico
                          </label>
                          <Field
                            name="email"
                            type="email"
                            placeholder="juanperez@tuyoapp.com"
                            className="w-100 rounded p-2"
                          />
                          <ErrorMessage
                            name="email"
                            component="span"
                            className="text-red"
                          />
                        </div>
                        <div className="col-12 col-md-4 mt-md-3">
                          <label htmlFor="phone" className="d-block">
                            Teléfono
                          </label>
                          <Field name="phone">
                            {({ field, form: { setFieldValue } }) => (
                              <>
                                <InputMask
                                  {...field}
                                  onChange={(event) =>
                                    setFieldValue('phone', event.target.value)
                                  }
                                  className="p-2 rounded w-100"
                                  maskChar={null}
                                  formatChars={{
                                    9: '[0-9]',
                                    7: '(2|6|7)',
                                  }}
                                  mask="7999 9999"
                                />
                                <ErrorMessage
                                  name="phone"
                                  component="span"
                                  className="text-red"
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        {!id && (
                          <>
                            <div className="col-12 col-md-4 mt-md-3">
                              <label htmlFor="password" className="d-block">
                                Contraseña
                              </label>
                              <Field name="password">
                                {({ field, form: { setFieldValue } }) => (
                                  <>
                                    <PasswordInput
                                      {...field}
                                      id="password"
                                      autoComplete="new-password"
                                      onChange={(value) =>
                                        setFieldValue('password', value)
                                      }
                                    />
                                    <ErrorMessage
                                      name="password"
                                      component="span"
                                      className="text-red"
                                    />
                                  </>
                                )}
                              </Field>
                            </div>
                            <div className="col-12 col-md-4 mt-md-3">
                              <label
                                htmlFor="passwordConfirmation"
                                className="d-block"
                              >
                                Confirmar contraseña
                              </label>
                              <Field name="passwordConfirmation">
                                {({ field, form: { setFieldValue } }) => (
                                  <>
                                    <PasswordInput
                                      {...field}
                                      id="passwordConfirmation"
                                      autoComplete="new-password"
                                      onChange={(value) =>
                                        setFieldValue(
                                          'passwordConfirmation',
                                          value
                                        )
                                      }
                                    />
                                    <ErrorMessage
                                      name="passwordConfirmation"
                                      component="span"
                                      className="text-red"
                                    />
                                  </>
                                )}
                              </Field>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="p-3 p-md-4 mt-3">
                  <div className="row">
                    <div className="col-12 col-md-2 text-center">
                      <p className="mb-2">Licencia de conducir</p>
                      <div className="col-12">
                        <button
                          type="button"
                          className="w-100 add-picture-btn"
                          style={{
                            backgroundSize: pictures.driversLicense
                              ? 'cover'
                              : '',
                            border: '2px solid rgba(0,0,0,0.16)',
                            paddingTop: '100%',
                            borderRadius: '1rem',
                            backgroundImage: pictures.driversLicense
                              ? "url('" +
                                (pictures.driversLicense.base64 ||
                                  pictures.driversLicense.URL) +
                                "')"
                              : '',
                          }}
                        >
                          <input
                            accept=".jpg,.jpeg,.png"
                            id="driver-license-img"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event) =>
                              addPicture('driversLicense', event)
                            }
                          />

                          <label
                            htmlFor="driver-license-img"
                            className="mb-0"
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                            }}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-2 text-center">
                      <p className="mb-2">Tarjeta de circulación</p>
                      <div className="col-12">
                        <button
                          type="button"
                          className="w-100 add-picture-btn"
                          style={{
                            backgroundSize: pictures.vehicleRegistration
                              ? 'cover'
                              : '',
                            border: '2px solid rgba(0,0,0,0.16)',
                            paddingTop: '100%',
                            borderRadius: '1rem',
                            backgroundImage: pictures.vehicleRegistration
                              ? "url('" +
                                (pictures.vehicleRegistration.base64 ||
                                  pictures.vehicleRegistration.URL) +
                                "')"
                              : '',
                          }}
                        >
                          <input
                            accept=".jpg,.jpeg,.png"
                            id="vehicle-registration-img"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event) =>
                              addPicture('vehicleRegistration', event)
                            }
                          />

                          <label
                            htmlFor="vehicle-registration-img"
                            className="mb-0"
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                            }}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-2 text-center">
                      <p className="mb-2">Antecedentes penales</p>
                      <div className="col-12">
                        <button
                          type="button"
                          className="w-100 add-picture-btn"
                          style={{
                            backgroundSize: pictures.criminalRecord
                              ? 'cover'
                              : '',
                            border: '2px solid rgba(0,0,0,0.16)',
                            paddingTop: '100%',
                            borderRadius: '1rem',
                            backgroundImage: pictures.criminalRecord
                              ? "url('" +
                                (pictures.criminalRecord.base64 ||
                                  pictures.criminalRecord.URL) +
                                "')"
                              : '',
                          }}
                        >
                          <input
                            accept=".jpg,.jpeg,.png"
                            id="criminal-record-img"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event) =>
                              addPicture('criminalRecord', event)
                            }
                          />

                          <label
                            htmlFor="criminal-record-img"
                            className="mb-0"
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                            }}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-2 text-center">
                      <p className="mb-2">Solvencia policial</p>
                      <div className="col-12">
                        <button
                          type="button"
                          className="w-100 add-picture-btn"
                          style={{
                            backgroundSize: pictures.policeSolvency
                              ? 'cover'
                              : '',
                            border: '2px solid rgba(0,0,0,0.16)',
                            paddingTop: '100%',
                            borderRadius: '1rem',
                            backgroundImage: pictures.policeSolvency
                              ? "url('" +
                                (pictures.policeSolvency.base64 ||
                                  pictures.policeSolvency.URL) +
                                "')"
                              : '',
                          }}
                        >
                          <input
                            accept=".jpg,.jpeg,.png"
                            id="police-solvency-img"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event) =>
                              addPicture('policeSolvency', event)
                            }
                          />

                          <label
                            htmlFor="police-solvency-img"
                            className="mb-0"
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                            }}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 d-flex flex-column justify-content-center">
                      <label htmlFor="dui" className="d-block">
                        DUI
                      </label>
                      <Field name="dui">
                        {({ field, form: { setFieldValue } }) => (
                          <>
                            <InputMask
                              {...field}
                              onChange={(event) =>
                                setFieldValue('dui', event.target.value)
                              }
                              className="p-2 rounded w-100"
                              maskChar={null}
                              mask="99999999 9"
                            />
                            <ErrorMessage
                              name="dui"
                              component="span"
                              className="text-red"
                            />
                          </>
                        )}
                      </Field>

                      <label htmlFor="nit" className="d-block mt-3">
                        NIT
                      </label>
                      <Field name="nit">
                        {({ field, form: { setFieldValue } }) => (
                          <>
                            <InputMask
                              {...field}
                              onChange={(event) =>
                                setFieldValue('nit', event.target.value)
                              }
                              className="p-2 rounded w-100"
                              maskChar={null}
                              mask="9999 999999 999 9"
                            />
                            <ErrorMessage
                              name="nit"
                              component="span"
                              className="text-red"
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <small className="font-style-italic text-gray mt-1">
                    Peso máximo por imagen: 2mb
                  </small>
                </Card>
                <Card className="p-3 p-md-4 mt-3">
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <label htmlFor="plateNumber" className="d-block">
                        Placa
                      </label>
                      <Field
                        name="plateNumber"
                        type="text"
                        placeholder="P18327"
                        className="w-100 rounded p-2"
                      />
                      <ErrorMessage
                        name="plateNumber"
                        component="span"
                        className="text-red"
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <label htmlFor="vehicleColor" className="d-block">
                        Color
                      </label>
                      <Field
                        name="vehicleColor"
                        type="text"
                        placeholder="Amarillo banana"
                        className="w-100 rounded p-2"
                      />
                      <ErrorMessage
                        name="vehicleColor"
                        component="span"
                        className="text-red"
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <label htmlFor="vehicleType" className="d-block">
                        Tipo
                      </label>
                      <Field
                        name="vehicleType"
                        as="select"
                        className="w-100 rounded p-2"
                      >
                        {vehicleTypeOptions.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="vehicleType"
                        component="span"
                        className="text-red"
                      />
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                      <label htmlFor="vehicleBrand" className="d-block">
                        Marca
                      </label>
                      <Field
                        name="vehicleBrand"
                        type="text"
                        placeholder="Hyundai"
                        className="w-100 rounded p-2"
                      />
                      <ErrorMessage
                        name="vehicleBrand"
                        component="span"
                        className="text-red"
                      />
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                      <label htmlFor="vehicleModel" className="d-block">
                        Modelo
                      </label>
                      <Field
                        name="vehicleModel"
                        type="text"
                        placeholder="Lancer"
                        className="w-100 rounded p-2"
                      />
                      <ErrorMessage
                        name="vehicleModel"
                        component="span"
                        className="text-red"
                      />
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                      <label htmlFor="vehicleYear" className="d-block">
                        Año
                      </label>
                      <Field name="vehicleYear">
                        {({ field, form: { setFieldValue } }) => (
                          <>
                            <InputMask
                              {...field}
                              onChange={(event) =>
                                setFieldValue('vehicleYear', event.target.value)
                              }
                              className="p-2 rounded w-100"
                              maskChar={null}
                              mask="9999"
                            />
                            <ErrorMessage
                              name="vehicleYear"
                              component="span"
                              className="text-red"
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                </Card>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default NewDriver;
