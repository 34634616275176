import { ErrorMessage, Field, Form, Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import api from 'utils/api';

function InternalNotes(props) {
  const { REACT_APP_COOKIES_JWT } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_JWT]);

  const { cxNotes, ordersId } = props;
  const [notes, setNotes] = useState({});
  const [show, setShow] = useState(true);

  useEffect(
    () =>
      setNotes({
        ...cxNotes,
      }),
    [props]
  );

  const updateNotes = async (values, { setSubmitting, resetForm }) => {
    const date = Date.now();
    const { note: message } = values;

    if (!ordersId) {
      toast.error('Id de orden no encontrado');
      return;
    }

    const jwt = cookies[REACT_APP_COOKIES_JWT];
    const decodedJwt = jwtDecode(jwt);

    const {
      user: { firstname },
    } = decodedJwt;

    const noteRes = await api.orders.getWithFilter({
      where: {
        ordersId,
      },
      attributes: ['cxNotes'],
    });
    const _notes = noteRes?.data?.[0]?.cxNotes || {};

    const body = {
      messages: [
        ...(_notes?.messages || []),
        { user: firstname, message, date },
      ],
    };

    api.orders
      .update(ordersId, {
        cxNotes: body,
      })
      .then(() => {
        setNotes(body);
        resetForm();
      })
      .catch(() => toast.error('Error al actualizar notas'))
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="row mt-4 py-2 border rounded space-y-3">
      <div className="col-12 d-flex">
        <p className="mr-3 text-indigo-dark font-extrabold">Notas:</p>
        <button
          className="font-weight-bold text-green underline-button"
          onClick={() => setShow(!show)}
        >
          {show ? 'Ocultar' : 'Mostrar'}
        </button>
      </div>

      <Formik
        initialValues={{
          note: '',
        }}
        validate={(value) => {
          let errors = {};
          if (!value.note) errors.note = 'Requerido';

          return errors;
        }}
        onSubmit={updateNotes}
      >
        {({ isSubmitting }) => (
          <div className={`col-12 col-md-6 ${show ? '' : 'd-none'}`}>
            <Form className="row w-full m-0">
              <div className="col-12 mb-2 mx-0 p-0">
                <label
                  className="d-block font-weight-bold text-green"
                  htmlFor="note"
                >
                  Nueva nota
                </label>
                <Field name="note" className="p-2 rounded w-100" />
                <ErrorMessage name="note">
                  {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                </ErrorMessage>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 px-4 py-2 rounded text-light font-weight-bold"
              >
                <span className="material-icons">add</span>
                <span>Agregar nota</span>
              </button>
            </Form>
          </div>
        )}
      </Formik>
      <div className={`col-12 col-md-6 mt-4 mt-md-0 ${show ? '' : 'd-none'}`}>
        <p className="font-weight-bold text-green">Notas internas</p>
        <div
          className="py-2"
          style={{
            maxHeight: '300px',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          {notes?.messages?.length > 0 ? (
            notes?.messages?.map((note, index) => (
              <div key={index} className="row">
                <div className="mt-2 col-12 d-flex align-items-baseline">
                  <span className="material-icons text-green mr-2 mt-auto ml-0">
                    person
                  </span>
                  <span className="font-bold">{note.user}</span>
                  <span className="mx-1">-</span>
                  <small>
                    {note?.date
                      ? new Date(note.date).toLocaleString()
                      : 'Sin fecha'}
                  </small>
                </div>
                <div className="col-12 d-flex align-items-start">
                  <span className="material-icons text-green mr-2 mb-auto ml-0">
                    sticky_note_2
                  </span>
                  <p>{note.message}</p>
                </div>
                <hr className="col-12 my-1" />
              </div>
            ))
          ) : (
            <div className="text-center d-flex justify-content-center">
              <span className="material-icons text-green mx-2">
                running_with_errors
              </span>
              No hay notas
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InternalNotes;
