import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import DashboardTotalCard from '../components/DashboardTotalCard';
import Card from '../components/Card';
import Status from '../components/Status';
import OrderDetail, { orderStatusTexts } from '../components/OrderDetail';

import api from '../utils/api';
import { getOrderCode, getOrderStatus, formatPrice } from '../utils/misc';

import PaginatedTable from 'components/TableWithPagination/PaginatedTable';

const totalCards = [
  { title: 'Pendientes', key: 'pending' },
  { title: 'Aceptados', key: 'accepted' },
  { title: 'Recolectados', key: 'collected' },
  { title: 'Finalizados', key: 'complete' },
  { title: 'No completados', key: 'uncomplete' },
  { title: 'Cancelados', key: 'cancelled' },
];

const tableProps = {
  pageSize: 10,
  baseURL: '/orders',
  rowId: 'ordersId',
  baseFilter: {
    subQuery: false,
    distinct: true,
    attributes: [
      'ordersId',
      'address',
      'apartment',
      'city',
      'state',
      'total',
      'createdAt',
      'paymentType',
      'paymentStatus',
      'shippingType',
    ],
    include: [
      {
        paranoid: false,
        association: 'user',
        attributes: ['firstname', 'lastname'],
      },
      {
        paranoid: false,
        separate: true,
        association: 'details',
        attributes: ['fk_purchaseStatusesId'],
        include: [
          {
            association: 'store',
            attributes: ['name'],
          },
        ],
      },
    ],
    order: [['ordersId', 'DESC']],
  },
  columns: [
    {
      title: '',
      objectProperty: 'createdAt',
      middleware: (createdAt) => {
        const nowTime = new Date().getTime();
        const createdAtTime = new Date(createdAt).getTime();
        const difference = nowTime - createdAtTime;

        const diffHours = Math.ceil(difference / (1000 * 3600));

        return (
          <>
            <div
              data-tip={moment(createdAt).locale(moment.locale()).fromNow()}
              data-for={createdAt}
              className={
                diffHours > 24
                  ? 'bg-red'
                  : diffHours > 5
                  ? 'bg-orange'
                  : 'bg-green'
              }
              style={{
                padding: 2,
                width: 28,
                height: 28,
                borderRadius: 6,
              }}
            >
              <i className="material-icons-round text-white rounded">
                {diffHours > 24
                  ? 'hourglass_disabled'
                  : diffHours > 5
                  ? 'hourglass_bottom'
                  : 'hourglass_empty'}
              </i>
            </div>
            <ReactTooltip effect="solid" id={createdAt} />
          </>
        );
      },
    },
    {
      title: 'Estado',
      objectProperty: 'orderStatus',
      useAllObject: true,
      searchable: true,
      searchType: 'select',
      searchOptions: [
        {
          value: '',
          label: 'TODOS',
        },
        ...orderStatusTexts.map((status, index) => ({
          value: status.text,
          label: status.text.toUpperCase(),
        })),
      ],
      middleware: (order) => (
        <Status className="w-100" value={getOrderStatus(order.details)} />
      ),
    },
    {
      title: 'ID',
      objectProperty: 'ordersId',
      searchable: true,
      searchType: 'number-equal',
      sortable: true,
    },
    {
      title: 'Codigo',
      objectProperty: 'ordersId',
      searchable: true,
      searchType: 'number-equal',
      middleware: (ordersId) => getOrderCode(ordersId),
      searchMiddleware: (searchTerm) => parseInt(searchTerm, 16) / 64,
    },
    {
      title: 'Tipo de Envio',
      objectProperty: 'shippingType',
      middleware: (shippingType) => {
        const isTurbo = shippingType.includes('TURBO');
        const isScheduled = shippingType.includes('SCHEDULED');

        return (
          <>
            <span
              data-tip={
                isTurbo
                  ? 'Turbo Envio'
                  : isScheduled
                  ? 'Envio Programado'
                  : 'Envio Normal'
              }
              data-for="turbo"
              className={`material-icons-round ${
                isTurbo
                  ? 'text-turbo'
                  : isScheduled
                  ? 'text-green'
                  : 'text-stone-400'
              }`}
            >
              {isTurbo
                ? 'pedal_bike'
                : isScheduled
                ? 'schedule'
                : 'local_shipping'}
            </span>
            <ReactTooltip effect="solid" id="turbo" />
          </>
        );
      },
    },
    {
      title: 'Comercios',
      hasMany: 'details',
      objectProperty: 'details',
      searchable: true,
      searchType: 'text',
      gatewayObjectProperty: '$details.store.name$',
      middleware: (details) =>
        Array.from(new Set(details?.map((detail) => detail?.store?.name))).join(
          ', '
        ),
    },
    {
      title: 'Nombre',
      objectProperty: 'user',
      searchable: true,
      searchType: 'text',
      gatewayObjectProperty: '$user.firstname$',
      middleware: (user) => user?.firstname,
    },
    {
      title: 'Apellido',
      objectProperty: 'user',
      searchable: true,
      searchType: 'text',
      gatewayObjectProperty: '$user.lastname$',
      middleware: (user) => user?.lastname,
    },
    {
      title: 'Casa #',
      objectProperty: 'apartment',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Direccion',
      objectProperty: 'address',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Municipio',
      objectProperty: 'city',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Dpto.',
      objectProperty: 'state',
      searchable: true,
      searchType: 'text',
    },
    {
      title: 'Monto',
      objectProperty: 'total',
      searchable: true,
      searchType: 'number',
      sortable: true,
      middleware: (total) => formatPrice(total),
    },
    {
      title: 'Creado',
      objectProperty: 'createdAt',
      searchable: true,
      searchType: 'date',
      sortable: true,
      middleware: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: 'Pago',
      middleware: (order) => (
        <>
          {order?.paymentStatus === 'COMPLETADO' ? (
            <span
              data-tip="Pago completado"
              data-for="status"
              className="material-icons-round text-green"
            >
              check_circle
            </span>
          ) : order?.paymentType === 'CASH' ? (
            <span
              data-tip="Pago en efectivo"
              data-for="status"
              className="material-icons-round text-warning"
            >
              payments
            </span>
          ) : null}
          <ReactTooltip effect="solid" id="status" />
        </>
      ),
    },
  ],
};

function Dashboard() {
  const { REACT_APP_TITLE } = process.env;
  const { orderID } = useParams();

  useEffect(() => {
    document.title = `Inicio | ${REACT_APP_TITLE}`;
  }, []);

  const [counters, setCounters] = useState({
    pending: 0,
    accepted: 0,
    collected: 0,
    complete: 0,
    uncomplete: 0,
    cancelled: 0,
  });
  const [orderOnViewId, setOrderOnViewId] = useState();
  const [tableRemountCount, setTableRemountCount] = useState(0);

  useEffect(() => {
    api
      .findAll(
        '/count/orders?filter=' +
          JSON.stringify({
            where: {
              orderStatus: {
                $iLike: encodeURI('%ingresada%'),
              },
            },
          })
      )
      .then((response) => {
        setCounters((prevCounters) => ({
          ...prevCounters,
          pending: response.data.count,
        }));

        return api.findAll(
          '/count/orders?filter=' +
            JSON.stringify({
              where: {
                orderStatus: {
                  $iLike: encodeURI('%aceptada%'),
                },
              },
            })
        );
      })
      .then((response) => {
        setCounters((prevCounters) => ({
          ...prevCounters,
          accepted: response.data.count,
        }));

        return api.findAll(
          '/count/orders?filter=' +
            JSON.stringify({
              where: {
                orderStatus: {
                  $iLike: encodeURI('%en camino%'),
                },
              },
            })
        );
      })
      .then((response) => {
        setCounters((prevCounters) => ({
          ...prevCounters,
          collected: response.data.count,
        }));

        return api.findAll(
          '/count/orders?filter=' +
            JSON.stringify({
              where: {
                orderStatus: {
                  $iLike: encodeURI('%entregada%'),
                },
              },
            })
        );
      })
      .then((response) => {
        setCounters((prevCounters) => ({
          ...prevCounters,
          complete: response.data.count,
        }));

        return api.findAll(
          '/count/orders?filter=' +
            JSON.stringify({
              where: {
                orderStatus: {
                  $iLike: encodeURI('%no entregada%'),
                },
              },
            })
        );
      })
      .then((response) => {
        setCounters((prevCounters) => ({
          ...prevCounters,
          uncomplete: response.data.count,
        }));

        return api.findAll(
          '/count/orders?filter=' +
            JSON.stringify({
              where: {
                orderStatus: {
                  $iLike: encodeURI('%cancelada%'),
                },
              },
            })
        );
      })
      .then((response) => {
        setCounters((prevCounters) => ({
          ...prevCounters,
          cancelled: response.data.count,
        }));
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener el conteo de ordenes');
      });
  }, []);

  useEffect(() => {
    if (orderID) {
      setOrderOnViewId(parseInt(orderID));
    }
  }, []);

  return (
    <div className="content">
      <div>
        <div className="row">
          {totalCards.map((card, index) => (
            <div
              key={index}
              className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 px-xl-2 mb-3"
            >
              <DashboardTotalCard
                desc={card.title}
                amount={counters[card.key]}
              />
            </div>
          ))}
        </div>

        <div className="row mt-3 mt-md-4">
          <div className="col">
            <Card className="p-md-4">
              <div className="row">
                <div className="col-12 mt-3 mt-md-4">
                  <PaginatedTable
                    columns={tableProps.columns}
                    pageSize={tableProps.pageSize}
                    baseURL={tableProps.baseURL}
                    baseFilter={tableProps.baseFilter}
                    rowId={tableProps.rowId}
                    onRowClick={(order) => {
                      setOrderOnViewId(order?.ordersId);
                    }}
                    remountCount={tableRemountCount}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      {orderOnViewId && (
        <OrderDetail
          requestGetOrders={() => {
            setTableRemountCount(tableRemountCount + 1);
          }}
          showStatus
          sortProductsByStore
          id={orderOnViewId}
          onCloseRequest={() => setOrderOnViewId(null)}
        />
      )}
    </div>
  );
}

export default Dashboard;
