import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import api from '../utils/api';

import Modal from './Modal';

function SendNotification(props) {
  const [isSending, setIsSending] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [appURL, setAppURL] = useState('');
  const [webURL, setWebURL] = useState('');

  useEffect(() => {
    if (props?.webURL) setWebURL(props.webURL);
    if (props?.appURL) setAppURL(props.appURL);
  }, []);

  function sendNotification() {
    if (process.env.REACT_APP_ENV !== 'development') {
      if (!webURL.includes('https://tuyoapp.com/')) {
        toast.error('La URL Web debe incluir https://tuyoapp.com/');
        setWebURL((prev) => `https://tuyoapp.com/${prev.replace('/', '')}`);
        return;
      }
    }

    if (!appURL.includes('tuyoapp://')) {
      toast.error('La URL de la App debe incluir tuyoapp://');
      return;
    }

    setIsSending(true);

    api.oneSignal
      .sendNotification({
        usersIds: props.usersIds,
        content,
        title,
        webURL,
        appURL,
      })
      .then(() => {
        toast.success('Notificacion enviada');
        props.onCloseRequest();
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo enviar la notificacion');
      })
      .finally(setIsSending(false));
  }

  return (
    <Modal onCloseRequest={props.onCloseRequest}>
      <form>
        <label className="d-block" htmlFor="notificationTitle">
          Titulo
        </label>
        <input
          className="d-block rounded w-100 p-2"
          type="text"
          placeholder="Haz lo TUYO"
          id="notificationTitle"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />
        <label className="d-block mt-2" htmlFor="notificationContent">
          Contenido
        </label>
        <input
          className="d-block rounded w-100 p-2"
          type="text"
          id="notificationContent"
          placeholder="Compra lo TUYO"
          value={content}
          onChange={(event) => setContent(event.target.value)}
        />
        <label className="d-block mt-2" htmlFor="notificationAppURL">
          URL App
        </label>
        <input
          readOnly={props?.appURL?.trim()}
          className="d-block rounded w-100 p-2"
          type="text"
          id="notificationAppURL"
          placeholder="tuyoapp://cart"
          value={appURL}
          onChange={(event) => setAppURL(event.target.value)}
        />
        <label className="d-block mt-2" htmlFor="notificationWebURL">
          URL Web
        </label>
        <input
          readOnly={props?.webURL?.trim()}
          className="d-block rounded w-100 p-2"
          type="text"
          id="notificationWebURL"
          placeholder="http://tuyoapp.com/cart"
          value={webURL}
          onChange={(event) => setWebURL(event.target.value)}
        />
        <button
          onClick={sendNotification}
          disabled={isSending || !content || !title}
          type="button"
          className="tuyo-btn rounded w-100 bg-green text-light font-weight-bold py-2 mt-3"
        >
          {isSending ? 'Enviando...' : 'Enviar'}
        </button>
      </form>
    </Modal>
  );
}

export default SendNotification;
