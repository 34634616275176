import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import BounceLoader from 'react-spinners/BounceLoader';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import Card from '../../components/Card';

import api from '../../utils/api';
import colors from '../../assets/resources/colors';
import {
  TagSelectorModal,
  TagSelectorModalType,
} from 'components/TagSelectorModal';
import { DatePickerField } from 'components/DateTimeField';
import OffersTagSelector from 'components/SpecialDiscounts/TagSelectorButton';

function NewSpecialDiscount() {
  const formRef = useRef(null);
  const history = useHistory();

  /* URL params */
  const { id: specialDiscountsId } = useParams();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  const [initilizedValues, setInitilizedValues] = useState();
  const [specialDiscount, setSpecialDiscount] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    items: [],
    initialItems: [],
    type: null,
    onSubmit: () => {},
  });

  /* Set page title */
  useEffect(() => {
    document.title = `${
      specialDiscountsId ? 'Editar' : 'Nuevo'
    } descuento especial | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    if (specialDiscount) initializeIds();
  }, [specialDiscount]);

  useEffect(() => {
    if (specialDiscountsId) {
      api.specialDiscounts
        .getOne(specialDiscountsId)
        .then(({ data }) => {
          setSpecialDiscount({ ...data });
        })
        .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));
    }
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const initializeIds = () => {
    const { stores, products, categories, subcategories } = specialDiscount;

    let initial = {
      stores: [],
      products: [],
      categories: [],
      subcategories: [],
    };

    const tmpStores = (stores || []).map((store) => store.id);
    const tmpProducts = (products || []).map((product) => product.id);
    const tmpCategories = (categories || []).map((category) => category.id);
    const tmpSubCategories = (subcategories || []).map(
      (subcategory) => subcategory.id
    );

    Promise.allSettled([
      api.findAll(
        `/stores?filter=${JSON.stringify({
          attributes: ['storesId', 'name'],
          order: [['name', 'asc']],
          where: {
            active: true,
            storesId: {
              $in: tmpStores,
            },
          },
        })}`
      ),
      api.findAll(
        `/categories?filter=${JSON.stringify({
          attributes: ['productsCategoriesId', 'name'],
          order: [['name', 'asc']],
          where: {
            productsCategoriesId: {
              $in: tmpCategories,
            },
          },
        })}`
      ),
      api.findAll(
        `/products?filter=${JSON.stringify({
          where: { active: '1' },
          attributes: ['productsId', 'name'],
          order: [['name', 'asc']],
          where: {
            productsId: {
              $in: tmpProducts,
            },
          },
        })}`
      ),
      api.findAll(
        `/subcategories?filter=${JSON.stringify({
          attributes: ['productsSubcategoriesId', 'name'],
          order: [['name', 'asc']],
          where: {
            productsSubcategoriesId: {
              $in: tmpSubCategories,
            },
          },
        })}`
      ),
    ])
      .then(
        ([
          storesResponse,
          categoriesResponse,
          productsResponse,
          subcategoriesResponse,
        ]) => {
          if (storesResponse.status === 'fulfilled') {
            const res = storesResponse?.value?.data?.map((store) => ({
              id: store.storesId,
              value: store.name,
            }));

            const tmp = res.map((store) => {
              const tmpStore = stores.find((s) => s.id === store.id) || {};

              return {
                ...store,
                ...tmpStore,
              };
            });

            initial.stores = tmp;
          }

          if (categoriesResponse.status === 'fulfilled') {
            const res = categoriesResponse?.value?.data?.map((category) => ({
              id: category.productsCategoriesId,
              value: category.name,
            }));

            const tmp = res.map((category) => {
              const tmpCategory =
                categories.find((c) => c.id === category.id) || {};

              return {
                ...category,
                ...tmpCategory,
              };
            });

            initial.categories = tmp;
          }

          if (productsResponse.status === 'fulfilled') {
            const res = productsResponse?.value?.data?.map((product) => ({
              id: product.productsId,
              value: product.name,
            }));

            const tmp = res.map((product) => {
              const tmpProduct =
                products.find((p) => p.id === product.id) || {};

              return {
                ...product,
                ...tmpProduct,
              };
            });

            initial.products = tmp;
          }

          if (subcategoriesResponse.status === 'fulfilled') {
            const res = subcategoriesResponse?.value?.data?.map(
              (subcategory) => ({
                id: subcategory.productsSubcategoriesId,
                value: subcategory.name,
              })
            );

            const tmp = res.map((subcategory) => {
              const tmpSubcategory =
                subcategories.find((s) => s.id === subcategory.id) || {};

              return {
                ...subcategory,
                ...tmpSubcategory,
              };
            });

            initial.subcategories = tmp;
          }
        }
      )
      .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`))
      .finally(() => setInitilizedValues(initial));
  };

  async function onSubmit(values) {
    setIsSaving(true);

    const userId = cookies[REACT_APP_COOKIES_USER_ID];
    const _startline = new Date(values.startline).toISOString();
    const _deadline = new Date(values.deadline).toISOString();

    const _products = [...values.products];
    const _categories = [...values.categories];
    const _subcategories = [...values.subcategories];
    const _stores = [...values.stores];
    _products.forEach((product) => {
      delete product.value;
    });
    _categories.forEach((category) => {
      delete category.value;
    });
    _subcategories.forEach((subcategory) => {
      delete subcategory.value;
    });
    _stores.forEach((store) => {
      delete store.value;
    });

    toast.success(
      'Espere un momento, el descuento especial esta siendo procesado'
    );

    const body = {
      ...values,
      startline: _startline,
      deadline: _deadline,
      products: _products,
      categories: _categories,
      subcategories: _subcategories,
      stores: _stores,
      ...(specialDiscountsId
        ? {
            updatedBy: +userId,
          }
        : {
            createdBy: +userId,
          }),
    };

    const action = specialDiscountsId
      ? api.specialDiscounts.update(specialDiscountsId, body)
      : api.specialDiscounts.create(body);

    action
      .then((_) => {
        toast.success(
          `Descuento especial ${specialDiscountsId ? 'actualizado' : 'creado'}`
        );
        history.replace('/admin/special-discounts');
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
      })
      .finally((_) => setIsSaving(false));
  }

  return (
    <div className="content">
      <TagSelectorModal
        {...modalInfo}
        onClose={() => {
          setModalInfo({
            isOpen: false,
            items: [],
          });
        }}
      />
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center text-md-left w-100">
            {specialDiscountsId ? 'Editar' : 'Nuevo'} descuento especial
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <button
            disabled={isSaving}
            onClick={handleSubmit}
            type="button"
            className="bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
          >
            {isSaving ? 'Guardando' : 'Guardar'}
            <BounceLoader color="#fff" loading={isSaving} size="18" />
          </button>
        </div>
      </div>
      <Card className="p-4 mt-3 mt-md-4">
        {(specialDiscountsId && specialDiscount) || !specialDiscountsId ? (
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            validateOnChange={false}
            validate={(values) => {
              const errors = {};
              const {
                stores,
                products,
                categories,
                subcategories,
                name,
                startline,
                deadline,
              } = values;
              if (!name) errors.name = 'Requerido';
              if (!startline) errors.startline = 'Requerido';
              if (!deadline) errors.deadline = 'Requerido';

              if (stores.length > 0) {
                stores.forEach((store) => {
                  const _tuyoDiscount = store.discount?.[0];
                  const _storeDiscount = store.discount?.[1];

                  if (
                    _tuyoDiscount?.length === 0 ||
                    _storeDiscount?.length === 0
                  ) {
                    errors.stores = 'Los descuentos no pueden estar vacíos';
                    return;
                  }

                  if (isNaN(_tuyoDiscount) || isNaN(_storeDiscount)) {
                    errors.stores = 'El descuento debe ser un número';
                    return;
                  }

                  if (_tuyoDiscount < 0 || _tuyoDiscount > 100) {
                    errors.stores =
                      'El descuento de Tuyo debe ser entre 0 y 100';
                    return;
                  }

                  if (_storeDiscount < 0 || _storeDiscount > 100) {
                    errors.stores =
                      'El descuento del comercio debe ser entre 0 y 100';
                    return;
                  }

                  if (_tuyoDiscount + _storeDiscount > 100) {
                    errors.stores =
                      'La suma de los descuentos no puede ser mayor a 100';
                    return;
                  }
                });
              }

              if (products.length > 0) {
                products.forEach((product) => {
                  const _tuyoDiscount = product.discount?.[0];
                  const _storeDiscount = product.discount?.[1];

                  if (
                    _tuyoDiscount?.length === 0 ||
                    _storeDiscount?.length === 0
                  ) {
                    errors.stores = 'Los descuentos no pueden estar vacíos';
                    return;
                  }

                  if (isNaN(_tuyoDiscount) || isNaN(_storeDiscount)) {
                    errors.stores = 'El descuento debe ser un número';
                    return;
                  }

                  if (_tuyoDiscount < 0 || _tuyoDiscount > 100) {
                    errors.stores =
                      'El descuento de Tuyo debe ser entre 0 y 100';
                    return;
                  }

                  if (_storeDiscount < 0 || _storeDiscount > 100) {
                    errors.stores =
                      'El descuento del comercio debe ser entre 0 y 100';
                    return;
                  }

                  if (_tuyoDiscount + _storeDiscount > 100) {
                    errors.stores =
                      'La suma de los descuentos no puede ser mayor a 100';
                    return;
                  }
                });
              }

              if (categories.length > 0) {
                categories.forEach((category) => {
                  const _tuyoDiscount = category.discount?.[0];
                  const _storeDiscount = category.discount?.[1];

                  if (
                    _tuyoDiscount?.length === 0 ||
                    _storeDiscount?.length === 0
                  ) {
                    errors.stores = 'Los descuentos no pueden estar vacíos';
                    return;
                  }

                  if (isNaN(_tuyoDiscount) || isNaN(_storeDiscount)) {
                    errors.stores = 'El descuento debe ser un número';
                    return;
                  }

                  if (_tuyoDiscount < 0 || _tuyoDiscount > 100) {
                    errors.stores =
                      'El descuento de Tuyo debe ser entre 0 y 100';
                    return;
                  }

                  if (_storeDiscount < 0 || _storeDiscount > 100) {
                    errors.stores =
                      'El descuento del comercio debe ser entre 0 y 100';
                    return;
                  }

                  if (_tuyoDiscount + _storeDiscount > 100) {
                    errors.stores =
                      'La suma de los descuentos no puede ser mayor a 100';
                    return;
                  }
                });
              }

              if (subcategories.length > 0) {
                subcategories.forEach((subcategory) => {
                  const _tuyoDiscount = subcategory.discount?.[0];
                  const _storeDiscount = subcategory.discount?.[1];

                  if (
                    _tuyoDiscount?.length === 0 ||
                    _storeDiscount?.length === 0
                  ) {
                    errors.stores = 'Los descuentos no pueden estar vacíos';
                    return;
                  }

                  if (isNaN(_tuyoDiscount) || isNaN(_storeDiscount)) {
                    errors.stores = 'El descuento debe ser un número';
                    return;
                  }

                  if (_tuyoDiscount < 0 || _tuyoDiscount > 100) {
                    errors.stores =
                      'El descuento de Tuyo debe ser entre 0 y 100';
                    return;
                  }

                  if (_storeDiscount < 0 || _storeDiscount > 100) {
                    errors.stores =
                      'El descuento del comercio debe ser entre 0 y 100';
                    return;
                  }

                  if (_tuyoDiscount + _storeDiscount > 100) {
                    errors.stores =
                      'La suma de los descuentos no puede ser mayor a 100';
                    return;
                  }
                });
              }

              return errors;
            }}
            initialValues={{
              name: specialDiscount?.name || '',
              active: specialDiscount?.active || false,
              startline: specialDiscount?.startline || '',
              deadline: specialDiscount?.deadline || '',
              stores: initilizedValues?.stores || [],
              products: initilizedValues?.products || [],
              categories: initilizedValues?.categories || [],
              subcategories: initilizedValues?.subcategories || [],
            }}
            onSubmit={onSubmit}
          >
            {({}) => (
              <Form>
                <div className="row">
                  <div className="col-12 col-md-10 mt-2">
                    <label htmlFor="name" className="d-block">
                      Nombre
                    </label>
                    <Field
                      className="w-100 p-2 rounded"
                      name="name"
                      type="text"
                      placeholder="Ej. Descuento especial de navidad"
                    />
                    <ErrorMessage
                      name="name"
                      component="span"
                      className="text-red"
                    />
                  </div>

                  <div className="col-12 col-md-2 d-flex flex-column justify-content-center align-items-center mt-2 col-md-2">
                    <label className="d-block" htmlFor="active">
                      Activo
                    </label>
                    <Field name="active" type="checkbox" id="active" />
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <label className="d-block" htmlFor="startline">
                      Inicio
                    </label>
                    <DatePickerField name="startline" />
                    <ErrorMessage
                      name="startline"
                      component="small"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <label className="d-block" htmlFor="deadline">
                      Fin
                    </label>
                    <DatePickerField name="deadline" />
                    <ErrorMessage
                      name="deadline"
                      component="small"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-12 my-4">
                    <h4 className="text-dark-blue font-weight-bold text-center">
                      Participantes
                    </h4>
                  </div>

                  {/* stores */}
                  <div className="col-12 mt-4">
                    <Field name="stores">
                      {({ field, form: { setFieldValue } }) => (
                        <OffersTagSelector
                          name="stores"
                          className="w-full md:w-1/2"
                          title={
                            field?.value?.length > 0
                              ? 'Editar tiendas'
                              : 'Agregar tiendas'
                          }
                          tags={field.value}
                          onClick={() => {
                            setModalInfo({
                              title: 'Agregar tiendas',
                              initialItems: field.value,
                              onSubmit: (data) => {
                                setFieldValue(
                                  'stores',
                                  data.map((item) => ({
                                    freeDelivery: false,
                                    discount: [0, 0],
                                    ...item,
                                  }))
                                );
                              },
                              isOpen: true,
                              type: TagSelectorModalType.STORES,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  {/* categories */}
                  <div className="col-12 mt-4">
                    <Field name="categories">
                      {({ field, form: { setFieldValue } }) => (
                        <OffersTagSelector
                          name="categories"
                          className="w-full md:w-1/2"
                          title={
                            field?.value?.length > 0
                              ? 'Editar categorías'
                              : 'Agregar categorías'
                          }
                          tags={field.value}
                          onClick={() => {
                            setModalInfo({
                              title: 'Agregar categorías',
                              initialItems: field.value,
                              onSubmit: (data) => {
                                setFieldValue(
                                  'categories',
                                  data.map((item) => ({
                                    freeDelivery: false,
                                    discount: [0, 0],
                                    ...item,
                                  }))
                                );
                              },
                              isOpen: true,
                              type: TagSelectorModalType.CATEGORIES,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  {/* subcategories */}
                  <div className="col-12 mt-4">
                    <Field name="subcategories">
                      {({ field, form: { setFieldValue } }) => (
                        <OffersTagSelector
                          name="subcategories"
                          className="w-full md:w-1/2"
                          title={
                            field?.value?.length > 0
                              ? 'Editar subcategorías'
                              : 'Agregar subcategorías'
                          }
                          tags={field.value}
                          onClick={() => {
                            setModalInfo({
                              title: 'Agregar subcategorías',
                              initialItems: field.value,
                              onSubmit: (data) => {
                                setFieldValue(
                                  'subcategories',
                                  data.map((item) => ({
                                    freeDelivery: false,
                                    discount: [0, 0],
                                    ...item,
                                  }))
                                );
                              },
                              isOpen: true,
                              type: TagSelectorModalType.SUBCATEGORIES,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  {/* products */}
                  <div className="col-12 mt-4">
                    <Field name="products">
                      {({ field, form: { setFieldValue } }) => (
                        <OffersTagSelector
                          name="products"
                          className="w-full md:w-1/2"
                          title={
                            field?.value?.length > 0
                              ? 'Editar productos'
                              : 'Agregar productos'
                          }
                          tags={field.value}
                          onClick={() => {
                            setModalInfo({
                              title: 'Agregar productos',
                              initialItems: field.value,
                              onSubmit: (data) => {
                                setFieldValue(
                                  'products',
                                  data.map((item) => ({
                                    freeDelivery: false,
                                    discount: [0, 0],
                                    ...item,
                                  }))
                                );
                              },
                              isOpen: true,
                              type: TagSelectorModalType.PRODUCTS,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </Card>
    </div>
  );
}

export default NewSpecialDiscount;
