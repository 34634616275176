import * as htmlToImage from 'html-to-image';

import { useEffect, useRef, useState } from 'react';

import { BounceLoader } from 'react-spinners';
import React from 'react';
import api from 'utils/api';
import { formatPrice } from 'utils/misc';
import { getOrderCode } from 'utils/misc';
import { parseDate } from 'utils/misc';

function PayInvoice({ voucher, className, isLoading, onClose }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [card, setCard] = useState(null);
  const refModal = useRef(null);

  useEffect(() => {
    api.paymentMovements
      .getAll({
        where: {
          fk_paymentVouchersId: voucher.paymentVouchersId,
          resCode: '00',
        },
      })
      .then((res) => {
        const _transactions = res.data || [];
        const _findedCard =
          (_transactions.find((el) => el.type == 'CARD') || {}).card || '';
        const _card = _findedCard.split('|').reverse().join(' - ');

        setTransactions(_transactions);
        setCard(_card);
      });
  }, [voucher]);

  function printInvoice() {
    if (refModal?.current) {
      setIsDownloading(true);

      setTimeout(() => {
        htmlToImage
          .toPng(refModal?.current, {
            width: refModal?.current?.offsetWidth,
            height: refModal?.current?.offsetHeight,
          })
          .then((dataUrl) => {
            setIsDownloading(false);
            const link = document.createElement('a');
            link.download = `PTY-${getOrderCode(
              voucher?.paymentVouchersId
            )}.png`;
            link.href = dataUrl;
            link.click();
          });
      }, 100);
    }
  }

  return (
    <div
      ref={refModal}
      className={`position-relative bg-white p-4 ${className}`}
    >
      <img
        src="/images/invoice-textures.svg"
        className="position-absolute top-0 left-0 z-0 w-1/4"
        alt=""
      />
      <img
        src="/images/invoice-tuyo.svg"
        className="position-absolute bottom-0 right-0 top-0 z-0 h-full"
        alt=""
      />
      <div className="w-full h-full z-10 position-relative flex flex-col justify-center">
        <span className="material-icons-round text-green text-center text-7xl">
          check_circle
        </span>
        <p className="text-indigo-dark text-center font-extrabold text-3xl py-2">
          ¡Pago exitoso!
        </p>
        <p className="text-indigo-dark text-center font-bold text-xl">
          Gracias por usar Tuyo Pay.
        </p>

        <p className="text-indigo-dark text-center font-normal text-lg">
          A continuación te compartimos los detalles de pago:
        </p>

        <hr className="mt-4" style={{ borderTop: '1px dashed lightgrey' }} />

        <div className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center">
          <span className="font-light text-indigo-dark">Comercio</span>
          <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right">
            {voucher?.store || voucher?.name}
          </span>
        </div>

        {voucher?.firstname && voucher?.lastname && (
          <div className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center">
            <span className="font-light text-indigo-dark mr-8">Nombre</span>
            <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right">
              {`${voucher?.firstname} ${voucher?.lastname}`}
            </span>
          </div>
        )}

        {voucher?.concept && (
          <div className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center">
            <span className="font-light text-indigo-dark mr-8">Concepto</span>
            <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right">
              {voucher?.concept}
            </span>
          </div>
        )}

        <div className="mt-2 mb-8 d-flex justify-content-between whitespace-pre-wrap text-center">
          <span className="font-light text-indigo-dark mr-8">Recibo</span>
          <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right">
            {`PTY-${getOrderCode(voucher?.paymentVouchersId)}`}
          </span>
        </div>

        {card && (
          <div className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center">
            <span className="font-light text-indigo-dark mr-8">Tarjeta</span>
            <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right text-uppercase">
              {card}
            </span>
          </div>
        )}

        {(transactions ?? [])?.map((x, i) => {
          return (
            <>
              <div
                key={i}
                className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center"
              >
                <span className="font-light text-indigo-dark mr-8">
                  {x?.type === 'MILLAS'
                    ? 'Cargo en puntos'
                    : x?.type === 'CARD'
                    ? 'Cargo en tarjeta'
                    : x?.type === 'CARD_INSTALLMENT'
                    ? 'Cargo en tarjeta (cuotas)'
                    : x?.type == 'BALANCE'
                    ? 'Cargo en saldo'
                    : ''}
                  :{' '}
                </span>
                <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right">
                  {formatPrice(Math.min(+x?.amount / 100))}
                </span>
              </div>
              <div className="mt-2 d-flex justify-content-between whitespace-pre-wrap text-center">
                <span className="font-light text-indigo-dark mr-8">
                  Referencia
                </span>
                <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right text-uppercase">
                  {x.ref}
                </span>
              </div>
              <div className="mt-2 mb-4 d-flex justify-content-between whitespace-pre-wrap text-center">
                <span className="font-light text-indigo-dark mr-8">
                  Fecha de pago
                </span>
                <span className="font-bold text-indigo-dark whitespace-pre-wrap text-right text-uppercase">
                  {parseDate(x?.createdAt)}
                </span>
              </div>
            </>
          );
        })}

        <hr className="mt-4 mb-2" />

        <div className="d-flex justify-content-between whitespace-pre-wrap text-center">
          <span className="font-extrabold text-indigo-dark text-xl">
            Total pagado
          </span>
          <span className="font-extrabold text-indigo-dark text-xl text-uppercase">
            {formatPrice(+voucher?.amount ?? 0)}
          </span>
        </div>

        {!isDownloading && (
          <div>
            <button
              type="button"
              disabled={isLoading || isDownloading}
              className="rounded-2xl bg-green transitionable w-full py-3 px-4 font-extrabold text-white text-lg d-flex justify-content-center justify-items-items-center mt-4"
              onClick={printInvoice}
            >
              <span className="material-icons-round">save_alt</span>
              <p className="mx-2">Descargar comprobante</p>
              {isLoading ? <BounceLoader className="text-grey" /> : ''}
            </button>
            <button
              type="button"
              disabled={isLoading || isDownloading}
              className="rounded-2xl pale-grey-button transitionable w-full py-3 px-4 font-extrabold text-indigo-dark text-lg flex justify-center mt-2"
              onClick={onClose}
            >
              Cerrar
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PayInvoice;
