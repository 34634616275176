import ReactModal from 'components/Modal';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'utils/api';
import { deconvertId } from 'utils/misc';
import { BounceLoader } from 'react-spinners';
import colors from 'assets/resources/colors';
import ReactTooltip from 'react-tooltip';

const TagSelectorModalType = {
  STORES: 'stores',
  PRODUCTS: 'products',
  CATEGORIES: 'categories',
  SUBCATEGORIES: 'subcategories',
};

function TagSelectorModal({
  initialItems,
  title,
  onSubmit,
  onRemove,
  submitText,
  removeText,
  isOpen,
  onClose,
  type,
  where,
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (initialItems) setSelectedItems([...(initialItems ?? [])]);
  }, [initialItems]);

  useEffect(() => {
    setItems([]);
  }, [type]);

  const searchItems = async (values, { setSubmitting }) => {
    setIsLoading(true);
    setSubmitting(true);
    const { query: value } = values;
    let _items = [];

    switch (type) {
      case TagSelectorModalType.STORES:
        const items = await api.stores.findByName(
          value,
          ['name', 'image', 'storesId'],
          null,
          where
        );
        _items = items?.data?.map((item) => ({
          id: item?.storesId,
          image: item?.image,
          value: item?.name,
        }));

        break;
      case TagSelectorModalType.PRODUCTS:
        const products = await api.products.findByName({
          name: value,
          attributes: ['name', 'productsId'],
          filter: {
            include: [
              {
                association: 'images',
                limit: 1,
                attributes: ['url'],
              },
            ],
          },
          where,
        });
        _items = products?.data?.map((item) => ({
          id: item?.productsId,
          image: item?.images?.[0]?.url,
          value: item?.name,
        }));
        break;
      case TagSelectorModalType.CATEGORIES:
        const categories = await api.categories.findByName(
          value,
          ['name', 'image', 'productsCategoriesId'],
          where
        );
        _items = categories?.data?.map((item) => ({
          id: item?.productsCategoriesId,
          image: item?.image,
          value: item?.name,
        }));
        break;
      case TagSelectorModalType.SUBCATEGORIES:
        const subcategories = await api.subcategories.findByName(
          value,
          ['name', 'image', 'productsSubcategoriesId'],
          where
        );
        _items = subcategories?.data?.map((item) => ({
          id: item?.productsSubcategoriesId,
          image: item?.image,
          value: item?.name,
        }));
        break;
      default:
        _items = [];
        break;
    }

    _items.sort((a, b) => a.value.localeCompare(b.value));
    setItems(_items);

    setSubmitting(false);
    setIsLoading(false);
  };

  const searchByUrl = (values, { setSubmitting }) => {
    setSubmitting(true);
    const hexId = values.url.replace(/(.*\/p\/.*\/)/g, '');

    if (!hexId) {
      toast.error('Porfavor, ingrese una URL válida');
      setSubmitting(false);
      return;
    }

    const id = deconvertId(hexId);

    api.products
      .getById(id)
      .then((res) => {
        if (!res.data) {
          toast.error('Porfavor, ingrese una URL válida');
          return;
        }

        const item = {
          id: res.data.productsId,
          value: res.data.name,
        };
        setSelectedItems([...selectedItems, item]);
      })
      .catch(() => {
        toast.error('Error al agregar producto, porfavor use otra URL');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const selectItem = (item) => {
    if (!selectedItems.find((i) => i.id === item.id)) {
      setSelectedItems((prev) => [...prev, item]);
      setItems((prev) => prev.filter((i) => i.id !== item.id));
    }
  };

  const selectAll = () => {
    setSelectedItems((prev) => [...new Set([...prev, ...items])]);
    setItems([]);
  };

  const removeItem = (item) => {
    setSelectedItems((prev) => prev.filter((i) => i.id !== item.id));

    setItems((prev) => {
      const _items = [...prev, item];
      _items.sort((a, b) => (a.value > b.value ? 1 : -1));
      return _items;
    });
  };

  const removeAll = () => {
    setItems((prev) => {
      const _items = [...new Set([...prev, ...selectedItems])];
      _items.sort((a, b) => (a.value > b.value ? 1 : -1));
      return _items;
    });
    setSelectedItems([]);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onCloseRequest={() => {
        if (onClose) onClose();
      }}
    >
      <div className="d-flex flex-column">
        <h4 className="text-dark-blue font-size-x font-weight-bold whitespace-pre-line">
          {title}
        </h4>

        <Formik
          initialValues={{
            query: '',
          }}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={searchItems}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="row w-full m-0 my-3">
                <label
                  name="query"
                  htmlFor="query"
                  className="mx-0 px-0 col-12"
                >
                  Criterio de búsqueda
                </label>
                <div className="col-12 col-md-9 px-0 pr-md-4">
                  <Field
                    className="rounded w-full h-10 px-2"
                    name="query"
                    type="text"
                    placeholder="Ej. Zapatos"
                  />
                  <small className="font-size-xxs text-gray">
                    Si el criterio de búsqueda es vacío, se mostrarán todos los
                    elementos
                  </small>
                </div>
                <button
                  type="submit"
                  style={{
                    margin: 'auto !important',
                  }}
                  disabled={isSubmitting}
                  className="h-10 bg-green tuyo-btn px-4 rounded text-light font-weight-bold d-flex align-items-center justify-content-around col-12 col-md-3 mt-2 mt-md-0 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Buscar
                </button>
              </div>
            </Form>
          )}
        </Formik>

        {type === TagSelectorModalType.PRODUCTS && (
          <Formik
            initialValues={{
              url: '',
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validate={(value) => {
              const errors = {};

              if (!value.url.trim()) {
                toast.error('Porfavor, ingrese una URL');
                errors.url = 'Requerido';
              }

              return errors;
            }}
            onSubmit={searchByUrl}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="row w-full m-0 mb-3">
                  <label name="url" htmlFor="url" className="mx-0 px-0 col-12">
                    URL del producto
                  </label>
                  <div className="col-12 col-md-9 px-0 pr-md-4">
                    <Field
                      className="rounded w-full h-full h-10 px-2"
                      name="url"
                      type="text"
                      placeholder="Ej. https://tuyoapp.com/p/Camisa-Talla-10-A-12---S.M/a1ce"
                    />
                  </div>
                  <button
                    type="submit"
                    style={{
                      margin: 'auto !important',
                    }}
                    disabled={isSubmitting}
                    className="h-10 bg-green tuyo-btn px-4 rounded text-light font-weight-bold d-flex align-items-center justify-content-around  col-12 col-md-3 mt-2 mt-md-0  disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Agregar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}

        <div className="w-full flex justify-center">
          <BounceLoader color={colors.green} loading={isLoading} size="30" />
        </div>

        <hr className="w-full my-2" />

        <div
          style={{
            overflow: 'none',
          }}
          className="mx-0 row w-full"
        >
          <div
            style={{
              maxHeight: 320,
              overflowY: 'auto',
            }}
            className="col-12 col-md-6"
          >
            {items?.map((item) => (
              <button
                key={item.id}
                onClick={selectItem.bind(null, item)}
                className="p-2 box-shadow-sm hover-darker my-2 w-full rounded flex justify-between align-items-center"
              >
                <img
                  className="rounded w-9 h-9 border shrink-0"
                  src={item.image}
                  alt={item.value}
                />
                <p>
                  {item.value.length > 50
                    ? item.value.substring(0, 50) + '...'
                    : item.value}
                </p>
              </button>
            ))}
          </div>
          <div className="col-12 col-md-6 ">
            <div className="w-full flex space-x-2 h-10">
              <button
                type="submit"
                data-tip="Agregar todos"
                disabled={items.length === 0}
                className="bg-purple tuyo-btn p-3 mt-1 ml-1 rounded text-light font-weight-bold d-flex align-items-center justify-content-center mb-2 w-full md:w-auto disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={selectAll}
              >
                <span className="material-icons">add</span>
                <ReactTooltip effect="solid" place="top" />
              </button>
              <button
                type="submit"
                data-tip="Limpiar"
                disabled={selectedItems.length === 0}
                className="bg-red tuyo-btn p-3 mt-1 ml-1 rounded text-light font-weight-bold d-flex align-items-center justify-content-center mb-2 w-full md:w-auto disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={removeAll}
              >
                <span className="material-icons">delete</span>
                <ReactTooltip effect="solid" place="top" />
              </button>
            </div>
            <div
              style={{
                maxHeight: 300,
                overflowY: 'auto',
              }}
              className="h-full"
            >
              {selectedItems?.map((item) => (
                <button
                  style={{
                    display: 'inline-flex',
                  }}
                  className="p-1 m-1 rounded-lg bg-light-gray"
                  onClick={removeItem.bind(null, item)}
                  key={item.id}
                >
                  {item.value.length > 50
                    ? item.value.substring(0, 50) + '...'
                    : item.value}
                  <i
                    style={{
                      fontSize: '1rem',
                    }}
                    className="material-icons m-auto ml-1"
                  >
                    close
                  </i>
                </button>
              ))}
            </div>
          </div>
        </div>

        {(selectedItems?.length > 0 || items?.length > 0) && (
          <hr className="w-full my-2" />
        )}

        <div className="row my-3 -mx-[15px]">
          <div className={onRemove ? 'col-6' : 'col-12'}>
            <button
              type="button"
              style={{
                margin: 'auto !important',
                zIndex: 10,
              }}
              onClick={() => {
                if (onSubmit) onSubmit(selectedItems);
                if (onClose) onClose();
              }}
              className="bg-green tuyo-btn  mx-0 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around w-full"
            >
              {submitText || 'Agregar'}
            </button>
          </div>
          <div className={`col-6 ${onRemove ? '' : 'd-none'}`}>
            <button
              type="button"
              style={{
                margin: 'auto !important',
                zIndex: 10,
              }}
              onClick={() => {
                if (onRemove) onRemove(selectedItems);
                if (onClose) onClose();
              }}
              className="bg-red tuyo-btn mx-0 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around w-full"
            >
              {removeText || 'Eliminar'}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export { TagSelectorModal, TagSelectorModalType };
