import React from 'react';
import { Field, FieldArray } from 'formik';
import ReactTooltip from 'react-tooltip';

function LimitsTagSelector({ title, onClick, tags, name, className, onEdit }) {
  return (
    <>
      <button
        onClick={() => {
          if (onClick) onClick();
        }}
        type="button"
        className={`tuyo-btn bg-green text-white px-4 py-2 rounded w-full mb-2 ${
          className || ''
        }`}
      >
        {title}
      </button>
      <div>
        <FieldArray
          name={name}
          render={(arrayHelpers) => (
            <>
              {tags?.length > 0 && (
                <div className="flex">
                  <div className="w-full row px-0">
                    <span className="col-8 col-md-6" />
                    <div className="col-4 flex justify-content-center">
                      <span className="text-center font-weight-bold">
                        Límite de compra
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {tags?.map((item, index) => (
                <>
                  <div key={index} className="flex row px-0">
                    <div className="col-8 col-md-6 flex flex-column">
                      <Field name={`${name}[${index}].id`}>
                        {({ field }) => (
                          <>
                            <span>
                              Items seleccionados: {field?.value?.length}
                            </span>
                            <div
                              style={{
                                maxHeight: 250,
                              }}
                              className="overflow-y-auto"
                            >
                              {(field?.value ?? []).map((item, index) => (
                                <span
                                  type="button"
                                  className="rounded-lg bg-light-gray p-1 m-1 text-sm"
                                  key={index}
                                >
                                  {item.value}
                                </span>
                              ))}
                            </div>
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="h-10 col-4">
                      <Field
                        type="number"
                        placeholder="Ej: 15"
                        min="0"
                        step="1"
                        name={`${name}.${index}.limit`}
                        className="w-full h-full rounded text-center"
                        onBlur={(e) =>
                          (e.target.value = parseInt(e.target.value))
                        }
                      />
                    </div>
                    <div className="h-10 col-12 col-md-2 flex space-x-2 mt-3 mt-md-0">
                      <button
                        type="button"
                        data-tip="Editar límite"
                        className="material-icons text-white bg-purple rounded h-10 w-10 active:opacity-150"
                        onClick={() => onClick(`${name}.${index}`, item.id)}
                      >
                        edit
                      </button>
                      <button
                        type="button"
                        data-tip="Eliminar límite"
                        className="material-icons text-white bg-red rounded w-10 w-10 active:opacity-150"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        delete
                      </button>
                      <ReactTooltip effect="solid" place="top" />
                    </div>
                  </div>
                  <hr />
                </>
              ))}
            </>
          )}
        />
      </div>
    </>
  );
}

export default LimitsTagSelector;
