import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import addSVG from '../../assets/icons/add.svg';

import Card from '../../components/Card';
import PaginatedTable from '../../components/PaginatedTable/Table';

import api from '../../utils/api';

const now = new Date();

const table = {
  options: {
    id: 'banksAvailableFeesId',
    pageSize: 10,
  },
  columns: [
    {
      text: 'Banco',
      key: 'bank',
      className: 'text-center',
      searchable: true,
      searchType: 'text'
    },
    {
      text: 'Plazos disponibles',
      key: 'availableMonths',
      className: 'text-center',
      searchable: true,
      searchType: 'text',
      middleware: availableMonths => availableMonths.split(',').join(', ')
    },
  ],
};

function BanksAvailableMonthsList() {
  const { REACT_APP_TITLE } = process.env;
  const history = useHistory();

  useEffect(() => {
    document.title = `Pagos en cuotas | ${REACT_APP_TITLE}`;
  }, []);

  function getPage(pageNumber, setter, filter) {
    api
      .findAll(
        '/banksavailablefees?filter=' +
        JSON.stringify({
          order: [filter?.order || ['banksAvailableFeesId', 'desc']],
          where: filter?.where || {},
          limit: table.options.pageSize,
          offset: table.options.pageSize * (pageNumber - 1),
        })
      )
      .then(response => {
        setter(
          [...response.data]
        );
      })
      .catch(error => {
        console.error(error);
        toast.warning('[SERVER_ERROR] ' + error);
      });
  }

  function getCount(setter) {
    api
      .count('/banksavailablefees')
      .then(response => {
        setter(response.data.count);
      })
      .catch(error => {
        console.error(error);
        toast.warning('[SERVER_ERROR] ' + error);
      });
  }

  function getFilteredCount(where, setter) {
    api.findAll('/banksavailablefees?filter=' + JSON.stringify({
      where: { ...where },
      attributes: ['createdBy']
    }))
      .then(response => {
        setter(response.data.length);
      })
      .catch(error => {
        console.error(error);
        toast.warning('[SERVER_ERROR] ' + error);
      });
  };

  return (
    <div className='content'>
      <div className='row'>
        <div className='col-12 col-md-8 col-xl-9 d-flex align-items-center'>
          <h3 className='text-dark-blue font-size-2x font-weight-bold'>Pagos en cuotas</h3>
        </div>
        <div className='col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0'>
          <Link to='/admin/banks-available-months/new' className='w-100'>
            <button
              type='button'
              className='d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold'>
              <img src={addSVG} alt='' className='d-inline-block mr-2' style={{ height: '16px' }} />
              Nuevo banco
            </button>
          </Link>
        </div>
      </div>
      <div className='row mt-3 mt-md-4' style={{ flexGrow: '1' }}>
        <div className='col-12'>
          <Card className='p-md-4'>
            <PaginatedTable
              getCount={getCount}
              getFilteredCount={getFilteredCount}
              getPage={getPage}
              columns={table.columns}
              options={table.options}
              onRowClick={id => history.push(`/admin/banks-available-months/${id}/edit`)}
              pageSize={table.options.pageSize}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default BanksAvailableMonthsList;
