import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';

function Logout() {
  const {
    REACT_APP_COOKIES_JWT,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_STORE_ID
  } = process.env;

  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies([]);

  useEffect(() => {
    removeCookie(REACT_APP_COOKIES_JWT, { path: '/' });
    removeCookie(REACT_APP_COOKIES_USER_ID, { path: '/' });
    removeCookie(REACT_APP_COOKIES_STORE_ID, { path: '/' });

    history.push('/login');
  }, []);

  return null;
}

export default Logout;
