import { ErrorMessage, Field, Form, Formik } from 'formik';

import { BounceLoader } from 'react-spinners';
import Card from './Card';
import React from 'react';

function SearchReport({ className, onSubmit }) {
    const onValidate = (values) => {
        const errors = {};

        if (!values.startDate) errors.startDate = 'Requerido';
        if (!values.endDate) errors.endDate = 'Requerido';

        return errors;
    };

    return (
        <Formik
            initialValues={{
                startDate: '',
                endDate: '',
            }}
            onSubmit={onSubmit}
            validate={onValidate}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <Card className={className}>
                        <p className="text-dark-blue font-weight-bold font-size-125x mb-2">
                            Reporte de búsquedas
                        </p>
                        <div className="row">
                            <div className="col d-flex flex-column">
                                <label htmlFor="startDate">Inicio</label>
                                <Field className="rounded p-2" type="date" name="startDate" />
                                <ErrorMessage
                                    name="startDate"
                                    component="span"
                                    className="text-danger"
                                />
                            </div>
                            <div className="col d-flex flex-column">
                                <label htmlFor="endDate">Final</label>
                                <Field className="rounded p-2" type="date" name="endDate" />
                                <ErrorMessage
                                    name="endDate"
                                    component="span"
                                    className="text-danger"
                                />
                            </div>
                        </div>
                        <button
                            type="submit"
                            disabled={isSubmitting || !dirty || !isValid}
                            className="w-100 bg-purple tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around mt-2"
                        >
                            {isSubmitting ? 'Generando reporte...' : 'Generar reporte'}
                            <BounceLoader color="#fff" loading={isSubmitting} size="18" />
                        </button>
                    </Card>
                </Form>
            )}
        </Formik>
    );
}

export default SearchReport;
