import React, { createRef, useEffect, useState } from 'react';
import { BounceLoader } from 'react-spinners';

import Card from 'components/Card';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import api from 'utils/api';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import TagSelectorModalButton from 'components/TagSelectorModalButton';
import {
  TagSelectorModal,
  TagSelectorModalType,
} from 'components/TagSelectorModal';
import { DatePickerField } from 'components/DateTimeField';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';

function BannedCoupons() {
  const formRef = createRef();

  const { REACT_APP_TITLE, REACT_APP_COOKIES_USER_ID, REACT_APP_COOKIES_JWT } =
    process.env;
  const [cookies] = useCookies([
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  ]);
  const [selectedBannedCoupon, setSelectedBannedCoupon] = useState({});
  const [isSelectionLoading, setIsSelectionLoading] = useState(false);
  const [tableRemountCount, setTableRemountCount] = useState(0);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    items: [],
    initialItems: [],
    type: null,
    onSubmit: () => {},
  });

  const tableProps = {
    rowId: 'bannedCouponsId',
    baseURL: '/bannedcoupons',
    baseFilter: {
      order: [['bannedCouponsId', 'DESC']],
    },
    pageSize: 10,
    columns: [
      {
        title: 'Id',
        objectProperty: 'bannedCouponsId',
        className: 'text-center',
      },
      {
        title: 'Razón',
        objectProperty: 'reason',
        className: 'text-center',
      },
      {
        title: 'Inicio',
        objectProperty: 'startline',
        className: 'text-center',
        middleware: (value) => moment(value).format('DD/MM/YYYY HH:mm A'),
      },
      {
        title: 'Fin',
        objectProperty: 'deadline',
        className: 'text-center',
        isCustom: true,
        middleware: (value) => moment(value).format('DD/MM/YYYY HH:mm A'),
      },
      {
        title: 'Creado',
        objectProperty: 'createdAt',
        className: 'text-center',
        middleware: (value) => moment(value).format('DD/MM/YYYY HH:mm A'),
      },
      {
        title: 'Tiendas',
        objectProperty: 'stores',
        className: 'text-center',
        middleware: (stores) => JSON.stringify(stores),
      },
    ],
  };

  const formikValidate = (values) => {
    const errors = {};

    if (!values.reason) errors.reason = 'Campo requerido';
    if (!values.startline) errors.startline = 'Campo requerido';
    if (!values.deadline) errors.deadline = 'Campo requerido';
    if (!values.stores) errors.stores = 'Campo requerido';

    return errors;
  };

  const formikOnSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const createdBy = cookies[REACT_APP_COOKIES_USER_ID];
    const start = new Date(values.startline).getTime();
    const end = new Date(values.deadline).getTime();

    const body = {
      ...values,
      stores: values.stores.map((store) => store.id),
      ...(selectedBannedCoupon?.bannedCouponsId
        ? { updatedBy: +createdBy }
        : { createdBy: +createdBy }),
      startline: start,
      deadline: end,
    };

    const action = selectedBannedCoupon?.bannedCouponsId
      ? api.bannedCoupons.update(selectedBannedCoupon?.bannedCouponsId, {
          ...body,
        })
      : api.bannedCoupons.create({
          ...body,
        });

    action
      .then(() => {
        toast.success(
          selectedBannedCoupon?.bannedCouponsId
            ? 'Restricción actualizada con éxito'
            : 'Restricción creada con éxito'
        );

        resetForm();
        setSelectedBannedCoupon({});
        setTableRemountCount(tableRemountCount + 1);
      })
      .catch((err) => {
        const details = err?.response?.data?.response?.details;
        console.error(err);
        toast.error(
          selectedBannedCoupon?.bannedCouponsId
            ? 'Error al actualizar la restricción'
            : 'Error al crear restricción'
        );
        toast.error(details.map((detail) => detail.message).join(', '));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onBannedCouponSelect = (bannedCoupon) => {
    setIsSelectionLoading(true);
    api.bannedCoupons
      .getOne(bannedCoupon.bannedCouponsId)
      .then(async (res) => {
        const { data } = res;
        const storesRes = await api.stores.getAll({
          attributes: ['storesId', 'name'],
          order: [['name', 'asc']],
          where: {
            active: true,
            storesId: {
              $in: data?.stores || [],
            },
          },
        });

        setSelectedBannedCoupon({
          ...data,
          stores: storesRes?.data?.map((store) => ({
            id: store.storesId,
            value: store.name,
          })),
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error al obtener la restricción de cupones');
      })
      .finally(() => {
        setIsSelectionLoading(false);
      });
  };

  const cancelUpdate = () => {
    setSelectedBannedCoupon({});
    if (formRef.current) formRef.current.resetForm();
  };

  return (
    <div className="content">
      <TagSelectorModal
        {...modalInfo}
        onClose={() => {
          setModalInfo({
            isOpen: false,
            items: [],
          });
        }}
      />
      <div>
        <div className="row">
          <h3 className="text-dark-blue font-size-2x font-weight-bold col-12 mb-2">
            Restriccion de cupones
          </h3>
          <div className="col">
            <Card className="p-md-4 mb-4">
              <Formik
                innerRef={formRef}
                enableReinitialize={true}
                initialValues={{
                  startline: selectedBannedCoupon.startline || '',
                  deadline: selectedBannedCoupon.deadline || '',
                  active: selectedBannedCoupon.active || true,
                  reason: selectedBannedCoupon.reason || '',
                  stores: selectedBannedCoupon.stores || [],
                }}
                validate={formikValidate}
                onSubmit={formikOnSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="row">
                      <div className="col-12 col-md-8 mt-2">
                        <label htmlFor="value" className="d-block">
                          Razón
                        </label>
                        <Field
                          className="w-100 p-2 rounded border border-gray-300"
                          name="reason"
                          type="text"
                          placeholder="Ej: 0.25"
                        />
                        <ErrorMessage
                          name="reason"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-4 mt-2">
                        <label htmlFor="active" className="d-block">
                          Activo
                        </label>
                        <Field
                          className="w-100 p-2 rounded border border-gray-300"
                          name="active"
                          placeholder="Ej: 0.25"
                          type="checkbox"
                        />
                        <ErrorMessage
                          name="active"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <label className="d-block" htmlFor="startline">
                          Inicio
                        </label>
                        <DatePickerField name="startline" />
                        <ErrorMessage
                          name="startline"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <label className="d-block" htmlFor="deadline">
                          Fin
                        </label>
                        <DatePickerField name="deadline" />
                        <ErrorMessage
                          name="deadline"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <label htmlFor="stores" className="d-block">
                          Tiendas
                        </label>
                        <Field name="stores">
                          {({ field, form: { setFieldValue } }) => (
                            <TagSelectorModalButton
                              title="Seleccionar tiendas"
                              tags={field.value || []}
                              onClick={() => {
                                setModalInfo({
                                  title: 'Agregar tiendas',
                                  initialItems: field.value || [],
                                  onSubmit: (data) =>
                                    setFieldValue('stores', data),
                                  isOpen: true,
                                  type: TagSelectorModalType.STORES,
                                });
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="stores"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="row w-full col-12">
                        {isSelectionLoading ? (
                          <div className="col-12 d-flex justify-content-center">
                            <BounceLoader color="#01d781" size="26" />
                          </div>
                        ) : (
                          <>
                            <div className="col-12 col-md-6 mt-3">
                              <button
                                type="reset"
                                onClick={cancelUpdate}
                                className="w-100 bg-red tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                              >
                                Limpiar
                              </button>
                            </div>

                            {selectedBannedCoupon?.bannedCouponsId ? (
                              <div className="col-12 col-md-6 mt-3">
                                <button
                                  type="submit"
                                  className="w-100 bg-green tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                                >
                                  Editar
                                  <BounceLoader
                                    color="#fff"
                                    loading={isSubmitting}
                                    size="18"
                                  />
                                </button>
                              </div>
                            ) : (
                              <div className="col-12 col-md-6  mt-3">
                                <button
                                  type="submit"
                                  className="w-100 bg-green tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                                >
                                  Crear restricción
                                  <BounceLoader
                                    color="#fff"
                                    loading={isSubmitting}
                                    size="18"
                                  />
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>

            <Card className="p-md-4">
              <PaginatedTable
                columns={tableProps.columns}
                pageSize={tableProps.pageSize}
                baseURL={tableProps.baseURL}
                baseFilter={tableProps.baseFilter}
                rowId={tableProps.rowId}
                onRowClick={onBannedCouponSelect}
                remountCount={tableRemountCount}
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannedCoupons;
