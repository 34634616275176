import React, { useEffect, useState } from 'react';

import Card from '../components/Card';
import OrderDetail from '../components/OrderDetail';
import Status from '../components/Status';
import Table from 'components/TableV2/Table';

import { getOrderCode, getOrderStatus, formatPrice } from '../utils/misc';

const tableProps = {
  pageSize: 10,
  baseURL: '/stores-dashboard/orders',
  rowId: 'ordersId',
  baseFilter: {
    where: {
      $or: [
        { orderStatus: { $iRegexp: 'peticion de cambio' } },
        { orderStatus: { $iRegexp: 'devolución' } },
        { orderStatus: { $iRegexp: 'peticion de reembolso' } },
        { orderStatus: { $iRegexp: 'reembolso' } },
      ],
    },
    attributes: ['ordersId', 'total'],
    include: [
      {
        association: 'details',
        attributes: ['fk_purchaseStatusesId'],
        include: [{ association: 'store', attributes: ['storesId', 'name'] }],
      },
      { association: 'user', attributes: ['firstname', 'lastname'] },
      { association: 'driver', attributes: ['firstname', 'lastname'] },
    ],
    order: [['ordersId', 'DESC']],
  },
  columns: [
    {
      title: 'ID',
      objectProperty: 'ordersId',
      searchable: true,
      searchType: 'number-equal',
      sortable: true,
    },
    {
      title: 'Codigo',
      objectProperty: 'ordersId',
      searchable: true,
      searchType: 'number-equal',
      middleware: (ordersId) => getOrderCode(ordersId),
      searchMiddleware: (searchTerm) => parseInt(searchTerm, 16) / 64,
    },
    {
      title: 'Comercios',
      objectProperty: 'details',
      searchable: true,
      searchType: 'text',
      gatewayObjectProperty: '$details.store.name$',
      middleware: (details) =>
        Array.from(new Set(details.map((detail) => detail?.store?.name))).join(
          ', '
        ),
    },
    {
      title: 'Nombre Comprador',
      objectProperty: 'user',
      searchable: true,
      searchType: 'text',
      gatewayObjectProperty: '$user.firstname$',
      middleware: (user) => user?.firstname,
    },
    {
      title: 'Apellido Comprador',
      objectProperty: 'user',
      searchable: true,
      searchType: 'text',
      gatewayObjectProperty: '$user.lastname$',
      middleware: (user) => user?.lastname,
    },
    {
      title: 'Nombre Conductor',
      objectProperty: 'driver',
      searchable: true,
      searchType: 'text',
      gatewayObjectProperty: '$driver.firstname$',
      middleware: (driver) => driver?.firstname,
    },
    {
      title: 'Apellido Conductor',
      objectProperty: 'driver',
      searchable: true,
      searchType: 'text',
      gatewayObjectProperty: '$driver.lastname$',
      middleware: (driver) => driver?.lastname,
    },
    {
      title: 'Monto',
      objectProperty: 'total',
      searchable: true,
      searchType: 'number',
      sortable: true,
      middleware: (total) => formatPrice(total),
    },
    {
      title: 'Estado',
      middleware: (order) => (
        <Status className="w-100" value={getOrderStatus(order.details)} />
      ),
    },
  ],
};

function CanceledOrders() {
  const { REACT_APP_TITLE } = process.env;

  useEffect(() => {
    document.title = `Reembolsos y cambios | ${REACT_APP_TITLE}`;
  }, []);

  const [orderOnViewId, setOrderOnViewId] = useState();
  const [tableRemountCount, setTableRemountCount] = useState(0);

  return (
    <div className="content">
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          <Card className="p-md-4">
            <Table
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
              onRowClick={(order) => {
                setOrderOnViewId(order?.ordersId);
              }}
              remountCount={tableRemountCount}
            />
          </Card>
        </div>
      </div>

      {orderOnViewId && (
        <OrderDetail
          requestGetOrders={() => {
            setTableRemountCount(tableRemountCount + 1);
          }}
          showStatus
          sortProductsByStore
          id={orderOnViewId}
          onCloseRequest={() => setOrderOnViewId(null)}
        />
      )}
    </div>
  );
}

export default CanceledOrders;
