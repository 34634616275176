import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import {
  deleteFromS3,
  fileToBase64URL,
  uploadPictureToS3,
  validPctg,
} from '../../utils/misc';

import BounceLoader from 'react-spinners/BounceLoader';
import Card from 'components/Card';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import InputMask from 'react-input-mask';
import api from '../../utils/api';
import chevronLeftSVG from '../../assets/icons/chevron-left.svg';
import colors from '../../assets/resources/colors';
import swal from '@sweetalert/with-react';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { StoreBranchModal } from 'components/StoreBranchModal';

const PaymentStoreState = {
  CREATED: 'CREATED',
  ERROR: 'ERROR',
  NOT_CREATED: 'NOT_CREATED',
  LOADING: 'LOADING',
  IDLE: 'IDLE',
};

function CheckAffiliationRequest() {
  const { REACT_APP_TITLE } = process.env;
  const formRef = useRef(null);

  const history = useHistory();
  const { id } = useParams();

  const { REACT_APP_COOKIES_USER_ID } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  const [isSaving, setIsSaving] = useState(false);
  const [store, setStore] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showStoreBranchModal, setShowStoreBranchModal] = useState({
    isOpen: false,
    storeBranch: {},
  });
  const [pictures, setPictures] = useState({
    userPhoto: null,
    storeImage: { URL: 'empty' },
    bannerImage: { URL: 'empty' },
    representativeDUI: { URL: 'empty' },
    representativeNIT: { URL: 'empty' },
    companyNIT: { URL: 'empty' },
    contract: { URL: 'empty' },
    societyMembershipCard: { URL: 'empty' },
    cardIVA: { URL: 'empty' },
  });
  const [picturesToDelete, setPicturesToDelete] = useState([]);
  const [documents, setDocuments] = useState();
  const [createPaymentStore, setCreatePaymentStore] = useState(
    PaymentStoreState.IDLE
  );

  useEffect(() => {
    document.title = `${id ? 'Editar' : 'Nuevo'} comercio | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    if (id) {
      api.affiliations
        .getOne(id)
        .then((response) => {
          const store = response.data[0];
          setStore(store);
          setDocuments(JSON.parse(store.docs));
        })
        .catch((err) => {
          toast.warning(
            '[SERVER_ERROR] No se pudo obtener la info. del comercio'
          );
          console.error(err);
        });
    }
  }, []);

  useEffect(() => {
    if (store && documents) {
      setPictures({
        userPhoto: { URL: null },
        storeImage: { URL: null },
        bannerImage: { URL: null },
        representativeDUI: { URL: documents.dui },
        representativeNIT: { URL: documents.nit },
        companyNIT: { URL: documents.nitStore },
        contract: { URL: documents.contract },
        societyMembershipCard: { URL: documents.societyCard },
        cardIVA: { URL: documents.iva },
      });
    }
  }, [store, documents]);

  const handleSubmit = () => {
    formRef.current && formRef.current.handleSubmit();
  };

  const addPicture = async (field, event) => {
    const file = event.target.files[0];
    if (file.size / 1024 >= 2048) {
      toast.warning('Error: La imagen supera el peso maximo permitido');
      event.target.value = '';
    } else {
      const prevURL = pictures[field] ? pictures[field].URL : null;
      prevURL && setPicturesToDelete([...picturesToDelete, prevURL]);
      file &&
        setPictures({
          ...pictures,
          [field]: {
            base64: await fileToBase64URL(file),
            type: file.type,
            name: file.name,
          },
        });
    }
  };

  async function saveStore(formValues) {
    console.log(formValues);
    /* if (!pictures.userPhoto) {
      toast.error('La imagen del usuario es requerida');
      return;
    }

    if (pictures.storeImage.URL === 'empty') {
      toast.error('La imagen de comercio es requerida');
      return;
    }

    if (pictures.bannerImage.URL === 'empty') {
      toast.error('El banner del comercio es requerido');
      return;
    }

    if (pictures.cardIVA.URL === 'empty') {
      toast.error('La tarjeta de IVA del comercio es requerido');
      return;
    } */

    setIsSaving(true);

    const {
      userPhoto,
      storeImage,
      bannerImage,
      representativeDUI,
      representativeNIT,
      companyNIT,
      contract,
      societyMembershipCard,
      cardIVA,
    } = pictures;

    const {
      firstname,
      lastname,
      email,
      userPhone,
      userDui,
      userNit,
      passwordConfirmation,
      storeName,
      registry,
      representative,
      freeDelivery,
      description,
      discount,
      commission,
      isVerified,
      canRefund,
      onlyCash,
      onlyCard,
    } = formValues;

    api.affiliations
      .update(store?.affiliationRequestsId, {
        status: 'APPROVED',
        /* photo: await uploadPictureToS3(
          userPhoto.base64,
          userPhoto.type,
          userPhoto.name
        ),
        dui: userDui,
        nit: userNit, */
        registry: registry,
        representative: representative,
        commission,
        description: description,
        docs: store?.docs,
        updatedBy: userID,
        /* image: await uploadPictureToS3(
          storeImage.base64,
          storeImage.type,
          storeImage.name
        ), */
        /* banner: await uploadPictureToS3(
          bannerImage.base64,
          bannerImage.type,
          bannerImage.name
        ), */
      })
      .then(() => {
        toast.success(`¡Solicitud aprobada y comercio creado!`);
        history.push('/admin/market-affiliations');
      })
      .catch((err) => {
        console.error(err);
        toast.warning(`[SERVER_ERROR] ${err}`);
      })
      .finally(() => setIsSaving(true));

    /* if (id) {
      let userBody = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: userPhone,
        dui: userDui,
        nit: userNit,
        photo: userPhoto.base64
          ? await uploadPictureToS3(
              userPhoto.base64,
              userPhoto.type,
              userPhoto.name
            )
          : userPhoto.URL,
      };

      if (passwordConfirmation != '') {
        userBody = { ...userBody, password: passwordConfirmation };
      }

      picturesToDelete.forEach(async (URL) => {
        await deleteFromS3(URL.substring(URL.lastIndexOf('/') + 1));
      });

      api.stores
        .updateOne(store.storesId, {
          commission,
          discount: parseFloat(discount),
          updatedBy: userID,
          name: storeName,
          registry,
          representative,
          freeDelivery,
          description,
          isVerified,
          canRefund,
          onlyCash,
          onlyCard,
          image: storeImage.base64
            ? await uploadPictureToS3(
                storeImage.base64,
                storeImage.type,
                storeImage.name
              )
            : storeImage.URL,
          banner: bannerImage.base64
            ? await uploadPictureToS3(
                bannerImage.base64,
                bannerImage.type,
                bannerImage.name
              )
            : bannerImage.URL,
        })
        .then(async () => {
          api.users.updateOne(store.user.usersId, userBody).then(async () => {
            const storesDocumentsId = documents.storesDocumentsId;
            const body = {
              ...(storesDocumentsId
                ? {
                    cardIVA: cardIVA.base64
                      ? await uploadPictureToS3(
                          cardIVA.base64,
                          cardIVA.type,
                          cardIVA.name
                        )
                      : cardIVA.URL,
                    representativeDUI: representativeDUI.base64
                      ? await uploadPictureToS3(
                          representativeDUI.base64,
                          representativeDUI.type,
                          representativeDUI.name
                        )
                      : representativeDUI.URL,
                    societyMembershipCard: societyMembershipCard.base64
                      ? await uploadPictureToS3(
                          societyMembershipCard.base64,
                          societyMembershipCard.type,
                          societyMembershipCard.name
                        )
                      : societyMembershipCard.URL,
                    contract: contract.base64
                      ? await uploadPictureToS3(
                          contract.base64,
                          contract.type,
                          contract.name
                        )
                      : contract.URL,
                    representativeNIT: representativeNIT.base64
                      ? await uploadPictureToS3(
                          representativeNIT.base64,
                          representativeNIT.type,
                          representativeNIT.name
                        )
                      : representativeNIT.URL,
                    companyNIT: companyNIT.base64
                      ? await uploadPictureToS3(
                          companyNIT.base64,
                          companyNIT.type,
                          companyNIT.name
                        )
                      : companyNIT.URL,
                    fk_storesId: store.storesId,
                    updatedBy: userID,
                  }
                : {
                    cardIVA: cardIVA.base64
                      ? await uploadPictureToS3(
                          cardIVA.base64,
                          cardIVA.type,
                          cardIVA.name
                        )
                      : '',
                    representativeDUI: representativeDUI.base64
                      ? await uploadPictureToS3(
                          representativeDUI.base64,
                          representativeDUI.type,
                          representativeDUI.name
                        )
                      : '',
                    societyMembershipCard: societyMembershipCard.base64
                      ? await uploadPictureToS3(
                          societyMembershipCard.base64,
                          societyMembershipCard.type,
                          societyMembershipCard.name
                        )
                      : '',
                    contract: contract.base64
                      ? await uploadPictureToS3(
                          contract.base64,
                          contract.type,
                          contract.name
                        )
                      : '',
                    representativeNIT: representativeNIT.base64
                      ? await uploadPictureToS3(
                          representativeNIT.base64,
                          representativeNIT.type,
                          representativeNIT.name
                        )
                      : '',
                    companyNIT: companyNIT.base64
                      ? await uploadPictureToS3(
                          companyNIT.base64,
                          companyNIT.type,
                          companyNIT.name
                        )
                      : '',
                    fk_storesId: store.storesId,
                    createdBy: userID,
                  }),
            };
            storesDocumentsId
              ? api.stores.documents.updateOne(
                  documents.storesDocumentsId,
                  body
                )
              : api.stores.documents.create(body);
          });
        })
        .then(() => {
          toast.success('¡Comercio actualizado!');
          history.push('/admin/stores');
        })
        .catch((err) => {
          toast.warning(`[SERVER_ERROR] ${err}`);
          console.error(err);
          setIsSaving(false);
        });
    } else {
      
    } */
  }

  function deniedRequest() {
    swal({
      title: '¿Desea rechazar esta Solicitud?',
      text: 'Esta es una acción irreversible',
      icon: 'warning',
      button: 'Eliminar',
      dangerMode: true,
    }).then((pressedOK) => {
      if (pressedOK) {
        setIsDeleting(true);

        swal({
          content: {
            element: 'input',
            attributes: {
              placeholder: 'Motivo del rechazo',
            },
          },
          title: 'Razón de rechazo de la solicitud',
          icon: 'warning',
          button: {
            text: 'Rechazar',
          },
        }).then((reason) => {
          if (reason) {
            api.affiliations
              .update(store?.affiliationRequestsId, {
                status: 'DENIED',
                reason,
                updatedBy: userID,
              })
              .then(() => {
                swal({
                  title: 'Solicitud rechazada',
                  icon: 'success',
                  button: false,
                  className: 'pb-4',
                });
                history.push('/admin/market-affiliations');
              })
              .catch((error) => {
                swal({
                  title: 'Error',
                  text: error,
                  icon: 'error',
                  button: false,
                  className: 'pb-4',
                });
                console.error(error);
              })
              .finally(() => {
                setIsDeleting(false);
              });
          } else {
            setIsDeleting(false);
          }
        });
      }
    });
  }

  return (
    <div className="content">
      <StoreBranchModal
        isOpen={showStoreBranchModal.isOpen}
        initialValues={showStoreBranchModal.storeBranch}
        onClose={() =>
          setShowStoreBranchModal({
            isOpen: false,
            storeBranch: null,
          })
        }
      />
      <div className="row">
        <div className="col-4 col-md-3 col-xl-2">
          <Link to="/admin/market-affiliations">
            <button
              type="button"
              className="w-100 bg-beige tuyo-btn px-3 py-2 mr-2 rounded-pill border d-inline-flex align-items-center justify-content-center"
            >
              <img src={chevronLeftSVG} alt="" className="mr-2" />
              Regresar
            </button>
          </Link>
        </div>
        {!['DENIED', 'APPROVED'].includes(store?.status) && (
          <>
            <div className="col-4 col-md-2 order-md-3 col-xl-2">
              <button
                disabled={isSaving}
                type="button"
                className="w-100 bg-green tuyo-btn px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                onClick={handleSubmit}
              >
                {isSaving ? 'Creando' : 'Aprobar'}
                <BounceLoader color="#fff" loading={isSaving} size="18" />
              </button>
            </div>
            <div className="col-4 col-md-2 order-md-3 col-xl-2">
              <button
                disabled={isDeleting}
                type="button"
                className="w-100 bg-danger tuyo-btn px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                onClick={deniedRequest}
              >
                {isDeleting ? 'Rechazando' : 'Rechazar'}
                <BounceLoader color="#fff" loading={isSaving} size="18" />
              </button>
            </div>
          </>
        )}
        <div className="col-12 col-md-5 col-xl-6 mt-3 mt-md-0">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center">
            Revisión de Solicitud
          </h3>
        </div>
      </div>
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          {(id && store) || !id ? (
            <>
              <Formik
                enableReinitialize={true}
                innerRef={formRef}
                validationSchema={Yup.object().shape({
                  firstname: Yup.string().trim().required('Campo requerido'),
                  lastname: Yup.string().trim().required('Campo requerido'),
                  email: Yup.string()
                    .trim()
                    .required('Campo requerido')
                    .email('Email no válido'),
                  userPhone: Yup.string()
                    .trim()
                    .matches(
                      /([267])[0-9]{3}[ ]?[0-9]{4}/,
                      'Número de teléfono no válido'
                    ),
                  userDui: Yup.string()
                    .trim()
                    .matches(/[0-9]{8}[ ]?[0-9]/, 'DUI no válido'),
                  userNit: Yup.string()
                    .trim()
                    .matches(
                      /[0-9]{4}[ ]?[0-9]{6}[ ]?[0-9]{3}[ ]?[0-9]/,
                      'NIT no válido'
                    ),
                  commission: Yup.number()
                    .typeError('Comisión debe ser un número')
                    .required('Campo requerido'),
                  storeName: Yup.string().trim().required('Campo requerido'),
                  registry: Yup.string().trim().required('Campo requerido'),
                  representative: Yup.string()
                    .trim()
                    .required('Campo requerido'),
                  description: Yup.string().trim(),
                })}
                initialValues={{
                  firstname: id ? store?.firstname : '',
                  lastname: id ? store?.lastname : '',
                  email: id ? store?.email : '',
                  userPhone: id ? store?.phone : '',
                  userDui: '',
                  userNit: '',
                  storeName: id ? store?.company : '',
                  registry: '',
                  representative: '',
                  description: '',
                  commission: '',
                }}
                onSubmit={(values) => {
                  saveStore(values);
                }}
              >
                {() => (
                  <Form>
                    <Card className="p-3 p-md-4">
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <p className="mb-2">Foto de usuario</p>
                          <button
                            type="button"
                            className="w-100 add-picture-btn"
                            style={{
                              backgroundSize: pictures.userPhoto
                                ? 'contain'
                                : '',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              border: '2px solid rgba(0,0,0,0.16)',
                              width: '150px',
                              height: '150px',
                              borderRadius: '1rem',
                              backgroundImage: pictures.userPhoto
                                ? "url('" +
                                  (pictures.userPhoto.base64 ||
                                    pictures.userPhoto.URL) +
                                  "')"
                                : '',
                            }}
                          >
                            <input
                              accept=".jpg,.jpeg,.png"
                              id="userPhoto"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(event) =>
                                addPicture('userPhoto', event)
                              }
                            />
                            <label
                              htmlFor="userPhoto"
                              className="mb-0"
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </button>
                          <small className="font-style-italic text-gray mt-1">
                            Peso máximo por imagen: 2mb
                          </small>
                        </div>
                        <div className="col-12 col-md-9">
                          <div className="row h-100 align-items-center">
                            <div className="col-12 col-md-4">
                              <label htmlFor="firstname" className="d-block">
                                Nombre
                              </label>
                              <Field
                                name="firstname"
                                type="text"
                                placeholder="Juan"
                                className="w-100 rounded p-2"
                              />
                              <ErrorMessage
                                name="firstname"
                                component="span"
                                className="text-red"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <label htmlFor="lastname" className="d-block">
                                Apellido
                              </label>
                              <Field
                                name="lastname"
                                type="text"
                                placeholder="Perez"
                                className="w-100 rounded p-2"
                              />
                              <ErrorMessage
                                name="lastname"
                                component="span"
                                className="text-red"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <label htmlFor="email" className="d-block">
                                Correo electrónico
                              </label>
                              <Field
                                name="email"
                                type="email"
                                placeholder="juanperez@tuyoapp.com"
                                className="w-100 rounded p-2"
                              />
                              <ErrorMessage
                                name="email"
                                component="span"
                                className="text-red"
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <label htmlFor="userPhone" className="d-block">
                                Telefono
                              </label>
                              <Field name="userPhone">
                                {({ field, form: { setFieldValue } }) => (
                                  <>
                                    <InputMask
                                      {...field}
                                      onChange={(event) =>
                                        setFieldValue(
                                          'userPhone',
                                          event.target.value
                                        )
                                      }
                                      className="p-2 rounded w-100"
                                      maskChar={null}
                                      formatChars={{
                                        9: '[0-9]',
                                        7: '(2|6|7)',
                                      }}
                                      mask="7999 9999"
                                    />
                                    <ErrorMessage
                                      name="userPhone"
                                      component="span"
                                      className="text-red"
                                    />
                                  </>
                                )}
                              </Field>
                            </div>
                            <div className="col-12 col-md-4">
                              <label htmlFor="userDui" className="d-block">
                                DUI
                              </label>
                              <Field name="userDui">
                                {({ field, form: { setFieldValue } }) => (
                                  <>
                                    <InputMask
                                      {...field}
                                      onChange={(event) =>
                                        setFieldValue(
                                          'userDui',
                                          event.target.value
                                        )
                                      }
                                      className="p-2 rounded w-100"
                                      maskChar={null}
                                      mask="99999999 9"
                                    />
                                    <ErrorMessage
                                      name="userDui"
                                      component="span"
                                      className="text-red"
                                    />
                                  </>
                                )}
                              </Field>
                            </div>
                            <div className="col-12 col-md-4">
                              <label htmlFor="userNit" className="d-block">
                                NIT
                              </label>
                              <Field name="userNit">
                                {({ field, form: { setFieldValue } }) => (
                                  <>
                                    <InputMask
                                      {...field}
                                      onChange={(event) =>
                                        setFieldValue(
                                          'userNit',
                                          event.target.value
                                        )
                                      }
                                      className="p-2 rounded w-100"
                                      maskChar={null}
                                      mask="9999 999999 999 9"
                                    />
                                    <ErrorMessage
                                      name="userNit"
                                      component="span"
                                      className="text-red"
                                    />
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card className="p-3 p-md-4 mt-3">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-md-3">
                          <label htmlFor="storeName" className="d-block">
                            Nombre del comercio
                          </label>
                          <Field
                            name="storeName"
                            type="text"
                            placeholder="Comercio SA de CV"
                            className="w-100 rounded p-2"
                          />
                          <ErrorMessage
                            name="storeName"
                            component="span"
                            className="text-red"
                          />
                        </div>
                        <div className="col-12 col-md-3">
                          <label htmlFor="registy" className="d-block">
                            Registro
                          </label>
                          <Field
                            name="registry"
                            type="text"
                            placeholder="123456-7"
                            className="w-100 rounded p-2"
                          />
                          <ErrorMessage
                            name="registry"
                            component="span"
                            className="text-red"
                          />
                        </div>
                        <div className="col-12 col-md-3">
                          <label htmlFor="representative" className="d-block">
                            Representante
                          </label>
                          <Field
                            name="representative"
                            type="text"
                            placeholder="Lic. Juan Perez"
                            className="w-100 rounded p-2"
                          />
                          <ErrorMessage
                            name="representative"
                            component="span"
                            className="text-red"
                          />
                        </div>
                        <div className="col-12 col-md-3">
                          <label htmlFor="commission" className="d-block">
                            Comisión (%)
                          </label>
                          <Field name="commission">
                            {({ field, form: { setFieldValue } }) => (
                              <>
                                <input
                                  {...field}
                                  type="text"
                                  placeholder="23"
                                  className="w-100 rounded p-2"
                                  onChange={(event) => {
                                    setFieldValue(
                                      'commission',
                                      validPctg(event.target.value)
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name="commission"
                                  component="span"
                                  className="text-red"
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="col-12 col-md-12">
                          <label htmlFor="description" className="d-block">
                            Descripcion
                          </label>
                          <Field
                            placeholder="Descripcion de tu producto"
                            name="description"
                            as="textarea"
                            className="rounded w-100 p-2"
                            rows="5"
                          />
                          <ErrorMessage
                            name="description"
                            component="span"
                            className="text-red"
                          />
                        </div>
                      </div>
                    </Card>
                    <Card className="p-3 p-md-4 mt-3">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-md-3 text-center">
                          <p className="mb-2">DUI representante</p>
                          <button
                            type="button"
                            className="w-100 add-picture-btn"
                            style={{
                              backgroundSize: pictures.representativeDUI
                                ? 'contain'
                                : '',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              border: '2px solid rgba(0,0,0,0.16)',
                              paddingTop: '100%',
                              borderRadius: '1rem',
                              backgroundImage: pictures.representativeDUI
                                ? "url('" +
                                  (pictures.representativeDUI.base64 ||
                                    pictures.representativeDUI.URL) +
                                  "')"
                                : '',
                            }}
                          >
                            {/* <input
                              accept=".jpg,.jpeg,.png"
                              id="representativeDUI"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(event) =>
                                addPicture('representativeDUI', event)
                              }
                            />
                            <label
                              htmlFor="representativeDUI"
                              className="mb-0"
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                              }}
                            /> */}
                          </button>
                        </div>
                        <div className="col-12 col-md-3 text-center">
                          <p className="mb-2">NIT representante</p>
                          <button
                            type="button"
                            className="w-100 add-picture-btn"
                            style={{
                              backgroundSize: pictures.representativeNIT
                                ? 'contain'
                                : '',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              border: '2px solid rgba(0,0,0,0.16)',
                              paddingTop: '100%',
                              borderRadius: '1rem',
                              backgroundImage: pictures.representativeNIT
                                ? "url('" +
                                  (pictures.representativeNIT.base64 ||
                                    pictures.representativeNIT.URL) +
                                  "')"
                                : '',
                            }}
                          >
                            <input
                              accept=".jpg,.jpeg,.png"
                              id="representativeNIT"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(event) =>
                                addPicture('representativeNIT', event)
                              }
                            />
                            <label
                              htmlFor="representativeNIT"
                              className="mb-0"
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </button>
                        </div>
                        <div className="col-12 col-md-3 text-center">
                          <p className="mb-2">NIT comercio</p>
                          <button
                            type="button"
                            className="w-100 add-picture-btn"
                            style={{
                              backgroundSize: pictures.companyNIT
                                ? 'contain'
                                : '',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              border: '2px solid rgba(0,0,0,0.16)',
                              paddingTop: '100%',
                              borderRadius: '1rem',
                              backgroundImage: pictures.companyNIT
                                ? "url('" +
                                  (pictures.companyNIT.base64 ||
                                    pictures.companyNIT.URL) +
                                  "')"
                                : '',
                            }}
                          >
                            <input
                              accept=".jpg,.jpeg,.png"
                              id="companyNIT"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(event) =>
                                addPicture('companyNIT', event)
                              }
                            />
                            <label
                              htmlFor="companyNIT"
                              className="mb-0"
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </button>
                        </div>
                        <div className="col-12 col-md-3 text-center">
                          <p className="mb-2">Contrato</p>
                          <button
                            type="button"
                            className="w-100 add-picture-btn"
                            style={{
                              backgroundSize: pictures.contract
                                ? 'contain'
                                : '',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              border: '2px solid rgba(0,0,0,0.16)',
                              paddingTop: '100%',
                              borderRadius: '1rem',
                              backgroundImage: pictures.contract
                                ? "url('" +
                                  (pictures.contract.base64 ||
                                    pictures.contract.URL) +
                                  "')"
                                : '',
                            }}
                          >
                            <input
                              accept=".jpg,.jpeg,.png,.pdf"
                              id="contract"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(event) =>
                                addPicture('contract', event)
                              }
                            />
                            <label
                              htmlFor="contract"
                              className="mb-0"
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </button>
                        </div>
                        <div className="col-12 col-md-3 text-center">
                          <p className="mb-2">
                            Tarjeta de afiliacion de sociedad
                          </p>
                          <button
                            type="button"
                            className="w-100 add-picture-btn"
                            style={{
                              backgroundSize: pictures.societyMembershipCard
                                ? 'contain'
                                : '',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              border: '2px solid rgba(0,0,0,0.16)',
                              paddingTop: '100%',
                              borderRadius: '1rem',
                              backgroundImage: pictures.societyMembershipCard
                                ? "url('" +
                                  (pictures.societyMembershipCard.base64 ||
                                    pictures.societyMembershipCard.URL) +
                                  "')"
                                : '',
                            }}
                          >
                            <input
                              accept=".jpg,.jpeg,.png"
                              id="societyMembershipCard"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(event) =>
                                addPicture('societyMembershipCard', event)
                              }
                            />
                            <label
                              htmlFor="societyMembershipCard"
                              className="mb-0"
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </button>
                        </div>
                        <div className="col-12 col-md-3 text-center">
                          <p className="mb-2">IVA</p>
                          <button
                            type="button"
                            className="w-100 add-picture-btn"
                            style={{
                              backgroundSize: pictures.cardIVA ? 'contain' : '',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              border: '2px solid rgba(0,0,0,0.16)',
                              paddingTop: '100%',
                              borderRadius: '1rem',
                              backgroundImage: pictures.cardIVA
                                ? "url('" +
                                  (pictures.cardIVA.base64 ||
                                    pictures.cardIVA.URL) +
                                  "')"
                                : '',
                            }}
                          >
                            <input
                              accept=".jpg,.jpeg,.png"
                              id="cardIVA"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(event) => addPicture('cardIVA', event)}
                            />
                            <label
                              htmlFor="cardIVA"
                              className="mb-0"
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </button>
                        </div>
                        <div className="col-12 col-md-3 text-center">
                          <p className="mb-2">Imagen del comercio</p>
                          <button
                            type="button"
                            className="w-100 add-picture-btn"
                            style={{
                              backgroundSize: pictures.storeImage
                                ? 'contain'
                                : '',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              border: '2px solid rgba(0,0,0,0.16)',
                              paddingTop: '100%',
                              borderRadius: '1rem',
                              backgroundImage: pictures.storeImage
                                ? "url('" +
                                  (pictures.storeImage.base64 ||
                                    pictures.storeImage.URL) +
                                  "')"
                                : '',
                            }}
                          >
                            <input
                              accept=".jpg,.jpeg,.png"
                              id="storeImage"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(event) =>
                                addPicture('storeImage', event)
                              }
                            />
                            <label
                              htmlFor="storeImage"
                              className="mb-0"
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </button>
                        </div>
                        <div className="col-12 col-md-3 text-center">
                          <p className="mb-2">Banner</p>
                          <button
                            type="button"
                            className="w-100 add-picture-btn"
                            style={{
                              backgroundSize: pictures.bannerImage
                                ? 'contain'
                                : '',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              border: '2px solid rgba(0,0,0,0.16)',
                              paddingTop: '100%',
                              borderRadius: '1rem',
                              backgroundImage: pictures.bannerImage
                                ? "url('" +
                                  (pictures.bannerImage.base64 ||
                                    pictures.bannerImage.URL) +
                                  "')"
                                : '',
                            }}
                          >
                            <input
                              accept=".jpg,.jpeg,.png"
                              id="bannerImage"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(event) =>
                                addPicture('bannerImage', event)
                              }
                            />
                            <label
                              htmlFor="bannerImage"
                              className="mb-0"
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </button>
                        </div>
                      </div>
                      <small className="font-style-italic text-gray mt-1">
                        Peso máximo por imagen: 2mb
                      </small>
                    </Card>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <div
              className="p-5 m-5 d-flex justify-content-center align-items-center"
              style={{
                flexGrow: '1',
              }}
            >
              <ClimbingBoxLoader color={colors.green} size="25" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CheckAffiliationRequest;
