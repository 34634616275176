const config = {
  api_url: process.env.REACT_APP_API_URL,
  tuyo_web_url: process.env.REACT_APP_TUYO_WEB_URL,
  url: process.env.REACT_APP_URL,
  env: process.env.REACT_APP_ENV,
  title: process.env.REACT_APP_TITLE,
  mapbox_access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  s3_region: process.env.REACT_APP_S3_REGION,
  s3_bucket_name_compressed: process.env.REACT_APP_S3_BUCKET_NAME_COMPRESSED,
  s3_bucket_name_raw: process.env.REACT_APP_S3_BUCKET_NAME_RAW,
  s3_access_key_id: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  s3_secret_access_key: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  spaces_name: process.env.REACT_APP_SPACES_NAME,
  spaces_endpoint: process.env.REACT_APP_SPACES_ENDPOINT,
  spaces_access_key_id: process.env.REACT_APP_SPACES_ACCESS_KEY_ID,
  spaces_secret_access_key: process.env.REACT_APP_SPACES_SECRET_ACCESS_KEY,
  urbano_shipper_branch_id: process.env.REACT_APP_URBANO_SHIPPER_BRANCH_ID,
  cookies_store_id: process.env.REACT_APP_COOKIES_STORE_ID,
  cookies_jwt: process.env.REACT_APP_COOKIES_JWT,
  cookies_user_id: process.env.REACT_APP_COOKIES_USER_ID,
  amazon_api: process.env.REACT_APP_AMAZON_API,
};

export { config };
