import React, { useState, useRef, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import BounceLoader from 'react-spinners/BounceLoader';
import swal from 'sweetalert';

import Card from '../../components/Card';
import Table from '../../components/Table/Table';
import Tabs from '../../components/Tabs';
import UserBalance from '../../components/UserBalance';
import SendNotification from 'components/SendNotification';

import { formatTimestamp, getOrderCode, ROLES } from '../../utils/misc';
import api from '../../utils/api';
import jwtDecode from 'jwt-decode';
import OrderDetail from 'components/OrderDetail';

const ordersTable = {
  columns: [
    {
      text: 'ID',
      key: 'ordersId',
      className: 'text-center',
    },
    {
      text: 'Código',
      key: 'code',
      className: 'text-center',
    },
    {
      text: 'Cupon',
      key: 'couponDisplay',
      className: 'text-center',
    },
    {
      text: 'Monto',
      key: 'total',
      className: 'text-right',
      isCurrency: true,
    },
    {
      text: 'Dirección de envío',
      key: 'address',
      className: 'text-center',
    },
    {
      text: 'Estado',
      key: 'orderStatus',
      className: 'text-center',
    },
    {
      text: 'Fecha y hora',
      key: 'createdAtDisplay',
      className: 'text-center',
    },
  ],
  options: {
    id: 'ordersId',
  },
};

function EditUser() {
  const history = useHistory();
  const formRef = useRef(null);

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE, REACT_APP_COOKIES_JWT } =
    process.env;

  const { id } = useParams();
  const [cookies] = useCookies([
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  ]);
  /**
   * loggedUser is the user that has access to the dashboard
   * and userID is the user that is being edited
   */
  const loggedUser = cookies[REACT_APP_COOKIES_USER_ID];
  const [user, setUser] = useState();
  const [orders, setOrders] = useState();
  const [pictures, setPictures] = useState({ photo: null });
  const [activeTab, setActiveTab] = useState('info');
  const [userRoles, setUserRoles] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [ordersId, setOrdersId] = useState(null);

  const [showNotificationModal, setShowNotificationModal] = useState(false);

  useEffect(() => {
    document.title = `Editar usuario | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    if (id) {
      api.users
        .getOne(id)
        .then((res) => {
          setUser({ ...res.data[0] });
          setPictures({ photo: res.data[0].photo });
          setOrders(
            res.data[0].orders_users.map((order) => {
              return {
                ...order,
                code: getOrderCode(order.ordersId),
                createdAtDisplay: formatTimestamp(order.createdAt),
                couponDisplay: order.coupon ? order.coupon.split('|')[0] : '',
              };
            })
          );
        })
        .catch((err) => {
          toast.warning(
            '[SERVER_ERROR] No se pudo obtener la info. del usuario'
          );
          console.error(err);
        });
    }
  }, []);

  useEffect(() => {
    const _jwt = cookies[REACT_APP_COOKIES_JWT];
    const JWT = jwtDecode(_jwt);
    const role = JWT.user.role.name;

    api.roles
      .getAll()
      .then((res) => {
        setUserRoles(
          res.data
            ?.filter((r) => (role == ROLES.ROOT ? true : r.name != 'root'))
            ?.map((r) => {
              return r;
            })
        );
      })
      .catch((err) => {
        toast.warning('[SERVER_ERROR] No se pudo obtener los roles de usuario');
        console.error(err);
      });
  }, []);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  function save(formValues) {
    setIsSaving(true);

    const {
      userRole,
      phone,
      firstname,
      lastname,
      email,
      username,
      referralCode,
    } = formValues;

    let body = {
      fk_rolesId: userRole,
      phone,
      firstname,
      lastname,
      email,
      username,
      referralCode,
    };
    id
      ? (body = {
          ...body,
          updatedBy: loggedUser,
        })
      : (body = {
          ...body,
          createdBy: loggedUser,
        });

    api.users
      .updateOne(id, body)
      .then((_) => {
        toast.success('Usuario actualizado');
        history.goBack();
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
      })
      .finally((_) => setIsSaving(false));
  }

  function addUserToBlacklist() {
    swal({
      content: {
        element: 'input',
        attributes: {
          placeholder: 'Se estaba portando mal',
        },
      },
      title: 'Razón de bloqueo del usuario',
      icon: 'warning',
      button: {
        text: 'Bloquear',
      },
    }).then((reason) => {
      if (reason) {
        setIsBlocking(true);

        api
          .create('/blacklist', {
            phone: user?.phone,
            email: user?.email,
            usersId: user?.usersId,
            reason,
            createdBy: loggedUser,
          })
          .then(() => {
            swal({
              title: 'Añadido a lista negra',
              icon: 'success',
              button: false,
              className: 'pb-4',
            });
          })
          .catch((error) => {
            swal({
              title: 'Error',
              text: error,
              icon: 'error',
              button: false,
              className: 'pb-4',
            });
            console.error(error);
          })
          .finally(() => {
            setIsBlocking(false);
          });
      }
    });
  }

  function deleteUser() {
    swal({
      title: '¿Desea eliminar este usuario?',
      text: 'Esta es una acción irreversible',
      icon: 'warning',
      button: 'Eliminar',
      dangerMode: true,
    }).then((pressedOK) => {
      if (pressedOK) {
        setIsDeleting(true);

        api
          .delete('/users', user?.usersId)
          .then(() => {
            swal({
              title: 'Usuario eliminado',
              icon: 'success',
              button: false,
              className: 'pb-4',
            });
            history.push('/admin/users');
          })
          .catch((error) => {
            swal({
              title: 'Error',
              text: error,
              icon: 'error',
              button: false,
              className: 'pb-4',
            });
            console.error(error);
          })
          .finally(() => {
            setIsDeleting(false);
          });
      }
    });
  }

  return (
    <div className="row">
      {ordersId && (
        <OrderDetail
          showStatus
          sortProductsByStore
          id={ordersId}
          onCloseRequest={() => setOrdersId(null)}
        />
      )}
      <div className="col-12">
        <Tabs
          onChange={(tab) => setActiveTab(tab)}
          tabs={[
            { text: 'Info.', key: 'info' },
            { text: 'Balance', key: 'balance' },
          ]}
          activeTab={activeTab}
        />
      </div>
      {activeTab === 'info' ? (
        <div className="col-12">
          <div className="row justify-content-around">
            <div className="col-12 col-md-3 mt-3">
              <button
                onClick={deleteUser}
                disabled={isDeleting}
                type="button"
                className="bg-red tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
              >
                {isDeleting ? 'Eliminando...' : 'Eliminar'}
              </button>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <button
                onClick={addUserToBlacklist}
                disabled={isBlocking}
                type="button"
                className="bg-red tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
              >
                {isBlocking ? 'Añadiendo...' : 'Añadir a lista negra'}
              </button>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <button
                className="bg-green tuyo-btn rounded text-light font-weight-bold w-100 h-100"
                onClick={() => {
                  setShowNotificationModal(true);
                }}
              >
                Enviar notificacion
              </button>
              {showNotificationModal && (
                <SendNotification
                  usersIds={[id]}
                  onCloseRequest={() => {
                    setShowNotificationModal(false);
                  }}
                />
              )}
            </div>
            <div className="col-12 col-md-3 mt-3">
              <button
                onClick={handleSubmit}
                disabled={isSaving}
                type="button"
                className="bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
              >
                {isSaving ? 'Guardando' : 'Guardar'}
                <BounceLoader color="#fff" loading={isSaving} size="18" />
              </button>
            </div>
          </div>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            validationSchema={Yup.object().shape({
              firstname: Yup.string().trim().required('Campo requerido'),
              lastname: Yup.string().trim().required('Campo requerido'),
              email: Yup.string()
                .trim()
                .required('Campo requerido')
                .email('Email no válido'),
              phone: Yup.string().trim().nullable(),
              // .matches(/(2|6|7)[0-9]{3}[ ]?[0-9]{4}/, 'Número de teléfono no válido'),
              userRole: Yup.string().trim(),
              referralCode: Yup.string().trim().required('Campo requerido'),
            })}
            initialValues={{
              firstname: id ? user?.firstname : '',
              lastname: id ? user?.lastname : '',
              email: id ? user?.email : '',
              phone: id ? user?.phone : '',
              username: id ? user?.username : '',
              creado: id ? formatTimestamp(user?.createdAt) : '',
              userRole: id ? user?.fk_rolesId : '',
              referralCode: id ? user?.referralCode : '',
            }}
            onSubmit={save}
          >
            {() => (
              <Form>
                <Card className="p-3 mt-3  p-md-4">
                  <div className="row">
                    <div className="col-12 col-md-3">
                      <div className="col-12">
                        <button
                          type="button"
                          className="w-100 add-picture-btn"
                          style={{
                            backgroundSize: pictures.photo ? 'cover' : '',
                            border: '2px solid rgba(0,0,0,0.16)',
                            paddingTop: '100%',
                            borderRadius: '1rem',
                            backgroundImage: pictures.photo
                              ? "url('" +
                                (pictures.photo || pictures.photo) +
                                "')"
                              : '',
                          }}
                        >
                          <input
                            accept=".jpg,.jpeg,.png"
                            id="photo-img"
                            type="file"
                            style={{ display: 'none' }}
                          />

                          <label
                            htmlFor="photo-img"
                            className="mb-0"
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                            }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="col-12 col-md-9">
                      <div className="row h-100 align-items-center">
                        <div className="col-12 col-md-4">
                          <label htmlFor="firstname" className="d-block">
                            Nombre
                          </label>
                          <Field
                            name="firstname"
                            type="text"
                            placeholder="Juan"
                            className="w-100 rounded p-2"
                          />
                          <ErrorMessage
                            name="firstname"
                            component="span"
                            className="text-red"
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <label htmlFor="lastname" className="d-block">
                            Apellido
                          </label>
                          <Field
                            name="lastname"
                            type="text"
                            placeholder="Perez"
                            className="w-100 rounded p-2"
                          />
                          <ErrorMessage
                            name="lastname"
                            component="span"
                            className="text-red"
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <label htmlFor="email" className="d-block">
                            Correo electrónico
                          </label>
                          <Field
                            name="email"
                            type="email"
                            placeholder="juanperez@tuyoapp.com"
                            className="w-100 rounded p-2"
                          />
                          <ErrorMessage
                            name="email"
                            component="span"
                            className="text-red"
                          />
                        </div>
                        <div className="col-12 col-md-4 mt-md-3">
                          <label htmlFor="phone" className="d-block">
                            Teléfono
                          </label>
                          <Field readOnly name="phone">
                            {({ field, form: { setFieldValue } }) => (
                              <>
                                <InputMask
                                  {...field}
                                  onChange={(event) =>
                                    setFieldValue('phone', event.target.value)
                                  }
                                  className="p-2 rounded w-100"
                                  maskChar={null}
                                  formatChars={{
                                    9: '[0-9]',
                                    7: '(2|6|7)',
                                  }}
                                  mask="7999 9999"
                                />
                                <ErrorMessage
                                  name="phone"
                                  component="span"
                                  className="text-red"
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="col-12 col-md-4">
                          <label htmlFor="username" className="d-block">
                            Nombre de usuario
                          </label>
                          <Field
                            name="username"
                            type="username"
                            placeholder="juanperez@tuyoapp.com"
                            className="w-100 rounded p-2"
                          />
                        </div>
                        <div className="col-12 col-md-4">
                          <label htmlFor="userRole" className="d-block">
                            Rol
                          </label>
                          <Field
                            name="userRole"
                            as="select"
                            className="w-100 rounded p-2"
                          >
                            {userRoles.map((role, index) => (
                              <option key={index} value={role.rolesId}>
                                {role.name.toUpperCase().replaceAll('-', ' ')}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className="col-12 col-md-4">
                          <label htmlFor="creado" className="d-block">
                            Codigo referidos
                          </label>
                          <Field
                            name="referralCode"
                            type="text"
                            placeholder="TUYOREFERIDO"
                            className="w-100 rounded p-2"
                          />
                        </div>
                        <div className="col-12 col-md-5">
                          <label htmlFor="creado" className="d-block">
                            Primera sesion
                          </label>
                          <Field
                            readOnly
                            name="creado"
                            type="creado"
                            placeholder="juanperez@tuyoapp.com"
                            className="w-100 rounded p-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Form>
            )}
          </Formik>
          <div className="row mt-3 mt-md-4">
            <div className="col">
              <Card className="p-md-4">
                <div className="row">
                  <div className="col-12 mt-3 mt-md-4">
                    {orders ? (
                      <Table
                        showFilters
                        showDateFilter
                        columns={ordersTable.columns}
                        data={orders}
                        options={ordersTable.options}
                        pageSize={10}
                        onRowClick={(orderId) => setOrdersId(orderId)}
                      />
                    ) : (
                      <div
                        className="p-5 m-5 d-flex justify-content-center align-items-center"
                        style={{
                          flexGrow: '1',
                        }}
                      />
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-12">
          <UserBalance userID={id} />
        </div>
      )}
    </div>
  );
}

export default EditUser;
