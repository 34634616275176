import React, { useEffect, useState } from 'react';

import Card from '../../components/Card';
import { useCookies } from 'react-cookie';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';
import api from 'utils/api';
import { toast } from 'react-toastify';
import { TagSelectorModal } from 'components/TagSelectorModal';
import TagSelectorModalButton from 'components/TagSelectorModalButton';
import { TagSelectorModalType } from 'components/TagSelectorModal';
import ReactTooltip from 'react-tooltip';

function TurboCategories() {
  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userId = cookies[REACT_APP_COOKIES_USER_ID];

  const [categoriesRemount, setCategoriesRemount] = useState(0);
  const [cleanReload, setCleanReload] = useState(false);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    items: [],
    initialItems: [],
    type: null,
    onSubmit: () => {},
    onRemove: null,
  });

  const turboButton = (value, row, field) => (
    <button
      onClick={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        api.categories
          .updateOne(row.productsCategoriesId, {
            [field]: !value,
            updatedBy: userId,
          })
          .then(() => {
            toast.success('Tienda actualizada');
            setCategoriesRemount((prev) => prev + 1);
          })
          .catch(() => {
            toast.error('No se pudo actualizar la tienda');
          });
      }}
    >
      <span
        data-tip={value ? 'Desactivar' : 'Activar'}
        className={`material-icons ${value ? 'text-turbo' : 'text-grey'}`}
      >
        {value ? 'check_box' : 'check_box_outline_blank'}
      </span>
      <ReactTooltip effect="solid" />
    </button>
  );

  const categoriesTable = {
    pageSize: 10,
    baseURL: '/categories',
    rowId: 'productsCategoriesId',
    baseFilter: {
      attributes: ['productsCategoriesId', 'name', 'image', 'isTurboShipping'],
      order: [
        ['productsCategoriesId', 'DESC'],
        ['isTurboShipping', 'DESC'],
      ],
    },
    columns: [
      {
        title: 'Id',
        objectProperty: 'productsCategoriesId',
        searchable: true,
        searchType: 'number-equal',
        columnClass: '!w-12',
        sortable: true,
      },
      {
        title: 'Pertenece a Turbo',
        objectProperty: 'isTurboShipping',
        className: 'w-16 text-center',
        middleware: (value, row) => turboButton(value, row, 'isTurboShipping'),
        sortable: true,
      },
      {
        title: '',
        objectProperty: 'image',
        columnClass: '!w-16',
        middleware: (value) => (
          <img
            className="img-fluid !w-12 !h-12 rounded-xl border border-gray-300 object-contain"
            src={value}
            alt="Imagen de la categoria"
          />
        ),
      },
      {
        title: 'Nombre',
        objectProperty: 'name',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
    ],
  };

  const updateCategories = (stores, enable) => {
    setCleanReload(true);
    api.turbo
      .updateCategories({
        categories: stores.map((store) => store.id),
        isTurboShipping: enable,
      })
      .then(() => {
        toast.success('Categorias actualizadas');
        setCategoriesRemount((prev) => prev + 1);
      })
      .catch(() => {
        toast.error('No se pudo actualizar las categorias');
      })
      .finally(() => {
        setCleanReload(false);
      });
  };

  useEffect(() => {
    document.title = `Categorias - Turbo Envios | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <TagSelectorModal
        {...modalInfo}
        onClose={() =>
          setModalInfo({
            isOpen: false,
            items: [],
          })
        }
      />
      <h3 className="text-dark-blue font-size-2x font-weight-bold">
        Categorias - Turbo Envios
      </h3>
      <div className="row mt-3 -mx-[15px]">
        <div className="col-12">
          <Card className="row">
            <div className="col-12 col-sm-5 col-md-4 col-lg-3 mx-[-15px]">
              <TagSelectorModalButton
                title="Agregar categorias"
                tags={[]}
                onClick={() => {
                  setModalInfo({
                    title: 'Agregar categorias',
                    initialItems: [],
                    onSubmit: (data) => updateCategories(data, true),
                    onRemove: (data) => updateCategories(data, false),
                    isOpen: true,
                    type: TagSelectorModalType.CATEGORIES,
                  });
                }}
              />
            </div>
            <PaginatedTable
              columns={categoriesTable.columns}
              pageSize={categoriesTable.pageSize}
              baseURL={categoriesTable.baseURL}
              baseFilter={categoriesTable.baseFilter}
              rowId={categoriesTable.rowId}
              remountCount={categoriesRemount}
              cleanReload={cleanReload}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default TurboCategories;
