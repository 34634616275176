import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '../../components/Card';
import Table from 'components/TableV2/Table';

import api from '../../utils/api';
import { formatPrice } from 'utils/misc';

import circleCheckSVG from '../../assets/icons/check-circle-solid-green.svg';
import addSVG from '../../assets/icons/add.svg';
import { useCookies } from 'react-cookie';

function CouponsList() {
  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  const history = useHistory();

  const [tableRemountCount, setTableRemountCount] = useState(0);
  const [couponsDisabled, setCouponsDisabled] = useState(false);
  const [refersDisabled, setRefersDisabled] = useState(false);

  function deleteCoupon(id) {
    api
      .delete('/coupons', id)
      .then(() => {
        toast.success('Cupón eliminado');
        setTableRemountCount(tableRemountCount + 1);
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo eliminar el cupón');
      });
  }

  function getCouponActive() {
    api.redis
      .getDeactivatedCoupons()
      .then((res) => {
        const value = res?.data?.value ?? false;

        setCouponsDisabled(value);
      })
      .catch((err) => {
        console.error(err);
        setCouponsDisabled(false);
      });
  }

  function getRefersActive() {
    api.redis
      .getDeactivatedRefers()
      .then((res) => {
        const value = res?.data?.value ?? false;

        setRefersDisabled(value);
      })
      .catch((err) => {
        console.error(err);
        setRefersDisabled(false);
      });
  }

  function handleCouponsActivation() {
    const userId = cookies[REACT_APP_COOKIES_USER_ID];

    const action = couponsDisabled
      ? api.redis.activateCoupons()
      : api.redis.deactivateCoupons(+userId);

    action
      .then(() => {})
      .catch((err) => {
        console.error(err);
        toast.warning('No se pudo activar/desactivar los cupones');
      })
      .finally(() => getCouponActive());
  }

  function handleRefersActivation() {
    const userId = cookies[REACT_APP_COOKIES_USER_ID];

    const action = refersDisabled
      ? api.redis.activateRefers()
      : api.redis.deactivateRefers(+userId);

    action
      .then(() => {})
      .catch((err) => {
        console.error(err);
        toast.warning('No se pudo activar/desactivar los referidos');
      })
      .finally(() => getRefersActive());
  }

  const tableProps = {
    rowId: 'couponsId',
    baseURL: '/coupons',
    baseFilter: {
      attributes: [
        'couponsId',
        'couponCode',
        'type',
        'paymentMethod',
        'value',
        'minCost',
        'maxDiscount',
        'remainingUses',
        'usesPerUser',
        'active',
        'startline',
        'deadline',
        'onlyFirstOrder',
      ],
      order: [['couponsId', 'desc']],
    },
    pageSize: 10,
    columns: [
      {
        title: 'Código',
        objectProperty: 'couponCode',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Tipo',
        objectProperty: 'type',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Método de pago',
        objectProperty: 'paymentMethod',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Valor',
        objectProperty: 'value',
        searchable: true,
        searchType: 'number',
        sortable: true,
        middleware: (value) =>
          value > 1
            ? formatPrice(value)
            : new Intl.NumberFormat('en-US', {
                style: 'percent',
              }).format(value),
      },
      {
        title: 'Compra mínima',
        objectProperty: 'minCost',
        searchable: true,
        searchType: 'number',
        sortable: true,
        middleware: (minCost) =>
          minCost > 0 ? formatPrice(minCost) : 'Sin mínimo',
      },
      {
        title: 'Descuento máximo',
        objectProperty: 'maxDiscount',
        searchable: true,
        searchType: 'number',
        sortable: true,
        middleware: (maxDiscount) =>
          maxDiscount > 0 ? formatPrice(maxDiscount) : 'Sin máximo',
      },
      {
        title: 'Usos disponibles',
        objectProperty: 'remainingUses',
        searchable: true,
        searchType: 'number',
        sortable: true,
        middleware: (remainingUses) =>
          remainingUses < 0 ? 'Sin límite' : remainingUses,
      },
      {
        title: 'Usos por usuario',
        objectProperty: 'usesPerUser',
        searchable: true,
        searchType: 'number',
        sortable: true,
        middleware: (usesPerUser) =>
          usesPerUser < 0 ? 'Sin límite' : usesPerUser,
      },
      {
        title: 'Solo primera compra',
        objectProperty: 'onlyFirstOrder',
        middleware: (onlyFirstOrder) =>
          onlyFirstOrder ? (
            <img src={circleCheckSVG} alt="" style={{ height: '18px' }} />
          ) : null,
      },
      {
        title: 'Activo',
        objectProperty: 'active',
        middleware: (active) =>
          active === 1 ? (
            <img src={circleCheckSVG} alt="" style={{ height: '18px' }} />
          ) : null,
      },
      {
        title: 'Inicio',
        objectProperty: 'startline',
        searchable: true,
        searchType: 'timestamp',
        sortable: true,
        middleware: (startline) =>
          startline
            ? new Date(startline).toLocaleString()
            : 'Sin fecha de inicio',
      },
      {
        title: 'Fin',
        objectProperty: 'deadline',
        searchable: true,
        searchType: 'timestamp',
        sortable: true,
        middleware: (deadline) =>
          deadline ? new Date(deadline).toLocaleString() : 'Sin fecha de fin',
      },
      {
        objectProperty: 'couponsId',
        middleware: (id) => (
          <button
            onClick={(event) => {
              event.stopPropagation();
              deleteCoupon(id);
            }}
          >
            🗑️
          </button>
        ),
      },
    ],
  };

  useEffect(() => {
    document.title = `Cupones | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    getCouponActive();
    getRefersActive();
  }, []);

  return (
    <div className="content">
      <div className="row mx-2">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Cupones
          </h3>
        </div>

        <div className="row col-12 col-md-4 col-xl-3 m-0 mt-3 m-md-0">
          <Link to="/admin/coupons/new" className="w-100">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold"
            >
              <img
                src={addSVG}
                alt=""
                className="d-inline-block mr-2"
                style={{ height: '16px' }}
              />
              Nuevo cupón
            </button>
          </Link>
        </div>
      </div>

      <div className="row mt-2 mx-2">
        <div className="col-0 col-lg-4 col-xl-6" />
        <div className="pl-md-2 col-12 col-md-6 col-lg-4 col-xl-3">
          <button
            type="button"
            onClick={handleCouponsActivation}
            className={`d-flex align-items-center justify-content-center tuyo-btn w-100 h-100 p-2 rounded text-light font-weight-bold ${
              couponsDisabled ? 'bg-green' : 'bg-red'
            }`}
          >
            {couponsDisabled ? (
              <>
                <i className="material-icons mr-2">visibility</i>
                Activar todos los cupones
              </>
            ) : (
              <>
                <i className="material-icons mr-2">visibility_off</i>
                Desactivar todos los cupones
              </>
            )}
          </button>
        </div>
        <div className="mt-2 mt-md-0 col-12 col-md-6 col-lg-4 col-xl-3">
          <button
            type="button"
            onClick={handleRefersActivation}
            className={`d-flex align-items-center justify-content-center tuyo-btn w-100 h-100 p-2 rounded text-light font-weight-bold ${
              refersDisabled ? 'bg-green' : 'bg-red'
            }`}
          >
            {refersDisabled ? (
              <>
                <i className="material-icons mr-2">visibility</i>
                Activar todos los referidos
              </>
            ) : (
              <>
                <i className="material-icons mr-2">visibility_off</i>
                Desactivar todos los referidos
              </>
            )}
          </button>
        </div>
      </div>

      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            <Table
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
              onRowClick={(row) => {
                history.push(`/admin/coupons/${row[tableProps.rowId]}/edit`);
              }}
              remountCount={tableRemountCount}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default CouponsList;
