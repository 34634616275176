import ReactModal from 'components/Modal';
import React, { useEffect } from 'react';
import { ClimbingBoxLoader } from 'react-spinners';
import { getPermutations } from 'utils/misc';
import { tuyoPriceFormula } from 'utils/misc';
import colors from 'assets/resources/colors';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useCookies } from 'react-cookie';

function AmazonDetailCard({ onSave, product, hide, onClose, categories }) {
  const { REACT_APP_COOKIES_USER_ID } = process.env;

  const [variations, setVariations] = React.useState([]);
  const [productsColors, setProductColors] = React.useState([]);
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  useEffect(() => {
    if (product) {
      setVariations([
        ...product?.variations?.map((variant) => ({
          ...variant,
          selected: false,
        })),
      ]);
      setProductColors([
        ...product?.colors?.map((color) => ({ ...color, selected: false })),
      ]);
    }
  }, [product]);

  const onSubmit = (values) => {
    const seletedVariations = variations?.filter((variant) => variant.selected);
    const seletedColors = productsColors?.filter((color) => color.selected);
    const {
      regularPrice: price,
      sku,
      categoryId,
      subCategoryId,
      oldPrice,
    } = values;
    const userId = parseInt(cookies[REACT_APP_COOKIES_USER_ID]);

    const hasVariants =
      seletedVariations.length > 0 || seletedColors.length > 0;

    let variants = [
      seletedColors.length > 0
        ? {
            key: 'Color',
            value: [...seletedColors?.map((color) => color?.description)],
          }
        : null,
      seletedVariations.length > 0
        ? {
            key: 'Variante',
            value: [
              ...seletedVariations?.map((variant) => variant?.description),
            ],
          }
        : null,
    ];

    variants = variants.filter((variant) => variant !== null);

    const permutations = getPermutations(variants);

    const tmpVariants = [
      ...permutations.map((permutation, index) => {
        const values = Object.values(permutation);

        return {
          attributes: values.map((value) => value.key).join(','),
          values: values.map((value) => value.value).join(','),
          sku: `${sku}${index}`,
          price,
          discount: 0,
          createdBy: 25,
          stock: [
            {
              stock: 10,
              // from store
              fk_storesBranchesId: null,
              createdBy: 25,
            },
          ],
        };
      }),
    ];

    const uniqueVariant = {
      attributes: 'Tipo',
      values: 'Unico',
      sku: values.sku,
      price: values.regularPrice,
      discount: 0,
      createdBy: userId,
      stock: [
        {
          stock: 10,
          //from store
          fk_storesBranchesId: null,
          createdBy: userId,
        },
      ],
    };

    const tmpProduct = {
      ...values,
      preparationTime: 1123200000,
      amazonPrice: oldPrice,
      discount: 0,
      freeShipping: false,
      cargoType: 'Motocicleta',
      fk_usersId: userId,
      createdBy: userId,
      active: 1,
      fk_storesId: null,
      hasVariant: hasVariants,
      fk_categoriesId: parseInt(categoryId),
      fk_subcategoriesId: parseInt(subCategoryId),
      fk_productsTypesId: categories.find(
        (category) => category.productsCategoriesId === parseInt(categoryId)
      )?.fk_productsTypesId,
      images: [
        ...product?.images.map((image) => ({
          url: image,
          createdBy: userId,
        })),
      ],
      stock: [
        {
          stock: 10,
          //from store
          fk_storesBranchesId: null,
          createdBy: userId,
        },
      ],
      variants: hasVariants ? tmpVariants : [uniqueVariant],
    };

    if (onSave) onSave(tmpProduct);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.sku) errors.sku = 'Requerido';
    if (!values.regularPrice) errors.regularPrice = 'Requerido';
    if (!values.categoryId) errors.categoryId = 'Requerido';
    if (!values.subCategoryId) errors.subCategoryId = 'Requerido';

    return errors;
  };

  const amazonPrice = () => {
    const { list_price, current_price } = product.prices;

    if (list_price && list_price > 0) return list_price;

    if (current_price && current_price > 0) return current_price;

    return 0;
  };

  return (
    <ReactModal
      isOpen={hide}
      onCloseRequest={() => {
        if (onClose) onClose();
      }}
    >
      {product ? (
        <div className="d-flex flex-column p-3">
          <div
            style={{
              overflowX: 'scroll',
              display: 'inline-flex',
              gap: '1rem',
            }}
          >
            {product?.images && (
              <>
                {product?.images?.map((image, index) => (
                  <img
                    key={index}
                    style={{
                      margin: '0 auto',
                    }}
                    className="border-image"
                    src={image}
                    alt="amazon-image "
                  />
                ))}
              </>
            )}
          </div>
          <Formik
            initialValues={{
              sku: product?.asin,
              name: product?.title,
              oldPrice: amazonPrice(),
              regularPrice: tuyoPriceFormula(amazonPrice()),
              description: product?.description,
              categoryId: '',
              subCategoryId: '',
            }}
            onSubmit={onSubmit}
            validate={validate}
          >
            {({ values, setFieldValue }) => (
              <>
                <Form className="row">
                  <div className="col-12 mt-3">
                    <label htmlFor="sku" className="d-block">
                      SKU
                    </label>
                    <Field
                      name="sku"
                      text="sku"
                      readOnly
                      placeholder="ABC123"
                      className="w-100 rounded p-2"
                    />
                    <ErrorMessage name="name">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 mt-3">
                    <label htmlFor="name" className="d-block">
                      Nombre
                    </label>
                    <Field
                      name="name"
                      text="name"
                      placeholder="Nintendo Switch"
                      className="w-100 rounded p-2"
                    />
                    <ErrorMessage name="name">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 mt-3">
                    <label htmlFor="description" className="d-block">
                      Descripción
                    </label>
                    <Field
                      name="description"
                      text="description"
                      placeholder="Producto de Amazon"
                      className="w-100 rounded p-2"
                      as="textarea"
                    />
                    <ErrorMessage name="description">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 col-md-5 mt-3">
                    <label htmlFor="oldPrice" className="d-block">
                      Precio
                    </label>
                    <Field
                      name="oldPrice"
                      text="oldPrice"
                      placeholder="Nintendo Switch"
                      className="w-100 rounded p-2"
                      onChange={(e) => {
                        setFieldValue('oldPrice', e.target.value);
                        setFieldValue(
                          'regularPrice',
                          tuyoPriceFormula(e.target.value)
                        );
                      }}
                    />
                    <ErrorMessage name="oldPrice">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 col-md-5 mt-3">
                    <label htmlFor="regularPrice" className="d-block">
                      Precio Tuyo
                    </label>
                    <Field
                      name="regularPrice"
                      text="regularPrice"
                      placeholder="Nintendo Switch"
                      className="w-100 rounded p-2"
                    />
                    <ErrorMessage name="regularPrice">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 mt-3">
                    <label htmlFor="categoryId" className="d-block">
                      Categoria
                    </label>
                    <Field
                      name="categoryId"
                      text="categoryId"
                      placeholder="Nintendo Switch"
                      className="w-100 rounded p-2"
                      as="select"
                    >
                      <option value="">Seleccione una categoria</option>
                      {categories?.map((category) => (
                        <option value={category.productsCategoriesId}>
                          {category.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="categoryId">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 mt-3">
                    <label htmlFor="subCategoryId" className="d-block">
                      Subcategoria
                    </label>
                    <Field
                      name="subCategoryId"
                      text="subCategoryId"
                      placeholder="Nintendo Switch"
                      className="w-100 rounded p-2"
                      as="select"
                    >
                      <option value="">Seleccione una sub categoria</option>
                      {categories
                        ?.find(
                          (category) =>
                            category.productsCategoriesId == values.categoryId
                        )
                        ?.subcategories?.map((subCategory) => (
                          <option value={subCategory.productsSubcategoriesId}>
                            {subCategory.name}
                          </option>
                        ))}
                    </Field>
                    <ErrorMessage name="subCategoryId">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  {product?.colors?.length > 0 && (
                    <div className="col-12 mt-3">
                      <label className="d-block">Colores</label>
                      <div
                        style={{
                          overflowX: 'scroll',
                          whiteSpace: 'nowrap',
                          padding: '0.3rem',
                        }}
                      >
                        {productsColors?.map((color, index) => (
                          <button
                            type="button"
                            key={index}
                            onClick={() => {
                              color.selected = !color.selected;
                              setProductColors([...productsColors]);
                            }}
                            className={`text-dark-blue font-size-x font-weight-normal rounded p-1 d-inline-block mr-3 ${
                              color.selected ? 'ring-green' : 'ring'
                            }`}
                          >
                            {color?.description}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                  {variations?.length > 0 && (
                    <div className="col-12 mt-3">
                      <label className="d-block">Variaciones</label>
                      <div
                        style={{
                          overflowX: 'scroll',
                          whiteSpace: 'nowrap',
                          padding: '0.3rem',
                        }}
                      >
                        {variations?.map((variant, index) => (
                          <button
                            type="button"
                            key={index}
                            onClick={() => {
                              variant.selected = !variant.selected;
                              setVariations([...variations]);
                            }}
                            className={`text-dark-blue font-size-x font-weight-normal rounded p-1 d-inline-block mr-3 ${
                              variant.selected ? 'ring-green' : 'ring'
                            }`}
                          >
                            {variant?.description}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}

                  <button
                    type="submit"
                    className="w-100 bg-purple tuyo-btn px-4 py-2 mt-1 rounded text-light font-weight-bold d-flex align-items-center justify-content-around mt-4"
                  >
                    AGREGAR PRODUCTO
                  </button>
                </Form>
              </>
            )}
          </Formik>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center pb-4">
          <h5 className="text-gray font-weight-bold text-center mb-4">
            Cargando producto, espere un momento...
          </h5>
          <ClimbingBoxLoader color={colors.green} size="25" />
        </div>
      )}
    </ReactModal>
  );
}

export default AmazonDetailCard;
