import React, { useEffect, useState } from 'react';

import Card from '../../components/Card';
import { useCookies } from 'react-cookie';
import PaginatedTable from 'components/TableWithPagination/PaginatedTable';
import api from 'utils/api';
import { toast } from 'react-toastify';
import { TagSelectorModal } from 'components/TagSelectorModal';
import TagSelectorModalButton from 'components/TagSelectorModalButton';
import { TagSelectorModalType } from 'components/TagSelectorModal';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router';
import { formatPrice } from 'utils/misc';

function TurboStores() {
  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userId = cookies[REACT_APP_COOKIES_USER_ID];

  const { id, store } = useParams();

  const [productsRemount, setProductsRemount] = useState(0);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    items: [],
    initialItems: [],
    type: null,
    onSubmit: () => {},
    onRemove: null,
  });

  const turboButton = (value, row, field) => (
    <button
      onClick={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        api.products
          .updateOne(row.productsId, {
            [field]: !value,
            updatedBy: userId,
          })
          .then(() => {
            toast.success('Producto actualizado');
            setProductsRemount((prev) => prev + 1);
          })
          .catch(() => {
            toast.error('No se pudo actualizar el producto');
          });
      }}
    >
      <span
        data-tip={value ? 'Desactivar' : 'Activar'}
        className={`material-icons ${value ? 'text-turbo' : 'text-grey'}`}
      >
        {value ? 'check_box' : 'check_box_outline_blank'}
      </span>
      <ReactTooltip effect="solid" />
    </button>
  );

  const productsTable = {
    pageSize: 20,
    baseURL: '/products',
    rowId: 'productsId',
    baseFilter: {
      where: {
        fk_storesId: id,
      },
      order: [
        ['productsId', 'DESC'],
        ['isTurboShipping', 'DESC'],
      ],
      include: [
        {
          association: 'images',
          limit: 1,
          attributes: ['url'],
        },
        {
          association: 'store',
          attributes: ['storesId', 'name', 'isTurboShipping'],
        },
      ],
    },
    columns: [
      {
        title: 'Id',
        objectProperty: 'productsId',
        searchable: true,
        columnClass: '!w-16 !text-center',
        searchType: 'number-equal',
        sortable: true,
      },
      {
        title: 'Pertenece a Turbo',
        objectProperty: 'isTurboShipping',
        className: 'w-16 text-center',
        middleware: (value, row) => turboButton(value, row, 'isTurboShipping'),
        sortable: true,
      },
      {
        title: '',
        objectProperty: 'images',
        columnClass: '!w-16',
        middleware: (value) => (
          <img
            className="img-fluid !w-12 !h-12 rounded-xl border border-gray-300 object-contain"
            src={value[0]?.url}
            alt="Imagen del producto"
          />
        ),
      },
      {
        title: 'Nombre',
        objectProperty: 'name',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
      {
        title: 'Precio',
        objectProperty: 'regularPrice',
        middleware: (value) => formatPrice(value),
      },
      {
        title: 'SKU',
        objectProperty: 'sku',
      },
      {
        title: 'Tienda',
        objectProperty: 'store',
        className: 'w-32 !text-center',
        middleware: (value) => value?.name ?? 'Sin tienda',
      },
    ],
  };

  const updateProducts = (stores, enable) => {
    api.turbo
      .updateProducts({
        products: stores.map((store) => store.id),
        isTurboShipping: enable,
      })
      .then(() => {
        toast.success('Productos actualizados');
        setProductsRemount((prev) => prev + 1);
      })
      .catch(() => {
        toast.error('No se pudo actualizar los productos');
      });
  };

  useEffect(() => {
    document.title = `${store} - Turbo Envios | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <TagSelectorModal
        {...modalInfo}
        where={{
          fk_storesId: id,
        }}
        onClose={() =>
          setModalInfo({
            isOpen: false,
            items: [],
          })
        }
      />
      <h3 className="text-dark-blue font-size-2x font-weight-bold">
        {store} - Turbo Envios
      </h3>
      <div className="row mt-3 -mx-[15px]">
        <div className="col-12">
          <Card className="row">
            <div className="col-12 col-sm-5 col-md-4 col-lg-3 mx-[-15px]">
              <TagSelectorModalButton
                title="Agregar productos"
                tags={[]}
                onClick={() => {
                  setModalInfo({
                    title: `Agregar productos - (${store})`,
                    initialItems: [],
                    onSubmit: (data) => updateProducts(data, true),
                    onRemove: (data) => updateProducts(data, false),
                    isOpen: true,
                    type: TagSelectorModalType.PRODUCTS,
                  });
                }}
              />
            </div>
            <PaginatedTable
              columns={productsTable.columns}
              pageSize={productsTable.pageSize}
              baseURL={productsTable.baseURL}
              baseFilter={productsTable.baseFilter}
              rowId={productsTable.rowId}
              remountCount={productsRemount}
              cleanReload={false}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default TurboStores;
