import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import BounceLoader from 'react-spinners/BounceLoader';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Datetime from 'react-datetime';

import Card from '../../components/Card';

import api from '../../utils/api';
import colors from '../../assets/resources/colors';
import {
  fileToBase64URL,
  uploadPictureToS3,
  deleteFromS3,
} from '../../utils/misc';
import moment from 'moment';

const types = {
  store_feature: '1:1',
  product_feature: '1:1',
  coupon: '2:1',
  default: '2:1',
  web_home: '2:1',
  web_featured_square: '1:1',
  web_featured_rect: '11:7',
  turbo_shipping: '2:1',
};

const aspect = {
  store_feature: 1 / 1,
  product_feature: 1 / 1,
  coupon: 1 / 2,
  default: 1 / 2,
  web_home: 1 / 2,
  web_featured_square: 1 / 1,
  web_featured_rect: 3 / 2,
  turbo_shipping: 1 / 2,
};

function NewBanner() {
  const formRef = useRef(null);
  const history = useHistory();

  /* URL params */
  const { id: bannerId } = useParams();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  const [selectedType, setSelectedType] = useState('store_feature');
  const [stores, setStores] = useState();
  const [products, setProducts] = useState();
  const [selectedStoreId, setSelectedStoreId] = useState();
  const [selectedProductId, setSelectedProductId] = useState();
  const [URL, setURL] = useState('');
  const [banner, setBanner] = useState();
  const [image, setImage] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [selectedProductTypeId, setSelectedProductTypeId] = useState();

  /* Set page title */
  useEffect(() => {
    document.title = `${
      bannerId ? 'Editar' : 'Nuevo'
    } banner | ${REACT_APP_TITLE}`;
  }, []);

  /* Get product types */
  useEffect(() => {
    api
      .findAll(
        '/productstypes?filter=' +
          JSON.stringify({
            attributes: ['productsTypesId', 'name'],
          })
      )
      .then((response) => {
        setProductTypes(response.data);
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener listado de tipos de producto');
      });
  }, []);

  /* Get stores */
  useEffect(() => {
    api
      .findAll(
        '/stores?filter=' +
          JSON.stringify({
            attributes: ['storesId', 'name'],
            order: [['name', 'asc']],
            where: {
              active: true,
            },
          })
      )
      .then(({ data }) => setStores(data))
      .catch((err) => toast.warning(`[SERVER_ERRR] ${err}`));
  }, []);

  /* Get products when selected store changes */
  useEffect(() => {
    setProducts(null);
    setSelectedProductId(null);

    selectedStoreId &&
      api
        .findAll(
          '/products?filter=' +
            JSON.stringify({
              where: { fk_storesId: selectedStoreId },
              attributes: ['productsId', 'name'],
              order: [['name', 'asc']],
            })
        )
        .then(({ data }) => setProducts(data))
        .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));
  }, [selectedStoreId]);

  useEffect(() => {
    if (banner && selectedStoreId && banner?.url.indexOf('product') !== -1) {
      setSelectedProductId(banner?.url.substring(banner?.url.indexOf('/') + 1));
    }
  }, [selectedStoreId, banner]);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  /* Get banner */
  useEffect((_) => {
    bannerId &&
      api
        .findOne('/storesbanners', bannerId)
        .then((res) => {
          setBanner(res.data);
        })
        .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));
  }, []);

  useEffect(() => {
    if (productTypes?.length > 0) {
      if (banner) {
        setSelectedProductTypeId(banner.fk_productsTypesId);
      } else {
        setSelectedProductTypeId(productTypes[0].productsTypesId);
      }
    }
  }, [banner, productTypes]);

  useEffect(
    (_) => {
      if (banner) {
        setSelectedType(banner.type);
        setImage({ URL: banner.image });
        setURL(banner.url);
        setSelectedStoreId(banner.fk_storesId);

        if (banner.url && banner.url.indexOf('product') !== -1) {
          setSelectedProductId(parseInt(banner.url.indexOf('/') + 1));
        }
      }
    },
    [banner]
  );

  async function save(formValues) {
    const { active, cta } = formValues;

    if (!image) {
      toast.error('Debes subir una imagen para el banner');
      return;
    }

    setIsSaving(true);

    const body = {
      ...formValues,
      active: active ? 1 : 0,
      url: URL,
      cta,
      type: selectedType,
      image: image.base64
        ? await uploadPictureToS3(image.base64, image.type, image.name)
        : image.URL,
      fk_storesId: selectedStoreId || 24,
      fk_productsTypesId: selectedProductTypeId,
    };

    if (image.oldURL) {
      await deleteFromS3(
        image.oldURL.substring(image.oldURL.lastIndexOf('/') + 1)
      );
    }

    body[bannerId ? 'updatedBy' : 'createdBy'] =
      cookies[REACT_APP_COOKIES_USER_ID];
    const action = bannerId
      ? api.update('/storesbanners', bannerId, body)
      : api.create('/storesbanners', [body]);

    action
      .then((_) => {
        toast.success(`Banner ${bannerId ? 'actualizado' : 'creado'}`);
        history.push('/admin/banners');
      })
      .catch((err) => {
        toast.warning(`[SERVER_ERROR] ${err}`);
      })
      .finally((_) => setIsSaving(false));
  }

  async function changeBanner(event) {
    const file = event.target.files[0];
    if (file) {
      if (file.size / 1024 >= 2048) {
        toast.warning('Error: La imagen supera el peso maximo permitido');
        event.target.value = '';
      } else {
        const { type, name } = file;

        setImage({
          base64: await fileToBase64URL(file),
          type,
          name,
          ...(bannerId && banner ? { oldURL: image.URL } : {}),
        });
      }
    }
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center text-md-left w-100">
            {bannerId ? 'Editar' : 'Nuevo'} banner
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <button
            disabled={isSaving}
            onClick={handleSubmit}
            type="button"
            className="bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
          >
            {isSaving ? 'Guardando' : 'Guardar'}
            <BounceLoader color="#fff" loading={isSaving} size="18" />
          </button>
        </div>
      </div>
      <Card className="p-4 mt-3 mt-md-4">
        {(bannerId && banner) || !bannerId ? (
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={{
              title: banner ? banner.title : '',
              subtitle: banner ? banner.subtitle : '',
              active: banner ? banner.active == 1 : false,
              cta: banner ? banner.cta : '',
              isSales: banner?.isSales || false,
              isFlashSales: banner?.isFlashSales || false,
              startline: banner?.startline || '',
              deadline: banner?.deadline || '',
            }}
            onSubmit={(values) => {
              save(values);
            }}
          >
            {() => (
              <Form>
                <div className="row justify-content-center">
                  <div
                    className={`${
                      ['default', 'coupon', 'web_home'].includes(selectedType)
                        ? 'col-12 col-md-6'
                        : 'col-6 col-md-3'
                    } mt-3 mt-md-0`}
                  >
                    <button
                      type="button"
                      className="w-100 add-picture-btn"
                      style={{
                        border: '2px solid rgba(0,0,0,0.16)',
                        paddingTop: `${aspect[selectedType] * 100}%`,
                        borderRadius: '1rem',
                      }}
                    >
                      <input
                        accept=".jpg,.jpeg,.png"
                        id="new-banner"
                        type="file"
                        className="d-none"
                        onChange={(event) => changeBanner(event)}
                      />
                      <label
                        htmlFor="new-banner"
                        className="mb-0 cursor-pointer rounded"
                        style={{
                          backgroundImage: image
                            ? `url('${image.base64 || image.URL}')`
                            : '',
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          right: '0',
                          bottom: '0',
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                        }}
                      />
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <small style={{ fontStyle: 'italic' }}>
                    Relación de aspecto recomendada: {types[selectedType]}
                  </small>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 mt-2">
                    <label htmlFor="title" className="d-block">
                      Título
                    </label>
                    <Field
                      className="w-100 p-2 rounded"
                      name="title"
                      type="text"
                      placeholder="Título del banner"
                    />
                  </div>
                  <div
                    className={`col-6 d-flex flex-column justify-content-center align-items-center mt-2 ${
                      selectedType.includes('web_') ? 'col-md-4' : 'col-md-2'
                    }`}
                  >
                    <label className="d-block" htmlFor="active">
                      Activo
                    </label>
                    <Field name="active" type="checkbox" id="active" />
                  </div>
                  {!selectedType.includes('web_') && (
                    <>
                      <div className="col-6 col-md-3 d-flex flex-column justify-content-center align-items-center mt-2">
                        <label className="d-block" htmlFor="isSales">
                          Oferta
                        </label>
                        <Field name="isSales" type="checkbox" id="isSales" />
                      </div>
                      <div className="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center mt-2">
                        <label className="d-block" htmlFor="isFlashSales">
                          Oferta relámpago
                        </label>
                        <Field
                          name="isFlashSales"
                          type="checkbox"
                          id="isFlashSales"
                        />
                      </div>

                      <div className="col-12 col-md-4 mt-2">
                        <label htmlFor="subtitle" className="d-block">
                          Subtítulo
                        </label>
                        <Field
                          className="w-100 p-2 rounded"
                          name="subtitle"
                          type="text"
                          placeholder="Subtítulo del banner"
                        />
                      </div>
                    </>
                  )}
                  <div className="col-12 col-md-4 mt-2">
                    <label className="d-block" htmlFor="fk_productsTypesId">
                      Tipo de producto
                    </label>
                    <select
                      value={selectedProductTypeId}
                      onChange={(event) =>
                        setSelectedProductTypeId(parseInt(event.target.value))
                      }
                      className="w-100 p-2 rounded"
                      name="fk_productsTypesId"
                      id="fk_productsTypesId"
                    >
                      {productTypes.map((type) => (
                        <option
                          key={type.productsTypesId}
                          value={type.productsTypesId}
                        >
                          {type.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 mt-2 col-md-4">
                    <label htmlFor="url" className="d-block">
                      URL
                    </label>
                    <input
                      type="text"
                      value={URL}
                      placeholder={
                        selectedType.includes('web_')
                          ? 'Ej. search/stores?q=&page=0'
                          : 'store/404'
                      }
                      id="url"
                      className="w-100 p-2 rounded"
                      onChange={({ target }) => setURL(target.value)}
                    />
                  </div>
                  {!selectedType.includes('web_') && (
                    <div className="col-12 col-md-4 mt-2">
                      <label htmlFor="cta" className="d-block">
                        CTA
                      </label>
                      <Field
                        className="w-100 p-2 rounded"
                        name="cta"
                        type="text"
                        placeholder="Comprame"
                      />
                    </div>
                  )}

                  <div className="col-12 col-md-4 mt-2">
                    <label className="d-block" htmlFor="startline">
                      Inicio
                    </label>
                    <Field name="startline">
                      {({ field, form: { setFieldValue } }) => (
                        <Datetime
                          value={moment(field.value)}
                          onChange={(data) =>
                            setFieldValue('startline', moment(data).toString())
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage name="startline">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 col-md-4 mt-2">
                    <label className="d-block" htmlFor="deadline">
                      Fin
                    </label>
                    <Field name="deadline">
                      {({ field, form: { setFieldValue } }) => (
                        <Datetime
                          value={moment(field.value)}
                          onChange={(data) =>
                            setFieldValue('deadline', moment(data).toString())
                          }
                        />
                      )}
                    </Field>
                    <ErrorMessage name="deadline">
                      {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 col-md-4 mt-2">
                    <label className="d-block" htmlFor="type">
                      Tipo
                    </label>
                    <select
                      value={selectedType}
                      onChange={(event) => setSelectedType(event.target.value)}
                      className="w-100 p-2 rounded"
                      name="type"
                      id="type"
                    >
                      {Object.entries(types).map((type, index) => (
                        <option key={index} value={type[0]}>
                          {type[0]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 mt-2">
                    <label className="d-block" htmlFor="type">
                      Tienda
                    </label>
                    <select
                      value={selectedStoreId}
                      onChange={({ target }) => {
                        setSelectedStoreId(parseInt(target.value));
                        !selectedType.includes('web_') &&
                          setURL(`store/${target.value}`);
                      }}
                      className="w-100 p-2 rounded"
                      name="type"
                      id="type"
                    >
                      <option />
                      {stores?.map((store) => (
                        <option key={store.storesId} value={store.storesId}>
                          {store.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-4 mt-2">
                    <label className="d-block" htmlFor="type">
                      Producto
                    </label>
                    <select
                      disabled={!selectedStoreId || products?.length < 1}
                      value={selectedProductId}
                      onChange={({ target }) => {
                        setSelectedProductId(parseInt(target.value));
                        !selectedType.includes('web_') &&
                          setURL(`product/${target.value}`);
                      }}
                      className="w-100 p-2 rounded"
                      name="type"
                      id="type"
                    >
                      <option />
                      {products?.map((product) => (
                        <option
                          key={product.productsId}
                          value={product.productsId}
                        >
                          {product.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div
            className="p-5 m-5 d-flex justify-content-center align-items-center"
            style={{
              flexGrow: '1',
            }}
          >
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </Card>
    </div>
  );
}

export default NewBanner;
