import React from 'react';

function Card(props) {
  return (
    <div className={'tuyo-card d-flex flex-column ' + props.className}>
      {props.children}
    </div>
  );
}

export default Card;
