import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BounceLoader } from 'react-spinners';
import Datetime from 'react-datetime';

import Card from 'components/Card';
import Table from 'components/Table/Table';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import TagSelector from 'components/TagSelector';
import SendNotification from 'components/SendNotification';
import api from 'utils/api';
import CartDetailCard from 'components/CartModal';
import { TagSelectorModal } from 'components/TagSelectorModal';
import TagSelectorModalButton from 'components/TagSelectorModalButton';
import { TagSelectorModalType } from 'components/TagSelectorModal';

const notificacionTypes = [
  {
    name: 'Usuarios',
    value: 'users',
  },
  {
    name: 'Carrito',
    value: 'cart',
  },
  {
    name: 'Tiendas en carrito',
    value: 'storesCart',
  },
  {
    name: 'Monto del carrito',
    value: 'cartAmount',
  },
  {
    name: 'Palabras clave',
    value: 'keywords',
  },
  {
    name: 'Lista personalizada',
    value: 'customList',
  },
];

function SendMasiveNotifications() {
  const { REACT_APP_TITLE } = process.env;
  const [stores, setStores] = useState([]);
  const [notificationModal, setNotificationModal] = useState({
    isOpen: false,
    usersId: [],
  });
  const [cartModal, setCartModal] = useState({
    isOpen: false,
    isLoading: false,
    data: [],
  });
  const [users, setUsers] = useState([]);

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    items: [],
    initialItems: [],
    type: null,
    onSubmit: () => {},
  });

  const tableProps = {
    options: {
      id: 'usersId',
      pageSize: 10,
    },
    columns: [
      {
        text: '✓',
        key: 'isSelected',
        width: '5%',
        className: 'text-center',
        isCheckbox: true,
        middleware: (_, rowId) => {
          const user = users.find((user) => user.usersId === rowId);
          user.isSelected = !user.isSelected;
          setUsers([...users]);
        },
      },
      {
        text: 'Nombre',
        key: 'name',
        className: 'text-center',
      },
      {
        text: 'Email',
        key: 'email',
        className: 'text-center',
      },

      {
        text: 'Telefono',
        key: 'phone',
        className: 'text-center',
      },
      {
        text: '',
        key: 'options',
        isButton: true,
        buttonText: 'Enviar notificacion',
        onClick: (rowId) => {
          setNotificationModal({
            isOpen: true,
            usersId: [rowId],
          });
        },
      },
    ],
  };

  const formikValidate = (values) => {
    const errors = {};

    if (!values.type) errors.type = 'Requerido';

    if (values.type !== 'customList') {
      if (!values.startDate) errors.startDate = 'Requerido';
      if (!values.endDate) errors.endDate = 'Requerido';
    }

    if (values.type === 'cartAmount') {
      const { minAmount, maxAmount } = values;

      if (!minAmount) errors.minAmount = 'Requerido';
      if (!maxAmount) errors.maxAmount = 'Requerido';

      if (minAmount && !(+minAmount > 0))
        errors.minAmount = 'Debe ser mayor que 0';

      if (maxAmount && !(+maxAmount > 0))
        errors.maxAmount = 'Debe ser mayor que 0';

      if (minAmount && maxAmount) {
        if (+minAmount > +maxAmount) {
          errors.minAmount = 'El minimo debe ser menor al maximo';
        }
      }
    }

    if (values.type === 'keywords') {
      if (!values.keywords) errors.keywords = 'Requerido';
    }

    if (values.startDate && values.endDate) {
      const startDate = moment(values.startDate);
      const endDate = moment(values.endDate);
      const diff = endDate.diff(startDate, 'days');
      if (diff > 30) {
        errors.endDate = 'La fecha de fin debe ser menor a 30 días';
        errors.startDate = 'La fecha de inicio debe ser menor a 30 días';
      }
    }

    if (values.type === 'customList') {
      if (!values.customList) errors.customList = 'Requerido';
      if (values.customList) {
        const regex = /^[0-9,]+$/;

        if (!regex.test(values.customList)) {
          errors.customList = 'Debe cumplir con el formato solicitado';
        }

        if (values.customList.toString().split(',').length > 500) {
          errors.customList = 'No puede tener más de 500 usuarios';
        }
      }
    }

    return errors;
  };

  const formikOnSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    let filters = [
      `startDate=${moment(values.startDate).format('YYYY-MM-DD')}`,
      `endDate=${moment(values.endDate).format('YYYY-MM-DD')}`,
      `type=${values.type}`,
    ];

    let action = null;
    let customList = [];

    if (values.type === 'cartAmount') {
      filters.push(`minAmount=${values.minAmount}`);
      filters.push(`maxAmount=${values.maxAmount}`);
    }

    if (values.type === 'keywords') {
      filters.push(`keywords=${values.keywords}`);
    }

    if (values.type === 'storesCart') {
      if (values.storesCart.length) {
        filters.push(
          `storesCart=[${values.storesCart?.map((m) => m.id).join(',')}]`
        );
      }

      if (values.excludedStores.length) {
        filters.push(
          `excludedStores=[${values.excludedStores
            ?.map((m) => m.id)
            .join(',')}]`
        );
      }
    }

    if (values.type === 'customList') {
      customList = values.customList
        .toString()
        .split(',')
        .map((id) => +id);
    }

    action =
      values.type === 'customList'
        ? api.users.getAll({
            attributes: ['usersId', 'email', 'firstname', 'lastname', 'phone'],
            where: {
              usersId: {
                $in: customList,
              },
            },
          })
        : api.oneSignal.getUsersByFilter(filters);

    action
      .then((response) => {
        const _users = response.data.map((user) => ({
          name: `${user.firstname} ${user.lastname}`,
          ...user,
        }));

        if (!_users.length) {
          toast.warning('No se encontraron usuarios');
          return;
        }

        setUsers(_users);
      })
      .catch((err) => console.error(err))
      .finally(() => setSubmitting(false));
  };

  const sendNotificationToAllUsers = () => {
    setNotificationModal({
      isOpen: true,
      usersId: [...users.map((user) => user.usersId)],
    });
  };

  const sendNotificationToSelectedUsers = () => {
    const selectedUsers = users.filter((user) => user.isSelected);
    if (!selectedUsers.length) {
      toast.warning('Debe seleccionar al menos un usuario');
      return;
    }

    setNotificationModal({
      isOpen: true,
      usersId: [...selectedUsers.map((user) => user.usersId)],
    });
  };

  const onRowClick = (rowId) => {
    setCartModal({
      isOpen: true,
      isLoading: true,
    });

    api.cart
      .findById(rowId)
      .then((res) => {
        if (!res?.data?.length) {
          setCartModal({
            isOpen: false,
          });
          toast.warning('El usuario tiene carrito vacio');
        } else {
          const data = res.data.map((item) => ({
            ...item,
            image: item.variant.image,
            name: item.variant.product.name,
            price: item.variant.price,
            discount: item.variant.discount,
          }));

          setCartModal({
            isOpen: true,
            isLoading: false,
            data,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error al obtener el carrito');
        setCartModal({
          isOpen: false,
        });
      });
  };

  useEffect(() => {
    document.title = `Notificaciones | ${REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    api.stores
      .getAll({
        attributes: ['storesId', 'name'],
        order: [['name', 'asc']],
        where: {
          active: true,
        },
      })
      .then((response) => {
        let tmpStores = response.data?.map((store) => ({
          id: store.storesId,
          value: store.name,
        }));

        setStores(tmpStores);
      })
      .catch((err) => toast.warning(`[SERVER_ERROR] ${err}`));
  }, []);

  return (
    <div className="content">
      <TagSelectorModal
        {...modalInfo}
        onClose={() => {
          setModalInfo({
            isOpen: false,
            items: [],
          });
        }}
      />
      {notificationModal?.isOpen && (
        <SendNotification
          usersIds={notificationModal.usersId}
          onCloseRequest={() => {
            setNotificationModal({
              isOpen: false,
              usersId: [],
            });
          }}
        />
      )}

      <CartDetailCard
        isOpen={cartModal.isOpen}
        isLoading={cartModal.isLoading}
        data={cartModal.data}
        onClose={() =>
          setCartModal({
            isOpen: false,
            isLoading: false,
            data: [],
          })
        }
      />

      <div>
        <div className="row">
          <div className="col">
            <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
              <h3 className="text-dark-blue font-size-2x font-weight-bold">
                Notificar usuarios
              </h3>
            </div>
            <Card className="p-md-4 mb-4">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  type: '',
                  startDate: '',
                  endDate: '',
                  minAmount: '',
                  maxAmount: '',
                  keywords: '',
                  storesCart: [],
                  customList: [],
                  excludedStores: [],
                }}
                validate={formikValidate}
                onSubmit={formikOnSubmit}
              >
                {({ values, isSubmitting, setFieldValue }) => (
                  <Form>
                    <div className="row">
                      <div className="col-12 mt-2">
                        <label htmlFor="report">Tipo de notificacion</label>
                        <Field
                          className="p-2 rounded w-100"
                          as="select"
                          name="type"
                        >
                          <option value="">Seleccione una opcion</option>
                          {notificacionTypes.map((type) => (
                            <option key={type.value} value={type.value}>
                              {type.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name="type">
                          {(msg) => (
                            <small style={{ color: 'red' }}>{msg}</small>
                          )}
                        </ErrorMessage>
                      </div>

                      {values.type !== 'customList' && (
                        <>
                          <div className="col-12 col-md-6 mt-2">
                            <label className="d-block" htmlFor="startDate">
                              Inicio
                            </label>
                            <Field name="startDate">
                              {({ field, form: { setFieldValue } }) => (
                                <Datetime
                                  value={moment(field.value)}
                                  onChange={(data) =>
                                    setFieldValue(
                                      'startDate',
                                      moment(data).toString()
                                    )
                                  }
                                />
                              )}
                            </Field>
                            <ErrorMessage name="startDate">
                              {(msg) => (
                                <small style={{ color: 'red' }}>{msg}</small>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="col-12 col-md-6 mt-2">
                            <label className="d-block" htmlFor="endDate">
                              Fin
                            </label>
                            <Field name="endDate">
                              {({ field, form: { setFieldValue } }) => (
                                <Datetime
                                  value={moment(field.value)}
                                  onChange={(data) =>
                                    setFieldValue(
                                      'endDate',
                                      moment(data).toString()
                                    )
                                  }
                                />
                              )}
                            </Field>
                            <ErrorMessage name="endDate">
                              {(msg) => (
                                <small style={{ color: 'red' }}>{msg}</small>
                              )}
                            </ErrorMessage>
                          </div>
                        </>
                      )}

                      {values.type === 'cartAmount' && (
                        <>
                          <div className="col-12 col-md-6 mt-2">
                            <label htmlFor="minAmount" className="d-block">
                              Monto minimo
                            </label>
                            <Field
                              className="w-100 p-2 rounded"
                              name="minAmount"
                              type="text"
                              placeholder="Ej: 10.00"
                            />
                            <ErrorMessage name="minAmount">
                              {(msg) => (
                                <small style={{ color: 'red' }}>{msg}</small>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="col-12 col-md-6 mt-2">
                            <label htmlFor="maxAmount" className="d-block">
                              Monto maximo
                            </label>
                            <Field
                              className="w-100 p-2 rounded"
                              name="maxAmount"
                              type="text"
                              placeholder="Ejemplo: 100.00"
                            />
                            <ErrorMessage name="maxAmount">
                              {(msg) => (
                                <small style={{ color: 'red' }}>{msg}</small>
                              )}
                            </ErrorMessage>
                          </div>
                        </>
                      )}

                      {values.type === 'keywords' && (
                        <div className="col-12 mt-2">
                          <label htmlFor="keywords" className="d-block">
                            Palabra clave
                          </label>
                          <Field
                            className="w-100 p-2 rounded"
                            name="keywords"
                            type="text"
                            placeholder="Ejemplo: 'Zapatos'"
                          />
                          <ErrorMessage name="keywords">
                            {(msg) => (
                              <small style={{ color: 'red' }}>{msg}</small>
                            )}
                          </ErrorMessage>
                        </div>
                      )}

                      {values.type === 'customList' && (
                        <div className="col-12 mt-2">
                          <label htmlFor="customList" className="d-block">
                            Palabra clave
                          </label>
                          <small>
                            Deben ir separadas por comas o espacios.
                          </small>
                          <Field
                            className="w-100 p-2 rounded"
                            name="customList"
                            type="text"
                            placeholder="Ejemplo: 2016,2231,1231,1234 o 123 2345 2342 2345"
                            onBlur={(e) => {
                              let value = e.target.value;
                              value = value.split(/[,\s]+/);
                              value = value.filter((v) => v !== '');
                              value = [...new Set(value)];

                              setFieldValue('customList', value);
                            }}
                          />
                          <ErrorMessage name="customList">
                            {(msg) => (
                              <small style={{ color: 'red' }}>{msg}</small>
                            )}
                          </ErrorMessage>
                        </div>
                      )}

                      {values.type === 'storesCart' && (
                        <div className="col-12 col-md-6 mt-2">
                          <label htmlFor="storesCart" className="d-block">
                            Tiendas en carrito
                          </label>
                          <Field name="storesCart">
                            {({ field, form: { setFieldValue } }) => (
                              <TagSelectorModalButton
                                title={
                                  field?.value?.length > 0
                                    ? 'Tiendas en carrito'
                                    : 'Agregar tiendas en carrito'
                                }
                                tags={field.value}
                                onClick={() => {
                                  setModalInfo({
                                    title: 'Tiendas en carrito',
                                    initialItems: field.value,
                                    onSubmit: (data) =>
                                      setFieldValue('storesCart', data),
                                    isOpen: true,
                                    type: TagSelectorModalType.STORES,
                                  });
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="storesCart"
                            component="small"
                            className="text-error"
                          />
                        </div>
                      )}

                      {values.type === 'storesCart' && (
                        <div className="col-12 col-md-6 mt-2">
                          <label htmlFor="excludedStores" className="d-block">
                            Tiendas excluidas
                          </label>
                          <Field name="excludedStores">
                            {({ field, form: { setFieldValue } }) => (
                              <TagSelectorModalButton
                                title={
                                  field?.value?.length > 0
                                    ? 'Tiendas excluidas'
                                    : 'Excluir tiendas'
                                }
                                tags={field.value}
                                onClick={() => {
                                  setModalInfo({
                                    title: 'Excluir tiendas',
                                    initialItems: field.value,
                                    onSubmit: (data) =>
                                      setFieldValue('excludedStores', data),
                                    isOpen: true,
                                    type: TagSelectorModalType.STORES,
                                  });
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="excludedStores"
                            component="small"
                            className="text-error"
                          />
                        </div>
                      )}

                      <div className="col-12 mt-3">
                        <button className="w-100 bg-purple tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around">
                          Buscar
                          <BounceLoader
                            color="#fff"
                            loading={isSubmitting}
                            size="18"
                          />
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
            {users.length > 0 && (
              <Card className="p-md-4">
                <div className="row">
                  <div className=" col-3 my-3">
                    <button
                      onClick={sendNotificationToSelectedUsers}
                      className="w-100 bg-purple tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                    >
                      Enviar a seleccionados
                    </button>
                  </div>
                  <div className="col-3 my-3">
                    <button
                      onClick={sendNotificationToAllUsers}
                      className="w-100 bg-green tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                    >
                      Enviar a todos
                    </button>
                  </div>
                </div>
                <Table
                  onRowClick={onRowClick}
                  options={tableProps.options}
                  columns={tableProps.columns}
                  data={users}
                  pageSize={10}
                />
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendMasiveNotifications;
