import React, { useEffect, useState } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import preval from 'preval.macro';

import api from 'utils/api';

import Card from '../components/Card';

import bgSVG from '../assets/img/login-bg.svg';
import logoSVG from '../assets/img/tuyo_icon.svg';
import PasswordInput from '../components/PasswordInput';
import { ROLES_ID } from 'utils/misc';

// build id DAY/MONTH/YEAR/HOUR/MINUTE/SECOND
const buildTimestamp = preval`module.exports = new Date().toLocaleString().replace(/[/ :,APM]/g, '');`;

function Login() {
  useEffect(() => {
    document.title = `Inicio de sesión | ${process.env.REACT_APP_TITLE}`;
  }, []);
  const history = useHistory();

  const { REACT_APP_COOKIES_JWT, REACT_APP_COOKIES_USER_ID } = process.env;
  const [cookies, setCookie] = useCookies([
    REACT_APP_COOKIES_JWT,
    REACT_APP_COOKIES_USER_ID,
  ]);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [tryingLogin, setTryingLogin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  /* componentDidMount */
  useEffect(() => {
    if (cookies[REACT_APP_COOKIES_JWT]) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const state = history.location.state;
      const { nextLocation } = state || {};

      history.push(nextLocation || '/admin/dashboard');
    }
  }, [isLoggedIn]);

  const tryLogin = (e) => {
    e.preventDefault();
    setTryingLogin(true);

    api
      .login({
        username: username.trim(),
        password,
      })
      .then((res) => {
        const JWT = res.data.token;
        const decodedJWT = jwt_decode(JWT);
        if (
          ![
            ROLES_ID.ROOT,
            ROLES_ID.GERENCY,
            ROLES_ID.FINANCE,
            ROLES_ID.LOGISTIC,
            ROLES_ID.MARKETING,
            ROLES_ID.LOGISTIC_USER,
            ROLES_ID.MARKETING_LEAD,
            ROLES_ID.CUSTOMER_EXPERIENCE,
            ROLES_ID.KEY_ACCOUNT_MANAGER,
            ROLES_ID.CUSTOMER_EXPERIENCE_LEAD,
            ROLES_ID.BUYER,
            ROLES_ID.PAYMENTS_KAM,
          ].includes(decodedJWT.user.role.rolesId)
        ) {
          toast.error('El usuario no tiene suficientes permisos');
          return;
        }
        setCookie(REACT_APP_COOKIES_JWT, JWT, { path: '/' });
        setCookie(REACT_APP_COOKIES_USER_ID, decodedJWT.user.usersId, {
          path: '/',
        });

        setIsLoggedIn(true);
      })
      .catch((err) => {
        const msg = err.response?.data;
        toast.error(`STATUS[${msg.status}]: ${msg.response}`);
      })
      .finally(() => {
        setTryingLogin(false);
      });
  };

  return (
    isLoggedIn === false && (
      <div
        className="tuyo-login d-flex align-items-center flex-column justify-content-center bg-beige"
        style={{
          backgroundImage: `url('${bgSVG}')`,
        }}
      >
        <div className="row w-100 h-100 d-flex align-items-center justify-content-center">
          <div className="col-11 col-md-8 col-lg-6 col-xl-4 ">
            <Card className="py-5 px-4 px-md-5">
              <form onSubmit={(e) => tryLogin(e)}>
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <img
                      src={logoSVG}
                      alt=""
                      style={{ width: '128px', height: '128px' }}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <p className="text-center text-gray">Hola, de nuevo</p>
                    <p
                      style={{ lineHeight: '1.25' }}
                      className="text-center font-size-2x font-weight-bold text-dark-blue"
                    >
                      Ingrese a su cuenta
                    </p>
                  </div>
                  <div className="col-12 mt-4">
                    <label
                      className="d-block text-green font-weight-bold"
                      htmlFor="username"
                    >
                      Usuario
                    </label>
                    <input
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="w-100 p-2 rounded"
                      type="text"
                      autoComplete="username"
                      autoFocus
                    />

                    <label
                      className="d-block text-green font-weight-bold mt-3"
                      htmlFor="password"
                    >
                      Contraseña
                    </label>
                    <PasswordInput
                      id="password"
                      autoComplete="current-pasword"
                      onChange={(value) => setPassword(value)}
                    />
                  </div>
                  <div className="col-12 mt-4 mt-md-5">
                    <button
                      disabled={
                        !username.trim() || !password.trim() || tryingLogin
                      }
                      type="submit"
                      onClick={tryLogin}
                      className="text-white font-weight-bold bg-green w-100 p-2 rounded tuyo-btn d-flex align-items-center justify-content-around"
                    >
                      {tryingLogin ? 'Iniciando' : 'Iniciar'} sesión
                      <BounceLoader
                        color="#fff"
                        loading={tryingLogin}
                        size="18"
                      />
                    </button>
                  </div>
                </div>
              </form>
            </Card>
          </div>
        </div>
        <p>{`Build: ${buildTimestamp}`}</p>
      </div>
    )
  );
}

export default Login;
