import { OrderAddressModal } from 'components/OrderAddressModal';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import api from 'utils/api';
import { getGoogleMapsUrFromCoordinates } from 'utils/misc';

function UpdateAddressSection(props) {
  const { REACT_APP_COOKIES_USER_ID, REACT_APP_COOKIES_JWT } = process.env;

  const [cookies] = useCookies([
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  ]);
  const userId = cookies[REACT_APP_COOKIES_USER_ID];

  const { order } = props;
  const [notes, setNotes] = useState({});
  const [show, setShow] = useState(false);
  const [addressModal, setAddressModal] = useState(false);

  useEffect(
    () =>
      setNotes({
        ...(order?.cxNotes || {}),
      }),
    [props]
  );

  const onAddresChange = (values) => {
    const timestamp = new Date().getTime();
    const { address, apartment, referencePoint, location, city, state } =
      values;

    const jwt = cookies[REACT_APP_COOKIES_JWT];
    const decodedJwt = jwtDecode(jwt);

    const {
      user: { firstname },
    } = decodedJwt;

    const newAddress = {
      address,
      apartment,
      referencePoint,
      location,
      city,
      state,
    };

    setAddressModal(false);

    api.orders.get(order.ordersId).then(({ data }) => {
      const { cxNotes } = data;
      const oldAddress = {
        address: data.address,
        apartment: data.apartment,
        referencePoint: data.referencePoint,
        location: data.location,
        city: data.city,
        state: data.state,
        date: timestamp,
        user: firstname,
      };
      const notes = {
        ...cxNotes,
        history: [...(cxNotes?.history || []), oldAddress],
      };

      api.orders
        .update(order.ordersId, {
          ...newAddress,
          updatedBy: userId,
          cxNotes: notes,
        })
        .then(() => {
          toast.success('Dirección actualizada');
          props.onUpdate();
        })
        .catch(() => {
          toast.error('SERVER ERROR, No se pudo actualizar la dirección');
        });
    });
  };

  return (
    <div className="row mt-4 py-2 border rounded !space-y-3">
      <OrderAddressModal
        isOpen={addressModal}
        initialValues={order}
        onChange={onAddresChange}
        onClose={() => setAddressModal(false)}
      />
      <div className="col-12 d-flex">
        <p className="mr-3 text-indigo-dark font-extrabold">
          Actualizar dirección:
        </p>
        <button
          className="font-weight-bold text-green underline-button"
          onClick={() => setShow(!show)}
        >
          {show ? 'Ocultar' : 'Mostrar'}
        </button>
      </div>

      {show && (
        <>
          <div className="col-12 col-md-6 mt-2">
            <button
              type="submit"
              onClick={() => setAddressModal(true)}
              className="d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 px-4 py-2 rounded text-light font-weight-bold"
            >
              <span className="material-icons">edit</span>
              <span>Actualizar</span>
            </button>
          </div>
          <div className="col-12 col-md-6 mt-2">
            <p className="font-weight-bold text-green">Historial de cambios</p>
            <div
              className="py-2"
              style={{
                maxHeight: '300px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {notes?.history?.length > 0 ? (
                notes?.history?.map((his, index) => (
                  <div key={index} className="row">
                    <p className="col-12">
                      <span className="font-weight-bold">Dirección:</span>{' '}
                      {his.address}
                    </p>
                    <p className="col-12">
                      <span className="font-weight-bold">Apartamento:</span>{' '}
                      {his.apartment}
                    </p>
                    <p className="col-12">
                      <span className="font-weight-bold">
                        Punto de referencia:
                      </span>{' '}
                      {his.referencePoint}
                    </p>
                    <p className="col-12">
                      <span className="font-weight-bold">Ciudad:</span>{' '}
                      {his.city}
                    </p>
                    <p className="col-12">
                      <span className="font-weight-bold">Departamento:</span>{' '}
                      {his.state}
                    </p>
                    <p className="col-12">
                      <span className="font-weight-bold">Ubicacion:</span>{' '}
                      <a
                        href={getGoogleMapsUrFromCoordinates(
                          order?.location?.coordinates[1],
                          order?.location?.coordinates[0]
                        )}
                        target="_blank"
                      >
                        Ver puntero
                      </a>
                    </p>
                    <p className="col-12">
                      <span className="font-weight-bold">Fecha:</span>{' '}
                      {new Date(his.date).toLocaleString()}
                    </p>
                    <p className="col-12">
                      <span className="font-weight-bold">Actualizado por:</span>
                      {his.user}
                    </p>
                    <hr className="col-12" />
                  </div>
                ))
              ) : (
                <div className="text-center d-flex justify-content-center">
                  <span className="material-icons text-green mx-2">
                    running_with_errors
                  </span>
                  No hay notas
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UpdateAddressSection;
