import React, { useState, useEffect, useRef } from 'react';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

import Row from './Row';

import colors from '../../assets/resources/colors';

function PaginatedTable({
  columns,
  options,
  onView,
  onUpdate,
  onDelete,
  onChoiceChange,
  onRowClick,
  onDisable,
  updateOrderStatus,
  isSaving,
  showDateFilter,
  showFilters,
  rowWarning,
  getCount,
  getPage,
  getFilteredCount,
  pageSize,
}) {
  const currentPageInput = useRef(null);
  const debounceTimeoutId = useRef();

  const [rows, setRows] = useState();
  const [order, setOrder] = useState(null);
  const [where, setWhere] = useState({});
  const [hideChoices, setHideChoices] = useState(true);
  const [IDRowSaving, setIDRowSaving] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const [count, setCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  /* Get count */
  useEffect(() => {
    getCount(setCount);
  }, []);

  useEffect(() => {
    setRows(null);

    /* Get page data */
    getPage(currentPage, setRows, {
      order: order ? [order.column, order.order] : null,
      where,
    });
  }, [currentPage, order, where]);

  /* Update current page input */
  useEffect(() => {
    if (currentPageInput.current) {
      currentPageInput.current.value = currentPage;
    }
  }, [currentPage]);

  useEffect(() => {
    getFilteredCount(where, setCount);
  }, [where]);

  useEffect(() => {
    clearTimeout(debounceTimeoutId.current);

    debounceTimeoutId.current = setTimeout(() => {
      setCurrentPage(1);

      const searchableColumns = columns.filter(column => column.searchable);
      const textSearchableColumns = searchableColumns
        .filter(column => column.searchType === 'text')
        .map(column => ({ [column.key]: { $iRegexp: searchTerm ? searchTerm.trim() : '' } }));
      const integerSearchableColumns =
        searchTerm?.trim() && !/[^0-9]/g.test(searchTerm)
          ? searchableColumns.filter(column => column.searchType === 'integer').map(column => ({ [column.key]: searchTerm ? searchTerm.trim() : '' }))
          : [];
      const floatSearchableColumns =
        searchTerm?.trim() && !/[^0-9\.]/g.test(searchTerm)
          ? searchableColumns.filter(column => column.searchType === 'float').map(column => ({ [column.key]: searchTerm ? searchTerm.trim() : '' }))
          : [];

      setWhere({
        ...where,
        ...(searchableColumns.length > 0 ? { $or: textSearchableColumns.concat(integerSearchableColumns).concat(floatSearchableColumns) } : {}),
      });
    }, 420);
  }, [searchTerm]);

  useEffect(() => {
    if (isSaving === false) {
      setIDRowSaving(null);
    }
  }, [isSaving]);

  /* Set the sorting column and order (desc or asc) */
  const updateSorting = column => {
    if (order) {
      if (order.column === column) {
        if (order.order === 'asc') {
          setOrder({ column, order: 'desc' });
        } else if (order.order === 'desc') {
          setOrder(null);
        }
      } else {
        setOrder({ column, order: 'asc' });
      }
    } else {
      setOrder({ column, order: 'asc' });
    }
  };

  return (
    <div>
      <div className='row mb-4 align-items-end'>
        <div className={`col-12 ${showFilters ? 'col-md-6 col-lg-8' : ''} ${showDateFilter ? 'col-md-4 col-lg-6' : ''}`}>
          <input onChange={event => setSearchTerm(event.target.value.toLowerCase())} placeholder='Buscar...' type='text' className='w-100 px-2 py-1 rounded' />
        </div>
      </div>
      <div className='pb-3 pb-lg-0' style={{ overflowX: 'auto' }}>
        <table className='tuyo-table'>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  onClick={() => {
                    if (column.sortable !== false) updateSorting(column.key);
                  }}>
                  <button style={{ width: '100%', height: '100%' }} className='text-black font-weight-bold'>
                    {column.text}
                    {order &&
                      order.column === column.key &&
                      (order.order === 'asc' ? <i className='ml-1 fa fa-chevron-up'></i> : <i className='ml-1 fa fa-chevron-down'></i>)}
                  </button>
                </th>
              ))}
              {(options.showViewBtn || options.showUpdateBtn || options.showDelBtn || options.showDisableBtn) && <th></th>}
            </tr>
          </thead>
          <tbody>
            {rows?.map(row => {
              const rowId = Array.isArray(options.id) ? options.id.map(id => row[id]).join('-') : row[options.id];

              return (
                <Row
                  warning={rowWarning ? row[rowWarning.field] < rowWarning.value : null}
                  key={rowId}
                  data={row}
                  status={row.status}
                  isSaving={IDRowSaving === row[options.id]}
                  updateOrderStatus={(rowId, val) => updateOrderStatus(rowId, val)}
                  setIDRowSaving={rowId => setIDRowSaving(rowId)}
                  onChoiceChange={(rowId, val) => onChoiceChange(rowId, val)}
                  onDelete={rowId => onDelete(rowId)}
                  onUpdate={rowId => onUpdate(rowId)}
                  onView={rowId => onView(rowId)}
                  onDisable={rowId => onDisable(rowId)}
                  options={options}
                  columns={columns}
                  rowId={rowId}
                  hideChoices={hideChoices}
                  onClick={
                    onRowClick
                      ? rowId => {
                          onRowClick(rowId, row);
                          setHideChoices(hideChoices + 1);
                        }
                      : null
                  }
                />
              );
            })}
          </tbody>
        </table>
      </div>
      {rows?.length === 0 && <p className='text-center pt-4 pb-1'>No hay resultados que mostrar</p>}
      {!rows && (
        <div
          className='p-5 m-5 d-flex justify-content-center align-items-center'
          style={{
            flexGrow: '1',
          }}>
          <ClimbingBoxLoader color={colors.green} size='25' />
        </div>
      )}
      <div className='row pager mt-4 font-size-075x text-gray' style={{ opacity: '0.7' }}>
        <div className='col-12 col-md-4 text-center text-md-left'>
          Resultados {(currentPage - 1) * pageSize + 1} - {rows ? (currentPage - 1) * pageSize + rows.length : '?'} de {count || '?'}
        </div>
        <div className='col-12 col-md-8 d-flex justify-content-end mt-3 mt-md-0'>
          <button className='px-2' onClick={() => setCurrentPage(1)}>
            Primero
          </button>
          <button
            className='px-2'
            onClick={() => {
              if (currentPage !== 1) {
                setCurrentPage(currentPage - 1);
              }
            }}>
            Anterior
          </button>
          <span className='px-2 text-green'>
            Página
            <input
              className='current-page rounded'
              ref={currentPageInput}
              type='text'
              onChange={event => {
                const newPage = event.target.value.trim();
                newPage && newPage > 0 && setCurrentPage(event.target.value);
              }}
            />
            de {count ? Math.ceil(count / pageSize) : '?'}
          </span>
          <button
            className='px-2'
            onClick={() => {
              if (currentPage !== Math.ceil(count / pageSize)) {
                setCurrentPage(currentPage + 1);
              }
            }}>
            Siguiente
          </button>
          <button
            className='px-2'
            onClick={() => {
              setCurrentPage(Math.ceil(count / pageSize));
            }}>
            Último
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaginatedTable;
