import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

import Card from '../../components/Card';
import Table from '../../components/Table/Table';

import colors from '../../assets/resources/colors';
import api from '../../utils/api';

import addSVG from '../../assets/icons/add.svg';

const table = {
  options: {
    id: 'specialOffersId',
  },
  columns: [
    { text: 'ID', key: 'specialOffersId', className: 'text-center' },
    { text: '', key: 'thumbnail', isImage: true, className: 'text-center' },
    { text: 'URL', key: 'url', className: 'text-center' },
    { text: 'Título', key: 'title' },
    { text: 'Subtítulo', key: 'subtitle' },
    {
      text: 'Activo',
      key: 'active',
      className: 'text-center',
      isBoolean: true,
    },
    { text: 'Creado', key: 'createdAt', className: 'text-center' },
    { text: 'Actualizado', key: 'updatedAt', className: 'text-center' },
  ],
};

function SpecialOffersList() {
  const { REACT_APP_TITLE } = process.env;
  const history = useHistory();

  useEffect(() => {
    document.title = `Ofertas Especiales | ${REACT_APP_TITLE}`;
  }, []);

  useEffect((_) => {
    api.specialOffers
      .getAll()
      .then((res) => {
        setSpecialOffers(
          res.data
            .filter((banner) => banner.type !== 'feature')
            .map((banner) => {
              return {
                ...banner,
                createdAt: new Date(banner.createdAt).toLocaleString(),
                updatedAt: new Date(banner.updatedAt).toLocaleString(),
              };
            })
        );
      })
      .catch((err) => console.error(err));
  }, []);

  const [specialOffers, setSpecialOffers] = useState();

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Secciones Especiales
          </h3>
        </div>
        <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
          <Link to="/admin/special-offers/new" className="w-100">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold"
            >
              <img
                src={addSVG}
                alt=""
                className="d-inline-block mr-2"
                style={{ height: '16px' }}
              />
              Nueva sección especial
            </button>
          </Link>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            {specialOffers ? (
              <Table
                columns={table.columns}
                data={specialOffers}
                options={table.options}
                pageSize={10}
                onRowClick={(id) =>
                  history.push(`/admin/special-offers/${id}/edit`)
                }
              />
            ) : (
              <div
                className="p-5 m-5 d-flex justify-content-center align-items-center"
                style={{
                  flexGrow: '1',
                }}
              >
                <ClimbingBoxLoader color={colors.green} size="25" />
              </div>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default SpecialOffersList;
