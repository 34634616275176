import React from 'react';
import ReactTooltip from 'react-tooltip';
import { getOrderCode } from 'utils/misc';

const MapModal = (props) => {
  const { usersId, firstname, lastname, orders, photo } = props.data;
  return (
    <div className="text-left">
      <div className="row mb-2">
        {photo && (
          <div
            className="ml-3 p-px product-thumbnail border border-gray-200"
            style={{
              backgroundImage: `url('${photo}')`,
            }}
          ></div>
        )}
        <div className="col">
          <p>
            <span className="font-weight-bold text-green">
              ID del conductor:{' '}
            </span>
            {usersId}
          </p>
          <p>
            <span className="font-weight-bold text-green">Nombre: </span>
            {`${firstname} ${lastname}`}
          </p>
        </div>
      </div>
      {orders.length > 0 ? (
        <>
          <p>
            <span className="font-weight-bold text-green">
              Órdenes asignadas:
            </span>{' '}
            {orders?.length}
          </p>
          <div className="flex flex-col max-h-[400px] pt-2">
            <div className="overflow-auto -mx-2">
              <div className="inline-block min-w-full">
                <div className="overflow-hidden">
                  <table className="min-w-full text-center text-sm font-light">
                    <thead className="border-b font-medium dark:border-neutral-500">
                      <tr>
                        <th scope="col" className="px-2 py-2">
                          Acción
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Orden
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Comprador
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Dirección
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Comercios
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Estado
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders
                        ?.sort((x, y) =>
                          x.ordersId < y.ordersId
                            ? 1
                            : x.ordersId > y.ordersId
                            ? -1
                            : 0
                        )
                        ?.map((order) => {
                          const stores = order?.details?.map(
                            (x) => x?.store?.name
                          );
                          return (
                            <tr className="border-b dark:border-neutral-500">
                              <td className="whitespace-nowrap px-2 py-2">
                                <a
                                  href={`${window.location.origin}/admin/dashboard/${order?.ordersId}`}
                                  target="_blank"
                                  className="bg-green tuyo-btn text-sm py-1 px-1.5 rounded text-white font-weight-bold"
                                >
                                  Ver orden
                                </a>
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 font-medium d-flex justify-items-center justify-content-center">
                                {`${order?.ordersId} (TY-${getOrderCode(
                                  order?.ordersId
                                )})`}{' '}
                                {order?.isTurboShipping ? (
                                  <>
                                    <span
                                      className="ml-1 material-icons text-base text-turbo"
                                      data-tip="Envío Turbo"
                                      data-for="turbo"
                                    >
                                      pedal_bike
                                    </span>
                                    <ReactTooltip effect="solid" id="turbo" />
                                  </>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-left">{`${order?.user.firstname} ${order?.user.lastname}`}</td>
                              <td className="whitespace-nowrap px-2 py-2 text-left">
                                {[
                                  order?.apartment,
                                  order?.address,
                                  order?.city,
                                  order?.state,
                                ].join(', ')}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-left">
                                {stores
                                  ?.filter((x, i) => stores?.indexOf(x) === i)
                                  ?.join(', ')}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2">
                                {order?.orderStatus}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default MapModal;
