import React from 'react';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import api from 'utils/api';
import BooleanButton from './BooleanButton';
import SelectButton from './SelectButton';
import TextInput from './TextInput';

function UpdateModal({ product: p, categories }) {
  const { REACT_APP_COOKIES_USER_ID } = process.env;
  const [cookies, setCookie] = useCookies([REACT_APP_COOKIES_USER_ID]);

  const [product, setProduct] = useState(p);
  const [variants, setVariants] = useState(p.variants);
  const [fieldToUpdate, setFieldToUpdate] = useState(null);
  const [variantToUpdate, setVariantToUpdate] = useState([]);
  const [stockToUpdate, setStockToUpdate] = useState([]);

  const updateProduct = (value) => {
    setProduct((prev) => ({ ...prev, ...value }));
    setFieldToUpdate((prev) => ({ ...prev, ...value }));
  };

  const updateVariant = (value, id) => {
    setVariants((prev) => {
      const index = prev.findIndex((v) => v.productsVariantsId === id);
      const _prev = [...prev];
      _prev[index] = { ..._prev[index], ...value };
      return _prev;
    });

    const existing = variantToUpdate?.find((v) => v.productsVariantsId === id);
    if (existing) {
      setVariantToUpdate((prev) => {
        const index = prev.findIndex((v) => v.productsVariantsId === id);
        prev[index] = { ...prev[index], ...value };
        return prev;
      });
    } else {
      setVariantToUpdate((prev) => [
        ...prev,
        { productsVariantsId: id, ...value },
      ]);
    }
  };

  const updateStock = (value, id) => {
    setVariants((prev) => {
      const index = prev.findIndex(
        (v) => v.stock?.[0]?.productsVariantsStocksId === id
      );
      const _prev = [...prev];
      _prev[index].stock[0] = { ..._prev[index].stock[0], ...value };
      return _prev;
    });

    const existing = stockToUpdate?.find(
      (v) => v.productsVariantsStocksId === id
    );
    if (existing) {
      setStockToUpdate((prev) => {
        const index = prev.findIndex((v) => v.productsVariantsStocksId === id);
        prev[index] = { ...prev[index], ...value };
        return prev;
      });
    } else {
      setStockToUpdate((prev) => [
        ...prev,
        { productsVariantsStocksId: id, ...value },
      ]);
    }
  };

  const handleSave = () => {
    const updatedBy = +cookies[REACT_APP_COOKIES_USER_ID];

    if (fieldToUpdate?.onlyCard === true && fieldToUpdate?.onlyCash === true) {
      toast.error(
        'Solo efectivo y solo tarjeta no pueden estar activos al mismo tiempo'
      );
      return;
    }

    if (fieldToUpdate != null) {
      api.products
        .updateOne(product.productsId, {
          ...fieldToUpdate,
          updatedBy,
        })
        .then(() => toast.success('Producto actualizado'))
        .catch((err) => {
          console.error(err);
          toast.error('Error al actualizar el producto');
        });
    }

    if (variantToUpdate?.length) {
      Promise.allSettled(
        variantToUpdate.map((v) =>
          api.productVariants.updateOne(v.productsVariantsId, {
            ...v,
            updatedBy,
          })
        )
      )
        .then(() => toast.success('Variantes actualizadas'))
        .catch((err) => {
          console.error(err);
          toast.error('Error al actualizar las variantes');
        });
    }

    if (stockToUpdate?.length) {
      Promise.allSettled(
        stockToUpdate.map((v) =>
          api.productVariantStocks.updateOne(v.productsVariantsStocksId, {
            ...v,
            updatedBy,
          })
        )
      )
        .then(() => toast.success('Stock actualizado'))
        .catch((err) => {
          console.error(err);
          toast.error('Error al actualizar el stock');
        });
    }
  };

  return (
    <div className="d-flex align-items-center flex-column">
      <div className="row w-full">
        <div className="col-6 mt-2">
          <p className="text-indigo-dark text-center text-2xl font-bold mb-4">
            Producto
          </p>
          <TextInput
            value={product.name}
            onChange={updateProduct}
            field="name"
            title="Nombre"
            placeholder="Nombre del producto"
            className="mt-2"
            rows={2}
          />
          <TextInput
            value={product.regularPrice}
            onChange={updateProduct}
            field="regularPrice"
            title="Precio"
            placeholder="Precio"
            type="number"
            className="mt-2"
            area={false}
          />
          <TextInput
            value={product.preparationTime}
            onChange={updateProduct}
            valueFormat={(v) => +v / 24 / 60 / 60 / 1000}
            onChangeFormat={(v) => +v * 24 * 60 * 60 * 1000}
            step={1}
            field="preparationTime"
            title="Tiempo de preparación (dias)"
            placeholder="2"
            type="number"
            className="mt-2"
            area={false}
          />
          <TextInput
            value={product.brand}
            onChange={updateProduct}
            field="brand"
            title="Marca"
            placeholder="Marca"
            type="text"
            rows={1}
            className="mt-2"
          />
          <TextInput
            value={product.tags}
            onChange={updateProduct}
            field="tags"
            title="Tags"
            placeholder="Tags"
            className="mt-2"
            rows={3}
          />
          <TextInput
            value={product.description}
            onChange={updateProduct}
            field="description"
            title="Descripción"
            placeholder="Descripción"
            className="mt-2"
          />

          <SelectButton
            values={[
              {
                value: 'Motocicleta',
                label: 'Motocicleta',
              },
              {
                value: 'Liviano',
                label: 'Liviano',
              },
              {
                value: 'Pesado',
                label: 'Pesado',
              },
            ]}
            field="cargoType"
            title="Tipo de carga"
            className="mt-2 w-full"
            value={product.cargoType}
            onChange={updateProduct}
          />

          <SelectButton
            values={categories?.map((c) => ({
              value: +c.productsCategoriesId,
              label: c.name,
            }))}
            field="fk_categoriesId"
            title="Categoría"
            className="mt-2 w-full"
            value={product.fk_categoriesId}
            onChange={updateProduct}
          />

          <SelectButton
            values={
              categories
                .find(
                  (c) => +c.productsCategoriesId === +product.fk_categoriesId
                )
                ?.subcategories?.map((s) => ({
                  value: +s.productsSubcategoriesId,
                  label: s.name,
                })) || []
            }
            field="fk_subcategoriesId"
            title="Subcategoría"
            className="mt-2 w-full"
            value={product.fk_subcategoriesId}
            onChange={updateProduct}
          />

          <div className="row mt-2">
            <BooleanButton
              value={product.active}
              onChange={updateProduct}
              field="active"
              title="Activo"
              trueValue="1"
              falseValue="0"
              className="col-6 mt-2"
            />
            <BooleanButton
              value={product.canRefund}
              onChange={updateProduct}
              field="canRefund"
              title="Acepta devolución"
              className="col-6 mt-2"
            />
            <BooleanButton
              value={product.nationwide}
              onChange={updateProduct}
              field="nationwide"
              title="Envío a todo el país"
              className="col-6 mt-2"
            />
            <BooleanButton
              value={product.onlyCash}
              onChange={updateProduct}
              field="onlyCash"
              title="Solo efectivo"
              className="col-6 mt-2"
            />
            <BooleanButton
              value={product.onlyCard}
              onChange={updateProduct}
              field="onlyCard"
              title="Solo tarjeta"
              className="col-6 mt-2"
            />
            <BooleanButton
              value={product.warehouse}
              onChange={updateProduct}
              field="warehouse"
              title="Producto en bodega"
              className="col-6 mt-2"
            />
          </div>
        </div>
        <div className="col-6 mt-2">
          <p className="text-indigo-dark text-center text-2xl font-bold mb-4">
            Variantes
          </p>
          {variants.map((variant, i) => {
            const { image, price, productsVariantsId, stock } = variant;
            const { stock: stockValue, productsVariantsStocksId } =
              stock?.[0] || {};

            return (
              <>
                <div key={i} className="row mb-3">
                  <div className="col-12 d-flex">
                    {image ? (
                      <img
                        src={image}
                        alt="image"
                        className="img-thumbnail img-fluid rounded"
                        style={{ width: '50px', height: '50px' }}
                      />
                    ) : (
                      <div
                        className="img-thumbnail img-fluid rounded bg-light-gray"
                        style={{ width: '50px', height: '50px' }}
                      />
                    )}
                    <div className="ml-3 text-left">
                      <p className="text-lg text-indigo-dark font-semibold">
                        Atributos: {variant.attributes}
                      </p>
                      <p className="text-lg text-indigo-dark font-semibold">
                        Valores: {variant.values}
                      </p>
                    </div>
                  </div>
                  <TextInput
                    value={price}
                    onChange={(value) =>
                      updateVariant(value, productsVariantsId)
                    }
                    field="price"
                    title="Precio"
                    placeholder="Precio"
                    type="number"
                    className="col-12 mt-2"
                    area={false}
                  />
                  <TextInput
                    value={stockValue}
                    onChange={(value) =>
                      updateStock(value, productsVariantsStocksId)
                    }
                    field="stock"
                    title="Stock"
                    placeholder="Stock"
                    type="number"
                    className="col-12 mt-2"
                    area={false}
                  />
                </div>
                <hr />
              </>
            );
          })}
        </div>
        <div className="col-12 mt-4">
          <button
            className="w-100 bg-green tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-center"
            onClick={handleSave}
          >
            <span className="material-icons mx-3">save</span> Actualizar
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdateModal;
