import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import BounceLoader from 'react-spinners/BounceLoader';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import download from 'downloadjs';

import Card from 'components/Card';
import Table from 'components/TableV2/Table';

import api from 'utils/api';

const reportTypes = {
  updatedStockStores: {
    display: 'Comercios con inventario actualizado',
    fileName: 'UpdatedStockStoresReport',
    HTTPRequestMethod: 'GET',
    endpoint: '/report/updated/inventory',
    showDateSelectors: false,
    showFileTypeSelector: false,
    showStoreSelector: false,
    defaultFileType: 'csv',
  },
  settlement: {
    display: 'Liquidación',
    fileName: 'SettlementReport',
    HTTPRequestMethod: 'POST',
    endpoint: '/orderdetails/download',
    showDateSelectors: true,
    showFileTypeSelector: true,
    showStoreSelector: false,
    defaultFileType: 'csv',
  },
  sales: {
    display: 'Ventas',
    fileName: 'Sales',
    HTTPRequestMethod: 'POST',
    endpoint: '/report/sales',
    showDateSelectors: true,
    showFileTypeSelector: false,
    showStoreSelector: true,
    defaultFileType: 'csv',
  },
  lostSales: {
    display: 'Ventas perdidas',
    fileName: 'LostSales',
    HTTPRequestMethod: 'POST',
    endpoint: '/report/lost/sales',
    showDateSelectors: true,
    showFileTypeSelector: false,
    showStoreSelector: true,
    defaultFileType: 'csv',
  },
  zeroStockProducts: {
    display: 'Productos sin inventario',
    fileName: 'ZeroStockProducts',
    HTTPRequestMethod: 'POST',
    endpoint: '/report/zero/stock',
    showDateSelectors: false,
    showFileTypeSelector: false,
    showStoreSelector: true,
    defaultFileType: 'csv',
  },
};

function StoresList() {
  const history = useHistory();
  const { REACT_APP_COOKIES_USER_ID } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  const [isTogglingFreeDelivery, setIsTogglingFreeDelivery] = useState(false);
  const [tableRemountCount, setTableRemountCount] = useState(0);

  useEffect(() => {
    document.title = `Comercios | ${process.env.REACT_APP_TITLE}`;
  }, []);

  const tableProps = {
    rowId: 'storesId',
    baseURL: '/stores',
    baseFilter: {
      attributes: [
        'storesId',
        'name',
        'representative',
        'active',
        'isVerified',
        'freeDelivery',
      ],
      include: [
        { association: 'user', attributes: ['usersId', 'username', 'email'] },
        {
          association: 'stores_branches',
          attributes: ['phone'],
        },
      ],
      order: [['storesId', 'desc']],
    },
    pageSize: 10,
    columns: [
      {
        title: 'ID',
        objectProperty: 'storesId',
        searchable: true,
        searchType: 'number-equal',
        sortable: true,
      },
      {
        title: 'Nombre',
        objectProperty: 'name',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Usuario',
        objectProperty: 'user',
        middleware: (user) => user?.username,
      },
      {
        title: 'Representante',
        objectProperty: 'representative',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Verificado',
        objectProperty: 'isVerified',
        columnType: 'check',
      },
      {
        title: 'Envío gratis',
        objectProperty: 'freeDelivery',
        columnType: 'boolean',
      },
      {
        title: 'Telefono',
        objectProperty: 'stores_branches',
        middleware: (stores_branches) => stores_branches[0]?.phone,
      },
      {
        title: 'Email',
        objectProperty: 'user',
        middleware: (user) => user?.email,
      },
      {
        middleware: (store) => (
          <>
            <button
              data-tip="Eliminar"
              onClick={(event) => {
                event.stopPropagation();
                onDelete(store);
              }}
            >
              <i className="material-icons text-dark-blue">delete</i>
            </button>
            <ReactTooltip effect="solid" />
          </>
        ),
      },
      {
        middleware: (store) => (
          <>
            <button
              data-tip={store?.active ? 'Desactivar' : 'Activar'}
              onClick={(event) => {
                event.stopPropagation();
                onDisable(store);
              }}
            >
              <i className="material-icons text-dark-blue">
                {store?.active ? 'visibility' : 'visibility_off'}
              </i>
            </button>
            <ReactTooltip effect="solid" />
          </>
        ),
      },
    ],
  };

  const [reportType, setReportType] = useState('settlement');
  const [reportStartDate, setReportStartDate] = useState();
  const [reportEndDate, setReportEndDate] = useState();
  const [reportFileType, setReportFileType] = useState('pdf');
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [stores, setStores] = useState([{ storesId: null, name: '' }]);
  const [selectedStore, setSelectedStore] = useState({
    storesId: null,
    name: '',
  });

  useEffect(() => {
    setReportFileType(reportTypes[reportType].defaultFileType);
  }, [reportType]);

  // get stores list
  useEffect(() => {
    api
      .findAll(
        '/stores?filter=' +
          JSON.stringify({
            attributes: ['storesId', 'name'],
            order: [['name', 'asc']],
          })
      )
      .then((response) => {
        setStores((prevStores) => [...prevStores, ...response.data]);
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo obtener el listado de tiendas');
      });
  }, []);

  function generateReport() {
    setIsGeneratingReport(true);

    const selectedReportType = reportTypes[reportType];

    const action =
      selectedReportType.HTTPRequestMethod === 'GET'
        ? api.findAll(selectedReportType.endpoint, { responseType: 'blob' })
        : api.create(
            selectedReportType.endpoint,
            {
              startDate: reportStartDate,
              endDate: reportEndDate,
              ...(selectedReportType.showFileTypeSelector
                ? { type: reportFileType }
                : {}),
              ...(selectedReportType.showStoreSelector && selectedStore.storesId
                ? { storesId: selectedStore.storesId }
                : {}),
            },
            { responseType: 'blob' }
          );

    action
      .then((response) => {
        download(
          response.data,
          `${selectedReportType.fileName} - ${
            selectedReportType.showStoreSelector ? selectedStore.name : ''
          } - ${
            selectedReportType.showDateSelectors
              ? `${reportStartDate} to ${reportEndDate}`
              : new Date().toLocaleString()
          }.${reportFileType}`,
          response.headers['content-type']
        );
      })
      .catch((error) => {
        console.error(error);
        toast.warning('[SERVER_ERROR] ' + error);
      })
      .finally(() => {
        setIsGeneratingReport(false);
      });
  }

  const onDisable = (store) => {
    swal({
      buttons: ['Cancelar', store?.active ? 'Deshabilitar' : 'Habilitar'],
      dangerMode: true,
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">
            Deseas {store?.active ? 'deshabilitar' : 'habilitar'} este comercio
          </p>
          <p className="mt-3">Esto podría tener efectos no esperados</p>
        </div>
      ),
    }).then((res) => {
      if (res) {
        api
          .update('/stores', store?.storesId, {
            active: !store?.active,
            updatedBy: userID,
          })
          .then(() => {
            setTableRemountCount(tableRemountCount + 1);
            toast.success(
              'Comercio ' + store.active ? 'deshabilitado' : 'habilitado'
            );
          })
          .catch((error) => {
            toast.warning(error);
          });
      }
    });
  };

  function onDelete(store) {
    swal({
      buttons: ['Cancelar', 'Eliminar'],
      dangerMode: true,
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">
            ¿Deseas eliminar este comercio?
          </p>
          <p className="mt-3">Esto podría tener efectos no esperados</p>
        </div>
      ),
    }).then((res) => {
      if (res) {
        api
          .delete('/stores', store.storesId)
          .then(() => api.delete('/users', store.user.usersId))
          .then(() => {
            setTableRemountCount(tableRemountCount + 1);
            toast.success('¡Comercio eliminado!');
          })
          .catch((error) => {
            toast.warning(error);
          });
      }
    });
  }

  const activateFreeShipping = (freeShipping) => {
    setIsTogglingFreeDelivery(true);

    api.stores
      .bulkUpdate({
        bulk: true,
        freeDelivery: freeShipping,
        updatedBy: userID,
      })
      .then(() => {
        toast.success(
          `Se ${
            freeShipping ? 'activo' : 'desactivo'
          } el envio gratis a todos los comercios.`
        );
      })
      .catch((err) => {
        toast.warning(
          `[SERVER_ERROR] Hubo un problema al ${
            freeShipping ? 'activar' : 'desactivar'
          } el envio gratis a todos los comercios.`
        );
        console.error(err);
      })
      .finally(() => {
        setIsTogglingFreeDelivery(false);
      });
  };

  const selectShipping = () => {
    swal({
      buttons: {
        cancel: 'Cancelar',
        deactivate: { text: 'Desactivar', value: false },
        activate: { text: 'Activar', value: true },
      },
      dangerMode: true,
      icon: 'warning',
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">
            Envio Gratis
          </p>
          <p className="mt-3">¿Que desea hacer?</p>
        </div>
      ),
    }).then((res) => {
      res
        ? activateFreeShipping(true)
        : res == false
        ? activateFreeShipping(false)
        : swal.close();
    });
  };

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 col-md-6">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Comercios
          </h3>
        </div>
        <div className="col-12 col-md-3 d-flex">
          <button
            disabled={isTogglingFreeDelivery}
            type="button"
            className="w-100 bg-purple tuyo-btn px-3 py-2 mr-2 rounded text-light font-weight-bold d-inline-flex align-items-center justify-content-center"
            onClick={selectShipping}
          >
            {isTogglingFreeDelivery ? 'Guardando...' : 'Envío gratis'}
          </button>
        </div>
        <div className="col-12 col-md-3 d-flex">
          <button
            type="button"
            className="w-100 bg-purple tuyo-btn px-3 py-2 mr-2 rounded text-light font-weight-bold d-inline-flex align-items-center justify-content-center"
            onClick={() => {
              history.push('/admin/stores/new');
            }}
          >
            Nuevo comercio
          </button>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4 mb-4">
            <div className="row">
              <div className="col d-flex flex-column">
                <label htmlFor="report">Tipo de reporte</label>
                <select
                  className="p-2"
                  name="report"
                  id="report"
                  defaultValue={reportType}
                  onChange={(event) => {
                    setReportType(event.target.value);
                  }}
                >
                  {Object.entries(reportTypes).map(([key, value], index) => (
                    <option value={key} key={index}>
                      {value.display}
                    </option>
                  ))}
                </select>
              </div>
              {reportTypes[reportType].showFileTypeSelector && (
                <div className="d-flex flex-column justify-content-center col-1">
                  <div className="d-flex">
                    <input
                      onChange={(event) => {
                        if (event.target.value === 'on') {
                          setReportFileType('csv');
                        }
                      }}
                      type="radio"
                      name="fileType"
                      id="csv"
                      checked={reportFileType === 'csv'}
                    />
                    <label htmlFor="csv" className="ml-2">
                      .csv
                    </label>
                  </div>
                  <div className="d-flex">
                    <input
                      onChange={(event) => {
                        if (event.target.value === 'on') {
                          setReportFileType('pdf');
                        }
                      }}
                      type="radio"
                      name="fileType"
                      id="pdf"
                      checked={reportFileType === 'pdf'}
                    />
                    <label htmlFor="pdf" className="ml-2">
                      .pdf
                    </label>
                  </div>
                </div>
              )}
              {reportTypes[reportType].showStoreSelector && (
                <div className="col d-flex flex-column">
                  <label htmlFor="store">Tienda</label>
                  <select
                    name="store"
                    id="store"
                    className="h-100 rounded"
                    onChange={(event) => {
                      setSelectedStore(
                        stores.find(
                          (store) =>
                            store.storesId === parseInt(event.target.value)
                        )
                      );
                    }}
                  >
                    {stores.map((store) => (
                      <option key={store.storesId} value={store.storesId}>
                        {store.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {reportTypes[reportType].showDateSelectors && (
                <div className="col">
                  <div className="row">
                    <div className="col d-flex flex-column">
                      <label htmlFor="report">Inicio</label>
                      <input
                        className="rounded p-2"
                        type="date"
                        value={reportStartDate}
                        onChange={(event) => {
                          setReportStartDate(event.target.value);
                        }}
                      />
                    </div>
                    <div className="col d-flex flex-column">
                      <label htmlFor="report">Final</label>
                      <input
                        className="rounded p-2"
                        type="date"
                        value={reportEndDate}
                        onChange={(event) => {
                          setReportEndDate(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <button
              onClick={generateReport}
              disabled={
                isGeneratingReport ||
                (reportTypes[reportType].showDateSelectors &&
                  (!reportStartDate || !reportEndDate))
              }
              className="w-100 bg-purple tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around mt-2"
            >
              Generar
              <BounceLoader
                color="#fff"
                loading={isGeneratingReport}
                size="18"
              />
            </button>
          </Card>
          <Card className="p-md-4">
            <Table
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
              onRowClick={(store) => {
                history.push(`/admin/stores/${store.storesId}/edit`);
              }}
              remountCount={tableRemountCount}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default StoresList;
