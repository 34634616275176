import React, { useEffect, useState } from 'react';

import { BounceLoader } from 'react-spinners';
import Pagination from './Pagination';
import TableDateSelector from './TableDateSelector';
import api from 'utils/api';
import axios from 'axios';
import { toast } from 'react-toastify';

function PaginatedTable(props) {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filterWhere, setFilterWhere] = useState(props.baseFilter.where || {});
  const [cleanReload, setCleanReload] = useState(true);
  const [filterOrder, setFilterOrder] = useState(props.baseFilter.order);

  useEffect(() => {
    if (props.remountCount > 0) {
      setCleanReload(props.cleanReload);
      setFilterWhere({ ...filterWhere });
    }
  }, [props.remountCount]);

  // Request data for each page
  useEffect(() => {
    if (cleanReload) setRows(null);

    const { pageSize } = props;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const hasMany = filterWhere.hasMany || [];

    const _baseFilter = {
      ...props.baseFilter,
    };

    const _filterWhere = {
      ...filterWhere,
    };
    delete _filterWhere.hasMany;

    _baseFilter.include = _baseFilter?.include?.map((inc) => {
      if (typeof inc.separate === 'boolean') {
        inc.separate = !hasMany.includes(inc.association);
      }

      return inc;
    });

    const params = [
      'paginate=true',
      ...(page ? [`page=${page - 1}`] : []),
      ...(pageSize ? [`limit=${pageSize}`] : []),
      `filter=${JSON.stringify({
        ..._baseFilter,
        where: _filterWhere,
        order: filterOrder,
      })}`,
    ];

    api
      .findAll(`${props.baseURL}?${params.join('&')}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        const rows = data.vouchers || data.products || data.items;
        const { totalPages, totalItems } = data;

        setRows(rows);
        setTotalRows(totalItems);
        setTotalPages(totalPages);
      })
      .catch((error) => {
        setRows([]);
        if (error.message !== 'Not necessary anymore') {
          console.error(error);
          toast.warning('No se pudo obtener la página');
        }
      })
      .finally(() => {
        setCleanReload(false);
      });

    return () => {
      source.cancel('Not necessary anymore');
    };
  }, [page, filterWhere, filterOrder]);

  // Go to first page when search or sort change
  useEffect(() => {
    setPage(1);
  }, [filterWhere, filterOrder]);

  function updateWhereText(field, newValue, isNumber, hasMany) {
    const currentValue = filterWhere[field];
    const _hasMany = filterWhere.hasMany || [];

    if (newValue) {
      if (
        !currentValue ||
        (isNumber ? newValue : encodeURI('%' + newValue + '%')) !==
          (isNumber ? currentValue : currentValue['$iLike'])
      ) {
        setFilterWhere({
          ...filterWhere,
          hasMany: hasMany ? [..._hasMany, hasMany] : _hasMany,
          [field]: isNumber
            ? newValue
            : {
                $iLike: encodeURI('%' + newValue + '%'),
              },
        });
      }
    } else {
      if (currentValue) {
        const tmpWhere = { ...filterWhere };
        delete tmpWhere[field];

        if (hasMany) {
          const index = _hasMany.indexOf(hasMany);
          if (index > -1) _hasMany.splice(index, 1);
        }

        setFilterWhere({ ...tmpWhere, hasMany: _hasMany });
      }
    }
  }

  function updateWhereRange(field, newValue, operator, isDate) {
    if (newValue) {
      setFilterWhere({
        ...filterWhere,
        [field]: {
          ...filterWhere[field],
          [operator]: newValue + (isDate ? '-06:00' : ''),
        },
      });
    } else {
      const tmpWhere = { ...filterWhere };

      if (tmpWhere[field] && tmpWhere[field][operator]) {
        delete tmpWhere[field][operator];

        if (Object.keys(tmpWhere[field]).length < 1) {
          delete tmpWhere[field];
        }

        setFilterWhere(tmpWhere);
      }
    }
  }

  function updateDateRange(field, startDate, endDate, selected) {
    if (selected) {
      setFilterWhere((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          $between: [startDate, endDate],
        },
      }));
    } else {
      const tmpWhere = { ...filterWhere };
      delete tmpWhere[field];
      setFilterWhere(tmpWhere);
    }
  }

  function searchFilter(column) {
    const { searchType } = column;
    let filter = null;

    switch (searchType) {
      case 'text':
      case 'number-equal':
        filter = (
          <input
            className="w-100 rounded"
            type="text"
            onKeyPress={(event) => {
              const trimmedValue = event.target.value.trim();

              if (event.key === 'Enter') {
                updateWhereText(
                  column.gatewayObjectProperty || column.objectProperty,
                  column.searchMiddleware
                    ? column.searchMiddleware(trimmedValue)
                    : trimmedValue,
                  column.searchType === 'number-equal',
                  column.hasMany
                );
              }
            }}
            onBlur={(event) => {
              const trimmedValue = event.target.value.trim();

              updateWhereText(
                column.gatewayObjectProperty || column.objectProperty,
                column.searchMiddleware
                  ? column.searchMiddleware(trimmedValue)
                  : trimmedValue,
                column.searchType === 'number-equal',
                column.hasMany
              );
            }}
          />
        );
        break;

      case 'timestamp':
      case 'number':
        filter = (
          <div
            className={
              column.searchType === 'number'
                ? 'd-flex justify-content-center w-100'
                : ''
            }
          >
            <input
              type={
                column.searchType === 'timestamp' ? 'datetime-local' : 'number'
              }
              style={column.searchType === 'number' ? { width: '4rem' } : {}}
              className={
                column.searchType === 'number' ? 'mr-2' : '' + 'rounded'
              }
              onBlur={(event) => {
                updateWhereRange(
                  column.objectProperty,
                  event.target.value,
                  '$gte',
                  column.searchType === 'timestamp'
                );
              }}
            />
            <input
              type={
                column.searchType === 'timestamp' ? 'datetime-local' : 'number'
              }
              className="rounded"
              style={column.searchType === 'number' ? { width: '4rem' } : {}}
              onChange={(event) => {
                updateWhereRange(
                  column.objectProperty,
                  event.target.value,
                  '$lte',
                  column.searchType === 'timestamp'
                );
              }}
            />
          </div>
        );
        break;

      case 'date':
        filter = (
          <>
            <TableDateSelector
              onChange={(selection) => {
                updateDateRange(
                  column.objectProperty,
                  selection.startDate,
                  selection.endDate,
                  selection.selected
                );
              }}
            />
          </>
        );
        break;

      case 'select':
        filter = (
          <select
            className="w-100 rounded-0 py-0 px-1"
            style={{ height: 27 }}
            onChange={(event) => {
              updateWhereText(
                column.objectProperty,
                event.target.value,
                column.selectType === 'number',
                column.hasMany
              );
            }}
          >
            <option value="" disabled>
              Seleccione una opción
            </option>
            {column.searchOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
        break;

      default:
        break;
    }

    return filter;
  }

  return (
    <div style={{ overflowX: 'scroll' }} className="w-100">
      <Pagination
        page={page}
        pageSize={props.pageSize}
        totalRows={totalRows}
        totalPages={totalPages}
        setPage={setPage}
      />
      <table style={{ fontSize: '0.875rem', minWidth: '100%' }}>
        <thead className="bg-green-mint">
          <tr>
            {props.columns.map((column, index) => (
              <th
                key={`${column.objectProperty}-${index}`}
                className={`px-2 pt-2 ${column.columnClass ?? ''}`}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p className="flex-grow-1 text-center">{column.title}</p>
                  {column.sortable && (
                    <button
                      onClick={() => {
                        const [orderBy, orderMode] = filterOrder[0];
                        const newOrderBy =
                          column.gatewayObjectProperty || column.objectProperty;

                        if (orderBy === newOrderBy) {
                          if (orderMode === 'asc') {
                            setFilterOrder([[newOrderBy, 'desc']]);
                          } else {
                            setFilterOrder(props.baseFilter.order);
                          }
                        } else {
                          setFilterOrder([[newOrderBy, 'asc']]);
                        }
                      }}
                    >
                      {(column.gatewayObjectProperty ||
                        column.objectProperty) === filterOrder[0][0] ? (
                        filterOrder[0][1] === 'asc' ? (
                          <span className="material-icons-round">
                            keyboard_arrow_up
                          </span>
                        ) : (
                          <span className="material-icons-round">
                            keyboard_arrow_down
                          </span>
                        )
                      ) : (
                        <span className="material-icons-round">
                          unfold_more
                        </span>
                      )}
                    </button>
                  )}
                </div>
              </th>
            ))}
          </tr>
          <tr>
            {props.columns.map((column, index) =>
              column.searchable ? (
                <th
                  key={`${column.objectProperty}-${index}`}
                  className={`p-2 ${column.columnClass ?? ''}`}
                >
                  {searchFilter(column)}
                </th>
              ) : (
                <th
                  key={`${column.objectProperty}-${index}`}
                  className={`${column.columnClass ?? ''}`}
                />
              )
            )}
          </tr>
        </thead>
        <tbody>
          {rows ? (
            rows.length > 0 ? (
              rows.map((row, index) => (
                <tr
                  key={row[props.rowId]}
                  style={{
                    backgroundColor: index % 2 !== 0 ? 'rgba(0,0,0,0.07)' : '',
                    cursor: props.onRowClick ? 'pointer' : '',
                  }}
                  onClick={() => {
                    if (props.onRowClick) {
                      props.onRowClick(row);
                    }
                  }}
                >
                  {props.columns.map((column, index) => {
                    let value = column.objectMiddleware
                      ? column.objectMiddleware(
                          column.objectProperty
                            ? row[column?.objectProperty]
                            : row
                        )
                      : column.useAllObject
                      ? row
                      : column?.objectProperty
                      ? row[column.objectProperty]
                      : row;

                    return (
                      <td
                        className={`p-2 ${column.className || ''}`}
                        key={`${column.objectProperty}-${index}`}
                        style={{
                          maxWidth: (1 / props.columns.length) * 100 + '%',
                          minWidth: '10px',
                        }}
                      >
                        {column.middleware ? (
                          column.middleware(value, row)
                        ) : column.columnType === 'check' ? (
                          <i
                            style={{
                              color: value ? 'var(--tuyo-green)' : 'gray',
                            }}
                            className="material-icons"
                          >
                            verified
                          </i>
                        ) : column.columnType === 'boolean' ? (
                          <i
                            style={{
                              color: value ? 'var(--tuyo-green)' : 'gray',
                            }}
                            className="material-icons"
                          >
                            check_circle
                          </i>
                        ) : column.objectProperty ? (
                          value
                        ) : (
                          JSON.stringify(value)
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={props.columns.length}
                  className="p-2 text-center"
                  style={{
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                  }}
                >
                  {props.noDataMessage || 'No se encontraron datos'}
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td
                colSpan={props.columns.length}
                className="p-2 text-center w-full "
                style={{
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                }}
              >
                <div className="margin-auto my-4 d-flex justify-content-center">
                  <BounceLoader color="#01d781" size="26" />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        page={page}
        pageSize={props.pageSize}
        totalRows={totalRows}
        totalPages={totalPages}
        setPage={setPage}
      />
    </div>
  );
}

export default PaginatedTable;
