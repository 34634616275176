import React from 'react';
import { NavLink } from 'react-router-dom';

function ChildItem({ icon, label, path, onClick, isActive, className, flexGrow }) {
  return <NavLink
    style={{ flexGrow }}
    className={`position-relative select-none ${className ?? ''}`}
    to={path}
    onClick={event => {
      event.stopPropagation();
      onClick && onClick();
    }}>
    <div className={'transition-all item child w-100 ' + (isActive ? 'active' : '')}>
      <div className='transition-all label'>
        <i className="material-icons mx-3">{icon}</i>
        <p>{label}</p>
      </div>
    </div>
  </NavLink>
};

export default ChildItem;
